import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  Avatar,
  Button,
  DataTable,
  Flex,
  HStack,
  Modal,
  Spinner,
  TableHeader,
  TableRow,
  Text,
  Toast,
  useToast,
  Wrap,
} from '@rhythm/components'

import { GetAllPatientsProps } from '../../../../../features/patients/api'
import { useGetSetting } from '../../../../../features/settings/useGetSetting'
import { useTransmissionReportsListContext } from '../../../../../features/transmissionReports'
import { useSignAllGreenReports } from '../../../../../features/transmissionReports/api/sendSignAllGreenReports'
import {
  queryClient,
  TransmissionReportDto,
  TransmissionReportDtoColorEnum,
  TransmissionReportDtoSignatureStatusEnum,
} from '../../../../../lib/api'
import formatDate from '../../../../../utils/formatDate'
import SignAllPopUp from '../Popup/SignAllPopUp'

export interface SignAllRoutineModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  searchTerm?: string
  isLoading?: boolean
  queryData: GetAllPatientsProps
  setQueryData: (params: GetAllPatientsProps) => void
}

const SignAllRoutineModal = ({
  isOpen,
  onClose,
  onConfirm,
  searchTerm,
  isLoading,
  queryData,
  setQueryData,
}: SignAllRoutineModalProps): React.ReactElement => {
  const memoizedIsNew = useMemo(() => queryData?.isNew, [queryData?.isNew])
  const memoizedIsRecentlySigned = useMemo(
    () => queryData?.isRecentSigned,
    [queryData?.isRecentSigned],
  )
  const DEFAULT_PAGE_SIZE = 10
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DEFAULT_PAGE_SIZE)
  const [sortBy, setSortBy] = useState<string | undefined>(undefined)
  const [sortDirection, setSortDirection] = useState<string | undefined>(
    undefined,
  )
  const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false)
  const { mutateAsync: uploadPdf, isLoading: isUploadPdfLoading } =
    useSignAllGreenReports()

  const { data: signAllSetting, isLoading: settingLoading } = useGetSetting({
    settingName: 'signAllWithSqs',
  })

  const { data: transmissionReportsData } = useTransmissionReportsListContext()
  const tableData = useMemo(() => {
    if (isLoading || !transmissionReportsData) {
      return []
    }

    return transmissionReportsData.transmissionReports.filter(
      t =>
        t.color === TransmissionReportDtoColorEnum.Green &&
        t.signatureStatus ===
          TransmissionReportDtoSignatureStatusEnum.NonPractitionerSigned,
    )
  }, [transmissionReportsData, isLoading])

  let greenId: any = []
  for (let dfg = 0; dfg < tableData.length; dfg++) {
    greenId.push(tableData[dfg].id)
  }

  const fetchData = useCallback(
    ({
      search: searchParam,
      offset,
      limit,
      sortBy,
      sortDirection,
    }: GetAllPatientsProps) => {
      const searchQuery = searchParam || searchTerm
      const updatedQueryData: GetAllPatientsProps = {
        isNew: memoizedIsNew,
        isRecentSigned: memoizedIsRecentlySigned,
      }

      if (searchQuery && searchQuery.length >= 3) {
        updatedQueryData.search = searchQuery
      }
      updatedQueryData.offset = offset
      updatedQueryData.limit = limit

      if (sortBy !== undefined && sortDirection !== undefined) {
        updatedQueryData.sortBy = sortBy
        updatedQueryData.sortDirection = sortDirection ? 'DESC' : 'ASC'
      }

      setQueryData(updatedQueryData)
    },
    [searchTerm, memoizedIsNew, memoizedIsRecentlySigned, setQueryData],
  )
  const getData = (data: any): void => {
    if (offset !== data.pageIndex) {
      setOffset(data.pageIndex * data.pageSize)
    }
    if (limit !== data.pageSize) {
      setLimit(data.pageSize)
    }
    const sort = data.sortBy && data.sortBy.length ? data.sortBy[0] : null
    if (sort) {
      const { desc, id } = sort
      if (sortBy !== id || sortDirection !== desc) {
        setSortBy(id)
        setSortDirection(desc)
      }
    } else {
      if (sortBy !== undefined || sortDirection !== undefined) {
        setSortBy(undefined)
        setSortDirection(undefined)
      }
    }
  }

  useEffect(() => {
    fetchData({ search: searchTerm, offset, limit, sortBy, sortDirection })
  }, [searchTerm, offset, limit, sortBy, sortDirection, fetchData])

  const PatientCell = ({
    row: { original },
  }: {
    row: { original: TransmissionReportDto }
  }): React.ReactElement => {
    const fullName = `${original.patient?.givenName} ${original.patient?.familyName}`

    const handleChange = () => {
      if (greenId.includes(original?.id)) {
        greenId = greenId.filter((ids: number) => ids !== original?.id)
      } else {
        greenId.push(original?.id)
      }
    }

    return (
      <>
        <Flex alignItems="center">
          <div style={{ paddingRight: 10 }}>
            <input
              type="checkbox"
              defaultChecked={true}
              onChange={handleChange}
            />
          </div>
          <Avatar
            name={fullName}
            borderWidth="0.5px"
            borderColor="neutral.600"
            boxSize="lg"
            mr="lg"
            size="sm"
          />
          <Flex direction="column">
            <Text mb="xs" fontWeight="bold" color="neutral.black">
              {fullName}
            </Text>
          </Flex>
        </Flex>
      </>
    )
  }

  const DateOfBirthCell = ({
    row: { original },
  }: {
    row: { original: TransmissionReportDto }
  }): React.ReactElement => {
    const birthDate = original.patient?.birthDate

    return (
      <Text variant="secondary">{formatDate(birthDate, 'MM/DD/YYYY')}</Text>
    )
  }

  const ClinicCell = ({
    row: { original },
  }: {
    row: { original: TransmissionReportDto }
  }): React.ReactElement => {
    const clinic = original.patient?.clinic.name ?? '-'
    return <Text fontWeight="bold">{clinic}</Text>
  }

  const TypeCell = ({
    row: { original },
  }: {
    row: { original: TransmissionReportDto }
  }): React.ReactElement => {
    let matchDevices = original?.device?.deviceType

    if (matchDevices === 'no_device_type') {
      matchDevices = ''
    }
    const devices: string[] = []
    if (matchDevices && matchDevices.length > 0) {
      devices.push(matchDevices ? matchDevices : '-')
    }
    return (
      <>
        {devices.length > 0
          ? devices.map(e => (
              <>
                <Text key={e} fontWeight="bold">
                  {e}
                </Text>
                <br />
              </>
            ))
          : '-'}
      </>
    )
  }

  const DeviceTypeCell = ({
    row: { original },
  }: {
    row: { original: TransmissionReportDto }
  }): React.ReactElement => {
    const matchDevic: any = original.deviceVendor?.vendorName
    const venderCell: any[] = []

    if (matchDevic && matchDevic.length > 0) {
      venderCell.push(matchDevic ? matchDevic : '-')
    }

    return (
      <>
        {venderCell.length > 0
          ? venderCell.map((e: any) => (
              <>
                <Text key={e} fontWeight="bold">
                  {e}
                </Text>
              </>
            ))
          : '-'}
      </>
    )
  }

  const signAllTableColumns = [
    { Header: 'Patient', accessor: '25', Cell: PatientCell },
    {
      Header: 'DOB',
      accessor: 'birthDate',
      Cell: DateOfBirthCell,
    },
    {
      Header: 'Clinic Name',
      accessor: 'name',
      Cell: ClinicCell,
    },
    {
      Header: 'Device Type',
      accessor: 'type',
      Cell: TypeCell,
    },
    {
      Header: 'Vendor',
      accessor: 'vendor',
      Cell: DeviceTypeCell,
      width: 200,
    },
  ]
  let isBottom

  const submitBtn = useRef(null)
  const handleScroll = (e: any) => {
    e.preventDefault()
    if (
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight ||
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1
    ) {
      isBottom = true
    } else {
      isBottom = false
    }
    ;(submitBtn.current as any).disabled = !isBottom
    return 'good'
  }

  const toast = useToast()
  const showSignAllRoutineReportsSuccessToast = () => {
    toast({
      position: 'bottom',
      render: () => (
        <Toast variant="success">
          Hurray! The Sign All request has been successfully completed. All
          reports have been signed.
        </Toast>
      ),
    })
  }
  const showSignAllRoutineReportsRetryToast = () => {
    toast({
      position: 'bottom',
      render: () => (
        <Toast variant="warning">
          Your sign-all request is already being processed. Please wait a few
          minutes before trying again.
        </Toast>
      ),
    })
  }
  const showSignAllRoutineReportsFailedToast = () => {
    toast({
      position: 'bottom',
      render: () => (
        <Toast variant="error">
          Your sign-all request has failed. Please try again in a few
          minutes{' '}
        </Toast>
      ),
    })
  }

  const onSignAllRoutineReports = async () => {
    try {
      const response = await uploadPdf({
        transmissionReportSignAllParams: { signAll: greenId.toString() },
      })
      if (signAllSetting?.settingValue === 'true') {
        if (response.data === 'Success') {
          setPopupIsOpen(true)
        } else {
          showSignAllRoutineReportsRetryToast()
        }
      } else {
        showSignAllRoutineReportsSuccessToast()
        queryClient.invalidateQueries('TransmissionReportsCountByColor')
      }
    } catch (err) {
      showSignAllRoutineReportsFailedToast() // Show failure toast
    }

    onConfirm()
  }

  return (
    <>
      <Wrap onScrollCapture={handleScroll}>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          width="80%"
          footer={
            <HStack spacing="xl">
              <Button variant="secondaryDark" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="button"
                ref={submitBtn}
                disabled={isLoading || !tableData.length}
                onClick={onSignAllRoutineReports}
                minWidth="100px"
              >
                {isUploadPdfLoading ? <Spinner size="sm" /> : 'Sign All'}
              </Button>
            </HStack>
          }
        >
          <DataTable
            isSortable
            isSearchable
            isClickable
            columns={signAllTableColumns}
            fetchData={(data: any) => getData(data)}
            data={tableData as Record<string, any>[]}
            isLoading={isLoading}
            searchTerm={searchTerm}
            initialPageSize={DEFAULT_PAGE_SIZE}
            selectedRowBackgroundColor="primary.100"
            selectedRowBorderColor="primary.400"
            borderCollapse="separate"
            borderSpacing="0 2px"
            tableHeadCell={
              <TableHeader
                color="neutral.800"
                bg="neutral.white"
                borderBottomColor="neutral.200"
                borderBottomStyle="solid"
                borderBottomWidth="2px"
                px="2xl"
                py="xl"
              />
            }
            tableBodyRow={row => (
              <TableRow
                borderBottomColor="neutral.200"
                borderBottomStyle="solid"
                borderBottomWidth="2px"
                bg="neutral.white"
                sx={{
                  _hover: {
                    '#patients-list__action-menu': {
                      display: 'block',
                    },
                    cursor: 'pointer',
                    background: 'var(--chakra-colors-primary-100)',
                  },
                  '&:first-of-type': {
                    borderTopWidth: 'none',
                    '> td': {
                      '&:first-of-type': {
                        borderBottomLeftRadius: 'md',
                      },
                      '&:last-of-type': {
                        borderBottomRightRadius: 'md',
                      },
                    },
                  },
                  '&:not(:first-of-type) > td': {
                    '&:first-of-type': {
                      borderTopLeftRadius: 'md',
                      borderBottomLeftRadius: 'md',
                    },
                    '&:last-of-type': {
                      borderTopRightRadius: 'md',
                      borderBottomRightRadius: 'md',
                    },
                  },
                  '&:last-of-type': {
                    borderBottomWidth: 'none',
                  },
                }}
              ></TableRow>
            )}
          />
        </Modal>
      </Wrap>
      {popupIsOpen && (
        <SignAllPopUp
          isOpen={popupIsOpen}
          onClose={() => setPopupIsOpen(false)}
        />
      )}
    </>
  )
}

export default SignAllRoutineModal
