import { useState } from 'react'

import { Box, Select } from '@rhythm/components'

import { VtDeviceLead } from '../../../../../lib/api/generated-client'
import { DeviceLeadLocation } from '../../../../../types'

import { VendorOptions } from './DeviceDemographicsCard'
import { DeviceLeadEmptyState } from './DeviceLeadEmptyState'

export interface DeviceLeadVendorProps {
  deviceLeads?: VtDeviceLead[]
  leadVendorsOptions: VendorOptions[]
  isDisabled?: boolean
  onChange: (value: any) => void
}

export const DeviceLeadVendor = ({
  deviceLeads,
  leadVendorsOptions,
  isDisabled = false,
  onChange,
}: DeviceLeadVendorProps) => {
  const [selectedValues, setSelectedValues] = useState<
    Record<string, VendorOptions | null>
  >({})

  const renderDeviceLeadVendorSelect = (deviceLead: VtDeviceLead) => {
    const leadId = deviceLead.leadId ?? ''
    const defaultValue = deviceLead.vendorId
      ? (leadVendorsOptions.find(
          option => option.value === Number(deviceLead.vendorId),
        ) ?? null)
      : null

    const selectedValue = selectedValues[leadId] ?? defaultValue

    const handleVendorChange = (newValue: unknown) => {
      if (
        newValue !== null &&
        typeof newValue === 'object' &&
        newValue &&
        'value' in newValue
      ) {
        const selectedOption = newValue as VendorOptions
        setSelectedValues(prev => ({
          ...prev,
          [leadId]: selectedOption,
        }))
        onChange({
          name: 'deviceLeads.vendorId',
          value: selectedOption.value ?? null,
          leadId: deviceLead.leadId ?? null,
        })
      }
    }

    return (
      <Box
        py="xl"
        pr="2xl"
        key={`deviceLeads.vendor-${leadId}`}
        borderBottomWidth="1px"
        borderBottomColor="neutral.200"
      >
        <Select
          value={selectedValue}
          options={leadVendorsOptions}
          isDisabled={isDisabled}
          onChange={handleVendorChange}
        />
      </Box>
    )
  }

  return (
    <>
      {Object.keys(DeviceLeadLocation).map((location, index) => {
        // Defensive null check for deviceLeads
        const deviceLead = deviceLeads?.find(
          lead => lead.leadLocation === location,
        )

        return deviceLead ? (
          renderDeviceLeadVendorSelect(deviceLead)
        ) : (
          <DeviceLeadEmptyState key={`deviceLeads.vendor-empty-${index}`} />
        )
      })}
    </>
  )
}
