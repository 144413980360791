import React, { useCallback, useEffect, useState } from 'react'

import { Icon } from '@rhythm/components'
import { debounce } from 'lodash'
import { useQuery } from 'react-query'
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  OptionProps,
} from 'react-select'

import { useApiContext } from '../../../../../../context/ApiContext'
import { LabelAndValue } from '../../../../../../lib/api'
import { DefaultMultiSelectStyles } from '../../../../../../utils/multiSelectOptions'

function highlightSearchTerm(text?: string, keyword?: string): React.ReactNode {
  if (!text || !keyword) return text

  const lowerKeyword = keyword.toLowerCase()
  const index = text.toLowerCase().indexOf(lowerKeyword)

  if (index === -1) return text

  const before = text.slice(0, index)
  const match = text.slice(index, index + keyword.length)
  const after = text.slice(index + keyword.length)

  return (
    <span>
      {before}
      <b>{match}</b>
      {after}
    </span>
  )
}

const HighlightedOption = (props: OptionProps<LabelAndValue, false>) => {
  const inputValue = props.selectProps.inputValue
  return (
    <components.Option {...props}>
      {highlightSearchTerm(props.data.label, inputValue)}
    </components.Option>
  )
}

const CustomDropdownIndicator = (
  props: DropdownIndicatorProps<LabelAndValue, false>,
) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon={'drop-down'} boxSize={'sm'} />
    </components.DropdownIndicator>
  )
}

interface DiagnosisSelectProps {
  value: LabelAndValue | null
  handleChange: (option: LabelAndValue | null) => void
  isUpdating?: boolean
}

const DiagnosisSelect: React.FC<DiagnosisSelectProps> = ({
  value,
  handleChange,
  isUpdating,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')

  const Api = useApiContext()

  const debouncedSetSearchTerm = useCallback(
    debounce((input: string) => setSearchTerm(input), 300),
    [],
  )

  useEffect(() => {
    return () => {
      debouncedSetSearchTerm.cancel()
    }
  }, [debouncedSetSearchTerm])

  const {
    data: icdCodes = [],
    isLoading: icdCodesLoading,
    error,
    refetch,
  } = useQuery<LabelAndValue[]>(
    ['getIcdDiagnosisCodes', searchTerm],
    async () => {
      const response = await Api.icdDiagnosisControllerGetIcdCodes({
        query: searchTerm,
      })

      // Initialize with a default "empty" option
      const codes = [{ label: '-', value: '' }]
      const seenCodes = new Set<string>()

      // Include the currently selected value in the dropdown, if any
      if (value?.value) {
        codes.push(value)
        seenCodes.add(value.value)
      }

      // Add codes from API response
      response?.data.forEach(({ code, description }) => {
        if (!seenCodes.has(code)) {
          codes.push({ label: `${code} - ${description}`, value: code })
          seenCodes.add(code)
        }
      })

      return codes
    },
  )

  useEffect(() => {
    if (value && !icdCodes.find(c => c.value === value.value)) {
      refetch()
    }
  }, [refetch, value])

  const noOptionsMessage = useCallback(() => {
    if (error) {
      return 'Unable to find the ICD codes'
    }
    return searchTerm ? 'No results found' : 'No Diagnosis Codes Found'
  }, [error, searchTerm])

  return (
    <ReactSelect
      isMulti={false}
      onChange={option => {
        if (option) handleChange(option)
      }}
      isDisabled={isUpdating}
      value={value}
      options={icdCodes}
      isLoading={icdCodesLoading}
      onInputChange={inputValue => debouncedSetSearchTerm(inputValue)}
      placeholder="Select Diagnosis"
      noOptionsMessage={noOptionsMessage}
      isSearchable={true}
      styles={DefaultMultiSelectStyles<LabelAndValue, false>()}
      components={{
        Option: HighlightedOption,
        DropdownIndicator: CustomDropdownIndicator,
      }}
    />
  )
}

export default DiagnosisSelect
