/* tslint:disable */
/* eslint-disable */
/**
 * Synergy API
 * Synergy API docs
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setBearerAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
// @ts-ignore
import {
  BASE_PATH,
  RequestArgs,
  BaseAPI,
} from './base'
import { string } from 'yup/lib/locale'


/**
 *
 * @export
 * @interface AccountOverviewFilter
 */
export interface AccountOverviewFilter {
  /**
   *
   * @type {string}
   * @memberof AccountOverviewFilter
   */
  'field': AccountOverviewFilterFieldEnum;
  /**
   *
   * @type {string}
   * @memberof AccountOverviewFilter
   */
  'operator': AccountOverviewFilterOperatorEnum;
  /**
   *
   * @type {Array<object>}
   * @memberof AccountOverviewFilter
   */
  'arguments': Array<object>;
}

/**
  * @export
  * @enum {string}
  */
export enum AccountOverviewFilterFieldEnum {
  AccountName = 'accountName',
  PatientName = 'patientName',
  DateOfBirth = 'dateOfBirth',
  EhrMrn = 'ehrMrn',
  Diagnosis = 'diagnosis',
  Vendor = 'vendor',
  DeviceType = 'deviceType',
  ModelName = 'modelName',
  SerialNumber = 'serialNumber',
  PatientStatus = 'patientStatus',
  PatientStatusReason = 'patientStatusReason',
  FollowingPractitioner = 'followingPractitioner',
  ReferringProvider = 'referringProvider'
}
/**
  * @export
  * @enum {string}
  */
export enum AccountOverviewFilterOperatorEnum {
  Between = 'between',
  In = 'in',
  Equals = 'equals',
  GreaterThan = 'greater_than',
  LessThan = 'less_than'
}

/**
*
* @export
* @interface AccountOverviewQuery
*/
export interface AccountOverviewQuery {
  /**
   *
   * @type {Array<AccountOverviewFilter>}
   * @memberof AccountOverviewQuery
   */
  'filters'?: Array<AccountOverviewFilter>;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountOverviewQuery
   */
  'columns'?: Array<AccountOverviewQueryColumnsEnum>;
  /**
   *
   * @type {object}
   * @memberof AccountOverviewQuery
   */
  'orderBy'?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountOverviewQuery
   */
  'dateRange': Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountOverviewQuery
   */
  'clinicIds': Array<string>;
  /**
   *
   * @type {number}
   * @memberof AccountOverviewQuery
   */
  'limit'?: number;
  /**
   *
   * @type {number}
   * @memberof AccountOverviewQuery
   */
  'offset'?: number;
  /**
   *
   * @type {boolean}
   * @memberof AccountOverviewQuery
   */
  'fetchAll'?: boolean;
}

/**
  * @export
  * @enum {string}
  */
export enum AccountOverviewQueryColumnsEnum {
  AccountName = 'accountName',
  PatientName = 'patientName',
  DateOfBirth = 'dateOfBirth',
  EhrMrn = 'ehrMrn',
  Diagnosis = 'diagnosis',
  Vendor = 'vendor',
  DeviceType = 'deviceType',
  ModelName = 'modelName',
  SerialNumber = 'serialNumber',
  PatientStatus = 'patientStatus',
  PatientStatusReason = 'patientStatusReason',
  FollowingPractitioner = 'followingPractitioner',
  ReferringProvider = 'referringProvider'
}

/**
*
* @export
* @interface AccountOverviewResponse
*/
export interface AccountOverviewResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof AccountOverviewResponse
   */
  'data': Array<object>;
  /**
   *
   * @type {object}
   * @memberof AccountOverviewResponse
   */
  'pagination': object;
}

/**
 *
 * @export
 * @interface AlertsFilter
 */
export interface AlertsFilter {
  /**
   *
   * @type {string}
   * @memberof AlertsFilter
   */
  'field': AlertsFilterFieldEnum;
  /**
   *
   * @type {string}
   * @memberof AlertsFilter
   */
  'operator': AlertsFilterOperatorEnum;
  /**
   *
   * @type {Array<object>}
   * @memberof AlertsFilter
   */
  'arguments': Array<object>;
}

/**
  * @export
  * @enum {string}
  */
export enum AlertsFilterFieldEnum {
  AccountName = 'accountName',
  AlertType = 'alertType',
  DateOfService = 'dateOfService',
  SignedDate = 'signedDate',
  PatientName = 'patientName',
  DateOfBirth = 'dateOfBirth',
  EhrMrn = 'ehrMrn',
  Diagnosis = 'diagnosis',
  Vendor = 'vendor',
  DeviceType = 'deviceType',
  ModelName = 'modelName',
  SerialNumber = 'serialNumber',
  PatientStatus = 'patientStatus',
  PatientStatusReason = 'patientStatusReason',
  FollowingPractitioner = 'followingPractitioner',
  ReferringProvider = 'referringProvider'
}
/**
  * @export
  * @enum {string}
  */
export enum AlertsFilterOperatorEnum {
  Between = 'between',
  In = 'in',
  Equals = 'equals',
  GreaterThan = 'greater_than',
  LessThan = 'less_than'
}

/**
*
* @export
* @interface AlertsQuery
*/
export interface AlertsQuery {
  /**
   *
   * @type {Array<AlertsFilter>}
   * @memberof AlertsQuery
   */
  'filters'?: Array<AlertsFilter>;
  /**
   *
   * @type {Array<string>}
   * @memberof AlertsQuery
   */
  'columns'?: Array<AlertsQueryColumnsEnum>;
  /**
   *
   * @type {object}
   * @memberof AlertsQuery
   */
  'orderBy'?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof AlertsQuery
   */
  'dateRange': Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof AlertsQuery
   */
  'clinicIds': Array<string>;
  /**
   *
   * @type {number}
   * @memberof AlertsQuery
   */
  'limit'?: number;
  /**
   *
   * @type {number}
   * @memberof AlertsQuery
   */
  'offset'?: number;
  /**
   *
   * @type {boolean}
   * @memberof AlertsQuery
   */
  'fetchAll'?: boolean;
}


/**
  * @export
  * @enum {string}
  */
export enum AlertsQueryColumnsEnum {
  AccountName = 'accountName',
  AlertType = 'alertType',
  DateOfService = 'dateOfService',
  SignedDate = 'signedDate',
  PatientName = 'patientName',
  DateOfBirth = 'dateOfBirth',
  EhrMrn = 'ehrMrn',
  Diagnosis = 'diagnosis',
  Vendor = 'vendor',
  DeviceType = 'deviceType',
  ModelName = 'modelName',
  SerialNumber = 'serialNumber',
  PatientStatus = 'patientStatus',
  PatientStatusReason = 'patientStatusReason',
  FollowingPractitioner = 'followingPractitioner',
  ReferringProvider = 'referringProvider'
}

/**
*
* @export
* @interface AlertsResponse
*/
export interface AlertsResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof AlertsResponse
   */
  'data': Array<object>;
  /**
   *
   * @type {object}
   * @memberof AlertsResponse
   */
  'pagination': object;
}

/**
 *
 * @export
 * @interface BillableFilters
 */
export interface BillableFilters {
  /**
   *
   * @type {string}
   * @memberof BillableFilters
   */
  'field': BillableFiltersFieldEnum;
  /**
   *
   * @type {string}
   * @memberof BillableFilters
   */
  'operator': BillableFiltersOperatorEnum;
  /**
   *
   * @type {Array<object>}
   * @memberof BillableFilters
   */
  'arguments': Array<object>;
}

/**
  * @export
  * @enum {string}
  */
export enum BillableFiltersFieldEnum {
  AccountName = 'accountName',
  PatientName = 'patientName',
  DateOfBirth = 'dateOfBirth',
  EhrMrn = 'ehrMrn',
  Diagnosis = 'diagnosis',
  Vendor = 'vendor',
  DeviceType = 'deviceType',
  ModelName = 'modelName',
  SerialNumber = 'serialNumber',
  ReportType = 'reportType',
  DateOfService = 'dateOfService',
  SigningDate = 'signingDate',
  NextScheduledDate = 'nextScheduledDate',
  FollowingPractitioner = 'followingPractitioner',
  ReferringProvider = 'referringProvider',
  ProfessionalCptCode = 'professionalCptCode',
  TechnicalCptCode = 'technicalCptCode',
  SigningPractitioner = 'signingPractitioner'
}
/**
  * @export
  * @enum {string}
  */
export enum BillableFiltersOperatorEnum {
  Between = 'between',
  In = 'in',
  Equals = 'equals',
  GreaterThan = 'greater_than',
  LessThan = 'less_than'
}

/**
*
* @export
* @interface BillableQuery
*/
export interface BillableQuery {
  /**
   *
   * @type {Array<BillableFilters>}
   * @memberof BillableQuery
   */
  'filters'?: Array<BillableFilters>;
  /**
   *
   * @type {Array<string>}
   * @memberof BillableQuery
   */
  'columns'?: Array<BillableQueryColumnsEnum>;
  /**
   *
   * @type {object}
   * @memberof BillableQuery
   */
  'orderBy'?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof BillableQuery
   */
  'dateRange': Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BillableQuery
   */
  'clinicIds': Array<string>;
  /**
   *
   * @type {number}
   * @memberof BillableQuery
   */
  'limit'?: number;
  /**
   *
   * @type {number}
   * @memberof BillableQuery
   */
  'offset'?: number;
  /**
   *
   * @type {boolean}
   * @memberof BillableQuery
   */
  'fetchAll'?: boolean;
}

/**
  * @export
  * @enum {string}
  */
export enum BillableQueryColumnsEnum {
  AccountName = 'accountName',
  PatientName = 'patientName',
  DateOfBirth = 'dateOfBirth',
  EhrMrn = 'ehrMrn',
  Diagnosis = 'diagnosis',
  Vendor = 'vendor',
  DeviceType = 'deviceType',
  ModelName = 'modelName',
  SerialNumber = 'serialNumber',
  ReportType = 'reportType',
  DateOfService = 'dateOfService',
  SigningDate = 'signingDate',
  NextScheduledDate = 'nextScheduledDate',
  FollowingPractitioner = 'followingPractitioner',
  ReferringProvider = 'referringProvider',
  ProfessionalCptCode = 'professionalCptCode',
  TechnicalCptCode = 'technicalCptCode',
  SigningPractitioner = 'signingPractitioner'
}

/**
*
* @export
* @interface BillableResponse
*/
export interface BillableResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof BillableResponse
   */
  'data': Array<object>;
  /**
   *
   * @type {object}
   * @memberof BillableResponse
   */
  'pagination': object;
}

/**
 *
 * @export
 * @interface SchedulingFilters
 */
export interface SchedulingFilters {
  /**
   *
   * @type {string}
   * @memberof SchedulingFilters
   */
  'field': SchedulingFiltersFieldEnum;
  /**
   *
   * @type {string}
   * @memberof SchedulingFilters
   */
  'operator': SchedulingFiltersOperatorEnum;
  /**
   *
   * @type {Array<object>}
   * @memberof SchedulingFilters
   */
  'arguments': Array<object>;
}

/**
  * @export
  * @enum {string}
  */
export enum SchedulingFiltersFieldEnum {
  AccountName = 'accountName',
  PatientName = 'patientName',
  DateOfBirth = 'dateOfBirth',
  Mrn = 'mrn',
  Diagnosis = 'diagnosis',
  Vendor = 'vendor',
  DeviceType = 'deviceType',
  ModelName = 'modelName',
  SerialNumber = 'serialNumber',
  NextScheduledDate = 'nextScheduledDate',
  ScheduleType = 'scheduleType'
}
/**
  * @export
  * @enum {string}
  */
export enum SchedulingFiltersOperatorEnum {
  Between = 'between',
  In = 'in',
  Equals = 'equals',
  GreaterThan = 'greater_than',
  LessThan = 'less_than'
}

/**
*
* @export
* @interface SchedulingQuery
*/
export interface SchedulingQuery {
  /**
   *
   * @type {Array<SchedulingFilters>}
   * @memberof SchedulingQuery
   */
  'filters'?: Array<SchedulingFilters>;
  /**
   *
   * @type {Array<string>}
   * @memberof SchedulingQuery
   */
  'columns'?: Array<SchedulingQueryColumnsEnum>;
  /**
   *
   * @type {object}
   * @memberof SchedulingQuery
   */
  'orderBy'?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof SchedulingQuery
   */
  'dateRange': Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SchedulingQuery
   */
  'clinicIds': Array<string>;
  /**
   *
   * @type {number}
   * @memberof SchedulingQuery
   */
  'limit'?: number;
  /**
   *
   * @type {number}
   * @memberof SchedulingQuery
   */
  'offset'?: number;
  /**
   *
   * @type {boolean}
   * @memberof SchedulingQuery
   */
  'fetchAll'?: boolean;
}

/**
  * @export
  * @enum {string}
  */
export enum SchedulingQueryColumnsEnum {
  AccountName = 'accountName',
  PatientName = 'patientName',
  DateOfBirth = 'dateOfBirth',
  Mrn = 'mrn',
  Diagnosis = 'diagnosis',
  Vendor = 'vendor',
  DeviceType = 'deviceType',
  ModelName = 'modelName',
  SerialNumber = 'serialNumber',
  NextScheduledDate = 'nextScheduledDate',
  ScheduleType = 'scheduleType'
}

/**
*
* @export
* @interface SchedulingResponse
*/
export interface SchedulingResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof SchedulingResponse
   */
  'data': Array<object>;
  /**
   *
   * @type {object}
   * @memberof SchedulingResponse
   */
  'pagination': object;
}

/**
 *
 * @export
 * @interface SignatureStatusFilters
 */
export interface SignatureStatusFilters {
  /**
   *
   * @type {string}
   * @memberof SignatureStatusFilters
   */
  'field': SignatureStatusFiltersFieldEnum;
  /**
   *
   * @type {string}
   * @memberof SignatureStatusFilters
   */
  'operator': SignatureStatusFiltersOperatorEnum;
  /**
   *
   * @type {Array<object>}
   * @memberof SignatureStatusFilters
   */
  'arguments': Array<object>;
}

/**
  * @export
  * @enum {string}
  */
export enum SignatureStatusFiltersFieldEnum {
  FollowingPractitioner = 'followingPractitioner',
  RedSigned = 'redSigned',
  RedAwaitingSignature = 'redAwaitingSignature',
  YellowSigned = 'yellowSigned',
  YellowAwaitingSignature = 'yellowAwaitingSignature',
  GreenSigned = 'greenSigned',
  GreenAwaitingSignature = 'greenAwaitingSignature'
}
/**
  * @export
  * @enum {string}
  */
export enum SignatureStatusFiltersOperatorEnum {
  Between = 'between',
  In = 'in',
  Equals = 'equals',
  GreaterThan = 'greater_than',
  LessThan = 'less_than'
}

/**
*
* @export
* @interface SignatureStatusQuery
*/
export interface SignatureStatusQuery {
  /**
   *
   * @type {Array<SignatureStatusFilters>}
   * @memberof SignatureStatusQuery
   */
  'filters'?: Array<SignatureStatusFilters>;
  /**
   *
   * @type {Array<string>}
   * @memberof SignatureStatusQuery
   */
  'columns'?: Array<SignatureStatusQueryColumnsEnum>;
  /**
   *
   * @type {object}
   * @memberof SignatureStatusQuery
   */
  'orderBy'?: object;
  /**
   *
   * @type {Array<string>}
   * @memberof SignatureStatusQuery
   */
  'dateRange': Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SignatureStatusQuery
   */
  'clinicIds': Array<string>;
  /**
   *
   * @type {number}
   * @memberof SignatureStatusQuery
   */
  'limit'?: number;
  /**
   *
   * @type {number}
   * @memberof SignatureStatusQuery
   */
  'offset'?: number;
  /**
   *
   * @type {boolean}
   * @memberof SignatureStatusQuery
   */
  'fetchAll'?: boolean;
}

/**
  * @export
  * @enum {string}
  */
export enum SignatureStatusQueryColumnsEnum {
  FollowingPractitioner = 'followingPractitioner',
  RedSigned = 'redSigned',
  RedAwaitingSignature = 'redAwaitingSignature',
  YellowSigned = 'yellowSigned',
  YellowAwaitingSignature = 'yellowAwaitingSignature',
  GreenSigned = 'greenSigned',
  GreenAwaitingSignature = 'greenAwaitingSignature'
}

/**
*
* @export
* @interface SignatureStatusResponse
*/
export interface SignatureStatusResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof SignatureStatusResponse
   */
  'data': Array<object>;
  /**
   *
   * @type {object}
   * @memberof SignatureStatusResponse
   */
  'pagination': object;
}

/**
 *
 * @export
 * @interface GetPractitionersQuery
 */
export interface GetPractitionersQuery {
  /**
   *
   * @type {Array<string>}
   * @memberof GetPractitionersQuery
   */
  'clinicIds': Array<string>;
}

/**
 *
 * @export
 * @interface GetPractitionersResponse
 */
export interface GetPractitionersResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof GetPractitionersResponse
   */
  'data': Array<object>;
}

/**
 *
 * @export
 * @interface DownloadReportResponse
 */
export interface DownloadReportResponse {
  /**
   *
   * @type {string}
   * @memberof DownloadReportResponse
   */
  'downloadUrl': string;
}

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  line1: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  line2: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  district: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  state: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  postalCode: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  country: string
}

/**
 *
 * @export
 * @interface AddressParams
 */
export interface AddressParams {
  /**
   *
   * @type {string}
   * @memberof AddressParams
   */
  line1?: string
  /**
   *
   * @type {string}
   * @memberof AddressParams
   */
  line2?: string
  /**
   *
   * @type {string}
   * @memberof AddressParams
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof AddressParams
   */
  district?: string
  /**
   *
   * @type {string}
   * @memberof AddressParams
   */
  state?: string
  /**
   *
   * @type {string}
   * @memberof AddressParams
   */
  postalCode?: string
  /**
   *
   * @type {string}
   * @memberof AddressParams
   */
  country?: string
}

/**
 *
 * @export
 * @interface ApplyOrReplaceTemplateRequest
 */
export interface ApplyOrReplaceTemplateRequest {
  /**
   *
   * @type {string}
   * @memberof ApplyOrReplaceTemplateRequest
   */
  'templateId': string;
  /**
   *
   * @type {number}
   * @memberof ApplyOrReplaceTemplateRequest
   */
  'reportId': number;
}
/**
*
* @export
* @interface ApplyOrRemoveTemplateResponse
*/
export interface ApplyOrRemoveTemplateResponse {
  /**
   *
   * @type {TemplateObject}
   * @memberof ApplyOrRemoveTemplateResponse
   */
  'template': TemplateObject;
}
export interface FetchAllTaxonomyCodesResponse {
  /**
   *
   * @type {Array<NuccTaxonomy>}
   * @memberof FetchAllTaxonomyCodesResponse
   */
  'taxonomyCodes': Array<NuccTaxonomy>;
}
/**
 *
 * @export
 * @interface NuccTaxonomy
 */
export interface NuccTaxonomy {
    /**
     *
     * @type {string}
     * @memberof NuccTaxonomy
     */
    'nucc_code': string;
    /**
     *
     * @type {string}
     * @memberof NuccTaxonomy
     */
    'grouping': string;
    /**
     *
     * @type {string}
     * @memberof NuccTaxonomy
     */
    'classification': string;
    /**
     *
     * @type {string}
     * @memberof NuccTaxonomy
     */
    'specialization': string;
    /**
     *
     * @type {string}
     * @memberof NuccTaxonomy
     */
    'display_name': string;
}

/**
 *
 * @export
 * @interface RegisteredNpis
 */
export interface RegisteredNpis {
  /**
   *
   * @type {string}
   * @memberof RegisteredNpis
   */
  'NPI': string;
  /**
   *
   * @type {string}
   * @memberof RegisteredNpis
   */
  'firstName': string;
  /**
   *
   * @type {string}
   * @memberof RegisteredNpis
   */
  'lastName': string;
  /**
   *
   * @type {string}
   * @memberof RegisteredNpis
   */
  'credential': string;
  /**
   *
   * @type {string}
   * @memberof RegisteredNpis
   */
  'altFirstName': string;
  /**
   *
   * @type {string}
   * @memberof RegisteredNpis
   */
  'altLastName': string;
  /**
   *
   * @type {string}
   * @memberof RegisteredNpis
   */
  'addressLine1': string;
  /**
   *
   * @type {string}
   * @memberof RegisteredNpis
   */
  'addressLine2': string;
  /**
   *
   * @type {string}
   * @memberof RegisteredNpis
   */
  'city': string;
  /**
   *
   * @type {string}
   * @memberof RegisteredNpis
   */
  'state': string;
  /**
   *
   * @type {string}
   * @memberof RegisteredNpis
   */
  'zipCode': string;
  /**
   *
   * @type {string}
   * @memberof RegisteredNpis
   */
  'phoneNumber': string;
  /**
   *
   * @type {string}
   * @memberof RegisteredNpis
   */
  'faxNumber': string;
  /**
   *
   * @type {NuccTaxonomy}
   * @memberof RegisteredNpis
   */
  'taxonomyCode': NuccTaxonomy;
  /**
   *
   * @type {boolean}
   * @memberof RegisteredNpis
   */
  'isDeactivated': boolean;
}

/**
*
* @export
* @interface ApplyOrRemoveTemplateResponse
*/
export interface ReplaceTemplateResponse {
  /**
   *
   * @type {TemplateObject}
   * @memberof ReplaceTemplateResponse
   */
  'removed': TemplateObject;

  /**
   *
   * @type {TemplateObject}
   * @memberof ReplaceTemplateResponse
   */
  'applied': TemplateObject;
}

/**
 *
 * @export
 * @interface GetTemplatesResponseDto
 */
export interface GetTemplatesResponseDto {
  /**
   *
   * @type {Array<ReportTemplate>}
   * @memberof GetTemplatesResponseDto
   */
  'templates': Array<ReportTemplate>;
}
/**
 *
 * @export
 * @interface BadRequestExample
 */
export interface BadRequestExample {
  /**
   *
   * @type {number}
   * @memberof BadRequestExample
   */
  status: number
  /**
   *
   * @type {object}
   * @memberof BadRequestExample
   */
  details: object
  /**
   *
   * @type {number}
   * @memberof BadRequestExample
   */
  responseType: number
}

/**
 *
 * @export
 * @interface UpdateBillingDto
 */
export interface UpdateBillingDto {
  /**
   *
   * @type {number}
   * @memberof UpdateBillingDto
   */
  transmissionReportId: number

  diagnosisCodes?: Array<{
    value: string
    isPatientDiagnosisCode: boolean
  }>

  technicalCode?: {
    value: string
  }


  professionalCodes?: Array<{
    value: string
  }>

  billingDate?: string | null

  isCounterResettable?: boolean

  nextRemoteDate?: string | null

  nextInClinicDate?: string | null
}

/**
 *
 * @export
 * @interface Billing
 */
export interface Billing {
  id: string
  createdAt: Date
  updatedAt: Date
  updatedBy: string
  version: number
  transmissionReport: TransmissionReport | null
  isBillable: boolean
  isAppDeterminedBillable: boolean
  billingDate: Date | null
  technicalCode: string
  appDeterminedReason: string
  diagnosisCodes: BillingDiagnosisCode[]
  professionalCodes: BillingProfessionalCode[]
  isCounterResettable: boolean
  nextRemoteDate: Date | null
  nextInClinicDate: Date | null
}

export interface BillingDiagnosisCode {
  id: string
  value: string
  isPatientDiagnosisCode: boolean
  billing: Billing
}

export interface BillingProfessionalCode {
  id: string
  value: string
  billing: Billing
}

/**
 *
 * @export
 * @interface BillingObject
 */
export interface BillingObject {
  /**
   *
   * @type {boolean}
   * @memberof BillingObject
   */
  billable?: boolean | null
  /**
   *
   * @type {object}
   * @memberof BillingObject
   */
  professional_code?: object | null
  /**
   *
   * @type {string}
   * @memberof BillingObject
   */
  billing_date?: string | null
  /**
   *
   * @type {string}
   * @memberof BillingObject
   */
  app_determined_billable?: string | null
  /**
   *
   * @type {string}
   * @memberof BillingObject
   */
  app_determined_reason?: string | null
  /**
   *
   * @type {string}
   * @memberof BillingObject
   */
  technical_code?: string | null
  /**
   *
   * @type {object}
   * @memberof BillingObject
   */
  diagnosis_code?: object | null
}

/**
 *
 * @export
 * @interface BasicResponse
 */
export interface BasicResponse {
  /**
   *
   * @type {boolean}
   * @memberof BasicResponse
   */
  'success': boolean;
  /**
   *
   * @type {string}
   * @memberof BasicResponse
   */
  'message': string;
  /**
   *
   * @type {string}
   * @memberof BasicResponse
   */
  'error'?: string;
}

/**
 *
 * @export
 * @interface TransmissionReportAttachment
 */
export interface TransmissionReportAttachment {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAttachment
   */
  'id': string;
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAttachment
   */
  'createdAt': string;
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAttachment
   */
  'updatedAt': string;
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAttachment
   */
  'originalName': string;
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAttachment
   */
  'fileName': string;
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAttachment
   */
  'path': string;
  /**
   *
   * @type {TransmissionReport}
   * @memberof TransmissionReportAttachment
   */
  'transmissionReport': TransmissionReport;
}

/**
 *
 * @export
 * @interface GetTransmissionAttachmentsResponse
 */
export interface GetTransmissionAttachmentsResponse {
  /**
   *
   * @type {Array<TransmissionReportAttachment>}
   * @memberof GetTransmissionAttachmentsResponse
   */
  'data': Array<TransmissionReportAttachment>;
  /**
   *
   * @type {number}
   * @memberof GetTransmissionAttachmentsResponse
   */
  'total': number;
}

/**
 *
 * @export
 * @interface ConflictErrorExample
 */
export interface ConflictErrorExample {
  /**
   *
   * @type {number}
   * @memberof ConflictErrorExample
   */
  status: number
  /**
   *
   * @type {object}
   * @memberof ConflictErrorExample
   */
  details: object
  /**
   *
   * @type {number}
   * @memberof ConflictErrorExample
   */
  responseType: number
}

/**
 *
 * @export
 * @interface Counters
 */
export interface Counters {
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  Id: string
  /**
   *
   * @type {TransmissionReport}
   * @memberof Counters
   */
  transmissionReport: TransmissionReport | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  symptomCounters: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  symptomTable: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  tachyCounters: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  tachyTime: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  pauseCounters: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  pauseTime: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  bradyCounters: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  bradyTime: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  ATCounters: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  ATTime: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  AFCounters: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  AFTime: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  TimeInATAFCounters: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  TimeInATAFTime: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  createdAt: string | null
  /**
   *
   * @type {string}
   * @memberof Counters
   */
  updatedAt: string | null
}

/**
 *
 * @export
 * @interface CountersObject
 */
export interface CountersObject {
  /**
   *
   * @type {number}
   * @memberof CountersObject
   */
  transmissionId?: number
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  symptomCounters?: string
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  symptomTable?: string
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  tachyCounters?: string
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  tachyTime?: string
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  pauseCounters?: string
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  pauseTime?: string
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  bradyCounters?: string
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  bradyTime?: string
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  ATCounters?: string
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  ATTime?: string
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  AFCounters?: string
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  AFTime?: string
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  TimeInATAFCounters?: string
  /**
   *
   * @type {string}
   * @memberof CountersObject
   */
  TimeInATAFTime?: string
}

/**
 * Request parameters for globalFilterControllerCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiGlobalFilterControllerCreateRequest
 */
export interface DefaultApiGlobalFilterControllerCreateRequest {
  /**
   *
   * @type {CreateGlobalFilterParams}
   * @memberof DefaultApiGlobalFilterControllerCreate
   */
  readonly createGlobalFilterParams: CreateGlobalFilterParams
}

/**
 * Request parameters for globalFilterControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiGlobalFilterControllerUpdateRequest
 */
export interface DefaultApiGlobalFilterControllerUpdateRequest {
  /**
   *
   * @type {UpdateGlobalFilterParams}
   * @memberof DefaultApiGlobalFilterControllerUpdate
   */
  readonly updateGlobalFilterParams: UpdateGlobalFilterParams
}
/**
 *
 * @export
 * @interface UpdateSettingsDTO
 */
export interface UpdateSettingsDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateSettingsDTO
   */
  'id': string;
  /**
   *
   * @type {number}
   * @memberof UpdateSettingsDTO
   */
  'setting': number;
}
/**
 * Request parameters for featureFlagControllerUpdateMany operation in DefaultApi.
 * @export
 * @interface DefaultApiFeatureFlagControllerUpdateManyRequest
 */
export interface DefaultApiFeatureFlagControllerUpdateManyRequest {
  /**
   *
   * @type {UpdateSettingsDTO}
   * @memberof DefaultApiFeatureFlagControllerUpdateMany
   */
  readonly updateSettingsDTO: UpdateSettingsDTO
}
/**
 *
 * @export
 * @interface UpdateFeatureFlagDto
 */
export interface UpdateFeatureFlagDto {
  /**
   *
   * @type {string}
   * @memberof UpdateFeatureFlagDto
   */
  'displayName'?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFeatureFlagDto
   */
  'displaySummary'?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFeatureFlagDto
   */
  'shortCode'?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateFeatureFlagDto
   */
  'setting'?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateFeatureFlagDto
   */
  'extendedSetting'?: string;
}
/**
 *
 * @export
 * @interface FlagsMapDTO
 */
export interface FlagsMapDTO {
  /**
   *
   * @type {string}
   * @memberof FlagsMapDTO
   */
  'shortCode': string;
  /**
   *
   * @type {number}
   * @memberof FlagsMapDTO
   */
  'setting': number;
}
/**
 *
 * @export
 * @interface GetAllFlagsResponse
 */
export interface GetAllFlagsResponse {
  /**
   *
   * @type {Array<FlagsMapDTO>}
   * @memberof GetAllFlagsResponse
   */
  'data': Array<FlagsMapDTO>;
}
/**
 *
 * @export
 * @interface GetFeatureFlagsResponse
 */
export interface GetFeatureFlagsResponse {
  /**
   *
   * @type {Array<FeatureFlag>}
   * @memberof GetFeatureFlagsResponse
   */
  'data': Array<FeatureFlag>;
  /**
   *
   * @type {number}
   * @memberof GetFeatureFlagsResponse
   */
  'total': number;
}
/**
 *
 * @export
 * @interface FeatureFlag
 */
export interface FeatureFlag {
  updated_by?: string | null
  /**
   *
   * @type {string}
   * @memberof FeatureFlag
   */
  'id': string;
  /**
   *
   * @type {string}
   * @memberof FeatureFlag
   */
  'displayName': string;
  /**
   *
   * @type {string}
   * @memberof FeatureFlag
   */
  'displaySummary': string;
  /**
   *
   * @type {string}
   * @memberof FeatureFlag
   */
  'shortCode': string;
  /**
   *
   * @type {number}
   * @memberof FeatureFlag
   */
  'setting': number;
  /**
   *
   * @type {string}
   * @memberof FeatureFlag
   */
  'extendedSetting'?: string;
  /**
   *
   * @type {string}
   * @memberof FeatureFlag
   */
  'createdAt': string;
  /**
   *
   * @type {string}
   * @memberof FeatureFlag
   */
  'updatedAt': string;
  /**
   *
   * @type {string}
   * @memberof FeatureFlag
   */
  'createdBy': string;
  /**
   *
   * @type {string}
   * @memberof FeatureFlag
   */
  'updatedBy': string;
}
/**
 *
 * @export
 * @interface CreateFeatureFlagDto
 */
export interface CreateFeatureFlagDto {
  /**
   *
   * @type {string}
   * @memberof CreateFeatureFlagDto
   */
  'displayName': string;
  /**
   *
   * @type {string}
   * @memberof CreateFeatureFlagDto
   */
  'displaySummary': string;
  /**
   *
   * @type {string}
   * @memberof CreateFeatureFlagDto
   */
  'shortCode': string;
  /**
   *
   * @type {number}
   * @memberof CreateFeatureFlagDto
   */
  'setting': number;
  /**
   *
   * @type {string}
   * @memberof CreateFeatureFlagDto
   */
  'extendedSetting'?: string;
}
/**
 *
 * @export
 * @interface CreateGlobalFilterParams
 */
export interface CreateGlobalFilterParams {
  /**
   *
   * @type {string}
   * @memberof CreateGlobalFilterParams
   */
  userId: string
  /**
   *
   * @type {Array<string>}
   * @memberof CreateGlobalFilterParams
   */
  organizationIds: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof CreateGlobalFilterParams
   */
  deviceTypes: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof CreateGlobalFilterParams
   */
  practitionerIds: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof CreateGlobalFilterParams
   */
  vendors: Array<string>
}

/**
 *
 * @export
 * @interface CreateGlobalFilterResponse
 */
export interface CreateGlobalFilterResponse {
  /**
   *
   * @type {string}
   * @memberof CreateGlobalFilterResponse
   */
  id: string
  /**
   *
   * @type {User}
   * @memberof CreateGlobalFilterResponse
   */
  user: User
  /**
   *
   * @type {Array<Organization>}
   * @memberof CreateGlobalFilterResponse
   */
  organizations: Array<Organization>
  /**
   *
   * @type {Array<string>}
   * @memberof CreateGlobalFilterResponse
   */
  vendors: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof CreateGlobalFilterResponse
   */
  deviceTypes: Array<string>
  /**
   *
   * @type {string}
   * @memberof CreateGlobalFilterResponse
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof CreateGlobalFilterResponse
   */
  updatedAt: string
}

/**
 *
 * @export
 * @interface GlobalFilter
 */
export interface GlobalFilter {
  /**
   *
   * @type {string}
   * @memberof GlobalFilter
   */
  id: string
  /**
   *
   * @type {User}
   * @memberof GlobalFilter
   */
  user: User
  /**
   *
   * @type {Array<Organization>}
   * @memberof GlobalFilter
   */
  organizations: Array<Organization>
  /**
   *
   * @type {Array<string>}
   * @memberof GlobalFilter
   */
  vendors: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof GlobalFilter
   */
  deviceTypes: Array<string>
  /**
   *
   * @type {string}
   * @memberof GlobalFilter
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof GlobalFilter
   */
  updatedAt: string
}

/**
 *
 * @export
 * @interface UpdateGlobalFilterParams
 */
export interface UpdateGlobalFilterParams {
  /**
   *
   * @type {string}
   * @memberof UpdateGlobalFilterParams
   */
  userId: string
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateGlobalFilterParams
   */
  organizationIds: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateGlobalFilterParams
   */
  deviceTypes: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof CreateGlobalFilterParams
   */
  practitionerIds: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateGlobalFilterParams
   */

  vendors: Array<string>
}

/**
 *
 * @export
 * @interface IcdDiagnosisCode
 */
export interface IcdDiagnosisCode {
  /**
   *
   * @type {string}
   * @memberof IcdDiagnosisCode
   */
  'createdAt': string;
  /**
   *
   * @type {string}
   * @memberof IcdDiagnosisCode
   */
  'updatedAt': string;
  /**
   *
   * @type {string}
   * @memberof IcdDiagnosisCode
   */
  'code': string;
  /**
   *
   * @type {string}
   * @memberof IcdDiagnosisCode
   */
  'description': string;
}

/**
 *
 * @export
 * @interface InclinicReporttempPdfResponse
 */
export interface InclinicReporttempPdfResponse {
  /**
   *
   * @type {Array<object>}
   * @memberof InclinicReporttempPdfResponse
   */
  'status': Array<object>;
}

/**
 *
 * @export
 * @interface UpdateGlobalFilterResponse
 */
export interface UpdateGlobalFilterResponse {
  /**
   *
   * @type {string}
   * @memberof UpdateGlobalFilterResponse
   */
  id: string
  /**
   *
   * @type {User}
   * @memberof UpdateGlobalFilterResponse
   */
  user: User
  /**
   *
   * @type {Array<Organization>}
   * @memberof UpdateGlobalFilterResponse
   */
  organizations: Array<Organization>
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateGlobalFilterResponse
   */
  vendors: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateGlobalFilterResponse
   */
  deviceTypes: Array<string>
  /**
   *
   * @type {string}
   * @memberof UpdateGlobalFilterResponse
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof UpdateGlobalFilterResponse
   */
  updatedAt: string
}

/**
 *
 * @export
 * @interface FindIsBillableTransmissionReportsResponse
 */
export interface FindIsBillableTransmissionReportsResponse {
  /**
   *
   * @type {Array<IsBillableTransmissionReports>}
   * @memberof FindIsBillableTransmissionReportsResponse
   */
  'isBillableData': Array<IsBillableTransmissionReports>;
}

export interface FindOneGlobalFilterResponse {
  /**
   *
   * @type {string}
   * @memberof FindOneGlobalFilterResponse
   */
  filterId: string | null
  /**
   *
   * @type {User}
   * @memberof FindOneGlobalFilterResponse
   */
  user: User
  /**
   *
   * @type {Array<Organization>}
   * @memberof FindOneGlobalFilterResponse
   */
  filteredClinics: Array<Organization>
  /**
   *
   * @type {Array<Organization>}
   * @memberof FindOneGlobalFilterResponse
   */
  clinics: Array<Organization>
  /**
   *
   * @type {Array<string>}
   * @memberof FindOneGlobalFilterResponse
   */
  deviceTypes: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof FindOneGlobalFilterResponse
   */
  vendors: Array<string>
  /**
   *
   * @type {Array<Organization>}
   * @memberof FindOneGlobalFilterResponse
   */
  filteredPractitioners: Array<Organization>
  /**
   *
   * @type {Array<Organization>}
   * @memberof FindOneGlobalFilterResponse
   */
  practitioners: Array<Organization>
}

/**
 *
 * @export
 * @interface CreateOrganizationParams
 */
export interface CreateOrganizationParams {
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationParams
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationParams
   */
  parentId?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationParams
   */
  mrn2?: string
  /**
   *
   * @type {AddressParams}
   * @memberof CreateOrganizationParams
   */
  address?: AddressParams
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationParams
   */
  phoneNumber?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationParams
   */
  faxNumber?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationParams
   */
  notes?: string
  /**
   *
   * @type {boolean}
   * @memberof CreateOrganizationParams
   */
  hasEmrAccess?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateOrganizationParams
   */
  ehrIntegration?: boolean
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationParams
   */
  emrName?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationParams
   */
  sentToEhr?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationParams
   */
  goLiveOn?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationParams
   */
  onboardingOn?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationParams
   */
  internalHuddleOn?: string
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationParams
   */
  kickoffHuddleOn?: string
  /**
   *
   * @type {Array<string>}
   * @memberof CreateOrganizationParams
   */
  fileIds?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof CreateOrganizationParams
   */
  isSignAll?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateOrganizationParams
   */
  isFollowingPractitionerReq?: boolean
}

/**
 *
 * @export
 * @interface CreateOrganizationResponse
 */
export interface CreateOrganizationResponse {
  /**
   *
   * @type {Organization}
   * @memberof CreateOrganizationResponse
   */
  organization: Organization
}

/**
 *
 * @export
 * @interface CreatePatientAddressParams
 */
export interface CreatePatientAddressParams {
  /**
   *
   * @type {AddressParams}
   * @memberof CreatePatientAddressParams
   */
  address: AddressParams
}

/**
 *
 * @export
 * @interface CreatePatientMatchDeviceParams
 */
export interface CreatePatientMatchDeviceParams {
  /**
   *
   * @type {Array<MatchDeviceParams>}
   * @memberof CreatePatientMatchDeviceParams
   */
  devices: Array<MatchDeviceParams>
}

/**
 *
 * @export
 * @interface CreatePatientParams
 */
export interface CreatePatientParams {
  /**
   *
   * @type {PatientSex}
   * @memberof CreatePatientParams
   */
  sex?: PatientSex
  /**
   *
   * @type {PatientStatus}
   * @memberof CreatePatientParams
   */
  status: PatientStatus
  /**
   *
   * @type {string}
   * @memberof CreatePatientParams
   */
  mrn1?: string
  /**
   *
   * @type {string}
   * @memberof CreatePatientParams
   */
  familyName: string
  /**
   *
   * @type {string}
   * @memberof CreatePatientParams
   */
  givenName: string
  /**
   *
   * @type {string}
   * @memberof CreatePatientParams
   */
  birthDate: string
  /**
   *
   * @type {string}
   * @memberof CreatePatientParams
   */
  mrn2?: string
  /**
   *
   * @type {string}
   * @memberof CreatePatientParams
   */
  ssn?: string
  /**
   *
   * @type {string}
   * @memberof CreatePatientParams
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof CreatePatientParams
   */
  phoneNumber?: string
  /**
   *
   * @type {Array<string>}
   * @memberof CreatePatientParams
   */
  fileIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof CreatePatientParams
   */
  emergencyName?: string
  /**
   *
   * @type {string}
   * @memberof CreatePatientParams
   */
  emergencyPhone?: string
  /**
   *
   * @type {string}
   * @memberof CreatePatientParams
   */
  patientNotes?: string
  /**
   *
   * @type {string}
   * @memberof CreatePatientParams
   */
  homePhone?: string
  /**
   *
   * @type {string}
   * @memberof CreatePatientParams
   */
  referralSource?: string
}

/**
 *
 * @export
 * @interface CreatePatientResponse
 */
export interface CreatePatientResponse {
  /**
   *
   * @type {Patient}
   * @memberof CreatePatientResponse
   */
  patient: Patient
}

/**
 *
 * @export
 * @interface CreateUserAddressParams
 */
export interface CreateUserAddressParams {
  /**
   *
   * @type {AddressParams}
   * @memberof CreateUserAddressParams
   */
  address: AddressParams
}

/**
 *
 * @export
 * @interface CreateUserParams
 */
export interface CreateUserParams {
  /**
   *
   * @type {Array<Prefixes>}
   * @memberof CreateUserParams
   */
  prefix?: Array<Prefixes>
  /**
   *
   * @type {Array<Credentials>}
   * @memberof CreateUserParams
   */
  credentials?: Array<Credentials>;
  /**
   *
   * @type {Roles}
   * @memberof CreateUserParams
   */
  role: Roles
  /**
   *
   * @type {string}
   * @memberof CreateUserParams
   */
  given: string
  /**
   *
   * @type {string}
   * @memberof CreateUserParams
   */
  family: string
  /**
   *
   * @type {string}
   * @memberof CreateUserParams
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof CreateUserParams
   */
  phoneNumber: string
  /**
   *
   * @type {Array<string>}
   * @memberof CreateUserParams
   */
  organizationIds: Array<string>
  /**
   *
   * @type {string}
   * @memberof CreateUserParams
   */
  birthDate?: string
  /**
   *
   * @type {string}
   * @memberof CreateUserParams
   */
  mobileNumber?: string
  /**
   *
   * @type {AddressParams}
   * @memberof CreateUserParams
   */
  address?: AddressParams
}

/**
 *
 * @export
 * @enum {string}
 */

export const Credentials = {
  Bsn: 'BSN',
  Ccds: 'CCDS',
  Crat: 'CRAT',
  Dnp: 'DNP',
  Do: 'DO',
  Ems: 'EMS',
  Lpn: 'LPN',
  Ma: 'MA',
  Md: 'MD',
  Np: 'NP',
  Pa: 'PA',
  Rn: 'RN',
  Ms: 'MS'
} as const

export type Credentials = typeof Credentials[keyof typeof Credentials];

/**
 *
 * @export
 * @interface CreateUserResponse
 */
export interface CreateUserResponse {
  /**
   *
   * @type {User}
   * @memberof CreateUserResponse
   */
  user: User
}

/**
 *
 * @export
 * @interface DeleteFileResponse
 */
export interface DeleteFileResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteFileResponse
   */
  deleted: boolean
}

/**
 *
 * @export
 * @enum {string}
 */

export enum DeviceTypes {
  Pm = 'PM',
  Icd = 'ICD',
  CrtD = 'CRT-D',
  CrtP = 'CRT-P',
  Ilr = 'ILR',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum DeviceVendors {
  Abbott = 'abbott',
  Biotronik = 'biotronik',
  BostonScientific = 'boston_scientific',
  Medtronic = 'medtronic',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum ReportType {
  AccountOverview = 'accountOverview',
  Alerts = 'alerts',
  Billable = 'billable',
  SignatureStatus = 'signatureStatus',
  Scheduling = 'scheduling'
}

/**
 *
 * @export
 * @interface DownloadReportRequest
 */
export interface DownloadReportRequest {
  /**
   *
   * @type {ReportType}
   * @memberof DownloadReportRequest
   */
  'reportType': ReportType;
  /**
   *
   * @type {object}
   * @memberof DownloadReportRequest
   */
  'query': object;
}

/**
 *
 * @export
 * @interface FetchAllICDCodesResponse
 */
export interface FetchAllICDCodesResponse {
  /**
   *
   * @type {Array<IcdDiagnosisCode>}
   * @memberof FetchAllICDCodesResponse
   */
  'icdDiagnosisCodes': Array<IcdDiagnosisCode>;
}

/**
 *
 * @export
 * @interface FetchTermDaysResponse
 */
export interface FetchTermDaysResponse {
  /**
   *
   * @type {Array<ScheduleTerm>}
   * @memberof FetchTermDaysResponse
   */
  'scheduleTerms': Array<ScheduleTerm>;
}

/**
 *
 * @export
 * @interface FileUploadStatus
 */
export interface FileUploadStatus {
  /**
   *
   * @type {string}
   * @memberof FileUploadStatus
   */
  'fileName': string;
  /**
   *
   * @type {string}
   * @memberof FileUploadStatus
   */
  'status': string;
}

/**
 *
 * @export
 * @interface FindAllAuthorizedPractitionersResponse
 */
export interface FindAllAuthorizedPractitionersResponse {
  /**
   *
   * @type {Array<User>}
   * @memberof FindAllAuthorizedPractitionersResponse
   */
  practitioners: Array<User>
}

/**
 *
 * @export
 * @interface FindAllOrganizationsQueryParams
 */
export interface FindAllOrganizationsQueryParams {
  /**
   *
   * @type {boolean}
   * @memberof FindAllOrganizationsQueryParams
   */
  isAccount?: boolean
  /**
   *
   * @type {boolean}
   * @memberof FindAllOrganizationsQueryParams
   */
  patientCount?: boolean
  /**
   *
   * @type {number}
   * @memberof FindAllOrganizationsQueryParams
   */
  offset?: number
  /**
   *
   * @type {number}
   * @memberof FindAllOrganizationsQueryParams
   */
  limit?: number
}

/**
 *
 * @export
 * @interface FindAllOrganizationsResponse
 */
export interface FindAllOrganizationsResponse {
  /**
   *
   * @type {Array<Organization>}
   * @memberof FindAllOrganizationsResponse
   */
  organizations: Array<Organization>
  /**
   *
   * @type {number}
   * @memberof FindAllOrganizationsResponse
   */
  currentOffset: number
  /**
   *
   * @type {number}
   * @memberof FindAllOrganizationsResponse
   */
  total: number
  /**
   *
   * @type {number}
   * @memberof FindAllOrganizationsResponse
   */
  totalNotDownloadedCount: number;
}

/**
 *
 * @export
 * @interface FindAllPatientsParams
 */
export interface FindAllPatientsParams {
  /**
   *
   * @type {number}
   * @memberof FindAllPatientsParams
   */
  skip?: number;
  /**
   *
   * @type {number}
   * @memberof FindAllPatientsParams
   */
  pageLimit?: number;
  /**
   *
   * @type {boolean}
   * @memberof FindAllPatientsParams
   */
  isNew?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FindAllPatientsParams
   */
  isRecentSigned?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FindAllPatientsParams
   */
  isDownloaded?: boolean;
  /**
   *
   * @type {string}
   * @memberof FindAllPatientsParams
   */
  search?: string;
  /**
   *
   * @type {string}
   * @memberof FindAllPatientsParams
   */
  sortBy?: string;
  /**
   *
   * @type {string}
   * @memberof FindAllPatientsParams
   */
  sortDirection?: string;
}

export const FindAllPatientsParamsSortDirectionEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const

export type FindAllPatientsParamsSortDirectionEnum = typeof FindAllPatientsParamsSortDirectionEnum[keyof typeof FindAllPatientsParamsSortDirectionEnum];

/**
 *
 * @export
 * @interface FindAllPatientsResponse
 */
export interface FindAllPatientsResponse {
  /**
   *
   * @type {Array<Patient>}
   * @memberof FindAllPatientsResponse
   */
  'patients': Array<Patient>;
  /**
   *
   * @type {number}
   * @memberof FindAllPatientsResponse
   */
  'currentOffset': number;
  /**
   *
   * @type {number}
   * @memberof FindAllPatientsResponse
   */
  'total': number;
  /**
   *
   * @type {number}
   * @memberof FindAllPatientsResponse
   */
  'totalNotDownloadedCount': number;

  //ad-hoc non-generated additional fields (will come in next generation)
  'transmissionScheduleReadOnly': boolean;
}

/**
 *
 * @export
 * @interface FindAllPatientsResponse
 */
export interface FindAllPatientsResponse {
  /**
   *
   * @type {Array<Patient>}
   * @memberof FindAllPatientsResponse
   */
  'patients': Array<Patient>;
  /**
   *
   * @type {number}
   * @memberof FindAllPatientsResponse
   */
  'currentOffset': number;
  /**
   *
   * @type {number}
   * @memberof FindAllPatientsResponse
   */
  'total': number;
  /**
   *
   * @type {number}
   * @memberof FindAllPatientsResponse
   */
  'totalNotDownloadedCount': number;
}

/**
 *
 * @export
 * @interface FindAllUsersQueryParams
 */
export interface FindAllUsersQueryParams {
  /**
   *
   * @type {Array<Roles>}
   * @memberof FindAllUsersQueryParams
   */
  roles?: Array<Roles>
  /**
   *
   * @type {Array<string>}
   * @memberof FindAllUsersQueryParams
   */
  organizationIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof FindAllUsersQueryParams
   */
  search?: string;
  /**
   *
   * @type {string}
   * @memberof FindAllUsersQueryParams
   */
  sortBy?: string;
  /**
   *
   * @type {string}
   * @memberof FindAllUsersQueryParams
   */
  sortDirection?: FindAllUsersQueryParamsSortDirectionEnum;
  /**
   *
   * @type {number}
   * @memberof FindAllUsersQueryParams
   */
  offset?: number
  /**
   *
   * @type {number}
   * @memberof FindAllUsersQueryParams
   */
  limit?: number
}

export const FindAllUsersQueryParamsSortDirectionEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const

export type FindAllUsersQueryParamsSortDirectionEnum = typeof FindAllUsersQueryParamsSortDirectionEnum[keyof typeof FindAllUsersQueryParamsSortDirectionEnum];

/**
 *
 * @export
 * @interface FindAllUsersResponse
 */
export interface FindAllUsersResponse {
  /**
   *
   * @type {Array<User>}
   * @memberof FindAllUsersResponse
   */
  users: Array<User>
  /**
   *
   * @type {number}
   * @memberof FindAllUsersResponse
   */
  currentOffset: number
  /**
   *
   * @type {number}
   * @memberof FindAllUsersResponse
   */
  total: number
}

/**
 *
 * @export
 * @interface FindOneOrganizationResponse
 */
export interface FindOneOrganizationResponse {
  /**
   *
   * @type {Organization}
   * @memberof FindOneOrganizationResponse
   */
  organization: Organization
}

/**
 *
 * @export
 * @interface FindOnePatientResponse
 */
export interface FindOnePatientResponse {
  /**
   *
   * @type {Patient}
   * @memberof FindOnePatientResponse
   */
  patient: Patient
}

/**
 *
 * @export
 * @interface FindOneTransmissionReportResponse
 */
export interface FindOneTransmissionReportResponse {
  /**
   *
   * @type {TransmissionReportDto}
   * @memberof FindOneTransmissionReportResponse
   */
  transmissionReport: TransmissionReportDto
}

/**
 *
 * @export
 * @interface FindOneUserResponse
 */
export interface FindOneUserResponse {
  /**
   *
   * @type {User}
   * @memberof FindOneUserResponse
   */
  user: User
}

/**
 *
 * @export
 * @interface FormErrorExample
 */
export interface FormErrorExample {
  /**
   *
   * @type {number}
   * @memberof FormErrorExample
   */
  status: number
  /**
   *
   * @type {Array<object>}
   * @memberof FormErrorExample
   */
  details: Array<object>
  /**
   *
   * @type {number}
   * @memberof FormErrorExample
   */
  responseType: number
}

/**
 *
 * @export
 * @interface GetTotalPatientsMonitoredParams
 */
export interface GetTotalPatientsMonitoredParams {
  /**
   *
   * @type {Array<string>}
   * @memberof GetTotalPatientsMonitoredParams
   */
  organizationIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof GetTotalPatientsMonitoredParams
   */
  startFrom?: string
  /**
   *
   * @type {string}
   * @memberof GetTotalPatientsMonitoredParams
   */
  endAt?: string
}

/**
 *
 * @export
 * @interface GetTotalPatientsMonitoredResponse
 */
export interface GetTotalPatientsMonitoredResponse {
  /**
   *
   * @type {string}
   * @memberof GetTotalPatientsMonitoredResponse
   */
  startFrom: string
  /**
   *
   * @type {string}
   * @memberof GetTotalPatientsMonitoredResponse
   */
  endAt: string
  /**
   *
   * @type {Array<PatientMonitoredData>}
   * @memberof GetTotalPatientsMonitoredResponse
   */
  patientsData: Array<PatientMonitoredData>
}

/**
 *
 * @export
 * @interface InternalServerErrorExample
 */
export interface InternalServerErrorExample {
  /**
   *
   * @type {number}
   * @memberof InternalServerErrorExample
   */
  status: number
  /**
   *
   * @type {object}
   * @memberof InternalServerErrorExample
   */
  details: object
  /**
   *
   * @type {number}
   * @memberof InternalServerErrorExample
   */
  responseType: number
}

/**
 *
 * @export
 * @interface IsBillableTransmissionReports
 */
export interface IsBillableTransmissionReports {
  /**
   *
   * @type {number}
   * @memberof IsBillableTransmissionReports
   */
  'transmissionReportId': number;
  /**
   *
   * @type {boolean}
   * @memberof IsBillableTransmissionReports
   */
  'isBillable': boolean;
  /**
   *
   * @type {string}
   * @memberof IsBillableTransmissionReports
   */
  'lastBillableDate'?: string;
  /**
   *
   * @type {string}
   * @memberof IsBillableTransmissionReports
   */
  'nextScheduledTransmissionDate'?: string;
  /**
   *
   * @type {boolean}
   * @memberof IsBillableTransmissionReports
   */
  'isEriTracked': boolean;
  /**
   *
   * @type {string}
   * @memberof IsBillableTransmissionReport
   */
  'scheduleTransmissionType'?: string;
}

/**
 *
 * @export
 * @interface ManualORInclinicTransmissionReportRequestDto
 */
export interface ManualORInclinicTransmissionReportRequestDto {
  /**
   *
   * @type {TransmissionReportType}
   * @memberof ManualORInclinicTransmissionReportRequestDto
   */
  'reportType': TransmissionReportType;
  /**
   *
   * @type {string}
   * @memberof ManualORInclinicTransmissionReportRequestDto
   */
  'patientId': string;
  /**
   *
   * @type {Array<string>}
   * @memberof ManualORInclinicTransmissionReportRequestDto
   */
  'files': Array<string>;
  /**
   *
   * @type {string}
   * @memberof ManualORInclinicTransmissionReportRequestDto
   */
  'deviceId': string;
}

/**
 *
 * @export
 * @interface MatchDevice
 */
export interface MatchDevice {
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'id': string;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'createdAt': string;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'updatedAt': string;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'createdBy': string;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'updatedBy': string;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'vendor': MatchDeviceVendorEnum;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'modelNumber': string;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'serialNumber': string;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'modelName': string;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'type': string;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'patientId': string;
  /**
   *
   * @type {Patient}
   * @memberof MatchDevice
   */
  'patient': Patient;
  /**
   *
   * @type {boolean}
   * @memberof MatchDevice
   */
  'scheduleEnabled': boolean;
  /**
   *
   * @type {Array<ScheduledTransmission>}
   * @memberof MatchDevice
   */
  'scheduleTransmissions': Array<ScheduledTransmission>;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'lastTransmissionAt': string | null;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'nextTransmissionAt': string | null;
  /**
   *
   * @type {boolean}
   * @memberof MatchDevice
   */
  'scheduleRequiresCorrection': boolean;
  /**
   *
   * @type {boolean}
   * @memberof MatchDevice
   */
  'isEriTracked': boolean;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'routineStartDate': string | null;
  /**
   *
   * @type {ScheduleTerm}
   * @memberof MatchDevice
   */
  'routineScheduleTerm': ScheduleTerm | null;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'heartFailureStartDate': string | null;
  /**
   *
   * @type {ScheduleTerm}
   * @memberof MatchDevice
   */
  'heartFailureScheduleTerm': ScheduleTerm | null;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'comboBillingStartDate': string | null;
  /**
   *
   * @type {ScheduleTerm}
   * @memberof MatchDevice
   */
  'comboBillingScheduleTerm': ScheduleTerm | null;
  /**
   *
   * @type {string}
   * @memberof MatchDevice
   */
  'scheduleNotSet': boolean;
}


/**
 *
 * @export
 * @interface DeviceSchedule
 */
export interface DeviceSchedule {
  /**
   *
   * @type {string}
   * @memberof DeviceSchedule
   */
  'id'?: string;
  /**
   *
   * @type {boolean}
   * @memberof DeviceSchedule
   */
  'isHeartFailureSchedule': boolean;
  /**
   *
   * @type {boolean}
   * @memberof DeviceSchedule
   */
  'isComboBilling': boolean;
  /**
   *
   * @type {string}
   * @memberof DeviceSchedule
   */
  'heartFailureDiagnosticCode': string;
  /**
   *
   * @type {string}
   * @memberof DeviceSchedule
   */
  'professionalCptCode': string;
  /**
   *
   * @type {string}
   * @memberof DeviceSchedule
   */
  'technicalCptCode': string;
  /**
   *
   * @type {string}
   * @memberof DeviceSchedule
   */
  'startDate': string;
  /**
   *
   * @type {string}
   * @memberof DeviceSchedule
   */
  'frequencyPreference': string;
  /**
   *
   * @type {MatchDevice}
   * @memberof DeviceSchedule
   */
  'matchDevice': MatchDevice;
  /**
   *
   * @type {string}
   * @memberof DeviceSchedule
   */
  'dateArray'?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MatchDeviceVendorEnum {
  Abbott = 'abbott',
  Biotronik = 'biotronik',
  BostonScientific = 'boston_scientific',
  Medtronic = 'medtronic',
}

/**
 * @export
 * @enum {string}
 */
export enum MatchDeviceTypeEnum {
  Pm = 'PM',
  Icd = 'ICD',
  CrtD = 'CRT-D',
  CrtP = 'CRT-P',
  Ilr = 'ILR',
}

/**
 *
 * @export
 * @interface MatchDeviceParams
 */
export interface MatchDeviceParams {
  /**
   *
   * @type {DeviceVendors}
   * @memberof MatchDeviceParams
   */
  id: string
  vendor: DeviceVendors
  /**
   *
   * @type {Array<DeviceTypes>}
   * @memberof MatchDeviceParams
   */
  type?: DeviceTypes
  /**
   *
   * @type {string}
   * @memberof MatchDeviceParams
   */
  modelNumber: string
  /**
   *
   * @type {string}
   * @memberof MatchDeviceParams
   */
  serialNumber: string
  /**
   *
   * @type {string}
   * @memberof MatchDeviceParams
   */
  modelName?: string
}

/**
 *
 * @export
 * @interface MatchTransmissionParams
 */
export interface MatchTransmissionParams {
  /**
   *
   * @type {string}
   * @memberof MatchTransmissionParams
   */
  familyName: string
  /**
   *
   * @type {string}
   * @memberof MatchTransmissionParams
   */
  givenName: string
  /**
   *
   * @type {string}
   * @memberof MatchTransmissionParams
   */
  deviceModelNumber: string
  /**
   *
   * @type {string}
   * @memberof MatchTransmissionParams
   */
  deviceSerialNumber: string
  /**
   *
   * @type {number}
   * @memberof MatchTransmissionParams
   */
  transmissionId: number
}

/**
 *
 * @export
 * @interface MatchTransmissionResponse
 */
export interface MatchTransmissionResponse {
  /**
   *
   * @type {string}
   * @memberof MatchTransmissionResponse
   */
  patientId: string
}

/**
 *
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  key: string
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  originalName: string
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  bucketName: string
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  expiredAt: string
  /**
   *
   * @type {object}
   * @memberof ModelFile
   */
  metaData: object
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  updatedAt: string
  /**
   *
   * @type {Organization}
   * @memberof ModelFile
   */
  organization: Organization
  /**
   *
   * @type {Patient}
   * @memberof ModelFile
   */
  patient: Patient
  /**
   *
   * @type {User}
   * @memberof ModelFile
   */
  uploadedBy: User
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  deletedAt?: string
}

/**
 *
 * @export
 * @enum {string}
 */

export const BatchJobStatus = {
  Initialized: 'initialized',
  Processing: 'processing',
  Success: 'success',
  Failed: 'failed',
} as const

export type BatchJobStatus = typeof BatchJobStatus[keyof typeof BatchJobStatus];

/**
 *
 * @export
 * @interface BatchPDFDownloadInfoRequest
 */
export interface BatchPDFDownloadInfoRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof BatchPDFDownloadInfoRequest
   */
  'tranmissionReportIds': Array<number>;
  /**
   *
   * @type {string}
   * @memberof BatchPDFDownloadInfoRequest
   */
  'downloadDate': string;
}

/**
 *
 * @export
 * @interface BatchPDFDownloadInfoResponse
 */
export interface BatchPDFDownloadInfoResponse {
  /**
   *
   * @type {object}
   * @memberof BatchPDFDownloadInfoResponse
   */
  result: object;
}

/**
 *
 * @export
 * @interface CreateInitializeBatchJobRequest
 */
export interface CreateInitializeBatchJobRequest {
  /**
   *
   * @type {Array<TransmissionData>}
   * @memberof CreateInitializeBatchJobRequest
   */
  transmissiondata: Array<TransmissionData>;
}

/**
 *
 * @export
 * @interface CreateInitializeBatchJobResponse
 */
export interface CreateInitializeBatchJobResponse {
  /**
   *
   * @type {string}
   * @memberof CreateInitializeBatchJobResponse
   */
  'jobId': string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreateInitializeBatchJobResponse
   */
  'isSuccess': boolean;
  /**
   *
   * @type {object}
   * @memberof CreateInitializeBatchJobResponse
   */
  'error': object;
}

/**
 *
 * @export
 * @interface Setting
 */
export interface Setting {
  /**
   *
   * @type {number}
   * @memberof Setting
   */
  'id': number;
  /**
   *
   * @type {string}
   * @memberof Setting
   */
  'createdAt': string;
  /**
   *
   * @type {string}
   * @memberof Setting
   */
  'updatedAt': string;
  /**
   *
   * @type {string}
   * @memberof Setting
   */
  'settingName': string;
  /**
   *
   * @type {string}
   * @memberof Setting
   */
  'settingValue': string;
}

/**
 *
 * @export
 * @interface ClinicalNote
 */
export interface ClinicalNote {
  /**
   *
   * @type {string}
   * @memberof ClinicalNote
   */
  'id': string;
  /**
   *
   * @type {Patient}
   * @memberof ClinicalNote
   */
  'patient': Patient;
  /**
   *
   * @type {string}
   * @memberof ClinicalNote
   */
  'content': string;
  /**
   *
   * @type {User}
   * @memberof ClinicalNote
   */
  'createdBy': User;
  /**
   *
   * @type {User}
   * @memberof ClinicalNote
   */
  'updatedBy': User;
  /**
   *
   * @type {string}
   * @memberof ClinicalNote
   */
  'createdAt': string;
  /**
   *
   * @type {string}
   * @memberof ClinicalNote
   */
  'updatedAt': string;
  /**
   *
   * @type {boolean}
   * @memberof ClinicalNote
   */
  'isPinned': boolean;
  /**
   *
   * @type {string}
   * @memberof ClinicalNote
   */
  'source': string;
}

/**
 *
 * @export
 * @interface CreateNoteRequest
 */
export interface CreateNoteRequest {
  /**
   *
   * @type {string}
   * @memberof CreateNoteRequest
   */
  'patientId': string;
  /**
   *
   * @type {string}
   * @memberof CreateNoteRequest
   */
  'content': string;
  /**
   *
   * @type {object}
   * @memberof CreateNoteRequest
   */
  'source'?: object | null;
}

/**
 *
 * @export
 * @interface UpdateNoteRequest
 */
export interface UpdateNoteRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateNoteRequest
   */
  'content'?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateNoteRequest
   */
  'isPinned'?: boolean | null;
}

/**
 *
 * @export
 * @interface GetClinicalNotesResponseDto
 */
export interface GetClinicalNotesResponseDto {
  /**
   *
   * @type {Array<ClinicalNote>}
   * @memberof GetClinicalNotesResponseDto
   */
  'notes': Array<ClinicalNote>;
}
/**
 *
 * @export
 * @interface GetPinnedNotesAndTotalCountResponseDto
 */
export interface GetPinnedNotesAndTotalCountResponseDto {
  /**
   *
   * @type {Array<ClinicalNote>}
   * @memberof GetPinnedNotesAndTotalCountResponseDto
   */
  'pinnedNotes': Array<ClinicalNote>;
  /**
   *
   * @type {number}
   * @memberof GetPinnedNotesAndTotalCountResponseDto
   */
  'count': number;
}
/**
 *
 * @export
 * @interface GetClinicalNoteResponseDto
 */
export interface GetClinicalNoteResponseDto {
  /**
   *
   * @type {Array<ClinicalNote>}
   * @memberof GetClinicalNoteResponseDto
   */
  'note': ClinicalNote;
}

/**
 *
 * @export
 * @interface CreateNoteResponse
 */
export interface CreateNoteResponse {
  /**
   *
   * @type {string}
   * @memberof CreateNoteResponse
   */
  'id': string;
}

/**
 *
 * @export
 * @interface UpdateNoteResponse
 */
export interface UpdateNoteResponse {
  /**
   *
   * @type {boolean}
   * @memberof UpdateNoteResponse
   */
  'updatedNote': ClinicalNote;
}

/**
 *
 * @export
 * @interface DeleteNoteResponse
 */
export interface DeleteNoteResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteNoteResponse
   */
  'success': boolean;
}

/**
 *
 * @export
 * @interface TransmissionData
 */
export interface TransmissionData {
  /**
   *
   * @type {number}
   * @memberof TransmissionData
   */
  'transmissionId'?: number;
  // /**
  //  *
  //  * @type {string}
  //  * @memberof TransmissionData
  //  */
  // 'uploadKey'?: string;
  // /**
  //  *
  //  * @type {string}
  //  * @memberof TransmissionData
  //  */
  // 'uploadNotes'?: string;
  // /**
  //  *
  //  * @type {string}
  //  * @memberof TransmissionData
  //  */
  // 'patientId'?: string;
  //   /**
  //  *
  //  * @type {string}
  //  * @memberof TransmissionData
  //  */
  //    'reportFileName'?: string;


}

/**
 *
 * @export
 * @interface TransmissionIdsRequest
 */
export interface TransmissionIdsRequest {
  /**
   *
   * @type {boolean}
   * @memberof TransmissionIdsRequest
   */
  'isNew'?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TransmissionIdsRequest
   */
  'isRecentSigned'?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TransmissionIdsRequest
   */
  'isDownloaded'?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TransmissionIdsRequest
   */
  'isSelectedAll'?: boolean;
  /**
   *
   * @type {string}
   * @memberof TransmissionIdsRequest
   */
  'search'?: string;
  /**
   *
   * @type {string}
   * @memberof TransmissionIdsRequest
   */
  'status'?: string;
}

/**
 *
 * @export
 * @interface TransmissionIdsResponse
 */
export interface TransmissionIdsResponse {
  /**
   *
   * @type {string}
   * @memberof TransmissionIdsResponse
   */
  'jobId': string | null;
  /**
   *
   * @type {boolean}
   * @memberof TransmissionIdsResponse
   */
  'isSuccess': boolean;
  /**
   *
   * @type {object}
   * @memberof TransmissionIdsResponse
   */
  'error': object;
  /**
   *
   * @type {Array<number>}
   * @memberof TransmissionIdsResponse
   */
  'transmissionIds': Array<number>;
}

/**
 *
 * @export
 * @interface UpdateBatchDownloadDto
 */
export interface UpdateBatchDownloadDto {
  /**
   *
   * @type {BatchJobStatus}
   * @memberof UpdateBatchDownloadDto
   */
  'status': BatchJobStatus;
  /**
   *
   * @type {string}
   * @memberof UpdateBatchDownloadDto
   */
  'message': string;
  /**
   *
   * @type {string}
   * @memberof UpdateBatchDownloadDto
   */
  's3SignedUrl': string;
  /**
   *
   * @type {number}
   * @memberof UpdateBatchDownloadDto
   */
  'tranmissionCount': number;
  /**
   *
   * @type {string}
   * @memberof UpdateBatchDownloadDto
   */
  'userId': string;
}


/**
 *
 * @export
 * @interface NotFoundExample
 */
export interface NotFoundExample {
  /**
   *
   * @type {number}
   * @memberof NotFoundExample
   */
  status: number
  /**
   *
   * @type {object}
   * @memberof NotFoundExample
   */
  details: object
  /**
   *
   * @type {number}
   * @memberof NotFoundExample
   */
  responseType: number
}

/**
 *
 * @export
 * @interface Organization
 */
export interface Organization {
  role: any
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  given: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  family: string
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  isAccount?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  isClinic?: boolean
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  mrn1: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  mrn2: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  sentToEhr: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  inClinicReportWorkflow: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  name: string
  /**
   *
   * @type {object}
   * @memberof Organization
   */
  logo: object
  /**
   *
   * @type {Address}
   * @memberof Organization
   */
  address: Address
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  phoneNumber: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  faxNumber: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  notes: string
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  hasEmrAccess: boolean
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  ehrIntegration?: boolean
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  emrName: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  goLiveOn: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  onboardingOn: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  internalHuddleOn: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  kickoffHuddleOn: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  parentId: string
  /**
   *
   * @type {Organization}
   * @memberof Organization
   */
  parent: Organization
  /**
   *
   * @type {Array<Organization>}
   * @memberof Organization
   */
  children: Array<Organization>
  /**
   *
   * @type {Array<User>}
   * @memberof Organization
   */
  users: Array<User>
  /**
   *
   * @type {Array<GlobalFilter>}
   * @memberof Organization
   */
  globalFilters: Array<GlobalFilter>
  /**
   *
   * @type {Array<Patient>}
   * @memberof Organization
   */
  patients: Array<Patient>
  /**
   *
   * @type {Array<any>}
   * @memberof Organization
   */
  files: Array<any>
  /**
   *
   * @type {Array<OrganizationVendorIntegration>}
   * @memberof Organization
   */
  vendorIntegrations: Array<OrganizationVendorIntegration>
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  rhythmId: string
  /**
   *
   * @type {number}
   * @memberof Organization
   */
  patientCount?: number
  saveCombinedPDF: boolean
  isSignAll?: boolean
  isFollowingPractitionerReq?: boolean
  isManagedInRS: boolean
  pmScheduleFrequency?: ScheduleTerm
  crtdScheduleFrequency?: ScheduleTerm
  crtpScheduleFrequency?: ScheduleTerm
  icdScheduleFrequency?: ScheduleTerm
  ilrScheduleFrequency?: ScheduleTerm
  billingType: string
  appDeterminedBillableUI?: boolean
}

/**
 *
 * @export
 * @interface OrganizationVendorIntegration
 */
export interface OrganizationVendorIntegration {
  /**
   *
   * @type {string}
   * @memberof OrganizationVendorIntegration
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof OrganizationVendorIntegration
   */
  name: OrganizationVendorIntegrationNameEnum
  /**
   *
   * @type {Organization}
   * @memberof OrganizationVendorIntegration
   */
  organization: Organization
  /**
   *
   * @type {string}
   * @memberof OrganizationVendorIntegration
   */
  status: OrganizationVendorIntegrationStatusEnum
  /**
   *
   * @type {string}
   * @memberof OrganizationVendorIntegration
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof OrganizationVendorIntegration
   */
  updatedAt: string
}

/**
 *
 * @export
 * @interface FindPatientsReportsDeviceTypeResponse
 */
export interface FindPatientsReportsDeviceTypeResponse {
  /**
   *
   * @type {Array<ReportDeviceType>}
   * @memberof FindPatientsReportsDeviceTypeResponse
   */
  'deviceTypes': Array<ReportDeviceType>;
}

/**
 * @export
 * @enum {string}
 */
export enum OrganizationVendorIntegrationNameEnum {
  Abbott = 'abbott',
  Biotronik = 'biotronik',
  BostonScientific = 'boston_scientific',
  Medtronic = 'medtronic',
}

/**
 * @export
 * @enum {string}
 */
export enum OrganizationVendorIntegrationStatusEnum {
  Configured = 'configured',
  Pending = 'pending',
  Disabled = 'disabled',
}

/**
 *
 * @export
 * @interface PatientActiveInactiveStatusParams
 */
export interface PatientActiveInactiveStatusParams {
  /**
   *
   * @type {string}
   * @memberof PatientActiveInactiveStatusParams
   */
  'Status': string;
  /**
   *
   * @type {string}
   * @memberof PatientActiveInactiveStatusParams
   */
  'Reason'?: PatientActiveInactiveStatusParamsReasonEnum;
  /**
   *
   * @type {string}
   * @memberof PatientActiveInactiveStatusParams
   */
  'Note'?: string;
}

export const PatientActiveInactiveStatusParamsReasonEnum = {
  Deceased: 'Deceased',
  Eos: 'EOS',
  Explanted: 'Explanted',
  RemoteMonitorElsewhere: 'Remote Monitor Elsewhere',
  UnableToBeRemotelyMonitored: 'Unable To Be Remotely Monitored',
  Other: 'Other',
} as const

export type PatientActiveInactiveStatusParamsReasonEnum = typeof PatientActiveInactiveStatusParamsReasonEnum[keyof typeof PatientActiveInactiveStatusParamsReasonEnum];


/**
 *
 * @export
 * @interface Patient
 */
export interface Patient {
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  mrn1: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  mrn2: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  familyName: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  givenName: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  birthDate: string
  /**
   *
   * @type {object}
   * @memberof Patient
   */
  sex: object | null
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  phoneNumber: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  emergencyName: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  emergencyPhone: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  patientStatus: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  patientNotes: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  homePhone: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  referralSource: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  ssn: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  status: PatientStatusEnum
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  updatedAt: string
  /**
   *
   * @type {Address}
   * @memberof Patient
   */
  address: Address
  /**
   *
   * @type {Organization}
   * @memberof Patient
   */
  clinic: Organization
  /**
   *
   * @type {Array<MatchDevice>}
   * @memberof Patient
   */
  matchDevices: Array<MatchDevice>
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  rhythmId: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  oac: PatientOacEnum
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  referringPhysician: string
   /**
   *
   * @type {RegisteredNpis}
   * @memberof Patient
   */
   referringProvider: RegisteredNpis;
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  monitorReason: string
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  diagnosisOption: string
  /**
   *
   * @type {User}
   * @memberof Patient
   */
  practitioner: User
  /**
   *
   * @type {Array<any>}
   * @memberof Patient
   */
  files: Array<any>
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  recentReportColor: TransmissionReportDtoColorEnum
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  recentReportType: string
  /**
   *
   * @type {Date}
   * @memberof Patient
   */
  recentReportDate: Date
  /**
   *
   * @type {Date}
   * @memberof Patient
   */
  recentReportId: number
  /**
   *
   * @type {string}
   * @memberof Patient
   */
  recentReportDeviceType: string

  recentHeartFailureStatus: boolean
  count: number
  reportData: any
  /**
   *
   * @type {Array<ClinicalNote>}
   * @memberof Patient
   */
  clinicalNotes: Array<ClinicalNote>;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientStatusEnum {
  Draft = 'draft',
  Saved = 'saved',
  InsuranceVerified = 'insurance_verified',
  PendingPof = 'pending_pof',
  Scheduled = 'scheduled',
}

/**
 * @export
 * @enum {string}
 */
export enum PatientOacEnum {
  Yes = 'Yes',
  No = 'No',
  NA = 'N/A',
  NotTolerated = 'Not Tolerated',
  PatientDeclined = 'Patient Declined',
  HOLAAClosureDevice = 'H/o LAA Closure Device',
  Contraindicated = 'Contraindicated'
}

/**
 *
 * @export
 * @interface PatientMonitoredData
 */
export interface PatientMonitoredData {
  /**
   *
   * @type {number}
   * @memberof PatientMonitoredData
   */
  year: number
  /**
   *
   * @type {number}
   * @memberof PatientMonitoredData
   */
  month: number
  /**
   *
   * @type {number}
   * @memberof PatientMonitoredData
   */
  count: number
}

/**
 *
 * @export
 * @interface PatientOffsetPaginationParams
 */
export interface PatientOffsetPaginationParams {
  /**
   *
   * @type {number}
   * @memberof PatientOffsetPaginationParams
   */
  offset?: number
  /**
   *
   * @type {number}
   * @memberof PatientOffsetPaginationParams
   */
  limit?: number
}

/**
 *
 * @export
 * @enum {string}
 */

export enum PatientSex {
  Male = 'male',
  Female = 'female',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum PatientStatus {
  Draft = 'draft',
  Saved = 'saved',
  InsuranceVerified = 'insurance_verified',
  PendingPof = 'pending_pof',
  Scheduled = 'scheduled',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum Prefixes {
  Dr = 'Dr',
  Mr = 'Mr',
  Mrs = 'Mrs',
  Ms = 'Ms',
  Miss = 'Miss',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum Roles {
  InternalapiAccess = 'roles:internal:api-access',
  InternalsuperAdmin = 'roles:internal:super-admin',
  Internaladmin = 'roles:internal:admin',
  Internalclinical = 'roles:internal:clinical',
  InternalnonClinical = 'roles:internal:non-clinical',
  Externaladmin = 'roles:external:admin',
  ExternalpractitionerAdmin = 'roles:external:practitioner-admin',
  Externalpractitioner = 'roles:external:practitioner',
  Externalprovider = 'roles:external:provider',
  ExternalnonPractitionerAdmin = 'roles:external:non-practitioner-admin',
  ExternalnonPractitioner = 'roles:external:non-practitioner',
  ExternalnonProvider = 'roles:external:non-provider',
  Externalclerical = 'roles:external:clerical',
  ExternalheartFailure = 'roles:external:heart-failure',
}

/**
 *
 * @export
 * @interface TransmissionReport
 */
export interface TransmissionReport {
  /**
   *
   * @type {number}
   * @memberof TransmissionReport
   */
  id: number
  /**
   *
   * @type {Patient}
   * @memberof TransmissionReport
   */
  patient: Patient | null
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  color: TransmissionReportColorEnum
  /**
   *
   * @type {Array<TransmissionReportNote>}
   * @memberof TransmissionReport
   */
  notes?: Array<TransmissionReportNote>
  /**
   *
   * @type {Array<TransmissionReportImage>}
   * @memberof TransmissionReport
   */
  images: Array<TransmissionReportImage>
  /**
   *
   * @type {Array<TransmissionReportAlert>}
   * @memberof TransmissionReport
   */
  alerts: Array<TransmissionReportAlert>
  /**
   *
   * @type {Array<TransmissionReportSignature>}
   * @memberof TransmissionReport
   */
  signatures: Array<TransmissionReportSignature>
  /**
   *
   * @type {VendorDataOverrides}
   * @memberof TransmissionReport
   */
  overrides: VendorDataOverrides
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  signatureStatus: TransmissionReportSignatureStatusEnum
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  orderStatus: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  PDFUploadStatus: TransmissionReportPDFUploadStatusEnum
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  PDFUploadNotes: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  PDFUploadKey: string
  /**
   *
   * @type {boolean}
   * @memberof TransmissionReport
   */
  isEdited: boolean
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  editedUser: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  editedDate: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  transmissionType: TransmissionReportTransmissionTypeEnum
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  comment: string
  /**
   *
   * @type {UploadResponse}
   * @memberof TransmissionReport
   */
  filesUploadStatus: UploadResponse
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  smartNarrativeStatus: string | null
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  smartNarrativeStatusUpdated: string | null
  /**
   *
   * @type {boolean}
   * @memberof TransmissionReport
   */
  flagHeartFailure: boolean
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  updatedBy: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  createdBy: string
  /**
     *
     * @type {ReportTemplate}
     * @memberof TransmissionReport
     */
  'activeTemplate': ReportTemplate | null;
}

/**
 *
 * @export
 * @interface SignAllRequests
 */
export interface SignAllRequests {
  /**
   *
   * @type {string}
   * @memberof SignAllRequests
   */
  'id': string;
  /**
   *
   * @type {string}
   * @memberof SignAllRequests
   */
  'user_id': string;
  /**
   *
   * @type {string}
   * @memberof SignAllRequests
   */
  'status': SignAllRequestsStatusEnum;
  /**
   *
   * @type {object}
   * @memberof SignAllRequests
   */
  'payload': object;
}

/**
  * @export
  * @enum {string}
  */
export enum SignAllRequestsStatusEnum {
  Initiated = 'initiated',
  InProgress = 'in_progress',
  Completed = 'completed',
  Failed = 'failed'
}

/**
 *
 * @export
 * @interface ReportTemplate
 */
export interface ReportTemplate {
  /**
   *
   * @type {string}
   * @memberof ReportTemplate
   */
  'id': string;
  /**
   *
   * @type {string}
   * @memberof ReportTemplate
   */
  'createdAt': string;
  /**
   *
   * @type {string}
   * @memberof ReportTemplate
   */
  'updatedAt': string;
  /**
   *
   * @type {string}
   * @memberof ReportTemplate
   */
  'name': string;
  /**
   *
   * @type {string}
   * @memberof ReportTemplate
   */
  'organizationId': string;
  /**
   *
   * @type {TemplateObject}
   * @memberof ReportTemplate
   */
  'templateobject': TemplateObject;
  /**
   *
   * @type {boolean}
   * @memberof ReportTemplate
   */
  'isExternalAccess': boolean;
  /**
   *
   * @type {boolean}
   * @memberof ReportTemplate
   */
  'isVisible': boolean;
  /**
   *
   * @type {string}
   * @memberof ReportTemplate
   */
  'deviceType': string;
  /**
   *
   * @type {ReportTemplate}
   * @memberof ReportTemplate
   */
  'parentReportTemplate': ReportTemplate;
  /**
   *
   * @type {Array<TransmissionReport>}
   * @memberof ReportTemplate
   */
  'transmissionReports': Array<TransmissionReport>;
}
/**
*
* @export
* @interface RemoveTemplateRequest
*/
export interface RemoveTemplateRequest {
  /**
   *
   * @type {number}
   * @memberof RemoveTemplateRequest
   */
  'reportId': number;
}

/**
 *
 * @export
 * @interface TemplateObject
 */
export interface TemplateObject {
  /**
   *
   * @type {string}
   * @memberof TemplateObject
   */
  'ALERT_PRESENTING'?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TemplateObject
   */
   'IS_HEART_FAILURE'?: boolean | null;
}

export const TransmissionReportPDFUploadStatusEnum = {
  Success: 'SUCCESS',
  Failed: 'FAILED',
  NotInitialized: 'NOT_INITIALIZED',
} as const

export type TransmissionReportPDFUploadStatusEnum = typeof TransmissionReportPDFUploadStatusEnum[keyof typeof TransmissionReportPDFUploadStatusEnum];

export const TransmissionReportTransmissionTypeEnum = {
  Remote: 'remote',
  InClinic: 'in-clinic',
  Manual: 'manual',
} as const

export type TransmissionReportTransmissionTypeEnum = typeof TransmissionReportTransmissionTypeEnum[keyof typeof TransmissionReportTransmissionTypeEnum];


/**
 * @export
 * @enum {string}
 */
export enum TransmissionReportColorEnum {
  Red = 'RED',
  Yellow = 'YELLOW',
  Green = 'GREEN',
  Gray = 'GRAY',
}

/**
 * @export
 * @enum {string}
 */
export enum TransmissionReportSignatureStatusEnum {
  Signed = 'SIGNED',
  NonPractitionerSigned = 'NON_PRACTITIONER_SIGNED',
  NotSigned = 'NOT_SIGNED',
  Dismissed = 'DISMISSED',
  SIGNATURE_NOT_REQUIRED = 'SIGNATURE_NOT_REQUIRED'
}

/**
 *
 * @export
 * @interface TransmissionReportAddAlertImageParams
 */
export interface TransmissionReportAddAlertImageParams {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAddAlertImageParams
   */
  fileId: string
}

/**
 *
 * @export
 * @interface TransmissionReportAddAlertImageResponse
 */
export interface TransmissionReportAddAlertImageResponse {
  /**
   *
   * @type {TransmissionReportImage}
   * @memberof TransmissionReportAddAlertImageResponse
   */
  image: TransmissionReportImage
}

/**
 *
 * @export
 * @interface TransmissionReportAlert
 */
export interface TransmissionReportAlert {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAlert
   */
  id: string
  /**
   *
   * @type {TransmissionReport}
   * @memberof TransmissionReportAlert
   */
  transmissionReport: TransmissionReport
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAlert
   */
  title: string
  /**
   *
   * @type {Array<TransmissionReportNote>}
   * @memberof TransmissionReportAlert
   */
  notes: Array<TransmissionReportNote>
  /**
   *
   * @type {Array<TransmissionReportImage>}
   * @memberof TransmissionReportAlert
   */
  images: Array<TransmissionReportImage>
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAlert
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAlert
   */
  updatedAt: string
}

/**
 *
 * @export
 * @interface TransmissionReportAlertCreateParams
 */
export interface TransmissionReportAlertCreateParams {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAlertCreateParams
   */
  title?: string
}

/**
 *
 * @export
 * @interface TransmissionReportAlertDeleteResponse
 */
export interface TransmissionReportAlertDeleteResponse {
  /**
   *
   * @type {boolean}
   * @memberof TransmissionReportAlertDeleteResponse
   */
  deleted: boolean
}

/**
 *
 * @export
 * @interface TransmissionReportAlertResponse
 */
export interface TransmissionReportAlertResponse {
  /**
   *
   * @type {TransmissionReportAlert}
   * @memberof TransmissionReportAlertResponse
   */
  alert: TransmissionReportAlert
}

/**
 *
 * @export
 * @interface TransmissionReportAlertUpdateParams
 */
export interface TransmissionReportAlertUpdateParams {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAlertUpdateParams
   */
  title: string
}

/**
 *
 * @export
 * @interface TransmissionReportBillingCreateParams
 */
export interface TransmissionReportBillingCreateParams {
  /**
   *
   * @type {BillingObject}
   * @memberof TransmissionReportBillingCreateParams
   */
  billingobject: BillingObject
}

/**
 *
 * @export
 * @interface TransmissionReportBillingResponse
 */
export interface TransmissionReportBillingResponse {
  /**
   *
   * @type {Billing}
   * @memberof TransmissionReportBillingResponse
   */
  billing: Billing
}

/**
 *
 * @export
 * @interface TransmissionReportBillingUpdateParams
 */
export interface TransmissionReportBillingUpdateParams {
  /**
   *
   * @type {BillingObject}
   * @memberof TransmissionReportBillingUpdateParams
   */
  billingobject: BillingObject
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TransmissionReportColors {
  Red = 'RED',
  Yellow = 'YELLOW',
  Green = 'GREEN',
  Gray = 'GRAY',
}

/**
 *
 * @export
 * @interface TransmissionReportCountParamsDto
 */
export interface TransmissionReportCountParamsDto {
  /**
   *
   * @type {TransmissionReportSignatureStatuses}
   * @memberof TransmissionReportCountParamsDto
   */
  signatureStatus?: TransmissionReportSignatureStatuses
  /**
   *
   * @type {Array<string>}
   * @memberof TransmissionReportCountParamsDto
   */
  clinicIds?: Array<string>
}

/**
 *
 * @export
 * @interface TransmissionReportCountResponseDto
 */
export interface TransmissionReportCountResponseDto {
  /**
   *
   * @type {object}
   * @memberof TransmissionReportCountResponseDto
   */
  counts: object
  /**
   *
   * @type {Array<string>}
   * @memberof TransmissionReportCountResponseDto
   */
  clinicIds: Array<string>

  /**
   *
   *  *
   *   *
   *  @type {Array<FileUploadStatus>}
   *  * @memberof TransmissionReportDtoFilesUploadStatus
   *  */
  filesUploadStatus: Array<FileUploadStatus>;
}

/**
 *
 * @export
 * @interface TransmissionReportCountersCreateParams
 */
export interface TransmissionReportCountersCreateParams {
  /**
   *
   * @type {CountersObject}
   * @memberof TransmissionReportCountersCreateParams
   */
  countersobject: CountersObject
}

/**
 *
 * @export
 * @interface TransmissionReportCountersResponse
 */
export interface TransmissionReportCountersResponse {
  /**
   *
   * @type {Counters}
   * @memberof TransmissionReportCountersResponse
   */
  counters: Counters
}

/**
 *
 * @export
 * @interface ProgrammedParameters
 */
export interface ProgrammedParameters {
  /**
   *
   * @type {string}
   * @memberof ProgrammedParameters
   */
  'id': string;
  /**
   *
   * @type {string}
   * @memberof ProgrammedParameters
   */
  'mode': string | null;
  /**
   *
   * @type {string}
   * @memberof ProgrammedParameters
   */
  'lowerRate': string | null;
  /**
   *
   * @type {string}
   * @memberof ProgrammedParameters
   */
  'upperRate': string | null;
  /**
   *
   * @type {string}
   * @memberof ProgrammedParameters
   */
  'atOrAf': string | null;
  /**
   *
   * @type {string}
   * @memberof ProgrammedParameters
   */
  'vtZone1': string | null;
  /**
   *
   * @type {string}
   * @memberof ProgrammedParameters
   */
  'vtZone2': string | null;
  /**
   *
   * @type {string}
   * @memberof ProgrammedParameters
   */
  'vf': string | null;
  /**
   *
   * @type {number}
   * @memberof ProgrammedParameters
   */
  'transmissionId': number;
}
/**
*
* @export
* @interface ProgrammedParametersDto
*/
export interface ProgrammedParametersDto {
  id?: string
  /**
   *
   * @type {string}
   * @memberof ProgrammedParametersDto
   */
  'mode'?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProgrammedParametersDto
   */
  'lowerRate'?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProgrammedParametersDto
   */
  'upperRate'?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProgrammedParametersDto
   */
  'atOrAf'?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProgrammedParametersDto
   */
  'vtZone1'?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProgrammedParametersDto
   */
  'vtZone2'?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProgrammedParametersDto
   */
  'vf'?: string | null;
}

/**
 *
 * @export
 * @interface TransmissionReportCountersUpdateParams
 */
export interface TransmissionReportCountersUpdateParams {
  /**
   *
   * @type {CountersObject}
   * @memberof TransmissionReportCountersUpdateParams
   */
  countersobject: CountersObject
}

/**
 *
 * @export
 * @interface TransmissionReportDeleteAlertImageResponse
 */
export interface TransmissionReportDeleteAlertImageResponse {
  /**
   *
   * @type {boolean}
   * @memberof TransmissionReportDeleteAlertImageResponse
   */
  deleted: boolean
}

/**
 *
 * @export
 * @interface TransmissionReportDismissResponse
 */
export interface TransmissionReportDismissResponse {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportDismissResponse
   */
  status: string
}

export interface TransmissionReportPresentingCard {
  /**
   * @type {string}
   * @memberof TransmissionReportPresentingCard
   */
  presentingRhythm: string | null
  /**
   *
   * @type {number}
   * @memberof TransmissionReportPresentingCard
   */
  presentingRate: number | null
  /**
   *
   * @type {string}
   * @memberof TransmissionReportPresentingCard
   */
  underlyingRhythm: string | null
  /**
   *
   * @type {number}
   * @memberof TransmissionReportPresentingCard
   */
  underlyingRate: number | null
}

/**
 *
 * @export
 * @interface TransmissionReportDto
 */
export interface TransmissionReportDto {
  /**
   *
   * @type {VtTransmission}
   * @memberof TransmissionReportDto
   */
  transmission: VtTransmission
  nextScheduledTransmissionDate: Date
  lastBillableDate: Date
  isBillable: boolean
  isEriTracked: boolean
  /**
   *
   * @type {string}
   * @memberof TransmissionReportDto
   */
  transmissionDate: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportDto
   */
  transmissionFileLocation: string | null
  /**
   *
   * @type {string}
   * @memberof TransmissionReportDto
   */
  transmissionFileName: string | null
  /**
   *
   * @type {Array<TransmissionReportNote>}
   * @memberof TransmissionReportDto
   */
  notes: Array<TransmissionReportNote>
  /**
   *
   * @type {Array<TransmissionReportAlert>}
   * @memberof TransmissionReportDto
   */
  alerts: Array<TransmissionReportAlert>
  /**
   *
   * @type {Array<TransmissionReportSignature>}
   * @memberof TransmissionReportDto
   */
  signatures: Array<TransmissionReportSignature>
  /**
   *
   * @type {VtDevice}
   * @memberof TransmissionReportDto
   */
  device?: VtDevice
  matchDevice?:MatchDevice
  /**
   *
   * @type {VtDeviceMeasurement}
   * @memberof TransmissionReportDto
   */
  deviceMeasurement?: VtDeviceMeasurement
  /**
   *
   * @type {Array<VtDeviceLead>}
   * @memberof TransmissionReportDto
   */
  deviceLeads?: Array<VtDeviceLead>
  /**
   *
   * @type {VtVendor}
   * @memberof TransmissionReportDto
   */
  transmissionVendor?: VtVendor
  /**
   *
   * @type {VtVendor}
   * @memberof TransmissionReportDto
   */
  deviceVendor?: VtVendor
  /**
   *
   * @type {object}
   * @memberof TransmissionReportDto
   */
  overrides: object
  /**
   *
   * @type {Counters}
   * @memberof TransmissionReportDto
   */
  counters: Counters
  /**
   *
   * @type {Billing}
   * @memberof TransmissionReportDto
   */
  billing?: Billing
  /**
   *
   * @type {TransmissionReportDtoFilesUploadStatus}
   * @memberof TransmissionReportDto
   */
  filesUploadStatus: TransmissionReportDtoFilesUploadStatus | null;
  /**
   *
   * @type {number}
   * @memberof TransmissionReportDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TransmissionReportDto
   */
  color: TransmissionReportDtoColorEnum
  /**
   *
   * @type {Patient}
   * @memberof TransmissionReportDto
   */
  patient: Patient | null
  /**
   *
   * @type {string}
   * @memberof TransmissionReportDto
   */
  signatureStatus: TransmissionReportDtoSignatureStatusEnum
  /**
   *
   * @type {string}
   * @memberof TransmissionReportDto
   */
  orderStatus: string | null
  /**
   *
   * @type {string}
   * @memberof TransmissionReportDto
   */
  smartNarrativeStatus: string | null
  /**
   *
   * @type {string}
   * @memberof TransmissionReportDto
   */
  comment: string | null
  account: Organization

  /**
   *
   * @type {string}
   * @memberof TransmissionReport
   */
  smartNarrativeStatusUpdated: string | null
  transmissionType: string
  /**
   *
   * @type {boolean}
   * @memberof TransmissionReportDto
   */
  flagHeartFailure: boolean | null

  isEdited: boolean | false
  editedUser: string
  editedDate: Date

  /**
   *
   * @type {string}
   * @memberof TransmissionReportDto
   */
  followUpReason: string | null
  /**
   *
   * @type {string}
   * @memberof TransmissionReportDto
   */
  otherReason: string | null
  /**
   *
   * @type {string}
   * @memberof TransmissionReportDto
   */
  programmingChanges: string | null

  /**
   *
   * @memberof TransmissionReportDto
   */
  presentingCard?: TransmissionReportPresentingCard
  /**
   *
   * @memberof TransmissionReportDto
   */
  inClinicEpisode?: TransmissionReportInClinicEpisode


  activeTemplate: ReportTemplate | null

  previousTxDeviceMeasurement?: VtDeviceMeasurement
  previousTxDeviceLeads?: Array<VtDeviceLead>

      /**
     *
     * @type {ProgrammedParameters}
     * @memberof TransmissionReportDto
     */
      'programmedParameter'?: ProgrammedParameters;

  /**
   *
   * @type {IcdDiagnosisCode}
   * @memberof TransmissionReportDto
   */
   icdDiagnosis:IcdDiagnosisCode | null;
}

/**
 * @export
 * @enum {string}
 */
export enum TransmissionReportDtoColorEnum {
  Red = 'RED',
  Yellow = 'YELLOW',
  Green = 'GREEN',
  Gray = 'GRAY',
}

/**
 * @export
 * @enum {string}
 */
export enum TransmissionReportDtoSignatureStatusEnum {
  Signed = 'SIGNED',
  NonPractitionerSigned = 'NON_PRACTITIONER_SIGNED',
  NotSigned = 'NOT_SIGNED',
  Dismissed = 'DISMISSED',
}

/**
 *
 * @export
 * @interface TransmissionReportImage
 */
export interface TransmissionReportImage {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportImage
   */
  id: string
  /**
   *
   * @type {TransmissionReport}
   * @memberof TransmissionReportImage
   */
  transmissionReport: TransmissionReport
  /**
   *
   * @type {any}
   * @memberof TransmissionReportImage
   */
  image: any
  /**
   *
   * @type {string}
   * @memberof TransmissionReportImage
   */
  location: TransmissionReportImageLocationEnum
  /**
   *
   * @type {TransmissionReportAlert}
   * @memberof TransmissionReportImage
   */
  alert: TransmissionReportAlert | null
  /**
   *
   * @type {string}
   * @memberof TransmissionReportImage
   */
  createdAt: string
}

/**
 * @export
 * @enum {string}
 */
export enum TransmissionReportImageLocationEnum {
  Alert = 'ALERT',
  MonitoredValues = 'MONITORED_VALUES',
  BatteryStatus = 'BATTERY_STATUS',
  Transmission = 'TRANSMISSION',
}

/**
 *
 * @export
 * @interface TransmissionReportListRequestDto
 */
export interface TransmissionReportListRequestDto {
  /**
   *
   * @type {TransmissionReportColors}
   * @memberof TransmissionReportListRequestDto
   */
  color?: TransmissionReportColors
  /**
   *
   * @type {TransmissionReportSignatureStatuses}
   * @memberof TransmissionReportListRequestDto
   */
  signatureStatus?: TransmissionReportSignatureStatuses
  /**
   *
   * @type {Array<string>}
   * @memberof TransmissionReportListRequestDto
   */
  clinicIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof TransmissionReportListRequestDto
   */
  patientId?: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportListRequestDto
   */
  deviceSerialNumber?: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportListRequestDto
   */
  deviceModelNumber?: string
  /**
   *
   * @type {number}
   * @memberof TransmissionReportListRequestDto
   */
  offset?: number
  /**
   *
   * @type {number}
   * @memberof TransmissionReportListRequestDto
   */
  limit?: number
}

/**
 *
 * @export
 * @interface TransmissionReportListResponseDto
 */
export interface TransmissionReportListResponseDto {
  /**
   *
   * @type {Array<TransmissionReportDto>}
   * @memberof TransmissionReportListResponseDto
   */
  transmissionReports: Array<TransmissionReportDto>
  /**
   *
   * @type {Array<string>}
   * @memberof TransmissionReportListResponseDto
   */
  clinicIds: Array<string>
  /**
   *
   * @type {string}
   * @memberof TransmissionReportListResponseDto
   */
  color?: TransmissionReportListResponseDtoColorEnum
  /**
   *
   * @type {string}
   * @memberof TransmissionReportListResponseDto
   */
  patientId?: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportListResponseDto
   */
  deviceSerialNumber?: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportListResponseDto
   */
  deviceModelNumber?: string
  /**
   *
   * @type {number}
   * @memberof TransmissionReportListResponseDto
   */
  currentOffset: number
  /**
   *
   * @type {number}
   * @memberof TransmissionReportListResponseDto
   */
  total: number
  /**
   *
   * @type {number}
   * @memberof TransmissionReportListResponseDto
   */
  totalNotDownloadedCount: number;
}

/**
 * @export
 * @enum {string}
 */
export enum TransmissionReportListResponseDtoColorEnum {
  Red = 'RED',
  Yellow = 'YELLOW',
  Green = 'GREEN',
  Gray = 'GRAY',
}

/**
 *
 * @export
 * @interface TransmissionReportSignAllLambdaResponse
 */
export interface TransmissionReportSignAllLambdaResponse {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportSignAllLambdaResponse
   */
  status: string
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TransmissionReportLocations {
  Alert = 'ALERT',
  MonitoredValues = 'MONITORED_VALUES',
  BatteryStatus = 'BATTERY_STATUS',
  Transmission = 'TRANSMISSION',
}

/**
 *
 * @export
 * @interface TransmissionReportNote
 */
export interface TransmissionReportNote {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNote
   */
  id: string
  /**
   *
   * @type {boolean}
   * @memberof TransmissionReportNote
   */
  isNew: boolean
  /**
   *
   * @type {TransmissionReport}
   * @memberof TransmissionReportNote
   */
  transmissionReport: TransmissionReport
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNote
   */
  note: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNote
   */
  location: TransmissionReportNoteLocationEnum
  /**
   *
   * @type {TransmissionReportAlert}
   * @memberof TransmissionReportNote
   */
  alert: TransmissionReportAlert | null
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNote
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNote
   */
  updatedAt: string

  /**
   *
   * @type {boolean}
   * @memberof TransmissionReportNote
   */
  flag: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum TransmissionReportNoteLocationEnum {
  Alert = 'ALERT',
  MonitoredValues = 'MONITORED_VALUES',
  BatteryStatus = 'BATTERY_STATUS',
  Transmission = 'TRANSMISSION',
}

/**
 *
 * @export
 * @interface TransmissionReportNoteCreateParams
 */
export interface TransmissionReportNoteCreateParams {
  /**
   *
   * @type {TransmissionReportLocations}
   * @memberof TransmissionReportNoteCreateParams
   */
  location: TransmissionReportLocations
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNoteCreateParams
   */
  note: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNoteCreateParams
   */
  alertId?: string

  /**
   *
   * @type {string}
   * @memberof TransmissionReportNoteCreateParams
   */
  noteId?: string

  /**
   *
   * @type {string}
   * @memberof TransmissionReportNoteCreateParams
   */
  flag?: boolean
}

/**
 *
 * @export
 * @interface TransmissionReportNoteDeleteResponse
 */
export interface TransmissionReportNoteDeleteResponse {
  /**
   *
   * @type {boolean}
   * @memberof TransmissionReportNoteDeleteResponse
   */
  deleted: boolean
}

/**
 *
 * @export
 * @interface TransmissionReportNoteResponse
 */
export interface TransmissionReportNoteResponse {
  /**
   *
   * @type {TransmissionReportNote}
   * @memberof TransmissionReportNoteResponse
   */
  note: TransmissionReportNote
}

/**
 *
 * @export
 * @interface TransmissionReportNoteUpdateParams
 */
export interface TransmissionReportNoteUpdateParams {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNoteUpdateParams
   */
  note: string
}

/**
 *
 * @export
 * @interface TransmissionReportPdfResponse
 */
export interface TransmissionReportPdfResponse {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportPdfResponse
   */
  'status': string;
  /**
   *
   * @type {string}
   * @memberof TransmissionReportPdfResponse
   */
  'notes': string;
}

/**
 *
 * @export
 * @interface TransmissionReportPDFDownloadInfoResponse
 */
export interface TransmissionReportPDFDownloadInfoResponse {
  /**
   *
   * @type {object}
   * @memberof TransmissionReportPDFDownloadInfoResponse
   */
  'stored': object;
}

/**
 *
 * @export
 * @interface TransmissionReportSignAllLambdaResponse
 */
export interface TransmissionReportSignAllLambdaResponse {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportSignAllLambdaResponse
   */
  status: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportPdfResponse
   */
  'notes': string;
}

/**
 *
 * @export
 * @interface TransmissionReportSignParams
 */
export interface TransmissionReportSignParams {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportSignParams
   */
  timezone: string;
  /**
   *
   * @type {string}
   * @memberof TransmissionReportSignParams
   */
  signDate?: string;
}

/**
 *
 * @export
 * @interface TransmissionReportSignAllParams
 */
export interface TransmissionReportSignAllParams {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportSignAllParams
   */
  'signAll': string;
}

/**
 *
 * @export
 * @interface ReportDeviceType
 */
export interface ReportDeviceType {
  /**
   *
   * @type {string}
   * @memberof ReportDeviceType
   */
  'id': string;
  /**
   *
   * @type {string}
   * @memberof ReportDeviceType
   */
  'patientId': string;
  /**
   *
   * @type {string}
   * @memberof ReportDeviceType
   */
  'device_type': string;
}

/**
 *
 * @export
 * @interface TransmissionReportSignResponse
 */
export interface TransmissionReportSignResponse {
  /**
   *
   * @type {TransmissionReportSignature}
   * @memberof TransmissionReportSignResponse
   */
  signature: TransmissionReportSignature
}
/**
 *
 * @export
 * @interface TransmissionReportSignature
 */
export interface TransmissionReportSignature {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportSignature
   */
  id: string
  /**
   *
   * @type {TransmissionReport}
   * @memberof TransmissionReportSignature
   */
  transmissionReport: TransmissionReport
  /**
   *
   * @type {User}
   * @memberof TransmissionReportSignature
   */
  user: User
  /**
   *
   * @type {string}
   * @memberof TransmissionReportSignature
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportSignature
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportSignature
   */
  signDate: string
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TransmissionReportSignatureStatuses {
  Signed = 'SIGNED',
  NonPractitionerSigned = 'NON_PRACTITIONER_SIGNED',
  NotSigned = 'NOT_SIGNED',
  Dismissed = 'DISMISSED',
}

/**
 *
 * @export
 * @enum {string}
 */

export const TransmissionReportType = {
  Remote: 'remote',
  InClinic: 'in-clinic',
  Manual: 'manual',
} as const

export type TransmissionReportType = typeof TransmissionReportType[keyof typeof TransmissionReportType];

/**
 *
 * @export
 * @interface TransmissionReportDtoFilesUploadStatus
 */
export interface TransmissionReportDtoFilesUploadStatus {
  /**
   *
   * @type {Array<FileUploadStatus>}
   * @memberof TransmissionReportDtoFilesUploadStatus
   */
  'fileStatus': Array<FileUploadStatus>;
}
export interface TransmissionReportsSubmitManualAndInclinicReportsDtoResponse {
  /**
   *
   * @type {Array<FileUploadStatus>}
   * @memberof TransmissionReportDtoFilesUploadStatus
   */
  filesUploadStatus : Array<FileUploadStatus>;
}

/**
 *
 * @export
 * @interface UnauthorizedExample
 */
export interface UnauthorizedExample {
  /**
   *
   * @type {number}
   * @memberof UnauthorizedExample
   */
  status: number
  /**
   *
   * @type {object}
   * @memberof UnauthorizedExample
   */
  details: object
  /**
   *
   * @type {number}
   * @memberof UnauthorizedExample
   */
  responseType: number
}

/**
 *
 * @export
 * @interface UpdateOrganizationParams
 */
export interface UpdateOrganizationParams {
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationParams
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationParams
   */
  parentId?: string
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationParams
   */
  mrn2?: string
  /**
   *
   * @type {AddressParams}
   * @memberof UpdateOrganizationParams
   */
  address?: AddressParams
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationParams
   */
  phoneNumber?: string
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationParams
   */
  faxNumber?: string
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationParams
   */
  notes?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateOrganizationParams
   */
  hasEmrAccess?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateOrganizationParams
   */
  ehrIntegration?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationParams
   */
  emrName?: string
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationParams
   */
  sentToEhr?: string
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationParams
   */
  goLiveOn?: string
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationParams
   */
  onboardingOn?: string
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationParams
   */
  internalHuddleOn?: string
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationParams
   */
  kickoffHuddleOn?: string
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateOrganizationParams
   */
  fileIds?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof UpdateOrganizationParams
   */
  isSignAll?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateOrganizationParams
   */
  isFollowingPractitionerReq?: boolean
}

/**
 *
 * @export
 * @interface UpdateOrganizationResponse
 */
export interface UpdateOrganizationResponse {
  /**
   *
   * @type {Organization}
   * @memberof UpdateOrganizationResponse
   */
  organization: Organization
}

/**
 *
 * @export
 * @interface UpdatePatientAddressParams
 */
export interface UpdatePatientAddressParams {
  /**
   *
   * @type {AddressParams}
   * @memberof UpdatePatientAddressParams
   */
  address: AddressParams
}

/**
 *
 * @export
 * @interface UpdatePatientMatchDeviceParams
 */
export interface UpdatePatientMatchDeviceParams {
  /**
   *
   * @type {Array<MatchDeviceParams>}
   * @memberof UpdatePatientMatchDeviceParams
   */
  devices: Array<MatchDeviceParams>
}

/**
 *
 * @export
 * @interface UpdatePatientParams
 */
export interface UpdatePatientParams {
  /**
   *
   * @type {PatientSex}
   * @memberof UpdatePatientParams
   */
  sex?: PatientSex
  /**
   *
   * @type {PatientStatus}
   * @memberof UpdatePatientParams
   */
  status?: PatientStatus
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  familyName?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  givenName?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  birthDate?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  oac?: UpdatePatientParamsOacEnum
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  referringPhysician?: string
  /**
     *
     * @type {RegisteredNpis}
     * @memberof UpdatePatientParams
     */
  referringProvider?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  monitorReason?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  diagnosisOption?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  practitionerId?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  mrn2?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  ssn?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  phoneNumber?: string
  /**
   *
   * @type {Array<string>}
   * @memberof UpdatePatientParams
   */
  fileIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  emergencyName?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  emergencyPhone?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  patientNotes?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  homePhone?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  referralSource?: string
  /**
   *
   * @type {string}
   * @memberof UpdatePatientParams
   */
  statusNotes?: string
}

/**
 * @export
 * @enum {string}
 */
export enum UpdatePatientParamsOacEnum {
  Yes = 'Yes',
  No = 'No',
  NA = 'N/A',
  NotTolerated = 'Not Tolerated',
}

/**
 *
 * @export
 * @interface BaseAuditEntity
 */
export interface BaseAuditEntity {
  /**
   *
   * @type {number}
   * @memberof BaseAuditEntity
   */
  rev?: number
  /**
   *
   * @type {string}
   * @memberof BaseAuditEntity
   */
  lastUpdatedUser?: string
  /**
   *
   * @type {string}
   * @memberof BaseAuditEntity
   */
  lastUpdatedDate?: string
  /**
   *
   * @type {string}
   * @memberof BaseAuditEntity
   */
  lastUpdatedUserId?: string
}


/**
 *
 * @export
 * @interface BillingAudit
 */
export interface BillingAudit extends BaseAuditEntity {
  /**
   *
   * @type {string}
   * @memberof BillingAudit
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof BillingAudit
   */
  transmissionReportId?: number
  /**
   *
   * @type {BillingObject}
   * @memberof BillingAudit
   */
  billingobject?: BillingObject
  /**
   *
   * @type {string}
   * @memberof BillingAudit
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof BillingAudit
   */
  updatedAt?: string
  /**
   *
   * @type {string}
   * @memberof BillingAudit
   */
  updatedBy?: string
}


/**
 *
 * @export
 * @interface CountersAudit
 */
export interface CountersAudit extends BaseAuditEntity {
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  Id: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  transmissionReportId: number
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  symptomCounters: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  symptomTable: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  tachyCounters: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  tachyTime: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  pauseCounters: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  pauseTime: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  bradyCounters: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  bradyTime: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  ATCounters: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  ATTime: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  AFCounters: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  AFTime: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  TimeInATAFCounters: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  TimeInATAFTime: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof CountersAudit
   */
  updatedBy: string
}

/**
 *
 * @export
 * @interface PatientAudit
 */
export interface PatientAudit extends BaseAuditEntity {

  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  mrn1: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  mrn2: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  familyName: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  givenName: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  birthDate: string
  /**
   *
   * @type {object}
   * @memberof PatientAudit
   */
  sex: object | null
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  phoneNumber: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  ssn: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  status: PatientStatusEnum
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  updatedAt: string

  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  addressId: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  clinicId: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */

  rhythmId: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  oac: PatientOacEnum
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  referringPhysician: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  monitorReason: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  practitionerId: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  emergencyName: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  emergencyPhone: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  patientNotes: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  homePhone: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  referralSource: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  patientStatus: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  statusReason: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  diagnosis_option: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  updatedBy: string
  /**
   *
   * @type {string}
   * @memberof PatientAudit
   */
  createdBy: string
}

/**
 *
 * @export
 * @interface TransmissionReportAlertAudit
 */
export interface TransmissionReportAlertAudit extends BaseAuditEntity {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAlertAudit
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAlertAudit
   */

  title: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAlertAudit
   */

  transmissionReportId: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAlertAudit
   */

  createdAt: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAlertAudit
   */

  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAlertAudit
   */

  updatedBy: string
}

/**
 *
 * @export
 * @interface TransmissionReportAudit
 */
export interface TransmissionReportAudit extends BaseAuditEntity {
  /**
   *
   * @type {number}
   * @memberof TransmissionReportAudit
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  patientId: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  color: TransmissionReportColorEnum

  /**
   *
   * @type {VendorDataOverrides}
   * @memberof TransmissionReportAudit
   */
  overrides: VendorDataOverrides

  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  signatureStatus: TransmissionReportSignatureStatusEnum
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  orderStatus: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  PDFUploadStatus: TransmissionReportPDFUploadStatusEnum
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  PDFUploadNotes: string

  /**
   *
   * @type {boolean}
   * @memberof TransmissionReportAudit
   */
  isEdited: boolean
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  editedUser: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  editedDate: string

  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  PDFUploadKey: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  updatedAt: string

  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  comment: string

  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  transmissionType: TransmissionReportTransmissionTypeEnum
  /**
   *
   * @type {UploadResponse}
   * @memberof TransmissionReportAudit
   */
  filesUploadStatus: UploadResponse
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  smartNarrativeStatus: string | null
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  smartNarrativeStatusUpdated: string | null
  /**
   *
   * @type {boolean}
   * @memberof TransmissionReportAudit
   */
  flagHeartFailure?: boolean
  /**
   *
   * @type {boolean}
   * @memberof TransmissionReportAudit
   */
  downloaded: boolean
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  downloadDate: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  downloadUserId: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportAudit
   */
  updatedBy: string
}

/**
 *
 * @export
 * @interface TransmissionReportImageAudit
 */
export interface TransmissionReportImageAudit extends BaseAuditEntity {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportImageAudit
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportImageAudit
   */
  transmissionReportId: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportImageAudit
   */
  imageId: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportImageAudit
   */
  location: TransmissionReportImageLocationEnum
  /**
   *
   * @type {string}
   * @memberof TransmissionReportImageAudit
   */
  alertId: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportImageAudit
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportImageAudit
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportImageAudit
   */
  updatedBy: string
}

/**
 *
 * @export
 * @interface TransmissionReportNoteAudit
 */
export interface TransmissionReportNoteAudit extends BaseAuditEntity {
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNoteAudit
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNoteAudit
   */
  transmissionReportId: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNoteAudit
   */
  note: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNoteAudit
   */
  location: TransmissionReportLocations
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNoteAudit
   */
  alertId: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNoteAudit
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNoteAudit
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof TransmissionReportNoteAudit
   */
  updatedBy: string
  /**
   *
   * @type {boolean}
   * @memberof TransmissionReportNoteAudit
   */
  flag: boolean
}

/**
 *
 * @export
 * @interface NewNoteAudit
 */
export interface NewNoteAudit extends BaseAuditEntity {
  /**
   *
   * @type {Record<string, any>}
   * @memberof NewNoteAudit
   */
  object: Record<string, any>
  /**
   *
   * @type {string}
   * @memberof NewNoteAudit
   */
  section: string
  /**
   *
   * @type {string}
   * @memberof NewNoteAudit
   */
  transmissionReportId: string
}

/**
 *
 * @export
 * @interface TransmissionReportHistoryDTO
 */
export interface TransmissionReportHistoryDTO {
  /**
   *
   * @type {transmissionReportAudit}
   * @memberof TransmissionReportHistoryDTO
   */
  transmissionReportAudit: TransmissionReportAudit[]
  /**
   *
   * @type {BillingAudit}
   * @memberof TransmissionReportHistoryDTO
   */
  billingAudit: BillingAudit[]
  /**
   *
   * @type {TransmissionReportNoteAudit}
   * @memberof TransmissionReportHistoryDTO
   */
  transmissionReportNoteAudit: TransmissionReportNoteAudit[]
  /**
   *
   * @type {NewNoteAudit}
   * @memberof TransmissionReportHistoryDTO
   */
  newNoteAudit: NewNoteAudit[]

  /**
   *
   * @type {PatientAudit}
   * @memberof TransmissionReportHistoryDTO
   */
  patientAudit: PatientAudit[]
  /**
   *
   * @type {TransmissionReportImageAudit}
   * @memberof TransmissionReportHistoryDTO
   */
  transmissionReportImageAudit: TransmissionReportImageAudit[]
  /**
   *
   * @type {CountersAudit}
   * @memberof TransmissionReportHistoryDTO
   */
  countersAudit: CountersAudit[]
  /**
   *
   * @type {TransmissionReportAlertAudit}
   * @memberof TransmissionReportHistoryDTO
   */
  transmissionReportAlertAudit: TransmissionReportAlertAudit[]
}

/**
 *
 * @export
 * @interface  TransmissionReportHistoryDTO
 */
export interface TransmissionReportHistoryResponse {
  /**
   *
   * @type {TransmissionReportHistoryDTO}
   * @memberof TransmissionReportHistoryResponse
   */
  transmissionReportHistory: TransmissionReportHistoryDTO
}

/**
 *
 * @export
 * @interface  ReportHistoryView
 */
export interface ReportHistoryView {
  /**
   *
   * @type {TransmissionReportHistoryDTO}
   * @memberof string
   */
  DATE: string
  /**
   *
   * @type {TransmissionReportHistoryDTO}
   * @memberof string
   */
  SECTION: string
  /**
   *
   * @type {TransmissionReportHistoryDTO}
   * @memberof string
   */
  USER: string
}

export interface UpdatePatientResponse {
  /**
   *
   * @type {Patient}
   * @memberof UpdatePatientResponse
   */
  patient: Patient
}

/**
 *
 * @export
 * @interface UpdateTransmissionReportParams
 */
export interface UpdateTransmissionReportParams {
  /**
   *
   * @type {TransmissionReportColors}
   * @memberof UpdateTransmissionReportParams
   */
  color?: TransmissionReportColors
  /**
   *
   * @type {number}
   * @memberof UpdateTransmissionReportParams
   */
  offset?: number
  /**
   *
   * @type {number}
   * @memberof UpdateTransmissionReportParams
   */
  limit?: number
  /**
   *
   * @type {number}
   * @memberof UpdateTransmissionReportParams
   */
  comment?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateTransmissionReportParams
   */
  flagHeartFailure?: boolean

  oac?: PatientOacEnum

  referringPhysician?: string

  diagnosisOption?: string

  practitionerId?: string

  followUpReason?: string

  otherReason?: string

  presentingRhythm?: string

  presentingRate?: number

  underlyingRhythm?: string

  underlyingRate?: number

  programmingChanges?: string
}

/**
 *
 * @export
 * @interface UpdateTransmissionReportResponse
 */
export interface UpdateTransmissionReportResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateTransmissionReportResponse
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateTransmissionReportResponse
   */
  color: UpdateTransmissionReportResponseColorEnum
  /**
   *
   * @type {Patient}
   * @memberof UpdateTransmissionReportResponse
   */
  patient: Patient | null
  /**
   *
   * @type {string}
   * @memberof UpdateTransmissionReportResponse
   */
  signatureStatus: UpdateTransmissionReportResponseSignatureStatusEnum
  /**
   *
   * @type {string}
   * @memberof UpdateTransmissionReportResponse
   */
  orderStatus: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateTransmissionReportResponse
   */
  comment: string | null
  /**
   *
   * @type {boolean}
   * @memberof UpdateTransmissionReportResponse
   */
  flagHeartFailure: boolean | null
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateTransmissionReportResponseColorEnum {
  Red = 'RED',
  Yellow = 'YELLOW',
  Green = 'GREEN',
  Gray = 'GRAY',
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateTransmissionReportResponseSignatureStatusEnum {
  Signed = 'SIGNED',
  NonPractitionerSigned = 'NON_PRACTITIONER_SIGNED',
  NotSigned = 'NOT_SIGNED',
  Dismissed = 'DISMISSED',
}

/**
 *
 * @export
 * @interface UpdateUserAddressParams
 */
export interface UpdateUserAddressParams {
  /**
   *
   * @type {AddressParams}
   * @memberof UpdateUserAddressParams
   */
  address: AddressParams
}

/**
 *
 * @export
 * @interface UpdateUserParams
 */
export interface UpdateUserParams {
  /**
   *
   * @type {Array<Prefixes>}
   * @memberof UpdateUserParams
   */
  prefix?: Array<Prefixes>
  /**
   *
   * @type {Roles}
   * @memberof UpdateUserParams
   */
  role?: Roles
  /**
   *
   * @type {string}
   * @memberof UpdateUserParams
   */
  given?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserParams
   */
  family?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserParams
   */
  birthDate?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserParams
   */
  phoneNumber?: string
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateUserParams
   */
  organizationIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof UpdateUserParams
   */
  mobileNumber?: string
  /**
   *
   * @type {AddressParams}
   * @memberof UpdateUserParams
   */
  address?: AddressParams
}

/**
 *
 * @export
 * @interface UpdateUserResponse
 */
export interface UpdateUserResponse {
  /**
   *
   * @type {User}
   * @memberof UpdateUserResponse
   */
  user: User
}

/**
 *
 * @export
 * @interface UpdateVendorIntegrationStatusParams
 */
export interface UpdateVendorIntegrationStatusParams {
  /**
   *
   * @type {VendorIntegrationStatus}
   * @memberof UpdateVendorIntegrationStatusParams
   */
  status: VendorIntegrationStatus
}

/**
 *
 * @export
 * @interface UpdateVendorIntegrationStatusResponse
 */
export interface UpdateVendorIntegrationStatusResponse {
  /**
   *
   * @type {OrganizationVendorIntegration}
   * @memberof UpdateVendorIntegrationStatusResponse
   */
  integration: OrganizationVendorIntegration
}

/**
 *
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
  /**
   *
   * @type {string}
   * @memberof UploadFileResponse
   */
  url: string
  /**
   *
   * @type {any}
   * @memberof UploadFileResponse
   */
  file: any
  /**
   *
   * @type {object}
   * @memberof UploadFileResponse
   */
  base64Url: object
}

/**
 *
 * @export
 * @interface UploadResponse
 */
export interface UploadResponse {
  /**
   *
   * @type {Array<FileUploadStatus>}
   * @memberof UploadResponse
   */
  'fileStatus': Array<FileUploadStatus>;
}

export interface ManualORInclinicTransmissionReportResponse {
  fileStatus: UploadResponse | null
}

/**
 *
 * @export
 * @interface User
 */
export interface User {
  credentials: any
  /**
   *
   * @type {Roles}
   * @memberof User
   */
  role: Roles
  /**
   *
   * @type {string}
   * @memberof User
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  family: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  given: string
  /**
   *
   * @type {Array<string>}
   * @memberof User
   */
  prefix: Array<UserPrefixEnum>
  /**
   *
   * @type {Array<string>}
   * @memberof User
   */
  suffix: Array<string>
  /**
   *
   * @type {string}
   * @memberof User
   */
  birthDate: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  phoneNumber: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  mobileNumber: string
  /**
   *
   * @type {Address}
   * @memberof User
   */
  address: Address
  /**
   *
   * @type {string}
   * @memberof User
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  updatedAt: string
  /**
   *
   * @type {object}
   * @memberof User
   */
  photo: object
  /**
   *
   * @type {Array<Organization>}
   * @memberof User
   */
  organizations: Array<Organization>
  /**
   *
   * @type {Organization}
   * @memberof User
   */
  parentOrganization?: Organization
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastLogin?: string

  /**
   *
   * @type {Array<ClinicalNote>}
   * @memberof User
   */
  createdNotes: Array<ClinicalNote>;
  /**
   *
   * @type {Array<ClinicalNote>}
   * @memberof User
   */
  updatedNotes: Array<ClinicalNote>;
  /**
     *
     * @type {number}
     * @memberof User
     */
  featureFlagRole: number;
}

/**
 * @export
 * @enum {string}
 */
export enum UserPrefixEnum {
  Dr = 'Dr',
  Mr = 'Mr',
  Mrs = 'Mrs',
  Ms = 'Ms',
  Miss = 'Miss',
}

/**
 *
 * @export
 * @interface VendorDataOverrides
 */
export interface VendorDataOverrides {
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'device.batteryStatus'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'device.batteryVoltage'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'device.deviceImplantDate'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'device.batteryMeasurementTime'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'device.batteryRemainingLongevity'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'device.batteryPercentRemaining'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'device.capacitorChargeTime'?: string | null
  /**
   *
   * @type {number}
   * @memberof VendorDataOverrides
   */
  'device.vendorId'?: number | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'deviceMeasurement.afBurden'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'deviceMeasurement.apAPacePercent'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'deviceMeasurement.rvpRvPacePercent'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'deviceMeasurement.bvpPercent'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'deviceMeasurement.lvpPercent'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'deviceMeasurement.hvImpedance'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'deviceLeads.pulsewidth'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'deviceLeads.sensing'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'deviceLeads.impedance'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'deviceLeads.threshold'?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  'deviceLeads.leadModelNumber'?: string | null
  /**
   *
   * @type {number}
   * @memberof VendorDataOverrides
   */
  'deviceLeads.vendorId'?: number | null
  /**
   *
   * @type {number}
   * @memberof VendorDataOverrides
   */
  deviceLeadId?: number | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  egmImage?: string | null
  /**
   *
   * @type {string}
   * @memberof VendorDataOverrides
   */
  egmImageStatus?: string | null

  // Programmed Params
  'deviceMeasurement.mode'?: string | null
  'deviceMeasurement.lowerRate'?: string | null
  'deviceMeasurement.upperRate'?: string | null
  'deviceMeasurement.atOrAf'?: string | null
  'deviceMeasurement.vtZone1'?: string | null
  'deviceMeasurement.vtZone2'?: string | null
  'deviceMeasurement.vf'?: string | null

// counters
  'deviceCounters.symptomCounters'?: string | null
  'deviceCounters.symptomTable'?: string | null
  'deviceCounters.tachyCounters'?: string | null
  'deviceCounters.tachyTime'?: string | null
  'deviceCounters.pauseCounters'?: string | null
  'deviceCounters.pauseTime'?: string | null
  'deviceCounters.bradyCounters'?: string | null
  'deviceCounters.bradyTime'?: string | null
  'deviceCounters.ATCounters'?: string | null
  'deviceCounters.ATTime'?: string | null
  'deviceCounters.AFCounters'?: string | null
  'deviceCounters.AFTime'?: string | null
  'deviceCounters.TimeInATAFCounters'?: string | null
  'deviceCounters.TimeInATAFTime'?: string | null
}

/**
 *
 * @export
 * @enum {string}
 */

export enum VendorIntegrationStatus {
  Configured = 'configured',
  Pending = 'pending',
  Disabled = 'disabled',
}

/**
 *
 * @export
 * @interface VendorDeviceTypeResponse
 */
export interface VendorDeviceTypeResponse {
  /**
   *
   * @type {string}
   * @memberof VendorDeviceTypeResponse
   */
  deviceType: string
  /**
   *
   * @type {boolean}
   * @memberof VendorDeviceTypeResponse
   */
  ILR: boolean
}

/**
 *
 * @export
 * @interface VendorTransmissionReportUrlParamsDto
 */
export interface VendorTransmissionReportUrlParamsDto {
  /**
   *
   * @type {string}
   * @memberof VendorTransmissionReportUrlParamsDto
   */
  filename: string
}

/**
 *
 * @export
 * @interface VendorTransmissionReportUrlResponseDto
 */
export interface VendorTransmissionReportUrlResponseDto {
  /**
   *
   * @type {string}
   * @memberof VendorTransmissionReportUrlResponseDto
   */
  vendorTransmissionReportUrl: string
}

/**
 *
 * @export
 * @interface VendorTransmissionReportsResponseDto
 */
export interface VendorTransmissionReportsResponseDto {
  /**
   *
   * @type {Array<object>}
   * @memberof VendorTransmissionReportsResponseDto
   */
  vendorTransmissionReports: Array<object>
}

/**
 *
 * @export
 * @interface VtDevice
 */
export interface VtDevice {
  /**
   *
   * @type {number}
   * @memberof VtDevice
   */
  deviceId: number
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  deviceType: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  deviceSerialNumber: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  deviceModelNumber: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  deviceExternalId: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  vendorId: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  deviceImplantDate: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  batteryMeasurementTime: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  batteryStatus: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  batteryVoltage: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  batteryRemainingLongevity: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  batteryPercentRemaining: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  pacingMode: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  vendorPacingMode: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  lowerRate: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  maxTrackRate: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  maxSensorRate: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  sensedAvDelay: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  pacedAvDelay: string | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  capacitorChargeTime: string | null
  /**
   *
   * @type {VtTransmission}
   * @memberof VtDevice
   */
  transmission: VtTransmission | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  createdAt: string | null
  /**
   *
   * @type {number}
   * @memberof VtDevice
   */
  recordState: number | null
  /**
   *
   * @type {string}
   * @memberof VtDevice
   */
  updatedAt: string | null
  /**
   *
   * @type {VtDeviceModel}
   * @memberof VtDevice
   */
  model: VtDeviceModel | null
}

/**
 *
 * @export
 * @interface VtDeviceLead
 */
export interface VtDeviceLead {
  /**
   *
   * @type {number}
   * @memberof VtDeviceLead
   */
  leadId: number
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  deviceExternalId: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  vendorId: string | null
  /**
   *
   * @type {VtVendor}
   * @memberof VtDeviceLead
   */
  vendor?: VtVendor
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  leadLocation: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  leadModelNumber: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  leadSerialNumber: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  leadImplantDate: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  sensing: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  threshold: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  pulsewidth: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  impedance: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  pacingOutput: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  pacingPulsewidth: string | null
  /**
   *
   * @type {VtTransmission}
   * @memberof VtDeviceLead
   */
  transmission: VtTransmission | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  createdAt: string | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceLead
   */
  recordState: number | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceLead
   */
  updatedAt: string | null

}

/**
 *
 * @export
 * @interface VtDeviceMeasurement
 */
export interface VtDeviceMeasurement {
  /**
   *
   * @type {number}
   * @memberof VtDeviceMeasurement
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  deviceExternalId: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  vendorId: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  heartRateMonitoringStartInterval: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  heartRateMonitoringEndInterval: string | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceMeasurement
   */
  atrialRates: number | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceMeasurement
   */
  ventricularRates: number | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  pacingPercentageStartDate: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  pacingPercentageEndDate: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  apAPacePercent: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  rvpRvPacePercent: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  apVp: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  asVp: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  apVs: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  asVs: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  atMonitoringIntervalStartDate: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  atMonitoringIntervalEndDate: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  afBurden: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  amsMsPercent: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  amsMsPercentPerDay: string | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceMeasurement
   */
  amsMsCount: number | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  amsMsCountPerDay: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  lvpPercent: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  bvpPercent: string | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceMeasurement
   */
  recentShockEpisodes: number | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceMeasurement
   */
  lifetimeTotalShocks: number | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceMeasurement
   */
  recentAbortedShocks: number | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceMeasurement
   */
  lifetimeAbortedShocks: number | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceMeasurement
   */
  recentAtpEpisodes: number | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceMeasurement
   */
  lifetimeTotalAtp: number | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  lifetimeTherapyTotalStartDate: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  lifetimeTherapyTotalEndDate: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  recentTherapyStartDate: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  recentTherapyEndDate: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  hvImpedanceMsmtStdt: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  hvImpedanceMsmtEndt: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  hvImpedance: string | null
  /**
   *
   * @type {VtTransmission}
   * @memberof VtDeviceMeasurement
   */
  transmission: VtTransmission | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  createdAt: string | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceMeasurement
   */
  recordState: number | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceMeasurement
   */
  updatedAt: string | null

  mode?: string | null
  lowerRate?: string | null
  upperRate?: string | null
  atOrAf?: string | null
  vtZone1?: string | null
  vtZone2?: string | null
  vf?: string | null
}

/**
 *
 * @export
 * @interface VtDeviceModel
 */
export interface VtDeviceModel {
  /**
   *
   * @type {string}
   * @memberof VtDeviceModel
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof VtDeviceModel
   */
  createdOn: string | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceModel
   */
  recordState: number | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceModel
   */
  updatedAt: string | null
  /**
   *
   * @type {boolean}
   * @memberof VtDeviceModel
   */
  mapped: boolean | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceModel
   */
  name: string | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceModel
   */
  statusId: number | null
  /**
   *
   * @type {VtDeviceType}
   * @memberof VtDeviceModel
   */
  type: VtDeviceType | null
  /**
   *
   * @type {VtVendor}
   * @memberof VtDeviceModel
   */
  vendor: VtVendor | null
  /**
   *
   * @type {boolean}
   * @memberof VtDeviceModel
   */
  primaryType: boolean | null
}

/**
 *
 * @export
 * @interface VtDeviceType
 */
export interface VtDeviceType {
  /**
   *
   * @type {string}
   * @memberof VtDeviceType
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof VtDeviceType
   */
  createdOn: string | null
  /**
   *
   * @type {number}
   * @memberof VtDeviceType
   */
  recordState: number | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceType
   */
  updatedOn: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceType
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof VtDeviceType
   */
  parentId: number | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceType
   */
  createdAt: string | null
  /**
   *
   * @type {string}
   * @memberof VtDeviceType
   */
  updatedAt: string | null
}

/**
 *
 * @export
 * @interface VtLeadVendor
 */
export interface VtLeadVendor {
  /**
   *
   * @type {string}
   * @memberof VtLeadVendor
   */
  leadVendorId: string
  /**
   *
   * @type {string}
   * @memberof VtLeadVendor
   */
  leadVendorName: string | null
  /**
   *
   * @type {string}
   * @memberof VtLeadVendor
   */
  leadVendorDescription: string | null
  /**
   *
   * @type {string}
   * @memberof VtLeadVendor
   */
  leadVendorCode: string | null
  /**
   *
   * @type {string}
   * @memberof VtLeadVendor
   */
  createdBy: string | null
  /**
   *
   * @type {string}
   * @memberof VtLeadVendor
   */
  createdOn: string | null
  /**
   *
   * @type {string}
   * @memberof VtLeadVendor
   */
  updatedBy: string | null
  /**
   *
   * @type {string}
   * @memberof VtLeadVendor
   */
  updatedOn: string | null
}

export interface LeadVendor {
  /**
   * @type {number}
   * @memberof LeadVendor
   */
  leadVendorId: number;

  /**
   * @type {string}
   * @memberof LeadVendor
   */
  leadVendorName: string;

  /**
   * @type {string | null}
   * @memberof LeadVendor
   */
  leadVendorDescription: string | null;

  /**
   * @type {string}
   * @memberof LeadVendor
   */
  leadVendorCode: string;

  /**
   * @type {string | null}
   * @memberof LeadVendor
   */
  createdBy: string | null;

  /**
   * @type {string}
   * @memberof LeadVendor
   */
  createdAt: string;

  /**
   * @type {string | null}
   * @memberof LeadVendor
   */
  updatedBy: string | null;

  /**
   * @type {string}
   * @memberof LeadVendor
   */
  updatedAt: string;
}


/**
 *
 * @export
 * @interface VtLeadVendorListParams
 */
export interface VtLeadVendorListParams {
  /**
   *
   * @type {number}
   * @memberof VtLeadVendorListParams
   */
  offset?: number
  /**
   *
   * @type {number}
   * @memberof VtLeadVendorListParams
   */
  limit?: number
}

/**
 *
 * @export
 * @interface VtLeadVendorListResponse
 */
export interface VtLeadVendorListResponse {
  /**
   *
   * @type {Array<VtLeadVendor>}
   * @memberof VtLeadVendorListResponse
   */
  vtLeadVendors: Array<VtLeadVendor>
  /**
   *
   * @type {number}
   * @memberof VtLeadVendorListResponse
   */
  currentOffset: number
  /**
   *
   * @type {number}
   * @memberof VtLeadVendorListResponse
   */
  total: number
  /**
   *
   * @type {number}
   * @memberof VtLeadVendorListResponse
   */
  totalNotDownloadedCount: number;
}

/**
 *
 * @export
 * @interface VtTransmission
 */
export interface VtTransmission {
  /**
   *
   * @type {number}
   * @memberof VtTransmission
   */
  transmissionId: number
  /**
   *
   * @type {string}
   * @memberof VtTransmission
   */
  transmissionDate: string
  /**
   *
   * @type {string}
   * @memberof VtTransmission
   */
  transmissionFileLocation: string | null
  /**
   *
   * @type {string}
   * @memberof VtTransmission
   */
  vendorId: string | null
  /**
   *
   * @type {string}
   * @memberof VtTransmission
   */
  transmissionFileName: string | null
  /**
   *
   * @type {string}
   * @memberof VtTransmission
   */
  deviceExternalId: string | null
  /**
   *
   * @type {number}
   * @memberof VtTransmission
   */
  transmissionCategory: number | null
  /**
   *
   * @type {number}
   * @memberof VtTransmission
   */
  transmissionStatus: number | null
  /**
   *
   * @type {number}
   * @memberof VtTransmission
   */
  patientId: number | null
  /**
   *
   * @type {number}
   * @memberof VtTransmission
   */
  deviceId: number | null
  /**
   *
   * @type {VtDevice}
   * @memberof VtTransmission
   */
  device: VtDevice
  /**
   *
   * @type {string}
   * @memberof VtTransmission
   */
  accountId: string | null
  /**
   *
   * @type {string}
   * @memberof VtTransmission
   */
  rhythmPatientId: string | null
  /**
   *
   * @type {string}
   * @memberof VtTransmission
   */
  createdAt: string | null
  /**
   *
   * @type {number}
   * @memberof VtTransmission
   */
  recordState: number | null
  /**
   *
   * @type {string}
   * @memberof VtTransmission
   */
  updatedAt: string | null
}

/**
 *
 * @export
 * @interface VtVendor
 */
export interface VtVendor {
  /**
   *
   * @type {string}
   * @memberof VtVendor
   */
  vendorId: string
  /**
   *
   * @type {string}
   * @memberof VtVendor
   */
  vendorName: string | null
  /**
   *
   * @type {string}
   * @memberof VtVendor
   */
  vendorDescription: string | null
  /**
   *
   * @type {string}
   * @memberof VtVendor
   */
  vendorCode: string | null
  /**
   *
   * @type {string}
   * @memberof VtVendor
   */
  createdBy: string | null
  /**
   *
   * @type {string}
   * @memberof VtVendor
   */
  createdOn: string | null
  /**
   *
   * @type {string}
   * @memberof VtVendor
   */
  updatedBy: string | null
  /**
   *
   * @type {string}
   * @memberof VtVendor
   */
  updatedOn: string | null
}

/**
 *
 * @export
 * @interface VtVendorListParams
 */
export interface VtVendorListParams {
  /**
   *
   * @type {number}
   * @memberof VtVendorListParams
   */
  offset?: number
  /**
   *
   * @type {number}
   * @memberof VtVendorListParams
   */
  limit?: number
}

/**
 *
 * @export
 * @interface VtVendorListResponse
 */
export interface VtVendorListResponse {
  /**
   *
   * @type {Array<VtVendor>}
   * @memberof VtVendorListResponse
   */
  vtVendors: Array<VtVendor>
  /**
   *
   * @type {number}
   * @memberof VtVendorListResponse
   */
  currentOffset: number
  /**
   *
   * @type {number}
   * @memberof VtVendorListResponse
   */
  total: number
  /**
   *
   * @type {number}
   * @memberof VtVendorListResponse
   */
  totalNotDownloadedCount: number;
}

/**
 *
 * @export
 * @interface CreateAdHocTransmissionDto
 */
export interface CreateAdHocTransmissionDto {
  /**
   *
   * @type {string}
   * @memberof CreateAdHocTransmissionDto
   */
  'deviceId': string;
  /**
   *
   * @type {string}
   * @memberof CreateAdHocTransmissionDto
   */
  'date': string;
  /**
   *
   * @type {string}
   * @memberof CreateAdHocTransmissionDto
   */
  'transmissionType': CreateAdHocTransmissionDtoTransmissionTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateScheduledTransmissionTypeDtoTransmissionTypeEnum {
  HeartFailure = 'heart_failure',
  Routine = 'routine',
  ComboBilling = 'combo_billing',
  EriTracking = 'eri_tracking'
}

/**
 *
 * @export
 * @interface UpdateScheduledTransmissionTypeDto
 */
export interface UpdateScheduledTransmissionTypeDto {
  /**
   *
   * @type {string}
   * @memberof UpdateScheduledTransmissionTypeDto
   */
  'transmissionType': UpdateScheduledTransmissionTypeDtoTransmissionTypeEnum;
}

/**
 *
 * @export
 * @interface TransmissionReportSearchUnsignedReportsRequestDto
 */
export interface TransmissionReportSearchUnsignedReportsRequestDto {
  /**
   *
   * @type {TransmissionReportColors}
   * @memberof TransmissionReportSearchUnsignedReportsRequestDto
   */
  'color'?: TransmissionReportColors;
  /**
   *
   * @type {string}
   * @memberof TransmissionReportSearchUnsignedReportsRequestDto
   */
  'query'?: string;
}

/**
 *
 * @export
 * @interface TransmissionReportSearchUnsignedReportsResponseDto
 */
export interface TransmissionReportSearchUnsignedReportsResponseDto {
  /**
   *
   * @type {Array<UnsignedReportSearchResult>}
   * @memberof TransmissionReportSearchUnsignedReportsResponseDto
   */
  'transmissionReports': Array<UnsignedReportSearchResult>;
}

/**
 *
 * @export
 * @interface UnsignedReportSearchResult
 */
export interface UnsignedReportSearchResult {
  /**
   *
   * @type {number}
   * @memberof UnsignedReportSearchResult
   */
  'id': number;
  /**
   *
   * @type {string}
   * @memberof UnsignedReportSearchResult
   */
  'color': string;
  /**
   *
   * @type {string}
   * @memberof UnsignedReportSearchResult
   */
  'givenName': string;
  /**
   *
   * @type {string}
   * @memberof UnsignedReportSearchResult
   */
  'familyName': string;
  /**
   *
   * @type {string}
   * @memberof UnsignedReportSearchResult
   */
  'deviceType': string;
  /**
   *
   * @type {string}
   * @memberof UnsignedReportSearchResult
   */
  'transmissionDate': string;
  /**
   *
   * @type {string}
   * @memberof UnsignedReportSearchResult
   */
  'deviceSerialNumber': string;
  /**
   *
   * @type {string}
   * @memberof UnsignedReportSearchResult
   */
  'deviceModelNumber': string;
}

export interface TransmissionReportInClinicEpisode {
  id: string

  atrModeSwitch?: string | null

  maxTime?: string | null

  atrialBurden?: string | null

  vhrEpisodes?: string | null

  episodeComments?: string | null
}

/**
 * @export
 * @enum {string}
 */
export enum CreateAdHocTransmissionDtoTransmissionTypeEnum {
  HeartFailure = 'heart_failure',
  Routine = 'routine',
  ComboBilling = 'combo_billing',
  EriTracking = 'eri_tracking'
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerGetInClinicEpisode: async (transmissionReportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists('transmissionReportsControllerGetInClinicEpisode', 'transmissionReportId', transmissionReportId)
      const localVarPath = `/transmission-reports/{transmissionReportId}/inClinicEpisode`
        .replace(`{${"transmissionReportId"}}`, encodeURIComponent(String(transmissionReportId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
    transmissionReportsControllerLeadVendorList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/transmission-reports/lead-vendor/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  },

    /**
     *
     * @param {number} transmissionReportId
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerUpdateInClinicEpisode: async (transmissionReportId: number, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists('transmissionReportsControllerUpdateInClinicEpisode', 'transmissionReportId', transmissionReportId)
      // verify required parameter 'body' is not null or undefined
      assertParamExists('transmissionReportsControllerUpdateInClinicEpisode', 'body', body)
      const localVarPath = `/transmission-reports/{transmissionReportId}/inClinicEpisode`
        .replace(`{${"transmissionReportId"}}`, encodeURIComponent(String(transmissionReportId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },

    /**
     *
     * @param {string} billingId
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingControllerUpdateBilling: async (billingId: string, body: UpdateBillingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'billingId' is not null or undefined
      assertParamExists('billingControllerUpdateBilling', 'billingId', billingId)
      // verify required parameter 'body' is not null or undefined
      assertParamExists('billingControllerUpdateBilling', 'body', body)
      const localVarPath = `/billing/{billingId}`
        .replace(`{${"billingId"}}`, encodeURIComponent(String(billingId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} billingId
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingControllerUpdateIsBillable: async (billingId: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'billingId' is not null or undefined
      assertParamExists('billingControllerUpdateIsBillable', 'billingId', billingId)
      // verify required parameter 'body' is not null or undefined
      assertParamExists('billingControllerUpdateIsBillable', 'body', body)
      const localVarPath = `/billing/{billingId}/billable`
        .replace(`{${"billingId"}}`, encodeURIComponent(String(billingId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerGetBilling: async (transmissionReportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists('transmissionReportsControllerGetBilling', 'transmissionReportId', transmissionReportId)
      const localVarPath = `/transmission-reports/{transmissionReportId}/billing`
        .replace(`{${"transmissionReportId"}}`, encodeURIComponent(String(transmissionReportId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} billingId
     * @param {ReportAddDiagnosisCodeDto} reportAddDiagnosisCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerAddDiagnosisCodeToBilling: async (transmissionReportId: number, billingId: string, reportAddDiagnosisCodeDto: ReportAddDiagnosisCodeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists('transmissionReportsControllerAddDiagnosisCodeToBilling', 'transmissionReportId', transmissionReportId)
      // verify required parameter 'billingId' is not null or undefined
      assertParamExists('transmissionReportsControllerAddDiagnosisCodeToBilling', 'billingId', billingId)
      // verify required parameter 'reportAddDiagnosisCodeDto' is not null or undefined
      assertParamExists('transmissionReportsControllerAddDiagnosisCodeToBilling', 'reportAddDiagnosisCodeDto', reportAddDiagnosisCodeDto)
      const localVarPath = `/transmission-reports/{transmissionReportId}/billing/{billingId}/addDiagnosisCode`
        .replace(`{${"transmissionReportId"}}`, encodeURIComponent(String(transmissionReportId)))
        .replace(`{${"billingId"}}`, encodeURIComponent(String(billingId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: 'PATCH', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(reportAddDiagnosisCodeDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param files
     * @param {Array<string>} [deletedFiles]
     * @param options
     * @throws {RequiredError}
     */
    transmissionReportsControllerAddAttachments: async (transmissionReportId: number, files?: any, deletedFiles?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists('transmissionReportsControllerAddAttachments', 'transmissionReportId', transmissionReportId)
      const localVarPath = `/transmission-reports/{transmissionReportId}/attachments`
        .replace(`{${'transmissionReportId'}}`, encodeURIComponent(String(transmissionReportId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)()

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (files !== undefined && files.length > 0) {
        files.map((file: any) => {
          localVarFormParams.append('files[]', file)
        })
      }

      if (deletedFiles) {
        deletedFiles.map((deletedFile) => {
          localVarFormParams.append('deletedFiles', deletedFile)
        })
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerGetAttachments: async (transmissionReportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists('transmissionReportsControllerGetAttachments', 'transmissionReportId', transmissionReportId)
      const localVarPath = `/transmission-reports/{transmissionReportId}/attachments`
        .replace(`{${'transmissionReportId'}}`, encodeURIComponent(String(transmissionReportId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },

    /**
     *
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesControllerGet: async (
      fileId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileId' is not null or undefined
      assertParamExists('filesControllerGet', 'fileId', fileId)
      const localVarPath = `/files/{fileId}`.replace(
        `{${'fileId'}}`,
        encodeURIComponent(String(fileId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} fileId
     * @param {string} path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesControllerRemove: async (fileId: string, path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'fileId' is not null or undefined
      assertParamExists('filesControllerRemove', 'fileId', fileId)
      // verify required parameter 'path' is not null or undefined
      assertParamExists('filesControllerRemove', 'path', path)
      const localVarPath = `/files/{fileId}/{path}`
        .replace(`{${'fileId'}}`, encodeURIComponent(String(fileId)))
        .replace(`{${'path'}}`, encodeURIComponent(String(path)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'DELETE', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {any} file
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesControllerUpload: async (
      file: any,
      path?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists('filesControllerUpload', 'file', file)
      const localVarPath = `/files/upload`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration &&
          configuration.formDataCtor) ||
        FormData)()

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (file !== undefined) {
        localVarFormParams.append('file', file as any)
      }

      if (path !== undefined) {
        localVarFormParams.append('path', path as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateGlobalFilterParams} createGlobalFilterParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    globalFilterControllerCreate: async (
      createGlobalFilterParams: CreateGlobalFilterParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createGlobalFilterParams' is not null or undefined
      assertParamExists(
        'globalFilterControllerCreate',
        'createGlobalFilterParams',
        createGlobalFilterParams,
      )
      const localVarPath = `/global-filter`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createGlobalFilterParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    globalFilterControllerFindOne: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/global-filter`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateGlobalFilterParams} updateGlobalFilterParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    globalFilterControllerUpdate: async (
      updateGlobalFilterParams: UpdateGlobalFilterParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateGlobalFilterParams' is not null or undefined
      assertParamExists(
        'globalFilterControllerUpdate',
        'updateGlobalFilterParams',
        updateGlobalFilterParams,
      )
      const localVarPath = `/global-filter`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateGlobalFilterParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthControllerPing: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/health/ping`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} transmissionReportId
     *  @param {string} patientId
     *  @param {string} signedDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    historyControllerFindOne: async (transmissionReportId: string, patientId: string, signedDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists('historyControllerFindOne', 'transmissionReportId', transmissionReportId)
      assertParamExists('historyControllerFindOne', 'patientId', patientId)
      assertParamExists('historyControllerFindOne', 'signedDate', signedDate)
      const localVarPath = `/history/{transmissionReportId}/{patientId}/{signedDate}/report-history`
        .replace(`{${'transmissionReportId'}}`, encodeURIComponent(String(transmissionReportId)))
        .replace(`{${'patientId'}}`, encodeURIComponent(String(patientId)))
        .replace(`{${'signedDate'}}`, encodeURIComponent(String(signedDate)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateOrganizationParams} createOrganizationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreate: async (
      createOrganizationParams: CreateOrganizationParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createOrganizationParams' is not null or undefined
      assertParamExists(
        'organizationsControllerCreate',
        'createOrganizationParams',
        createOrganizationParams,
      )
      const localVarPath = `/organizations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOrganizationParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} organizationId
     * @param {CreatePatientParams} createPatientParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreatePatient: async (
      organizationId: string,
      createPatientParams: CreatePatientParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        'organizationsControllerCreatePatient',
        'organizationId',
        organizationId,
      )
      // verify required parameter 'createPatientParams' is not null or undefined
      assertParamExists(
        'organizationsControllerCreatePatient',
        'createPatientParams',
        createPatientParams,
      )
      const localVarPath = `/organizations/{organizationId}/patients`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(organizationId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPatientParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {FindAllOrganizationsQueryParams} [queryParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerFindAll: async (
      queryParams?: FindAllOrganizationsQueryParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/organizations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (queryParams !== undefined) {
        localVarQueryParameter['queryParams'] = queryParams
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} organizationId
     * @param {PatientOffsetPaginationParams} [queryParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerFindAllPatients: async (
      organizationId: string,
      queryParams?: PatientOffsetPaginationParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        'organizationsControllerFindAllPatients',
        'organizationId',
        organizationId,
      )
      const localVarPath = `/organizations/{organizationId}/patients`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(organizationId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (queryParams !== undefined) {
        localVarQueryParameter['queryParams'] = queryParams
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} organizationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerFindOne: async (
      organizationId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        'organizationsControllerFindOne',
        'organizationId',
        organizationId,
      )
      const localVarPath = `/organizations/{organizationId}`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(organizationId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} organizationId
     * @param {UpdateOrganizationParams} updateOrganizationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdate: async (
      organizationId: string,
      updateOrganizationParams: UpdateOrganizationParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdate',
        'organizationId',
        organizationId,
      )
      // verify required parameter 'updateOrganizationParams' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdate',
        'updateOrganizationParams',
        updateOrganizationParams,
      )
      const localVarPath = `/organizations/{organizationId}`.replace(
        `{${'organizationId'}}`,
        encodeURIComponent(String(organizationId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateOrganizationParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} organizationId
     * @param {string} vendorIntegrationId
     * @param {UpdateVendorIntegrationStatusParams} updateVendorIntegrationStatusParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateVendorIntegrationStatus: async (
      organizationId: string,
      vendorIntegrationId: string,
      updateVendorIntegrationStatusParams: UpdateVendorIntegrationStatusParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdateVendorIntegrationStatus',
        'organizationId',
        organizationId,
      )
      // verify required parameter 'vendorIntegrationId' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdateVendorIntegrationStatus',
        'vendorIntegrationId',
        vendorIntegrationId,
      )
      // verify required parameter 'updateVendorIntegrationStatusParams' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdateVendorIntegrationStatus',
        'updateVendorIntegrationStatusParams',
        updateVendorIntegrationStatusParams,
      )
      const localVarPath = `/organizations/{organizationId}/vendor-integrations/{vendorIntegrationId}`
        .replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(organizationId)),
        )
        .replace(
          `{${'vendorIntegrationId'}}`,
          encodeURIComponent(String(vendorIntegrationId)),
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateVendorIntegrationStatusParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} patientId
     * @param {CreatePatientAddressParams} createPatientAddressParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerCreatePatientAddress: async (
      patientId: string,
      createPatientAddressParams: CreatePatientAddressParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists(
        'patientsControllerCreatePatientAddress',
        'patientId',
        patientId,
      )
      // verify required parameter 'createPatientAddressParams' is not null or undefined
      assertParamExists(
        'patientsControllerCreatePatientAddress',
        'createPatientAddressParams',
        createPatientAddressParams,
      )
      const localVarPath = `/patients/{patientId}/address`.replace(
        `{${'patientId'}}`,
        encodeURIComponent(String(patientId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPatientAddressParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} patientId
     * @param {CreatePatientMatchDeviceParams} createPatientMatchDeviceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerCreatePatientMatchDevice: async (
      patientId: string,
      createPatientMatchDeviceParams: CreatePatientMatchDeviceParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists(
        'patientsControllerCreatePatientMatchDevice',
        'patientId',
        patientId,
      )
      // verify required parameter 'createPatientMatchDeviceParams' is not null or undefined
      assertParamExists(
        'patientsControllerCreatePatientMatchDevice',
        'createPatientMatchDeviceParams',
        createPatientMatchDeviceParams,
      )
      const localVarPath = `/patients/{patientId}/match-device`.replace(
        `{${'patientId'}}`,
        encodeURIComponent(String(patientId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPatientMatchDeviceParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerFindAllAuthorizedPractitioners: async (
      patientId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists(
        'patientsControllerFindAllAuthorizedPractitioners',
        'patientId',
        patientId,
      )
      const localVarPath = `/patients/{patientId}/authorized-practitioners`.replace(
        `{${'patientId'}}`,
        encodeURIComponent(String(patientId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerFindOne: async (
      patientId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('patientsControllerFindOne', 'patientId', patientId)
      const localVarPath = `/patients/{patientId}`.replace(
        `{${'patientId'}}`,
        encodeURIComponent(String(patientId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} mrn1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerFindOneKareoPatient: async (
      mrn1: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mrn1' is not null or undefined
      assertParamExists('patientsControllerFindOneKareoPatient', 'mrn1', mrn1)
      const localVarPath = `/patients/kareo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (mrn1 !== undefined) {
        localVarQueryParameter['mrn1'] = mrn1
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {FindAllPatientsParams} findAllPatientsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetAllPatientsWithAccessTo: async (findAllPatientsParams: FindAllPatientsParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'findAllPatientsParams' is not null or undefined
      assertParamExists('patientsControllerGetAllPatientsWithAccessTo', 'findAllPatientsParams', findAllPatientsParams)
      const localVarPath = `/patients`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
      localVarRequestOptions.data = serializeDataIfNeeded(findAllPatientsParams, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {MatchTransmissionParams} matchTransmissionParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerMatchTransmission: async (
      matchTransmissionParams: MatchTransmissionParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'matchTransmissionParams' is not null or undefined
      assertParamExists(
        'patientsControllerMatchTransmission',
        'matchTransmissionParams',
        matchTransmissionParams,
      )
      const localVarPath = `/patients/match-transmission`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        matchTransmissionParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetTotalPatientsMonitoredParams} [queryParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerTotalMonitored: async (
      queryParams?: GetTotalPatientsMonitoredParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/patients/total-monitored`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (queryParams !== undefined) {
        localVarQueryParameter['queryParams'] = queryParams
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} patientId
     * @param {PatientActiveInactiveStatusParams} patientActiveInactiveStatusParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerPatientActiveInactiveStatus: async (patientId: string, patientActiveInactiveStatusParams: PatientActiveInactiveStatusParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('patientsControllerPatientActiveInactiveStatus', 'patientId', patientId)
      // verify required parameter 'patientActiveInactiveStatusParams' is not null or undefined
      assertParamExists('patientsControllerPatientActiveInactiveStatus', 'patientActiveInactiveStatusParams', patientActiveInactiveStatusParams)
      const localVarPath = `/patients/{patientId}/patientactive-inactivestatus`
        .replace(`{${'patientId'}}`, encodeURIComponent(String(patientId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
      localVarRequestOptions.data = serializeDataIfNeeded(patientActiveInactiveStatusParams, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} patientId
     * @param {UpdatePatientParams} updatePatientParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerUpdate: async (
      patientId: string,
      updatePatientParams: UpdatePatientParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('patientsControllerUpdate', 'patientId', patientId)
      // verify required parameter 'updatePatientParams' is not null or undefined
      assertParamExists(
        'patientsControllerUpdate',
        'updatePatientParams',
        updatePatientParams,
      )
      const localVarPath = `/patients/{patientId}`.replace(
        `{${'patientId'}}`,
        encodeURIComponent(String(patientId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePatientParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} patientId
     * @param {UpdatePatientAddressParams} updatePatientAddressParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerUpdatePatientAddress: async (
      patientId: string,
      updatePatientAddressParams: UpdatePatientAddressParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists(
        'patientsControllerUpdatePatientAddress',
        'patientId',
        patientId,
      )
      // verify required parameter 'updatePatientAddressParams' is not null or undefined
      assertParamExists(
        'patientsControllerUpdatePatientAddress',
        'updatePatientAddressParams',
        updatePatientAddressParams,
      )
      const localVarPath = `/patients/{patientId}/address`.replace(
        `{${'patientId'}}`,
        encodeURIComponent(String(patientId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePatientAddressParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} patientId
     * @param {UpdatePatientMatchDeviceParams} updatePatientMatchDeviceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerUpdatePatientMatchDevice: async (
      patientId: string,
      updatePatientMatchDeviceParams: UpdatePatientMatchDeviceParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists(
        'patientsControllerUpdatePatientMatchDevice',
        'patientId',
        patientId,
      )
      // verify required parameter 'updatePatientMatchDeviceParams' is not null or undefined
      assertParamExists(
        'patientsControllerUpdatePatientMatchDevice',
        'updatePatientMatchDeviceParams',
        updatePatientMatchDeviceParams,
      )
      const localVarPath = `/patients/{patientId}/match-device`.replace(
        `{${'patientId'}}`,
        encodeURIComponent(String(patientId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePatientMatchDeviceParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} alertId
     * @param {TransmissionReportAddAlertImageParams} transmissionReportAddAlertImageParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerAddAlertImage: async (
      transmissionReportId: number,
      alertId: string,
      transmissionReportAddAlertImageParams: TransmissionReportAddAlertImageParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerAddAlertImage',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'alertId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerAddAlertImage',
        'alertId',
        alertId,
      )
      // verify required parameter 'transmissionReportAddAlertImageParams' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerAddAlertImage',
        'transmissionReportAddAlertImageParams',
        transmissionReportAddAlertImageParams,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/alerts/{alertId}/image`
        .replace(
          `{${'transmissionReportId'}}`,
          encodeURIComponent(String(transmissionReportId)),
        )
        .replace(`{${'alertId'}}`, encodeURIComponent(String(alertId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        transmissionReportAddAlertImageParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} patientId
     * @param {string} fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerAddInClinicPdfToTempBucket: async (patientId: string, fileName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('transmissionReportsControllerAddInClinicPdfToTempBucket', 'patientId', patientId)
      // verify required parameter 'fileName' is not null or undefined
      assertParamExists('transmissionReportsControllerAddInClinicPdfToTempBucket', 'fileName', fileName)
      const localVarPath = `/transmission-reports/{patientId}/upload-inclinicpdf/{fileName}`
        .replace(`{${'patientId'}}`, encodeURIComponent(String(patientId)))
        .replace(`{${'fileName'}}`, encodeURIComponent(String(fileName)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerAddRhythmVendorPdfToBucket: async (
      transmissionReportId: number,
      file: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerAddRhythmVendorPdfToBucket',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'file' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerAddRhythmVendorPdfToBucket',
        'file',
        file,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/upload-pdf`.replace(
        `{${'transmissionReportId'}}`,
        encodeURIComponent(String(transmissionReportId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration &&
          configuration.formDataCtor) ||
        FormData)()

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (file !== undefined) {
        localVarFormParams.append('file', file as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {TransmissionReportCountParamsDto} [queryParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerCount: async (
      queryParams?: TransmissionReportCountParamsDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/transmission-reports/count`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (queryParams !== undefined) {
        localVarQueryParameter['queryParams'] = queryParams
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {TransmissionReportAlertCreateParams} transmissionReportAlertCreateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerCreateAlert: async (
      transmissionReportId: number,
      transmissionReportAlertCreateParams: TransmissionReportAlertCreateParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerCreateAlert',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'transmissionReportAlertCreateParams' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerCreateAlert',
        'transmissionReportAlertCreateParams',
        transmissionReportAlertCreateParams,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/alerts`.replace(
        `{${'transmissionReportId'}}`,
        encodeURIComponent(String(transmissionReportId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        transmissionReportAlertCreateParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {TransmissionReportCountersCreateParams} transmissionReportCountersCreateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerCreateCounters: async (
      transmissionReportId: number,
      transmissionReportCountersCreateParams: TransmissionReportCountersCreateParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerCreateCounters',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'transmissionReportCountersCreateParams' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerCreateCounters',
        'transmissionReportCountersCreateParams',
        transmissionReportCountersCreateParams,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/counters`.replace(
        `{${'transmissionReportId'}}`,
        encodeURIComponent(String(transmissionReportId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        transmissionReportCountersCreateParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {TransmissionReportBillingCreateParams} transmissionReportBillingCreateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerCreateBilling: async (
      transmissionReportId: number,
      transmissionReportBillingCreateParams: TransmissionReportBillingCreateParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerCreateBilling',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'transmissionReportBillingCreateParams' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerCreateBilling',
        'transmissionReportBillingCreateParams',
        transmissionReportBillingCreateParams,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/billing`.replace(
        `{${'transmissionReportId'}}`,
        encodeURIComponent(String(transmissionReportId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        transmissionReportBillingCreateParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {boolean} flag
     * @param {TransmissionReportNoteCreateParams} transmissionReportNoteCreateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerCreateNote: async (
      transmissionReportId: number,
      flag: boolean,
      transmissionReportNoteCreateParams: TransmissionReportNoteCreateParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerCreateNote',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'transmissionReportNoteCreateParams' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerCreateNote',
        'transmissionReportNoteCreateParams',
        transmissionReportNoteCreateParams,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/{flag}/notes`.replace(
        `{${'transmissionReportId'}}`,
        encodeURIComponent(String(transmissionReportId)),
      )
        .replace(`{${'flag'}}`, encodeURIComponent(Boolean(flag)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        transmissionReportNoteCreateParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} alertId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerDeleteAlert: async (
      transmissionReportId: number,
      alertId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerDeleteAlert',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'alertId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerDeleteAlert',
        'alertId',
        alertId,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/alerts/{alertId}`
        .replace(
          `{${'transmissionReportId'}}`,
          encodeURIComponent(String(transmissionReportId)),
        )
        .replace(`{${'alertId'}}`, encodeURIComponent(String(alertId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} alertId
     * @param {string} imageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerDeleteAlertImage: async (
      transmissionReportId: number,
      alertId: string,
      imageId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerDeleteAlertImage',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'alertId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerDeleteAlertImage',
        'alertId',
        alertId,
      )
      // verify required parameter 'imageId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerDeleteAlertImage',
        'imageId',
        imageId,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/alerts/{alertId}/image/{imageId}`
        .replace(
          `{${'transmissionReportId'}}`,
          encodeURIComponent(String(transmissionReportId)),
        )
        .replace(`{${'alertId'}}`, encodeURIComponent(String(alertId)))
        .replace(`{${'imageId'}}`, encodeURIComponent(String(imageId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
         *
         * @param {TransmissionReportSignAllParams} transmissionReportSignAllParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
    transmissionReportsControllerSignAllToLambda: async (transmissionReportSignAllParams: TransmissionReportSignAllParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportSignAllParams' is not null or undefined
      assertParamExists('transmissionReportsControllerSignAllToLambda', 'transmissionReportSignAllParams', transmissionReportSignAllParams)
      const localVarPath = `/transmission-reports/signAllToLambda`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(transmissionReportSignAllParams, localVarRequestOptions, configuration)

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} billingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerDeleteBilling: async (
      transmissionReportId: number,
      billingId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerDeleteBilling',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'billingId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerDeleteBilling',
        'billingId',
        billingId,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/billing/{billingId}`
        .replace(
          `{${'transmissionReportId'}}`,
          encodeURIComponent(String(transmissionReportId)),
        )
        .replace(`{${'billingId'}}`, encodeURIComponent(String(billingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerDeleteNote: async (
      transmissionReportId: number,
      noteId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerDeleteNote',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerDeleteNote',
        'noteId',
        noteId,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/notes/{noteId}`
        .replace(
          `{${'transmissionReportId'}}`,
          encodeURIComponent(String(transmissionReportId)),
        )
        .replace(`{${'noteId'}}`, encodeURIComponent(String(noteId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<number>} tranmissionReportIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerDismissAll: async (tranmissionReportIds: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tranmissionReportIds' is not null or undefined
      assertParamExists('transmissionReportsControllerDismissAll', 'tranmissionReportIds', tranmissionReportIds)
      const localVarPath = `/transmission-reports/{tranmissionReportIds}/dismissAll`
        .replace(`{${'tranmissionReportIds'}}`, encodeURIComponent(String(tranmissionReportIds)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {boolean} isEditable
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerEditReport: async (
      transmissionReportId: number,
      isEditable: boolean,
      file: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerEditReport',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'isEditable' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerEditReport',
        'isEditable',
        isEditable,
      )
      // verify required parameter 'file' is not null or undefined
      assertParamExists('transmissionReportsControllerEditReport', 'file', file)
      const localVarPath = `/transmission-reports/{transmissionReportId}/editReport/{isEditable}`
        .replace(
          `{${'transmissionReportId'}}`,
          encodeURIComponent(String(transmissionReportId)),
        )
        .replace(`{${'isEditable'}}`, encodeURIComponent(String(isEditable)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration &&
          configuration.formDataCtor) ||
        FormData)()

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (file !== undefined) {
        localVarFormParams.append('file', file as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerDismiss: async (
      transmissionReportId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerDismiss',
        'transmissionReportId',
        transmissionReportId,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/dismiss`.replace(
        `{${'transmissionReportId'}}`,
        encodeURIComponent(String(transmissionReportId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerUnDismiss: async (
      transmissionReportId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUnDismiss',
        'transmissionReportId',
        transmissionReportId,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/unDismiss`.replace(
        `{${'transmissionReportId'}}`,
        encodeURIComponent(String(transmissionReportId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerFetchICDDiagnosisCodes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/transmission-reports/icd-diagnosis-codes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get ICD Diagnosis Codes
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    icdDiagnosisControllerGetIcdCodes: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('icdDiagnosisControllerGetIcdCodes', 'query', query)
      const localVarPath = `/icd-diagnosis-codes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<number>} transmissionReportIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerFetchIsBillableForReports: async (transmissionReportIds: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportIds' is not null or undefined
      assertParamExists('transmissionReportsControllerFetchIsBillableForReports', 'transmissionReportIds', transmissionReportIds)
      const localVarPath = `/transmission-reports/{transmissionReportIds}/fetch-billable-data`
        .replace(`{${'transmissionReportIds'}}`, encodeURIComponent(String(transmissionReportIds)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerFindOne: async (
      transmissionReportId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerFindOne',
        'transmissionReportId',
        transmissionReportId,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}`.replace(
        `{${'transmissionReportId'}}`,
        encodeURIComponent(String(transmissionReportId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {TransmissionReportListRequestDto} transmissionReportListRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerList: async (
      transmissionReportListRequestDto: TransmissionReportListRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportListRequestDto' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerList',
        'transmissionReportListRequestDto',
        transmissionReportListRequestDto,
      )
      const localVarPath = `/transmission-reports/list`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        transmissionReportListRequestDto,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {TransmissionReportSearchUnsignedReportsRequestDto} transmissionReportSearchUnsignedReportsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerSearchUnsignedReports: async (transmissionReportSearchUnsignedReportsRequestDto: TransmissionReportSearchUnsignedReportsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportSearchUnsignedReportsRequestDto' is not null or undefined
      assertParamExists('transmissionReportsControllerSearchUnsignedReports', 'transmissionReportSearchUnsignedReportsRequestDto', transmissionReportSearchUnsignedReportsRequestDto)
      const localVarPath = `/transmission-reports/search-unsigned-reports`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(transmissionReportSearchUnsignedReportsRequestDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerFindPatientsReportsDeviceType: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('patientsControllerFindPatientsReportsDeviceType', 'patientId', patientId)
      const localVarPath = `/patients/{patientId}/find-patients-reports-deviceType`
        .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {TransmissionReportSignParams} transmissionReportSignDateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerSign: async (transmissionReportId: number, transmissionReportSignDateParams: TransmissionReportSignParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists('transmissionReportsControllerSign', 'transmissionReportId', transmissionReportId)
      // verify required parameter 'transmissionReportSignDateParams' is not null or undefined
      assertParamExists('transmissionReportsControllerSign', 'transmissionReportSignDateParams', transmissionReportSignDateParams)
      const localVarPath = `/transmission-reports/{transmissionReportId}/sign`
        .replace(`{${'transmissionReportId'}}`, encodeURIComponent(String(transmissionReportId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'PATCH', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
      localVarRequestOptions.data = serializeDataIfNeeded(transmissionReportSignDateParams, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ManualORInclinicTransmissionReportRequestDto} manualORInclinicTransmissionReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerSubmitManualAndInclinicReports: async (manualORInclinicTransmissionReportRequestDto: ManualORInclinicTransmissionReportRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'manualORInclinicTransmissionReportRequestDto' is not null or undefined
      assertParamExists('transmissionReportsControllerSubmitManualAndInclinicReports', 'manualORInclinicTransmissionReportRequestDto', manualORInclinicTransmissionReportRequestDto)
      const localVarPath = `/transmission-reports/manual-inclinic-reports/save`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
      localVarRequestOptions.data = serializeDataIfNeeded(manualORInclinicTransmissionReportRequestDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} signDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerStorePDFDownloadInfo: async (transmissionReportId: number, signDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists('transmissionReportsControllerStorePDFDownloadInfo', 'transmissionReportId', transmissionReportId)
      // verify required parameter 'signDate' is not null or undefined
      assertParamExists('transmissionReportsControllerStorePDFDownloadInfo', 'signDate', signDate)
      const localVarPath = `/transmission-reports/{transmissionReportId}/{signDate}/store`
        .replace(`{${'transmissionReportId'}}`, encodeURIComponent(String(transmissionReportId)))
        .replace(`{${'signDate'}}`, encodeURIComponent(String(signDate)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'PATCH', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {VendorDataOverrides} vendorDataOverrides
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerTransmissionOverride: async (
      transmissionReportId: number,
      vendorDataOverrides: VendorDataOverrides,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerTransmissionOverride',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'vendorDataOverrides' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerTransmissionOverride',
        'vendorDataOverrides',
        vendorDataOverrides,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/overrides`.replace(
        `{${'transmissionReportId'}}`,
        encodeURIComponent(String(transmissionReportId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        vendorDataOverrides,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {UpdateTransmissionReportParams} updateTransmissionReportParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerUpdate: async (
      transmissionReportId: number,
      updateTransmissionReportParams: UpdateTransmissionReportParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdate',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'updateTransmissionReportParams' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdate',
        'updateTransmissionReportParams',
        updateTransmissionReportParams,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}`.replace(
        `{${'transmissionReportId'}}`,
        encodeURIComponent(String(transmissionReportId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTransmissionReportParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} alertId
     * @param {TransmissionReportAlertUpdateParams} transmissionReportAlertUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerUpdateAlert: async (
      transmissionReportId: number,
      alertId: string,
      transmissionReportAlertUpdateParams: TransmissionReportAlertUpdateParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdateAlert',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'alertId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdateAlert',
        'alertId',
        alertId,
      )
      // verify required parameter 'transmissionReportAlertUpdateParams' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdateAlert',
        'transmissionReportAlertUpdateParams',
        transmissionReportAlertUpdateParams,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/alerts/{alertId}`
        .replace(
          `{${'transmissionReportId'}}`,
          encodeURIComponent(String(transmissionReportId)),
        )
        .replace(`{${'alertId'}}`, encodeURIComponent(String(alertId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        transmissionReportAlertUpdateParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} countersId
     * @param {TransmissionReportCountersUpdateParams} transmissionReportCountersUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerUpdateCounters: async (
      transmissionReportId: number,
      countersId: string,
      transmissionReportCountersUpdateParams: TransmissionReportCountersUpdateParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdateCounters',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'countersId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdateCounters',
        'countersId',
        countersId,
      )
      // verify required parameter 'transmissionReportCountersUpdateParams' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdateCounters',
        'transmissionReportCountersUpdateParams',
        transmissionReportCountersUpdateParams,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/updateCounters`
        .replace(
          `{${'transmissionReportId'}}`,
          encodeURIComponent(String(transmissionReportId)),
        )
        .replace(`{${'countersId'}}`, encodeURIComponent(String(countersId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        transmissionReportCountersUpdateParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} billingId
     * @param {TransmissionReportBillingUpdateParams} transmissionReportBillingUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerUpdateBilling: async (
      transmissionReportId: number,
      billingId: string,
      transmissionReportBillingUpdateParams: TransmissionReportBillingUpdateParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdateBilling',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'billingId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdateBilling',
        'billingId',
        billingId,
      )
      // verify required parameter 'transmissionReportBillingUpdateParams' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdateBilling',
        'transmissionReportBillingUpdateParams',
        transmissionReportBillingUpdateParams,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/billing/{billingId}`
        .replace(
          `{${'transmissionReportId'}}`,
          encodeURIComponent(String(transmissionReportId)),
        )
        .replace(`{${'billingId'}}`, encodeURIComponent(String(billingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        transmissionReportBillingUpdateParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} noteId
     * @param {TransmissionReportNoteUpdateParams} transmissionReportNoteUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerUpdateNote: async (
      transmissionReportId: number,
      noteId: string,
      transmissionReportNoteUpdateParams: TransmissionReportNoteUpdateParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdateNote',
        'transmissionReportId',
        transmissionReportId,
      )
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdateNote',
        'noteId',
        noteId,
      )
      // verify required parameter 'transmissionReportNoteUpdateParams' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerUpdateNote',
        'transmissionReportNoteUpdateParams',
        transmissionReportNoteUpdateParams,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/notes/{noteId}`
        .replace(
          `{${'transmissionReportId'}}`,
          encodeURIComponent(String(transmissionReportId)),
        )
        .replace(`{${'noteId'}}`, encodeURIComponent(String(noteId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        transmissionReportNoteUpdateParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },

            /**
         *
         * @param {number} transmissionReportId
         * @param {string} programmedParameterId
         * @param {ProgrammedParametersDto} programmedParametersDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
            transmissionReportsControllerUpdateProgrammedParameters: async (transmissionReportId: number, programmedParametersDto: ProgrammedParametersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
              // verify required parameter 'transmissionReportId' is not null or undefined
              assertParamExists('transmissionReportsControllerUpdateProgrammedParameters', 'transmissionReportId', transmissionReportId)
              // verify required parameter 'programmedParametersDto' is not null or undefined
              assertParamExists('transmissionReportsControllerUpdateProgrammedParameters', 'programmedParametersDto', programmedParametersDto)
              const localVarPath = `/transmission-reports/{transmissionReportId}/programmedParameters`
                  .replace(`{${"transmissionReportId"}}`, encodeURIComponent(String(transmissionReportId)))
              // use dummy base URL string because the URL constructor only accepts absolute URLs.
              const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
              let baseOptions;
              if (configuration) {
                  baseOptions = configuration.baseOptions;
              }

              const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
              const localVarHeaderParameter = {} as any;
              const localVarQueryParameter = {} as any;

              // authentication bearer required
              // http bearer authentication required
              await setBearerAuthToObject(localVarHeaderParameter, configuration)

              localVarHeaderParameter['Content-Type'] = 'application/json';

              setSearchParams(localVarUrlObj, localVarQueryParameter);
              let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
              localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
              localVarRequestOptions.data = serializeDataIfNeeded(programmedParametersDto, localVarRequestOptions, configuration)

              return {
                  url: toPathString(localVarUrlObj),
                  options: localVarRequestOptions,
              };
          },
    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerVendorDbPatientDeviceType: async (
      transmissionReportId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerVendorDbPatientDeviceType',
        'transmissionReportId',
        transmissionReportId,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/PtILRDeviceType`.replace(
        `{${'transmissionReportId'}}`,
        encodeURIComponent(String(transmissionReportId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {VendorTransmissionReportUrlParamsDto} [queryParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerVendorTransmissionReportUrl: async (
      transmissionReportId: number,
      queryParams?: VendorTransmissionReportUrlParamsDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerVendorTransmissionReportUrl',
        'transmissionReportId',
        transmissionReportId,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/vendor-transmission-report-url`.replace(
        `{${'transmissionReportId'}}`,
        encodeURIComponent(String(transmissionReportId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (queryParams !== undefined) {
        localVarQueryParameter['queryParams'] = queryParams
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transmissionReportsControllerVendorTransmissionsReports: async (
      transmissionReportId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transmissionReportId' is not null or undefined
      assertParamExists(
        'transmissionReportsControllerVendorTransmissionsReports',
        'transmissionReportId',
        transmissionReportId,
      )
      const localVarPath = `/transmission-reports/{transmissionReportId}/vendor-transmissions-reports`.replace(
        `{${'transmissionReportId'}}`,
        encodeURIComponent(String(transmissionReportId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateUserParams} createUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerCreate: async (
      createUserParams: CreateUserParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUserParams' is not null or undefined
      assertParamExists(
        'usersControllerCreate',
        'createUserParams',
        createUserParams,
      )
      const localVarPath = `/users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUserParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} userId
     * @param {CreateUserAddressParams} createUserAddressParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerCreateUserAddress: async (
      userId: string,
      createUserAddressParams: CreateUserAddressParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersControllerCreateUserAddress', 'userId', userId)
      // verify required parameter 'createUserAddressParams' is not null or undefined
      assertParamExists(
        'usersControllerCreateUserAddress',
        'createUserAddressParams',
        createUserAddressParams,
      )
      const localVarPath = `/users/{userId}/address`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUserAddressParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerDeleteAddress: async (
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersControllerDeleteAddress', 'userId', userId)
      const localVarPath = `/users/{userId}/address`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {FindAllUsersQueryParams} [queryParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerFindAll: async (
      queryParams?: FindAllUsersQueryParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (queryParams !== undefined) {
        localVarQueryParameter['queryParams'] = queryParams
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerFindOne: async (
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersControllerFindOne', 'userId', userId)
      const localVarPath = `/users/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerFindCurrentUserInfo: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/users/user/currentUserInfo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateUserParams} updateUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerUpdate: async (
      userId: string,
      updateUserParams: UpdateUserParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersControllerUpdate', 'userId', userId)
      // verify required parameter 'updateUserParams' is not null or undefined
      assertParamExists(
        'usersControllerUpdate',
        'updateUserParams',
        updateUserParams,
      )
      const localVarPath = `/users/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateUserAddressParams} updateUserAddressParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerUpdateUserAddress: async (
      userId: string,
      updateUserAddressParams: UpdateUserAddressParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersControllerUpdateUserAddress', 'userId', userId)
      // verify required parameter 'updateUserAddressParams' is not null or undefined
      assertParamExists(
        'usersControllerUpdateUserAddress',
        'updateUserAddressParams',
        updateUserAddressParams,
      )
      const localVarPath = `/users/{userId}/address`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserAddressParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {VtLeadVendorListParams} vtLeadVendorListParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vtVendorControllerLeadVendorList: async (
      vtLeadVendorListParams: VtLeadVendorListParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'vtLeadVendorListParams' is not null or undefined
      assertParamExists(
        'vtVendorControllerLeadVendorList',
        'vtLeadVendorListParams',
        vtLeadVendorListParams,
      )
      const localVarPath = `/vt-vendor/lead-vendor-list`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        vtLeadVendorListParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {VtVendorListParams} vtVendorListParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vtVendorControllerList: async (
      vtVendorListParams: VtVendorListParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'vtVendorListParams' is not null or undefined
      assertParamExists(
        'vtVendorControllerList',
        'vtVendorListParams',
        vtVendorListParams,
      )
      const localVarPath = `/vt-vendor/list`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        vtVendorListParams,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {AccountOverviewQuery} accountOverviewQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyticsControllerAccountOverviewReport: async (accountOverviewQuery: AccountOverviewQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountOverviewQuery' is not null or undefined
      assertParamExists('analyticsControllerAccountOverviewReport', 'accountOverviewQuery', accountOverviewQuery)
      const localVarPath = `/analytics/account-overview`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(accountOverviewQuery, localVarRequestOptions, configuration)

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AlertsQuery} alertsQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyticsControllerAlertsReport: async (alertsQuery: AlertsQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        // verify required parameter 'alertsQuery' is not null or undefined
        assertParamExists('analyticsControllerAlertsReport', 'alertsQuery', alertsQuery)
        const localVarPath = `/analytics/alerts`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
            baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication bearer required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)



        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
        localVarRequestOptions.data = serializeDataIfNeeded(alertsQuery, localVarRequestOptions, configuration)

        return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
        };
    },
    /**
     *
     * @param {BillableQuery} billableQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyticsControllerBillableReport: async (billableQuery: BillableQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        // verify required parameter 'billableQuery' is not null or undefined
        assertParamExists('analyticsControllerBillableReport', 'billableQuery', billableQuery)
        const localVarPath = `/analytics/billable`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
            baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication bearer required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)



        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
        localVarRequestOptions.data = serializeDataIfNeeded(billableQuery, localVarRequestOptions, configuration)

        return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
        };
    },
    /**
     *
     * @param {DownloadReportRequest} downloadReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyticsControllerDownloadReport: async (downloadReportRequest: DownloadReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'downloadReportRequest' is not null or undefined
      assertParamExists('analyticsControllerDownloadReport', 'downloadReportRequest', downloadReportRequest)
      const localVarPath = `/analytics/download-report`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(downloadReportRequest, localVarRequestOptions, configuration)

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GetPractitionersQuery} getPractitionersQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyticsControllerGetPractitioners: async (getPractitionersQuery: GetPractitionersQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        // verify required parameter 'getPractitionersQuery' is not null or undefined
        assertParamExists('analyticsControllerGetPractitioners', 'getPractitionersQuery', getPractitionersQuery)
        const localVarPath = `/analytics/practitioners`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
            baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication bearer required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)



        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
        localVarRequestOptions.data = serializeDataIfNeeded(getPractitionersQuery, localVarRequestOptions, configuration)

        return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
        };
    },
    /**
     *
     * @param {SchedulingQuery} schedulingQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyticsControllerSchedulingReport: async (schedulingQuery: SchedulingQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        // verify required parameter 'schedulingQuery' is not null or undefined
        assertParamExists('analyticsControllerSchedulingReport', 'schedulingQuery', schedulingQuery)
        const localVarPath = `/analytics/scheduling`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
            baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication bearer required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)



        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
        localVarRequestOptions.data = serializeDataIfNeeded(schedulingQuery, localVarRequestOptions, configuration)

        return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
        };
    },
    /**
     *
     * @param {SignatureStatusQuery} signatureStatusQuery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    analyticsControllerSignatureStatusReport: async (signatureStatusQuery: SignatureStatusQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        // verify required parameter 'signatureStatusQuery' is not null or undefined
        assertParamExists('analyticsControllerSignatureStatusReport', 'signatureStatusQuery', signatureStatusQuery)
        const localVarPath = `/analytics/signature`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
            baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication bearer required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration)



        localVarHeaderParameter['Content-Type'] = 'application/json';

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
        localVarRequestOptions.data = serializeDataIfNeeded(signatureStatusQuery, localVarRequestOptions, configuration)

        return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
        };
    },
    /**
     *
     * @param {CreateInitializeBatchJobRequest} createInitializeBatchJobRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchDownloadControllerCreate: async (createInitializeBatchJobRequest: CreateInitializeBatchJobRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createInitializeBatchJobRequest' is not null or undefined
      assertParamExists('batchDownloadControllerCreate', 'createInitializeBatchJobRequest', createInitializeBatchJobRequest)
      const localVarPath = `/batch-download`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
      localVarRequestOptions.data = serializeDataIfNeeded(createInitializeBatchJobRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchDownloadControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/batch-download/findAll`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchDownloadControllerFindOne: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('batchDownloadControllerFindOne', 'id', id)
      const localVarPath = `/batch-download/{id}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {TransmissionIdsRequest} transmissionIdsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchDownloadControllerGetPatientTransmissionData: async (transmissionIdsRequest: TransmissionIdsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'transmissionIdsRequest' is not null or undefined
      assertParamExists('batchDownloadControllerGetPatientTransmissionData', 'transmissionIdsRequest', transmissionIdsRequest)
      const localVarPath = `/batch-download/getPatientTransmissionData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
      localVarRequestOptions.data = serializeDataIfNeeded(transmissionIdsRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchDownloadControllerRemove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('batchDownloadControllerRemove', 'id', id)
      const localVarPath = `/batch-download/{id}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'DELETE', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {UpdateBatchDownloadDto} updateBatchDownloadDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchDownloadControllerUpdate: async (id: number, updateBatchDownloadDto: UpdateBatchDownloadDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('batchDownloadControllerUpdate', 'id', id)
      // verify required parameter 'updateBatchDownloadDto' is not null or undefined
      assertParamExists('batchDownloadControllerUpdate', 'updateBatchDownloadDto', updateBatchDownloadDto)
      const localVarPath = `/batch-download/{id}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'PATCH', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
      localVarRequestOptions.data = serializeDataIfNeeded(updateBatchDownloadDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {BatchPDFDownloadInfoRequest} batchPDFDownloadInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchDownloadControllerUpdateBatchPDFDownloadInfo: async (batchPDFDownloadInfoRequest: BatchPDFDownloadInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'batchPDFDownloadInfoRequest' is not null or undefined
      assertParamExists('batchDownloadControllerUpdateBatchPDFDownloadInfo', 'batchPDFDownloadInfoRequest', batchPDFDownloadInfoRequest)
      const localVarPath = `/batch-download/updateBatchPDFDownloadInfo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
      localVarRequestOptions.data = serializeDataIfNeeded(batchPDFDownloadInfoRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateNoteRequest} createNoteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clinicalNotesControllerCreateNote: async (createNoteRequest: CreateNoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createNoteRequest' is not null or undefined
      assertParamExists('clinicalNotesControllerCreateNote', 'createNoteRequest', createNoteRequest)
      const localVarPath = `/clinical-notes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(createNoteRequest, localVarRequestOptions, configuration)

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingControllerGetSetting: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'name' is not null or undefined
      assertParamExists('settingControllerGetSetting', 'name', name)
      const localVarPath = `/settings/{name}`
          .replace(`{${"name"}}`, encodeURIComponent(String(name)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clinicalNotesControllerDeleteNote: async (noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists('clinicalNotesControllerDeleteNote', 'noteId', noteId)
      const localVarPath = `/clinical-notes/{noteId}`
          .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clinicalNotesControllerGetClinicalNoteById: async (noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists('clinicalNotesControllerGetClinicalNoteById', 'noteId', noteId)
      const localVarPath = `/clinical-notes/{noteId}`
          .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
    },

    /**
     *
     * @param {string} patientId
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clinicalNotesControllerGetClinicalNotes: async (patientId: string, page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('clinicalNotesControllerGetClinicalNotes', 'patientId', patientId)
      const localVarPath = `/clinical-notes/patient/{patientId}`
          .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (page !== undefined) {
          localVarQueryParameter['page'] = page;
      }

      if (limit !== undefined) {
          localVarQueryParameter['limit'] = limit;
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clinicalNotesControllerGetPinnedNotesAndTotalCount: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('clinicalNotesControllerGetPinnedNotesAndTotalCount', 'patientId', patientId)
      const localVarPath = `/clinical-notes/patient/pinned/{patientId}`
          .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} noteId
     * @param {UpdateNoteRequest} updateNoteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clinicalNotesControllerUpdateNote: async (noteId: string, updateNoteRequest: UpdateNoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists('clinicalNotesControllerUpdateNote', 'noteId', noteId)
      // verify required parameter 'updateNoteRequest' is not null or undefined
      assertParamExists('clinicalNotesControllerUpdateNote', 'updateNoteRequest', updateNoteRequest)
      const localVarPath = `/clinical-notes/{noteId}`
          .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(updateNoteRequest, localVarRequestOptions, configuration)

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
    },
            /**
         *
         * @param {CreateFeatureFlagDto} createFeatureFlagDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
            featureFlagControllerCreateFeatureFlag: async (createFeatureFlagDto: CreateFeatureFlagDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
              // verify required parameter 'createFeatureFlagDto' is not null or undefined
              assertParamExists('featureFlagControllerCreateFeatureFlag', 'createFeatureFlagDto', createFeatureFlagDto)
              const localVarPath = `/feature-flags`;
              // use dummy base URL string because the URL constructor only accepts absolute URLs.
              const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
              let baseOptions;
              if (configuration) {
                  baseOptions = configuration.baseOptions;
              }

              const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
              const localVarHeaderParameter = {} as any;
              const localVarQueryParameter = {} as any;



              localVarHeaderParameter['Content-Type'] = 'application/json';

              setSearchParams(localVarUrlObj, localVarQueryParameter);
              let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
              localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
              localVarRequestOptions.data = serializeDataIfNeeded(createFeatureFlagDto, localVarRequestOptions, configuration)

              return {
                  url: toPathString(localVarUrlObj),
                  options: localVarRequestOptions,
              };
          },
          /**
           *
           * @param {*} [options] Override http request option.
           * @throws {RequiredError}
           */
          featureFlagControllerGetAllFlags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
              const localVarPath = `/feature-flags`;
              // use dummy base URL string because the URL constructor only accepts absolute URLs.
              const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
              let baseOptions;
              if (configuration) {
                  baseOptions = configuration.baseOptions;
              }

              const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
              const localVarHeaderParameter = {} as any;
              const localVarQueryParameter = {} as any;



              setSearchParams(localVarUrlObj, localVarQueryParameter);
              let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
              localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

              return {
                  url: toPathString(localVarUrlObj),
                  options: localVarRequestOptions,
              };
          },
          /**
           *
           * @param {*} [options] Override http request option.
           * @throws {RequiredError}
           */
          featureFlagControllerGetAllFlagsAdmin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
              const localVarPath = `/feature-flags/admin`;
              // use dummy base URL string because the URL constructor only accepts absolute URLs.
              const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
              let baseOptions;
              if (configuration) {
                  baseOptions = configuration.baseOptions;
              }

              const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
              const localVarHeaderParameter = {} as any;
              const localVarQueryParameter = {} as any;



              setSearchParams(localVarUrlObj, localVarQueryParameter);
              let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
              localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

              return {
                  url: toPathString(localVarUrlObj),
                  options: localVarRequestOptions,
              };
          },
          /**
           *
           * @param {string} shortCode
           * @param {*} [options] Override http request option.
           * @throws {RequiredError}
           */
          featureFlagControllerGetFlagStatus: async (shortCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
              // verify required parameter 'shortCode' is not null or undefined
              assertParamExists('featureFlagControllerGetFlagStatus', 'shortCode', shortCode)
              const localVarPath = `/feature-flags/{shortCode}`
                  .replace(`{${"shortCode"}}`, encodeURIComponent(String(shortCode)));
              // use dummy base URL string because the URL constructor only accepts absolute URLs.
              const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
              let baseOptions;
              if (configuration) {
                  baseOptions = configuration.baseOptions;
              }

              const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
              const localVarHeaderParameter = {} as any;
              const localVarQueryParameter = {} as any;



              setSearchParams(localVarUrlObj, localVarQueryParameter);
              let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
              localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

              return {
                  url: toPathString(localVarUrlObj),
                  options: localVarRequestOptions,
              };
          },
          /**
           *
           * @param {string} flagId
           * @param {*} [options] Override http request option.
           * @throws {RequiredError}
           */
          featureFlagControllerRemove: async (flagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
              // verify required parameter 'flagId' is not null or undefined
              assertParamExists('featureFlagControllerRemove', 'flagId', flagId)
              const localVarPath = `/feature-flags/{flagId}`
                  .replace(`{${"flagId"}}`, encodeURIComponent(String(flagId)));
              // use dummy base URL string because the URL constructor only accepts absolute URLs.
              const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
              let baseOptions;
              if (configuration) {
                  baseOptions = configuration.baseOptions;
              }

              const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
              const localVarHeaderParameter = {} as any;
              const localVarQueryParameter = {} as any;



              setSearchParams(localVarUrlObj, localVarQueryParameter);
              let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
              localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

              return {
                  url: toPathString(localVarUrlObj),
                  options: localVarRequestOptions,
              };
          },
          /**
           *
           * @param {string} flagId
           * @param {UpdateFeatureFlagDto} updateFeatureFlagDto
           * @param {*} [options] Override http request option.
           * @throws {RequiredError}
           */
          featureFlagControllerUpdate: async (flagId: string, updateFeatureFlagDto: UpdateFeatureFlagDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
              // verify required parameter 'flagId' is not null or undefined
              assertParamExists('featureFlagControllerUpdate', 'flagId', flagId)
              // verify required parameter 'updateFeatureFlagDto' is not null or undefined
              assertParamExists('featureFlagControllerUpdate', 'updateFeatureFlagDto', updateFeatureFlagDto)
              const localVarPath = `/feature-flags/{flagId}`
                  .replace(`{${"flagId"}}`, encodeURIComponent(String(flagId)));
              // use dummy base URL string because the URL constructor only accepts absolute URLs.
              const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
              let baseOptions;
              if (configuration) {
                  baseOptions = configuration.baseOptions;
              }

              const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
              const localVarHeaderParameter = {} as any;
              const localVarQueryParameter = {} as any;



              localVarHeaderParameter['Content-Type'] = 'application/json';

              setSearchParams(localVarUrlObj, localVarQueryParameter);
              let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
              localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
              localVarRequestOptions.data = serializeDataIfNeeded(updateFeatureFlagDto, localVarRequestOptions, configuration)

              return {
                  url: toPathString(localVarUrlObj),
                  options: localVarRequestOptions,
              };
          },
                  /**
         *
         * @param {UpdateSettingsDTO} updateSettingsDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureFlagControllerUpdateMany: async (updateSettingsDTO: UpdateSettingsDTO[], options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
          // verify required parameter 'updateSettingsDTO' is not null or undefined
          assertParamExists('featureFlagControllerUpdateMany', 'updateSettingsDTO', updateSettingsDTO)
          const localVarPath = `/feature-flags`;
          // use dummy base URL string because the URL constructor only accepts absolute URLs.
          const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
          let baseOptions;
          if (configuration) {
              baseOptions = configuration.baseOptions;
          }

          const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
          const localVarHeaderParameter = {} as any;
          const localVarQueryParameter = {} as any;



          localVarHeaderParameter['Content-Type'] = 'application/json';

          setSearchParams(localVarUrlObj, localVarQueryParameter);
          let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
          localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
          localVarRequestOptions.data = serializeDataIfNeeded(updateSettingsDTO, localVarRequestOptions, configuration)

          return {
              url: toPathString(localVarUrlObj),
              options: localVarRequestOptions,
          };
      },
    /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
    transmissionReportsControllerSignAllStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/transmission-reports/signAll/request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
    registeredNpisControllerGetAllTaxonomyData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/npi/taxonomy`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  },
  /**
   *
   * @param {string} [firstName]
   * @param {string} [lastName]
   * @param {string} [state]
   * @param {string} [city]
   * @param {string} [limit]
   * @param {string} [offset]
   * @param {string} [npi]
   * @param {string} [taxonomy]
   * @param {string} [sortBy]
   * @param {'ASC' | 'DESC'} [sortDirection]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  registeredNpisControllerGetNpisData: async (firstName?: string, lastName?: string, state?: string, city?: string, limit?: string, offset?: string, npi?: string, taxonomy?: string, sortBy?: string, sortDirection?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/npi`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (firstName !== undefined) {
          localVarQueryParameter['first_name'] = firstName;
      }

      if (lastName !== undefined) {
          localVarQueryParameter['last_name'] = lastName;
      }

      if (state !== undefined) {
          localVarQueryParameter['state'] = state;
      }

      if (city !== undefined) {
          localVarQueryParameter['city'] = city;
      }

      if (limit !== undefined) {
          localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
          localVarQueryParameter['offset'] = offset;
      }

      if (npi !== undefined) {
          localVarQueryParameter['npi'] = npi;
      }

      if (taxonomy !== undefined) {
          localVarQueryParameter['taxonomy'] = taxonomy;
      }

      if (sortBy !== undefined) {
          localVarQueryParameter['sortBy'] = sortBy;
      }

      if (sortDirection !== undefined) {
          localVarQueryParameter['sortDirection'] = sortDirection;
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  },
    /**
         *
         * @param {ApplyOrReplaceTemplateRequest} applyOrReplaceTemplateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
    reportTemplateControllerApplyTemplate: async (applyOrReplaceTemplateRequest: ApplyOrReplaceTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'applyOrReplaceTemplateRequest' is not null or undefined
      assertParamExists('reportTemplateControllerApplyTemplate', 'applyOrReplaceTemplateRequest', applyOrReplaceTemplateRequest)
      const localVarPath = `/report-template/apply`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(applyOrReplaceTemplateRequest, localVarRequestOptions, configuration)

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  },
  /**
   *
   * @param {number} transmissionReportId
   * @param {string} [deviceType]
   * @param {string} vendor
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  reportTemplateControllerGetReportTemplates: async (transmissionReportId: number, vendor: string, deviceType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
    // verify required parameter 'transmissionReportId' is not null or undefined
    assertParamExists('reportTemplateControllerGetReportTemplates', 'transmissionReportId', transmissionReportId)
    // verify required parameter 'vendor' is not null or undefined
    assertParamExists('reportTemplateControllerGetReportTemplates', 'vendor', vendor)
    const localVarPath = `/report-template/{transmissionReportId}`
        .replace(`{${"transmissionReportId"}}`, encodeURIComponent(String(transmissionReportId)));
    // use dummy base URL string because the URL constructor only accepts absolute URLs.
    const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
    let baseOptions;
    if (configuration) {
        baseOptions = configuration.baseOptions;
    }

    const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
    const localVarHeaderParameter = {} as any;
    const localVarQueryParameter = {} as any;

    // authentication bearer required
    // http bearer authentication required
    await setBearerAuthToObject(localVarHeaderParameter, configuration)

    if (deviceType !== undefined) {
        localVarQueryParameter['deviceType'] = deviceType;
    }

    if (vendor !== undefined) {
      localVarQueryParameter['vendor'] = vendor;
    }


    setSearchParams(localVarUrlObj, localVarQueryParameter);
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

    return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
    };
  },
  /**
   *
   * @param {ApplyOrReplaceTemplateRequest} applyOrReplaceTemplateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
    reportTemplateControllerReplaceTemplate: async (applyOrReplaceTemplateRequest: ApplyOrReplaceTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'applyOrReplaceTemplateRequest' is not null or undefined
      assertParamExists('reportTemplateControllerReplaceTemplate', 'applyOrReplaceTemplateRequest', applyOrReplaceTemplateRequest)
      const localVarPath = `/report-template/replace`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(applyOrReplaceTemplateRequest, localVarRequestOptions, configuration)

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RemoveTemplateRequest} removeTemplateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportTemplateControllerRemoveTemplate: async (removeTemplateRequest: RemoveTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'removeTemplateRequest' is not null or undefined
      assertParamExists('reportTemplateControllerRemoveTemplate', 'removeTemplateRequest', removeTemplateRequest)
      const localVarPath = `/report-template/remove`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(removeTemplateRequest, localVarRequestOptions, configuration)

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
    },
     /**
     *
     * @param {CreateScheduledTransmissionDto} createScheduledTransmissionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
     scheduledTransmissionControllerCreate: async (createScheduledTransmissionDto: CreateScheduledTransmissionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createScheduledTransmissionDto' is not null or undefined
      assertParamExists('scheduledTransmissionControllerCreate', 'createScheduledTransmissionDto', createScheduledTransmissionDto)
      const localVarPath = `/scheduled-transmission`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(createScheduledTransmissionDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scheduledTransmissionControllerFindUpcomingScheduledTransmission: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('scheduledTransmissionControllerFindUpcomingScheduledTransmission', 'patientId', patientId)
      const localVarPath = `/scheduled-transmission/{patientId}/find-upcoming-Scheduled-transmission`
        .replace(`{${'patientId'}}`, encodeURIComponent(String(patientId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any


      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} deviceId
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scheduledTransmissionControllerGetAll: async (deviceId: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('scheduledTransmissionControllerGetAll', 'deviceId', deviceId)
      const localVarPath = `/scheduled-transmission`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (deviceId !== undefined) {
        localVarQueryParameter['deviceId'] = deviceId
      }

      if (fromDate !== undefined) {
        localVarQueryParameter['fromDate'] = fromDate
      }

      if (toDate !== undefined) {
        localVarQueryParameter['toDate'] = toDate
      }


      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scheduledTransmissionControllerResetScheduledTransmissions: async (deviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists('scheduledTransmissionControllerResetScheduledTransmissions', 'deviceId', deviceId)
      const localVarPath = `/scheduled-transmission/reset`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'PATCH', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (deviceId !== undefined) {
        localVarQueryParameter['deviceId'] = deviceId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} scheduledTransmissionId
     * @param {UpdateDateScheduledTransmissionDto} updateDateScheduledTransmissionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scheduledTransmissionControllerUpdate: async (scheduledTransmissionId: string, updateDateScheduledTransmissionDto: UpdateDateScheduledTransmissionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'scheduledTransmissionId' is not null or undefined
      assertParamExists('scheduledTransmissionControllerUpdate', 'scheduledTransmissionId', scheduledTransmissionId)
      // verify required parameter 'updateDateScheduledTransmissionDto' is not null or undefined
      assertParamExists('scheduledTransmissionControllerUpdate', 'updateDateScheduledTransmissionDto', updateDateScheduledTransmissionDto)
      const localVarPath = `/scheduled-transmission/{scheduledTransmissionId}`
        .replace(`{${"scheduledTransmissionId"}}`, encodeURIComponent(String(scheduledTransmissionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: 'PATCH', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(updateDateScheduledTransmissionDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },

    /**
     *
     * @param {string} id
     * @param {UpdateScheduledTransmissionTypeDto} updateScheduledTransmissionTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scheduledTransmissionControllerUpdateScheduleTransmission: async (id: string, updateScheduledTransmissionTypeDto: UpdateScheduledTransmissionTypeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('scheduledTransmissionControllerUpdateScheduleTransmission', 'id', id)
      // verify required parameter 'updateScheduledTransmissionTypeDto' is not null or undefined
      assertParamExists('scheduledTransmissionControllerUpdateScheduleTransmission', 'updateScheduledTransmissionTypeDto', updateScheduledTransmissionTypeDto)
      const localVarPath = `/scheduled-transmission/type/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: 'PATCH', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = serializeDataIfNeeded(updateScheduledTransmissionTypeDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },

    /**
     *
     * @param {CreateAdHocTransmissionDto} createAdHocTransmissionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scheduledTransmissionControllerCreateAdHoc: async (createAdHocTransmissionDto: CreateAdHocTransmissionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createAdHocTransmissionDto' is not null or undefined
      assertParamExists('scheduledTransmissionControllerCreateAdHoc', 'createAdHocTransmissionDto', createAdHocTransmissionDto)
      const localVarPath = `/scheduled-transmission/adHoc`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any


      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
      localVarRequestOptions.data = serializeDataIfNeeded(createAdHocTransmissionDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scheduledTransmissionControllerFetchTermDays: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/scheduled-transmission/schedule-terms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  }
}

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (axiosInstance: AxiosInstance, configuration?: Configuration | undefined) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerGetInClinicEpisode(transmissionReportId: number, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<TransmissionReportInClinicEpisode>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerGetInClinicEpisode(transmissionReportId, options);
     return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
      /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      async transmissionReportsControllerLeadVendorList(options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<LeadVendor[]>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerLeadVendorList(options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /*
    /**
     *
     * @param {number} transmissionReportId
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerUpdateInClinicEpisode(transmissionReportId: number, body: object, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<BasicResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerUpdateInClinicEpisode(transmissionReportId, body, options);
     return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} billingId
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billingControllerUpdateBilling(billingId: string, body: UpdateBillingDto, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billingControllerUpdateBilling(billingId, body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} billingId
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billingControllerUpdateIsBillable(billingId: string, body: object, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billingControllerUpdateIsBillable(billingId, body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerGetBilling(transmissionReportId: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<Billing>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerGetBilling(transmissionReportId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} billingId
     * @param {ReportAddDiagnosisCodeDto} reportAddDiagnosisCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerAddDiagnosisCodeToBilling(transmissionReportId: number, billingId: string, reportAddDiagnosisCodeDto: ReportAddDiagnosisCodeDto, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<TransmissionReportNoteResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerAddDiagnosisCodeToBilling(transmissionReportId, billingId, reportAddDiagnosisCodeDto, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param files
     * @param {Array<string>} [deletedFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerAddAttachments(transmissionReportId: number, files?: any, deletedFiles?: Array<string>, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<BasicResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerAddAttachments(transmissionReportId, files, deletedFiles, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerGetAttachments(transmissionReportId: number, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<GetTransmissionAttachmentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerGetAttachments(transmissionReportId, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesControllerGet(
      fileId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UploadFileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerGet(
        fileId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} fileId
     * @param {string} path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesControllerRemove(fileId: string, path: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<DeleteFileResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerRemove(fileId, path, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {any} file
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesControllerUpload(
      file: any,
      path?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UploadFileResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerUpload(
        file,
        path,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {CreateGlobalFilterParams} createGlobalFilterParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async globalFilterControllerCreate(
      createGlobalFilterParams: CreateGlobalFilterParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateGlobalFilterResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.globalFilterControllerCreate(
        createGlobalFilterParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async globalFilterControllerFindOne(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FindOneGlobalFilterResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.globalFilterControllerFindOne(
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {UpdateGlobalFilterParams} updateGlobalFilterParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async globalFilterControllerUpdate(
      updateGlobalFilterParams: UpdateGlobalFilterParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateGlobalFilterResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.globalFilterControllerUpdate(
        updateGlobalFilterParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthControllerPing(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerPing(
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} transmissionReportId
     * @param {string} patientId
     * @param {string} signedDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async historyControllerFindOne(transmissionReportId: string, patientId: string, signedDate: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<TransmissionReportHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.historyControllerFindOne(transmissionReportId, patientId, signedDate, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateOrganizationParams} createOrganizationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreate(
      createOrganizationParams: CreateOrganizationParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateOrganizationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreate(
        createOrganizationParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} organizationId
     * @param {CreatePatientParams} createPatientParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreatePatient(
      organizationId: string,
      createPatientParams: CreatePatientParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreatePatientResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreatePatient(
        organizationId,
        createPatientParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {FindAllOrganizationsQueryParams} [queryParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerFindAll(
      queryParams?: FindAllOrganizationsQueryParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FindAllOrganizationsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerFindAll(
        queryParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} organizationId
     * @param {PatientOffsetPaginationParams} [queryParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerFindAllPatients(
      organizationId: string,
      queryParams?: PatientOffsetPaginationParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FindAllPatientsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerFindAllPatients(
        organizationId,
        queryParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} organizationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerFindOne(
      organizationId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FindOneOrganizationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerFindOne(
        organizationId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} organizationId
     * @param {UpdateOrganizationParams} updateOrganizationParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdate(
      organizationId: string,
      updateOrganizationParams: UpdateOrganizationParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateOrganizationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdate(
        organizationId,
        updateOrganizationParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} organizationId
     * @param {string} vendorIntegrationId
     * @param {UpdateVendorIntegrationStatusParams} updateVendorIntegrationStatusParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateVendorIntegrationStatus(
      organizationId: string,
      vendorIntegrationId: string,
      updateVendorIntegrationStatusParams: UpdateVendorIntegrationStatusParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateVendorIntegrationStatusResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateVendorIntegrationStatus(
        organizationId,
        vendorIntegrationId,
        updateVendorIntegrationStatusParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} patientId
     * @param {CreatePatientAddressParams} createPatientAddressParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerCreatePatientAddress(
      patientId: string,
      createPatientAddressParams: CreatePatientAddressParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreatePatientResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerCreatePatientAddress(
        patientId,
        createPatientAddressParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} patientId
     * @param {CreatePatientMatchDeviceParams} createPatientMatchDeviceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerCreatePatientMatchDevice(
      patientId: string,
      createPatientMatchDeviceParams: CreatePatientMatchDeviceParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreatePatientResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerCreatePatientMatchDevice(
        patientId,
        createPatientMatchDeviceParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerFindAllAuthorizedPractitioners(
      patientId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FindAllAuthorizedPractitionersResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerFindAllAuthorizedPractitioners(
        patientId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerFindOne(
      patientId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FindOnePatientResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerFindOne(
        patientId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} mrn1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerFindOneKareoPatient(
      mrn1: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FindOnePatientResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerFindOneKareoPatient(
        mrn1,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {FindAllPatientsParams} findAllPatientsParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerGetAllPatientsWithAccessTo(findAllPatientsParams: FindAllPatientsParams, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<FindAllPatientsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerGetAllPatientsWithAccessTo(findAllPatientsParams, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {MatchTransmissionParams} matchTransmissionParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerMatchTransmission(
      matchTransmissionParams: MatchTransmissionParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MatchTransmissionResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerMatchTransmission(
        matchTransmissionParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {GetTotalPatientsMonitoredParams} [queryParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerTotalMonitored(
      queryParams?: GetTotalPatientsMonitoredParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetTotalPatientsMonitoredResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerTotalMonitored(
        queryParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} patientId
     * @param {PatientActiveInactiveStatusParams} patientActiveInactiveStatusParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerPatientActiveInactiveStatus(
      patientId: string,
      patientActiveInactiveStatusParams: PatientActiveInactiveStatusParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdatePatientResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerPatientActiveInactiveStatus(
        patientId,
        patientActiveInactiveStatusParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },

    /**
     *
     * @param {string} patientId
     * @param {UpdatePatientParams} updatePatientParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerUpdate(
      patientId: string,
      updatePatientParams: UpdatePatientParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdatePatientResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerUpdate(
        patientId,
        updatePatientParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} patientId
     * @param {UpdatePatientAddressParams} updatePatientAddressParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerUpdatePatientAddress(
      patientId: string,
      updatePatientAddressParams: UpdatePatientAddressParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdatePatientResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerUpdatePatientAddress(
        patientId,
        updatePatientAddressParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} id
     * @param {UpdateScheduledTransmissionTypeDto} updateScheduledTransmissionTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scheduledTransmissionControllerUpdateScheduleTransmission(id: string, updateScheduledTransmissionTypeDto: UpdateScheduledTransmissionTypeDto, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledTransmissionResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledTransmissionControllerUpdateScheduleTransmission(id, updateScheduledTransmissionTypeDto, options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} patientId
     * @param {UpdatePatientMatchDeviceParams} updatePatientMatchDeviceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerUpdatePatientMatchDevice(
      patientId: string,
      updatePatientMatchDeviceParams: UpdatePatientMatchDeviceParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdatePatientResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerUpdatePatientMatchDevice(
        patientId,
        updatePatientMatchDeviceParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
     /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
     async registeredNpisControllerGetAllTaxonomyData(options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<FetchAllTaxonomyCodesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registeredNpisControllerGetAllTaxonomyData(options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
  },
  /**
   *
   * @param {string} [firstName]
   * @param {string} [lastName]
   * @param {string} [state]
   * @param {string} [city]
   * @param {string} [limit]
   * @param {string} [offset]
   * @param {string} [npi]
   * @param {string} [taxonomy]
   * @param {string} [sortBy]
   * @param {'ASC' | 'DESC'} [sortDirection]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  async registeredNpisControllerGetNpisData(firstName?: string, lastName?: string, state?: string, city?: string, limit?: string, offset?: string, npi?: string, taxonomy?: string, sortBy?: string, sortDirection?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<FindAllNpiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registeredNpisControllerGetNpisData(firstName, lastName, state, city, limit, offset, npi, taxonomy, sortBy, sortDirection, options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
  },
    /**
         *
         * @param {ApplyOrReplaceTemplateRequest} applyOrReplaceTemplateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
      async reportTemplateControllerApplyTemplate(applyOrReplaceTemplateRequest: ApplyOrReplaceTemplateRequest, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<ApplyOrRemoveTemplateResponse>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.reportTemplateControllerApplyTemplate(applyOrReplaceTemplateRequest, options);
        return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} vendor
     * @param {string} [deviceType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reportTemplateControllerGetReportTemplates(transmissionReportId: number, vendor: string, deviceType?: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<GetTemplatesResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reportTemplateControllerGetReportTemplates(transmissionReportId, vendor, deviceType, options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ApplyOrReplaceTemplateRequest} applyOrReplaceTemplateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reportTemplateControllerReplaceTemplate(applyOrReplaceTemplateRequest: ApplyOrReplaceTemplateRequest, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<ReplaceTemplateResponse>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.reportTemplateControllerReplaceTemplate(applyOrReplaceTemplateRequest, options);
        return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RemoveTemplateRequest} removeTemplateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reportTemplateControllerRemoveTemplate(removeTemplateRequest: RemoveTemplateRequest, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<ApplyOrRemoveTemplateResponse>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.reportTemplateControllerRemoveTemplate(removeTemplateRequest, options);
        return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} alertId
     * @param {TransmissionReportAddAlertImageParams} transmissionReportAddAlertImageParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerAddAlertImage(
      transmissionReportId: number,
      alertId: string,
      transmissionReportAddAlertImageParams: TransmissionReportAddAlertImageParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportAddAlertImageResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerAddAlertImage(
        transmissionReportId,
        alertId,
        transmissionReportAddAlertImageParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} patientId
     * @param {string} fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerAddInClinicPdfToTempBucket(patientId: string, fileName: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<InclinicReporttempPdfResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerAddInClinicPdfToTempBucket(patientId, fileName, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerFindPatientsReportsDeviceType(patientId: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<FindPatientsReportsDeviceTypeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patientsControllerFindPatientsReportsDeviceType(patientId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerAddRhythmVendorPdfToBucket(
      transmissionReportId: number,
      file: any,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportPdfResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerAddRhythmVendorPdfToBucket(
        transmissionReportId,
        file,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {TransmissionReportCountParamsDto} [queryParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerCount(
      queryParams?: TransmissionReportCountParamsDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportCountResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerCount(
        queryParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {TransmissionReportAlertCreateParams} transmissionReportAlertCreateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerCreateAlert(
      transmissionReportId: number,
      transmissionReportAlertCreateParams: TransmissionReportAlertCreateParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportAlertResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerCreateAlert(
        transmissionReportId,
        transmissionReportAlertCreateParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {TransmissionReportCountersCreateParams} transmissionReportCountersCreateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerCreateCounters(
      transmissionReportId: number,
      transmissionReportCountersCreateParams: TransmissionReportCountersCreateParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportCountersResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerCreateCounters(
        transmissionReportId,
        transmissionReportCountersCreateParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {TransmissionReportBillingCreateParams} transmissionReportBillingCreateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerCreateBilling(
      transmissionReportId: number,
      transmissionReportBillingCreateParams: TransmissionReportBillingCreateParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportBillingResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerCreateBilling(
        transmissionReportId,
        transmissionReportBillingCreateParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {boolean} flag
     * @param {TransmissionReportNoteCreateParams} transmissionReportNoteCreateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerCreateNote(
      transmissionReportId: number,
      flag: boolean,
      transmissionReportNoteCreateParams: TransmissionReportNoteCreateParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportNoteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerCreateNote(
        transmissionReportId,
        flag,
        transmissionReportNoteCreateParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} alertId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerDeleteAlert(
      transmissionReportId: number,
      alertId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportAlertDeleteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerDeleteAlert(
        transmissionReportId,
        alertId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} alertId
     * @param {string} imageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerDeleteAlertImage(
      transmissionReportId: number,
      alertId: string,
      imageId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportDeleteAlertImageResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerDeleteAlertImage(
        transmissionReportId,
        alertId,
        imageId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} billingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerDeleteBilling(
      transmissionReportId: number,
      billingId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportNoteDeleteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerDeleteBilling(
        transmissionReportId,
        billingId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerDeleteNote(
      transmissionReportId: number,
      noteId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportNoteDeleteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerDeleteNote(
        transmissionReportId,
        noteId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },

    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerDismiss(
      transmissionReportId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportDismissResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerDismiss(
        transmissionReportId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {Array<number>} tranmissionReportIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerDismissAll(tranmissionReportIds: Array<number>, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerDismissAll(tranmissionReportIds, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {boolean} isEditable
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerEditReport(
      transmissionReportId: number,
      isEditable: boolean,
      file: File,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportSignResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerEditReport(
        transmissionReportId,
        isEditable,
        file,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerUnDismiss(
      transmissionReportId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportDismissResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerUnDismiss(
        transmissionReportId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {TransmissionReportSignAllParams} transmissionReportSignAllParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
        async transmissionReportsControllerSignAllToLambda(transmissionReportSignAllParams: TransmissionReportSignAllParams, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerSignAllToLambda(transmissionReportSignAllParams, options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} signDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerStorePDFDownloadInfo(transmissionReportId: number, signDate: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<TransmissionReportPDFDownloadInfoResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerStorePDFDownloadInfo(transmissionReportId, signDate, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerFetchICDDiagnosisCodes(options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<FetchAllICDCodesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerFetchICDDiagnosisCodes(options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },

    /**
     *
     * @summary Get ICD Diagnosis Codes
     * @param {string} query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async icdDiagnosisControllerGetIcdCodes(query: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<Array<IcdDiagnosisCode>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.icdDiagnosisControllerGetIcdCodes(query, options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Array<number>} transmissionReportIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerFetchIsBillableForReports(transmissionReportIds: Array<number>, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<FindIsBillableTransmissionReportsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerFetchIsBillableForReports(transmissionReportIds, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerFindOne(
      transmissionReportId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FindOneTransmissionReportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerFindOne(
        transmissionReportId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {TransmissionReportListRequestDto} transmissionReportListRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerList(
      transmissionReportListRequestDto: TransmissionReportListRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportListResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerList(
        transmissionReportListRequestDto,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {TransmissionReportSearchUnsignedReportsRequestDto} transmissionReportSearchUnsignedReportsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerSearchUnsignedReports(transmissionReportSearchUnsignedReportsRequestDto: TransmissionReportSearchUnsignedReportsRequestDto, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<TransmissionReportSearchUnsignedReportsResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerSearchUnsignedReports(transmissionReportSearchUnsignedReportsRequestDto, options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {TransmissionReportSignParams} transmissionReportSignDateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerSign(transmissionReportId: number, transmissionReportSignDateParams: TransmissionReportSignParams, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<TransmissionReportSignature>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerSign(transmissionReportId, transmissionReportSignDateParams, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {ManualORInclinicTransmissionReportRequestDto} manualORInclinicTransmissionReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerSubmitManualAndInclinicReports(manualORInclinicTransmissionReportRequestDto: ManualORInclinicTransmissionReportRequestDto, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<UploadResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerSubmitManualAndInclinicReports(manualORInclinicTransmissionReportRequestDto, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {VendorDataOverrides} vendorDataOverrides
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerTransmissionOverride(
      transmissionReportId: number,
      vendorDataOverrides: VendorDataOverrides,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<VendorDataOverrides>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerTransmissionOverride(
        transmissionReportId,
        vendorDataOverrides,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {UpdateTransmissionReportParams} updateTransmissionReportParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerUpdate(
      transmissionReportId: number,
      updateTransmissionReportParams: UpdateTransmissionReportParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateTransmissionReportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerUpdate(
        transmissionReportId,
        updateTransmissionReportParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} alertId
     * @param {TransmissionReportAlertUpdateParams} transmissionReportAlertUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerUpdateAlert(
      transmissionReportId: number,
      alertId: string,
      transmissionReportAlertUpdateParams: TransmissionReportAlertUpdateParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportAlertResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerUpdateAlert(
        transmissionReportId,
        alertId,
        transmissionReportAlertUpdateParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} countersId
     * @param {TransmissionReportCountersUpdateParams} transmissionReportCountersUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerUpdateCounters(
      transmissionReportId: number,
      countersId: string,
      transmissionReportCountersUpdateParams: TransmissionReportCountersUpdateParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportCountersResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerUpdateCounters(
        transmissionReportId,
        countersId,
        transmissionReportCountersUpdateParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} billingId
     * @param {TransmissionReportBillingUpdateParams} transmissionReportBillingUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerUpdateBilling(
      transmissionReportId: number,
      billingId: string,
      transmissionReportBillingUpdateParams: TransmissionReportBillingUpdateParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportNoteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerUpdateBilling(
        transmissionReportId,
        billingId,
        transmissionReportBillingUpdateParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {string} noteId
     * @param {TransmissionReportNoteUpdateParams} transmissionReportNoteUpdateParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerUpdateNote(
      transmissionReportId: number,
      noteId: string,
      transmissionReportNoteUpdateParams: TransmissionReportNoteUpdateParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TransmissionReportNoteResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerUpdateNote(
        transmissionReportId,
        noteId,
        transmissionReportNoteUpdateParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
         *
         * @param {number} transmissionReportId
         * @param {string} programmedParameterId
         * @param {ProgrammedParametersDto} programmedParametersDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
    async transmissionReportsControllerUpdateProgrammedParameters(transmissionReportId: number, programmedParametersDto: ProgrammedParametersDto, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerUpdateProgrammedParameters(transmissionReportId, programmedParametersDto, options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
  },
    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerVendorDbPatientDeviceType(
      transmissionReportId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<VendorDeviceTypeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerVendorDbPatientDeviceType(
        transmissionReportId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {VendorTransmissionReportUrlParamsDto} [queryParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerVendorTransmissionReportUrl(
      transmissionReportId: number,
      queryParams?: VendorTransmissionReportUrlParamsDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<VendorTransmissionReportUrlResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerVendorTransmissionReportUrl(
        transmissionReportId,
        queryParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {number} transmissionReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transmissionReportsControllerVendorTransmissionsReports(
      transmissionReportId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<VendorTransmissionReportsResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerVendorTransmissionsReports(
        transmissionReportId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {CreateUserParams} createUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerCreate(
      createUserParams: CreateUserParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerCreate(
        createUserParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} userId
     * @param {CreateUserAddressParams} createUserAddressParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerCreateUserAddress(
      userId: string,
      createUserAddressParams: CreateUserAddressParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerCreateUserAddress(
        userId,
        createUserAddressParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerDeleteAddress(
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDeleteAddress(
        userId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerFindCurrentUserInfo(
      options?: any,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FindOneUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFindCurrentUserInfo(
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {FindAllUsersQueryParams} [queryParams]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerFindAll(
      queryParams?: FindAllUsersQueryParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FindAllUsersResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFindAll(
        queryParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerFindOne(
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FindOneUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFindOne(
        userId,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateUserParams} updateUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerUpdate(
      userId: string,
      updateUserParams: UpdateUserParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdate(
        userId,
        updateUserParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {string} userId
     * @param {UpdateUserAddressParams} updateUserAddressParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerUpdateUserAddress(
      userId: string,
      updateUserAddressParams: UpdateUserAddressParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateUserResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateUserAddress(
        userId,
        updateUserAddressParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {VtLeadVendorListParams} vtLeadVendorListParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async vtVendorControllerLeadVendorList(
      vtLeadVendorListParams: VtLeadVendorListParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<VtLeadVendorListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.vtVendorControllerLeadVendorList(
        vtLeadVendorListParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
     *
     * @param {VtVendorListParams} vtVendorListParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async vtVendorControllerList(
      vtVendorListParams: VtVendorListParams,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<VtVendorListResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.vtVendorControllerList(
        vtVendorListParams,
        options,
      )
      return createRequestFunction(
        localVarAxiosArgs,
        axiosInstance,
        BASE_PATH,
        configuration,
      )
    },
    /**
         *
         * @param {AccountOverviewQuery} accountOverviewQuery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
    async analyticsControllerAccountOverviewReport(accountOverviewQuery: AccountOverviewQuery, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<AccountOverviewResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsControllerAccountOverviewReport(accountOverviewQuery, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
  },
  /**
   *
   * @param {AlertsQuery} alertsQuery
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  async analyticsControllerAlertsReport(alertsQuery: AlertsQuery, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<AlertsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsControllerAlertsReport(alertsQuery, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
  },
  /**
   *
   * @param {BillableQuery} billableQuery
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  async analyticsControllerBillableReport(billableQuery: BillableQuery, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsControllerBillableReport(billableQuery, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
  },
   /**
   *
   * @param {DownloadReportRequest} downloadReportRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  async analyticsControllerDownloadReport(downloadReportRequest: DownloadReportRequest, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<DownloadReportResponse>> {
    const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsControllerDownloadReport(downloadReportRequest, options);
    return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
  },
  /**
   *
   * @param {GetPractitionersQuery} getPractitionersQuery
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  async analyticsControllerGetPractitioners(getPractitionersQuery: GetPractitionersQuery, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<GetPractitionersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsControllerGetPractitioners(getPractitionersQuery, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
  },
  /**
   *
   * @param {SchedulingQuery} schedulingQuery
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  async analyticsControllerSchedulingReport(schedulingQuery: SchedulingQuery, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<SchedulingResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsControllerSchedulingReport(schedulingQuery, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
  },
  /**
   *
   * @param {SignatureStatusQuery} signatureStatusQuery
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  async analyticsControllerSignatureStatusReport(signatureStatusQuery: SignatureStatusQuery, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<SignatureStatusResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsControllerSignatureStatusReport(signatureStatusQuery, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
  },
    /**
     *
     * @param {CreateInitializeBatchJobRequest} createInitializeBatchJobRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchDownloadControllerCreate(createInitializeBatchJobRequest: CreateInitializeBatchJobRequest, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<CreateInitializeBatchJobResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.batchDownloadControllerCreate(createInitializeBatchJobRequest, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchDownloadControllerFindAll(options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<BatchPDFDownloadInfoResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.batchDownloadControllerFindAll(options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchDownloadControllerFindOne(id: number, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<BatchPDFDownloadInfoResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.batchDownloadControllerFindOne(id, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {TransmissionIdsRequest} transmissionIdsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchDownloadControllerGetPatientTransmissionData(transmissionIdsRequest: TransmissionIdsRequest, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<TransmissionIdsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.batchDownloadControllerGetPatientTransmissionData(transmissionIdsRequest, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchDownloadControllerRemove(id: number, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<BatchPDFDownloadInfoResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.batchDownloadControllerRemove(id, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {UpdateBatchDownloadDto} updateBatchDownloadDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchDownloadControllerUpdate(id: number, updateBatchDownloadDto: UpdateBatchDownloadDto, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<BatchPDFDownloadInfoResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.batchDownloadControllerUpdate(id, updateBatchDownloadDto, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {BatchPDFDownloadInfoRequest} batchPDFDownloadInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchDownloadControllerUpdateBatchPDFDownloadInfo(batchPDFDownloadInfoRequest: BatchPDFDownloadInfoRequest, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<BatchPDFDownloadInfoResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.batchDownloadControllerUpdateBatchPDFDownloadInfo(batchPDFDownloadInfoRequest, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
     /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
     async transmissionReportsControllerSignAllStatus(options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<Array<SignAllRequests>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.transmissionReportsControllerSignAllStatus(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
  },
    /**
     *
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingControllerGetSetting(name: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<Setting>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.settingControllerGetSetting(name, options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreateNoteRequest} createNoteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clinicalNotesControllerCreateNote(createNoteRequest: CreateNoteRequest, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<CreateNoteResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clinicalNotesControllerCreateNote(createNoteRequest, options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
    /**
    *
    * @param {string} noteId
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    */
    async clinicalNotesControllerDeleteNote(noteId: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<DeleteNoteResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clinicalNotesControllerDeleteNote(noteId, options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clinicalNotesControllerGetClinicalNoteById(noteId: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<GetClinicalNoteResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clinicalNotesControllerGetClinicalNoteById(noteId, options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} patientId
     * @param {number} [page]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clinicalNotesControllerGetClinicalNotes(patientId: string, page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<GetClinicalNotesResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clinicalNotesControllerGetClinicalNotes(patientId, page, limit, options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clinicalNotesControllerGetPinnedNotesAndTotalCount(patientId: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<GetPinnedNotesAndTotalCountResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clinicalNotesControllerGetPinnedNotesAndTotalCount(patientId, options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
    /**
    *
    * @param {string} noteId
    * @param {UpdateNoteRequest} updateNoteRequest
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    */
    async clinicalNotesControllerUpdateNote(noteId: string, updateNoteRequest: UpdateNoteRequest, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<UpdateNoteResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clinicalNotesControllerUpdateNote(noteId, updateNoteRequest, options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
           /**
         *
         * @param {CreateFeatureFlagDto} createFeatureFlagDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
           async featureFlagControllerCreateFeatureFlag(createFeatureFlagDto: CreateFeatureFlagDto, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<GetFeatureFlagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureFlagControllerCreateFeatureFlag(createFeatureFlagDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureFlagControllerGetAllFlags(options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<Array<FlagsMapDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureFlagControllerGetAllFlags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureFlagControllerGetAllFlagsAdmin(options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<GetFeatureFlagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureFlagControllerGetAllFlagsAdmin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} shortCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureFlagControllerGetFlagStatus(shortCode: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<GetFeatureFlagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureFlagControllerGetFlagStatus(shortCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} flagId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureFlagControllerRemove(flagId: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureFlagControllerRemove(flagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} flagId
         * @param {UpdateFeatureFlagDto} updateFeatureFlagDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureFlagControllerUpdate(flagId: string, updateFeatureFlagDto: UpdateFeatureFlagDto, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<FeatureFlag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureFlagControllerUpdate(flagId, updateFeatureFlagDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
                /**
         *
         * @param {UpdateSettingsDTO} updateSettingsDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
                async featureFlagControllerUpdateMany(updateSettingsDTO: UpdateSettingsDTO[], options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureFlag>>> {
                  const localVarAxiosArgs = await localVarAxiosParamCreator.featureFlagControllerUpdateMany(updateSettingsDTO, options);
                  return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
              },
    /**
     *
     * @param {CreateScheduledTransmissionDto} createScheduledTransmissionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scheduledTransmissionControllerCreate(createScheduledTransmissionDto: CreateScheduledTransmissionDto, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledTransmissionResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledTransmissionControllerCreate(createScheduledTransmissionDto, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scheduledTransmissionControllerFindUpcomingScheduledTransmission(patientId: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<NextScheduleTranmissionResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledTransmissionControllerFindUpcomingScheduledTransmission(patientId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} deviceId
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scheduledTransmissionControllerGetAll(deviceId: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<GetScheduledTransmissionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledTransmissionControllerGetAll(deviceId, fromDate, toDate, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scheduledTransmissionControllerResetScheduledTransmissions(deviceId: string, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledTransmissionResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledTransmissionControllerResetScheduledTransmissions(deviceId, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} scheduledTransmissionId
     * @param {UpdateDateScheduledTransmissionDto} updateDateScheduledTransmissionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scheduledTransmissionControllerUpdate(scheduledTransmissionId: string, updateDateScheduledTransmissionDto: UpdateDateScheduledTransmissionDto, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledTransmissionResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledTransmissionControllerUpdate(scheduledTransmissionId, updateDateScheduledTransmissionDto, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateAdHocTransmissionDto} createAdHocTransmissionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scheduledTransmissionControllerCreateAdHoc(createAdHocTransmissionDto: CreateAdHocTransmissionDto, options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledTransmissionResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledTransmissionControllerCreateAdHoc(createAdHocTransmissionDto, options)
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scheduledTransmissionControllerFetchTermDays(options?: AxiosRequestConfig): Promise<(axios: AxiosInstance, basePath?: string) => AxiosPromise<FetchTermDaysResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledTransmissionControllerFetchTermDays(options);
      return createRequestFunction(localVarAxiosArgs, axiosInstance, BASE_PATH, configuration);
    },
  }
}

/**
 *
 * @export
 * @interface UpdateDateScheduledTransmissionDto
 */
export interface UpdateDateScheduledTransmissionDto {
  /**
   *
   * @type {string}
   * @memberof UpdateDateScheduledTransmissionDto
   */
  'newDate': string;
}

/**
 *
 * @export
 * @interface ScheduledTransmission
 */
export interface ScheduledTransmission {
  /**
   *
   * @type {string}
   * @memberof ScheduledTransmission
   */
  'id': string;
  /**
   *
   * @type {string}
   * @memberof ScheduledTransmission
   */
  'createdAt': string;
  /**
   *
   * @type {string}
   * @memberof ScheduledTransmission
   */
  'updatedAt': string;
  /**
   *
   * @type {string}
   * @memberof ScheduledTransmission
   */
  'createdBy': string;
  /**
   *
   * @type {string}
   * @memberof ScheduledTransmission
   */
  'updatedBy': string;
  /**
   *
   * @type {string}
   * @memberof ScheduledTransmission
   */
  'originalDate': string;
  /**
   *
   * @type {string}
   * @memberof ScheduledTransmission
   */
  'scheduledDate': string;
  /**
   *
   * @type {boolean}
   * @memberof ScheduledTransmission
   */
  'transmissionReceived': boolean;
  /**
   *
   * @type {string}
   * @memberof ScheduledTransmission
   */
  'transmissionType': ScheduledTransmissionTransmissionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ScheduledTransmission
   */
  'transmissionStatus': string;
  /**
   *
   * @type {boolean}
   * @memberof ScheduledTransmission
   */
  'isEriTracked': boolean;
  /**
   *
   * @type {ScheduleTerm}
   * @memberof ScheduledTransmission
   */
  'scheduleTerm': ScheduleTerm;
  /**
   *
   * @type {MatchDevice}
   * @memberof ScheduledTransmission
   */
  'device': MatchDevice;
  /**
   *
   * @type {TransmissionReport}
   * @memberof ScheduledTransmission
   */
  'transmissionReport': TransmissionReport;
  /**
   *
   * @type {boolean}
   * @memberof ScheduledTransmission
   */
  'isAdHocTransmission': boolean;
}

export const ScheduledTransmissionTransmissionTypeEnum = {
  HeartFailure: 'heart_failure',
  Routine: 'routine',
  ComboBilling: 'combo_billing',
  EriTracking: 'eri_tracking',
} as const

export type ScheduledTransmissionTransmissionTypeEnum = typeof ScheduledTransmissionTransmissionTypeEnum[keyof typeof ScheduledTransmissionTransmissionTypeEnum];

/**
 *
 * @export
 * @interface ScheduleTerm
 */
export interface ScheduleTerm {
  /**
   *
   * @type {string}
   * @memberof ScheduleTerm
   */
  'id': string;
  /**
   *
   * @type {string}
   * @memberof ScheduleTerm
   */
  'createdAt': string;
  /**
   *
   * @type {string}
   * @memberof ScheduleTerm
   */
  'updatedAt': string;
  /**
   *
   * @type {number}
   * @memberof ScheduleTerm
   */
  'termDays': number;
  /**
   *
   * @type {Array<Organization>}
   * @memberof ScheduleTerm
   */
  'organizations': Array<Organization>;
  /**
   *
   * @type {Array<MatchDevice>}
   * @memberof ScheduleTerm
   */
  'routineDevices': Array<MatchDevice>;
  /**
   *
   * @type {Array<MatchDevice>}
   * @memberof ScheduleTerm
   */
  'heartFailureDevices': Array<MatchDevice>;
  /**
   *
   * @type {Array<MatchDevice>}
   * @memberof ScheduleTerm
   */
  'comboBillingDevices': Array<MatchDevice>;
  /**
   *
   * @type {Array<ScheduledTransmission>}
   * @memberof ScheduleTerm
   */
  'scheduledTransmissions': Array<ScheduledTransmission>;
}

/**
 *
 * @export
 * @interface GetScheduledTransmissionsResponse
 */
export interface GetScheduledTransmissionsResponse {
  /**
   *
   * @type {Array<ScheduledTransmission>}
   * @memberof GetScheduledTransmissionsResponse
   */
  'data': Array<ScheduledTransmission>;
  /**
   *
   * @type {number}
   * @memberof GetScheduledTransmissionsResponse
   */
  'total': number;
  'transmissionScheduleReadOnly': boolean;
}

/**
 *
 * @export
 * @interface ScheduledTransmissionResponse
 */
export interface ScheduledTransmissionResponse {
  /**
   *
   * @type {boolean}
   * @memberof ScheduledTransmissionResponse
   */
  'success': boolean;
  /**
   *
   * @type {string}
   * @memberof ScheduledTransmissionResponse
   */
  'message': string;
}

/**
 *
 * @export
 * @interface ScheduleTransmissionTypeDto
 */
export interface ScheduleTransmissionTypeDto {
  /**
   *
   * @type {string}
   * @memberof ScheduleTransmissionTypeDto
   */
  'startDate': string;
  /**
   *
   * @type {number}
   * @memberof ScheduleTransmissionTypeDto
   */
  'transmissionFrequency': number;
}

/**
 *
 * @export
 * @interface CreateScheduledTransmissionDto
 */
export interface CreateScheduledTransmissionDto {
  /**
   *
   * @type {string}
   * @memberof CreateScheduledTransmissionDto
   */
  'deviceId': string;
  /**
   *
   * @type {boolean}
   * @memberof CreateScheduledTransmissionDto
   */
  'isEriTracked': boolean;
  /**
   *
   * @type {ScheduleTransmissionTypeDto}
   * @memberof CreateScheduledTransmissionDto
   */
  'routine'?: ScheduleTransmissionTypeDto;
  /**
   *
   * @type {ScheduleTransmissionTypeDto}
   * @memberof CreateScheduledTransmissionDto
   */
  'heartFailure'?: ScheduleTransmissionTypeDto;
  /**
   *
   * @type {ScheduleTransmissionTypeDto}
   * @memberof CreateScheduledTransmissionDto
   */
  'comboBilling'?: ScheduleTransmissionTypeDto;
}

export const CreateScheduledTransmissionDtoTransmissionTypeEnum = {
  HeartFailure: 'heart_failure',
  Routine: 'routine',
  ComboBilling: 'combo_billing',
  EriTracking: 'eri_tracking',
} as const

export type CreateScheduledTransmissionDtoTransmissionTypeEnum = typeof CreateScheduledTransmissionDtoTransmissionTypeEnum[keyof typeof CreateScheduledTransmissionDtoTransmissionTypeEnum];

/**
 * @export
 * @enum {string}
 */
export enum CreateScheduledTransmissionDtoInitialTransmissionTypeEnum {
  HeartFailure1 = 'heart_failure_1',
  HeartFailure2 = 'heart_failure_2',
  HeartFailure3 = 'heart_failure_3',
  Routine = 'routine'
}

/**
 * Request parameters for transmissionReportsControllerGetAttachments operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerGetAttachmentsRequest
 */
export interface DefaultApiTransmissionReportsControllerGetAttachmentsRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerGetAttachments
   */
  readonly transmissionReportId: number
}

/**
 * Request parameters for filesControllerGet operation in DefaultApi.
 * @export
 * @interface DefaultApiFilesControllerGetRequest
 */
export interface DefaultApiFilesControllerGetRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiFilesControllerGet
   */
  readonly fileId: string
}

/**
 * Request parameters for filesControllerRemove operation in DefaultApi.
 * @export
 * @interface DefaultApiFilesControllerRemoveRequest
 */
export interface DefaultApiFilesControllerRemoveRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiFilesControllerRemove
   */
  readonly fileId: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiFilesControllerRemove
   */
  readonly path: string
}

/**
 * Request parameters for filesControllerUpload operation in DefaultApi.
 * @export
 * @interface DefaultApiFilesControllerUploadRequest
 */
export interface DefaultApiFilesControllerUploadRequest {
  /**
   *
   * @type {any}
   * @memberof DefaultApiFilesControllerUpload
   */
  readonly file: any

  /**
   *
   * @type {string}
   * @memberof DefaultApiFilesControllerUpload
   */
  readonly path?: string
}

/**
 * Request parameters for organizationsControllerCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiOrganizationsControllerCreateRequest
 */
export interface DefaultApiOrganizationsControllerCreateRequest {
  /**
   *
   * @type {CreateOrganizationParams}
   * @memberof DefaultApiOrganizationsControllerCreate
   */
  readonly createOrganizationParams: CreateOrganizationParams
}


/**
 * Request parameters for historyControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiHistoryControllerFindOneRequest
 */
export interface DefaultApiHistoryControllerFindOneRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiHistoryControllerFindOne
   */
  readonly transmissionReportId: string
  /**
   *
   * @type {string}
   * @memberof DefaultApiHistoryControllerFindOne
   */
  readonly patientId: string
  /**
   *
   * @type {string}
   * @memberof DefaultApiHistoryControllerFindOne
   */
  readonly signedDate: string


}

/**
 * Request parameters for organizationsControllerCreatePatient operation in DefaultApi.
 * @export
 * @interface DefaultApiOrganizationsControllerCreatePatientRequest
 */
export interface DefaultApiOrganizationsControllerCreatePatientRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiOrganizationsControllerCreatePatient
   */
  readonly organizationId: string

  /**
   *
   * @type {CreatePatientParams}
   * @memberof DefaultApiOrganizationsControllerCreatePatient
   */
  readonly createPatientParams: CreatePatientParams
}

/**
 * Request parameters for organizationsControllerFindAll operation in DefaultApi.
 * @export
 * @interface DefaultApiOrganizationsControllerFindAllRequest
 */
export interface DefaultApiOrganizationsControllerFindAllRequest {
  /**
   *
   * @type {FindAllOrganizationsQueryParams}
   * @memberof DefaultApiOrganizationsControllerFindAll
   */
  readonly queryParams?: FindAllOrganizationsQueryParams
}

/**
 * Request parameters for organizationsControllerFindAllPatients operation in DefaultApi.
 * @export
 * @interface DefaultApiOrganizationsControllerFindAllPatientsRequest
 */
export interface DefaultApiOrganizationsControllerFindAllPatientsRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiOrganizationsControllerFindAllPatients
   */
  readonly organizationId: string

  /**
   *
   * @type {PatientOffsetPaginationParams}
   * @memberof DefaultApiOrganizationsControllerFindAllPatients
   */
  readonly queryParams?: PatientOffsetPaginationParams
}

/**
 * Request parameters for transmissionReportsControllerSignAllToLambda operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerSignAllToLambdaRequest
 */
export interface DefaultApiTransmissionReportsControllerSignAllToLambdaRequest {
  /**
   *
   * @type {TransmissionReportSignAllParams}
   * @memberof DefaultApiTransmissionReportsControllerSignAllToLambda
   */
  readonly transmissionReportSignAllParams: TransmissionReportSignAllParams
}

/**
 * Request parameters for organizationsControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiOrganizationsControllerFindOneRequest
 */
export interface DefaultApiOrganizationsControllerFindOneRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiOrganizationsControllerFindOne
   */
  readonly organizationId: string
}

/**
 * Request parameters for organizationsControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiOrganizationsControllerUpdateRequest
 */
export interface DefaultApiOrganizationsControllerUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiOrganizationsControllerUpdate
   */
  readonly organizationId: string

  /**
   *
   * @type {UpdateOrganizationParams}
   * @memberof DefaultApiOrganizationsControllerUpdate
   */
  readonly updateOrganizationParams: UpdateOrganizationParams
}

/**
 * Request parameters for organizationsControllerUpdateVendorIntegrationStatus operation in DefaultApi.
 * @export
 * @interface DefaultApiOrganizationsControllerUpdateVendorIntegrationStatusRequest
 */
export interface DefaultApiOrganizationsControllerUpdateVendorIntegrationStatusRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiOrganizationsControllerUpdateVendorIntegrationStatus
   */
  readonly organizationId: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiOrganizationsControllerUpdateVendorIntegrationStatus
   */
  readonly vendorIntegrationId: string

  /**
   *
   * @type {UpdateVendorIntegrationStatusParams}
   * @memberof DefaultApiOrganizationsControllerUpdateVendorIntegrationStatus
   */
  readonly updateVendorIntegrationStatusParams: UpdateVendorIntegrationStatusParams
}

/**
 * Request parameters for patientsControllerCreatePatientAddress operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerCreatePatientAddressRequest
 */
export interface DefaultApiPatientsControllerCreatePatientAddressRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiPatientsControllerCreatePatientAddress
   */
  readonly patientId: string

  /**
   *
   * @type {CreatePatientAddressParams}
   * @memberof DefaultApiPatientsControllerCreatePatientAddress
   */
  readonly createPatientAddressParams: CreatePatientAddressParams
}

/**
 * Request parameters for patientsControllerCreatePatientMatchDevice operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerCreatePatientMatchDeviceRequest
 */
export interface DefaultApiPatientsControllerCreatePatientMatchDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiPatientsControllerCreatePatientMatchDevice
   */
  readonly patientId: string

  /**
   *
   * @type {CreatePatientMatchDeviceParams}
   * @memberof DefaultApiPatientsControllerCreatePatientMatchDevice
   */
  readonly createPatientMatchDeviceParams: CreatePatientMatchDeviceParams
}

/**
 * Request parameters for patientsControllerFindAllAuthorizedPractitioners operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerFindAllAuthorizedPractitionersRequest
 */
export interface DefaultApiPatientsControllerFindAllAuthorizedPractitionersRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiPatientsControllerFindAllAuthorizedPractitioners
   */
  readonly patientId: string
}

/**
 * Request parameters for patientsControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerFindOneRequest
 */
export interface DefaultApiPatientsControllerFindOneRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiPatientsControllerFindOne
   */
  readonly patientId: string
}

/**
 * Request parameters for patientsControllerFindOneKareoPatient operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerFindOneKareoPatientRequest
 */
export interface DefaultApiPatientsControllerFindOneKareoPatientRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiPatientsControllerFindOneKareoPatient
   */
  readonly mrn1: string
}

/**
 * Request parameters for patientsControllerGetAllPatientsWithAccessTo operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerGetAllPatientsWithAccessToRequest
 */
export interface DefaultApiPatientsControllerGetAllPatientsWithAccessToRequest {
  /**
   *
   * @type {FindAllPatientsParams}
   * @memberof DefaultApiPatientsControllerGetAllPatientsWithAccessTo
   */
  readonly findAllPatientsParams: FindAllPatientsParams
}

/**
 * Request parameters for patientsControllerGetAllPatientsWithAccessTo operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerGetAllPatientsWithAccessToRequest
 */
export interface DefaultApiPatientsControllerGetAllPatientsWithAccessToRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiPatientsControllerGetAllPatientsWithAccessTo
   */
  readonly offset?: number

  /**
   *
   * @type {number}
   * @memberof DefaultApiPatientsControllerGetAllPatientsWithAccessTo
   */
  readonly limit?: number

  /**
   *
   * @type {boolean}
   * @memberof DefaultApiPatientsControllerGetAllPatientsWithAccessTo
   */
  readonly isNew?: boolean

  /**
   *
   * @type {boolean}
   * @memberof DefaultApiPatientsControllerGetAllPatientsWithAccessTo
   */
  readonly isRecentSigned?: boolean

  /**
   *
   * @type {boolean}
   * @memberof DefaultApiPatientsControllerGetAllPatientsWithAccessTo
   */
  readonly isDownloaded?: boolean

  /**
   *
   * @type {string}
   * @memberof DefaultApiPatientsControllerGetAllPatientsWithAccessTo
   */
  readonly search?: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiPatientsControllerGetAllPatientsWithAccessTo
   */
  readonly sortBy?: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiPatientsControllerGetAllPatientsWithAccessTo
   */
  readonly sortDirection?: string
}

/**
 * Request parameters for patientsControllerMatchTransmission operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerMatchTransmissionRequest
 */
export interface DefaultApiPatientsControllerMatchTransmissionRequest {
  /**
   *
   * @type {MatchTransmissionParams}
   * @memberof DefaultApiPatientsControllerMatchTransmission
   */
  readonly matchTransmissionParams: MatchTransmissionParams
}

/**
 * Request parameters for patientsControllerTotalMonitored operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerTotalMonitoredRequest
 */
export interface DefaultApiPatientsControllerTotalMonitoredRequest {
  /**
   *
   * @type {GetTotalPatientsMonitoredParams}
   * @memberof DefaultApiPatientsControllerTotalMonitored
   */
  readonly queryParams?: GetTotalPatientsMonitoredParams
}


/**
 * Request parameters for patientsControllerPatientActiveInactiveStatus operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerPatientActiveInactiveStatusRequest
 */
export interface DefaultApiPatientsControllerPatientActiveInactiveStatusRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiPatientsControllerPatientActiveInactiveStatus
   */
  readonly patientId: string

  /**
   *
   * @type {PatientActiveInactiveStatusParams}
   * @memberof DefaultApiPatientsControllerPatientActiveInactiveStatus
   */
  readonly patientActiveInactiveStatusParams: PatientActiveInactiveStatusParams
}

/**
 * Request parameters for patientsControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerUpdateRequest
 */
export interface DefaultApiPatientsControllerUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiPatientsControllerUpdate
   */
  readonly patientId: string

  /**
   *
   * @type {UpdatePatientParams}
   * @memberof DefaultApiPatientsControllerUpdate
   */
  readonly updatePatientParams: UpdatePatientParams
}

/**
 * Request parameters for patientsControllerUpdatePatientAddress operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerUpdatePatientAddressRequest
 */
export interface DefaultApiPatientsControllerUpdatePatientAddressRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiPatientsControllerUpdatePatientAddress
   */
  readonly patientId: string

  /**
   *
   * @type {UpdatePatientAddressParams}
   * @memberof DefaultApiPatientsControllerUpdatePatientAddress
   */
  readonly updatePatientAddressParams: UpdatePatientAddressParams
}

/**
 * Request parameters for patientsControllerUpdatePatientMatchDevice operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerUpdatePatientMatchDeviceRequest
 */
export interface DefaultApiPatientsControllerUpdatePatientMatchDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiPatientsControllerUpdatePatientMatchDevice
   */
  readonly patientId: string

  /**
   *
   * @type {UpdatePatientMatchDeviceParams}
   * @memberof DefaultApiPatientsControllerUpdatePatientMatchDevice
   */
  readonly updatePatientMatchDeviceParams: UpdatePatientMatchDeviceParams
}
/**
 *
 * @export
 * @interface FindAllNpiResponse
 */
export interface FindAllNpiResponse {
  /**
   *
   * @type {Array<RegisteredNpis>}
   * @memberof FindAllNpiResponse
   */
  'data': Array<RegisteredNpis>;
  /**
   *
   * @type {number}
   * @memberof FindAllNpiResponse
   */
  'total': number;
}

/**
 * Request parameters for registeredNpisControllerGetNpisData operation in DefaultApi.
 * @export
 * @interface DefaultApiRegisteredNpisControllerGetNpisDataRequest
 */
export interface DefaultApiRegisteredNpisControllerGetNpisDataRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiRegisteredNpisControllerGetNpisData
   */
  readonly firstName?: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiRegisteredNpisControllerGetNpisData
   */
  readonly lastName?: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiRegisteredNpisControllerGetNpisData
   */
  readonly state?: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiRegisteredNpisControllerGetNpisData
   */
  readonly city?: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiRegisteredNpisControllerGetNpisData
   */
  readonly limit?: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiRegisteredNpisControllerGetNpisData
   */
  readonly offset?: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiRegisteredNpisControllerGetNpisData
   */
  readonly npi?: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiRegisteredNpisControllerGetNpisData
   */
  readonly taxonomy?: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiRegisteredNpisControllerGetNpisData
   */
  readonly sortBy?: string

  /**
   *
   * @type {'ASC' | 'DESC'}
   * @memberof DefaultApiRegisteredNpisControllerGetNpisData
   */
  readonly sortDirection?: 'ASC' | 'DESC'
}

/**
 * Request parameters for reportTemplateControllerApplyTemplate operation in DefaultApi.
 * @export
 * @interface DefaultApiReportTemplateControllerApplyTemplateRequest
 */
export interface DefaultApiReportTemplateControllerApplyTemplateRequest {
  /**
   *
   * @type {ApplyOrReplaceTemplateRequest}
   * @memberof DefaultApiReportTemplateControllerApplyTemplate
   */
  readonly applyOrReplaceTemplateRequest: ApplyOrReplaceTemplateRequest
}

/**
 * Request parameters for reportTemplateControllerGetReportTemplates operation in DefaultApi.
 * @export
 * @interface DefaultApiReportTemplateControllerGetReportTemplatesRequest
 */
export interface DefaultApiReportTemplateControllerGetReportTemplatesRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiReportTemplateControllerGetReportTemplates
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {string}
   * @memberof DefaultApiReportTemplateControllerGetReportTemplates
   */
  readonly vendor: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiReportTemplateControllerGetReportTemplates
   */
  readonly deviceType?: string
}

/**
* Request parameters for reportTemplateControllerReplaceTemplate operation in DefaultApi.
* @export
* @interface DefaultApiReportTemplateControllerReplaceTemplateRequest
*/
export interface DefaultApiReportTemplateControllerReplaceTemplateRequest {
  /**
   *
   * @type {ApplyOrReplaceTemplateRequest}
   * @memberof DefaultApiReportTemplateControllerReplaceTemplate
   */
  readonly applyOrReplaceTemplateRequest: ApplyOrReplaceTemplateRequest
}

/**
* Request parameters for reportTemplateControllerRemoveTemplate operation in DefaultApi.
* @export
* @interface DefaultApiReportTemplateControllerRemoveTemplateRequest
*/
export interface DefaultApiReportTemplateControllerRemoveTemplateRequest {
  /**
   *
   * @type {RemoveTemplateRequest}
   * @memberof DefaultApiReportTemplateControllerRemoveTemplate
   */
  readonly removeTemplateRequest: RemoveTemplateRequest
}

/**
 * Request parameters for transmissionReportsControllerAddAlertImage operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerAddAlertImageRequest
 */
export interface DefaultApiTransmissionReportsControllerAddAlertImageRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerAddAlertImage
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerAddAlertImage
   */
  readonly alertId: string

  /**
   *
   * @type {TransmissionReportAddAlertImageParams}
   * @memberof DefaultApiTransmissionReportsControllerAddAlertImage
   */
  readonly transmissionReportAddAlertImageParams: TransmissionReportAddAlertImageParams
}

/**
 * Request parameters for transmissionReportsControllerAddInClinicPdfToTempBucket operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerAddInClinicPdfToTempBucketRequest
 */
export interface DefaultApiTransmissionReportsControllerAddInClinicPdfToTempBucketRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerAddInClinicPdfToTempBucket
   */
  readonly patientId: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerAddInClinicPdfToTempBucket
   */
  readonly fileName: string
}

/**
 * Request parameters for transmissionReportsControllerAddRhythmVendorPdfToBucket operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerAddRhythmVendorPdfToBucketRequest
 */
export interface DefaultApiTransmissionReportsControllerAddRhythmVendorPdfToBucketRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerAddRhythmVendorPdfToBucket
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {any}
   * @memberof DefaultApiTransmissionReportsControllerAddRhythmVendorPdfToBucket
   */
  readonly file: any
}

/**
 * Request parameters for transmissionReportsControllerCount operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerCountRequest
 */
export interface DefaultApiTransmissionReportsControllerCountRequest {
  /**
   *
   * @type {TransmissionReportCountParamsDto}
   * @memberof DefaultApiTransmissionReportsControllerCount
   */
  readonly queryParams?: TransmissionReportCountParamsDto
}

/**
 * Request parameters for transmissionReportsControllerCreateAlert operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerCreateAlertRequest
 */
export interface DefaultApiTransmissionReportsControllerCreateAlertRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerCreateAlert
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {TransmissionReportAlertCreateParams}
   * @memberof DefaultApiTransmissionReportsControllerCreateAlert
   */
  readonly transmissionReportAlertCreateParams: TransmissionReportAlertCreateParams
}

/**
 * Request parameters for transmissionReportsControllerCreateCounters operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerCreateCountersRequest
 */
export interface DefaultApiTransmissionReportsControllerCreateCountersRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerCreateCounters
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {TransmissionReportCountersCreateParams}
   * @memberof DefaultApiTransmissionReportsControllerCreateCounters
   */
  readonly transmissionReportCountersCreateParams: TransmissionReportCountersCreateParams
}

/**
 * Request parameters for transmissionReportsControllerCreateBilling operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerCreateBillingRequest
 */
export interface DefaultApiTransmissionReportsControllerCreateBillingRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerCreateBilling
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {TransmissionReportBillingCreateParams}
   * @memberof DefaultApiTransmissionReportsControllerCreateBilling
   */
  readonly transmissionReportBillingCreateParams: TransmissionReportBillingCreateParams
}

/**
 * Request parameters for patientsControllerFindPatientsReportsDeviceType operation in DefaultApi.
 * @export
 * @interface DefaultApiPatientsControllerFindPatientsReportsDeviceTypeRequest
 */
export interface DefaultApiPatientsControllerFindPatientsReportsDeviceTypeRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiPatientsControllerFindPatientsReportsDeviceType
   */
  readonly patientId: string
}

/**
 * Request parameters for transmissionReportsControllerCreateNote operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerCreateNoteRequest
 */
export interface DefaultApiTransmissionReportsControllerCreateNoteRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerDeleteAlert
   */
  readonly alertId?: string

  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerCreateNote
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerCreateNote
   */
  readonly flag: boolean

  /**
   *
   * @type {TransmissionReportNoteCreateParams}
   * @memberof DefaultApiTransmissionReportsControllerCreateNote
   */
  readonly transmissionReportNoteCreateParams: TransmissionReportNoteCreateParams
}

/**
 * Request parameters for transmissionReportsControllerDeleteAlert operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerDeleteAlertRequest
 */
export interface DefaultApiTransmissionReportsControllerDeleteAlertRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerDeleteAlert
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerDeleteAlert
   */
  readonly alertId: string
}

/**
 * Request parameters for transmissionReportsControllerDeleteAlertImage operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerDeleteAlertImageRequest
 */
export interface DefaultApiTransmissionReportsControllerDeleteAlertImageRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerDeleteAlertImage
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerDeleteAlertImage
   */
  readonly alertId: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerDeleteAlertImage
   */
  readonly imageId: string
}

/**
 * Request parameters for transmissionReportsControllerDeleteBilling operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerDeleteBillingRequest
 */
export interface DefaultApiTransmissionReportsControllerDeleteBillingRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerDeleteBilling
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerDeleteBilling
   */
  readonly billingId: string
}

/**
 * Request parameters for transmissionReportsControllerDeleteNote operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerDeleteNoteRequest
 */
export interface DefaultApiTransmissionReportsControllerDeleteNoteRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerDeleteNote
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerDeleteNote
   */
  readonly noteId: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerDeleteNote
   */
  readonly note?: any
}

/**
 * Request parameters for transmissionReportsControllerDismiss operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerDismissRequest
 */
export interface DefaultApiTransmissionReportsControllerDismissRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerDismiss
   */
  readonly transmissionReportId: number
}

/**
 * Request parameters for transmissionReportsControllerDismissAll operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerDismissAllRequest
 */
export interface DefaultApiTransmissionReportsControllerDismissAllRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof DefaultApiTransmissionReportsControllerDismissAll
   */
  readonly tranmissionReportIds: Array<number>
}

/**
 * Request parameters for transmissionReportsControllerEditReport operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerEditReportRequest
 */
export interface DefaultApiTransmissionReportsControllerEditReportRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerEditReport
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {boolean}
   * @memberof DefaultApiTransmissionReportsControllerEditReport
   */
  readonly isEditable: boolean

  /**
   *
   * @type {File}
   * @memberof DefaultApiTransmissionReportsControllerEditReport
   */
  readonly file: File
}

export interface DefaultApiTransmissionReportsControllerUnDismissRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerUnDismissRequest
   */
  readonly transmissionReportId: number
}

/**
 * Request parameters for transmissionReportsControllerFetchIsBillableForReports operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerFetchIsBillableForReportsRequest
 */
export interface DefaultApiTransmissionReportsControllerFetchIsBillableForReportsRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof DefaultApiTransmissionReportsControllerFetchIsBillableForReports
   */
  readonly transmissionReportIds: Array<number>
}

/**
 * Request parameters for transmissionReportsControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerFindOneRequest
 */
export interface DefaultApiTransmissionReportsControllerFindOneRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerFindOne
   */
  readonly transmissionReportId: number
}

/**
 * Request parameters for transmissionReportsControllerList operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerListRequest
 */
export interface DefaultApiTransmissionReportsControllerListRequest {
  /**
   *
   * @type {TransmissionReportListRequestDto}
   * @memberof DefaultApiTransmissionReportsControllerList
   */
  readonly transmissionReportListRequestDto: TransmissionReportListRequestDto
}

/**
 * Request parameters for transmissionReportsControllerSearchUnsignedReports operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerSearchUnsignedReportsRequest
 */
export interface DefaultApiTransmissionReportsControllerSearchUnsignedReportsRequest {
  /**
   *
   * @type {TransmissionReportSearchUnsignedReportsRequestDto}
   * @memberof DefaultApiTransmissionReportsControllerSearchUnsignedReports
   */
  readonly transmissionReportSearchUnsignedReportsRequestDto: TransmissionReportSearchUnsignedReportsRequestDto
}

/**
 * Request parameters for transmissionReportsControllerSign operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerSignRequest
 */
export interface DefaultApiTransmissionReportsControllerSignRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerSign
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {TransmissionReportSignParams}
   * @memberof DefaultApiTransmissionReportsControllerSign
   */
  readonly transmissionReportSignParams: TransmissionReportSignParams
}

/**
 * Request parameters for transmissionReportsControllerSubmitManualAndInclinicReports operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerSubmitManualAndInclinicReportsRequest
 */
export interface DefaultApiTransmissionReportsControllerSubmitManualAndInclinicReportsRequest {
  /**
   *
   * @type {ManualORInclinicTransmissionReportRequestDto}
   * @memberof DefaultApiTransmissionReportsControllerSubmitManualAndInclinicReports
   */
  readonly manualORInclinicTransmissionReportRequestDto: ManualORInclinicTransmissionReportRequestDto
}

/**
 * Request parameters for transmissionReportsControllerTransmissionOverride operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerTransmissionOverrideRequest
 */
export interface DefaultApiTransmissionReportsControllerTransmissionOverrideRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerTransmissionOverride
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {VendorDataOverrides}
   * @memberof DefaultApiTransmissionReportsControllerTransmissionOverride
   */
  readonly vendorDataOverrides: VendorDataOverrides
}

/**
 * Request parameters for transmissionReportsControllerStorePDFDownloadInfo operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerStorePDFDownloadInfoRequest
 */
export interface DefaultApiTransmissionReportsControllerStorePDFDownloadInfoRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerStorePDFDownloadInfo
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerStorePDFDownloadInfo
   */
  readonly downloadDate: string
}

/**
 * Request parameters for transmissionReportsControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerUpdateRequest
 */
export interface DefaultApiTransmissionReportsControllerUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerUpdate
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {UpdateTransmissionReportParams}
   * @memberof DefaultApiTransmissionReportsControllerUpdate
   */
  readonly updateTransmissionReportParams: UpdateTransmissionReportParams
}

/**
 * Request parameters for transmissionReportsControllerUpdateAlert operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerUpdateAlertRequest
 */
export interface DefaultApiTransmissionReportsControllerUpdateAlertRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerUpdateAlert
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerUpdateAlert
   */
  readonly alertId: string

  /**
   *
   * @type {TransmissionReportAlertUpdateParams}
   * @memberof DefaultApiTransmissionReportsControllerUpdateAlert
   */
  readonly transmissionReportAlertUpdateParams: TransmissionReportAlertUpdateParams
}

/**
 * Request parameters for transmissionReportsControllerUpdateCounters operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerUpdateCountersRequest
 */
export interface DefaultApiTransmissionReportsControllerUpdateCountersRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerUpdateCounters
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerUpdateCounters
   */
  readonly countersId: string

  /**
   *
   * @type {TransmissionReportCountersUpdateParams}
   * @memberof DefaultApiTransmissionReportsControllerUpdateCounters
   */
  readonly transmissionReportCountersUpdateParams: TransmissionReportCountersUpdateParams
}

/**
 * Request parameters for transmissionReportsControllerUpdateBilling operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerUpdateBillingRequest
 */
export interface DefaultApiTransmissionReportsControllerUpdateBillingRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerUpdateBilling
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerUpdateBilling
   */
  readonly billingId: string

  /**
   *
   * @type {TransmissionReportBillingUpdateParams}
   * @memberof DefaultApiTransmissionReportsControllerUpdateBilling
   */
  readonly transmissionReportBillingUpdateParams: TransmissionReportBillingUpdateParams
}

/**
 * Request parameters for transmissionReportsControllerUpdateNote operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerUpdateNoteRequest
 */
export interface DefaultApiTransmissionReportsControllerUpdateNoteRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerUpdateNote
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerUpdateNote
   */
  readonly noteId: string

  /**
   *
   * @type {TransmissionReportNoteUpdateParams}
   * @memberof DefaultApiTransmissionReportsControllerUpdateNote
   */
  readonly transmissionReportNoteUpdateParams: TransmissionReportNoteUpdateParams
}

/**
 * Request parameters for transmissionReportsControllerVendorTransmissionReportUrl operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerVendorTransmissionReportUrlRequest
 */
export interface DefaultApiTransmissionReportsControllerVendorTransmissionReportUrlRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerVendorTransmissionReportUrl
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {VendorTransmissionReportUrlParamsDto}
   * @memberof DefaultApiTransmissionReportsControllerVendorTransmissionReportUrl
   */
  readonly queryParams?: VendorTransmissionReportUrlParamsDto
}

/**
 * Request parameters for transmissionReportsControllerVendorTransmissionsReports operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerVendorTransmissionsReportsRequest
 */
export interface DefaultApiTransmissionReportsControllerVendorTransmissionsReportsRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerVendorTransmissionsReports
   */
  readonly transmissionReportId: number
}

/**
 * Request parameters for usersControllerFindOneByEmail operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerFindOneByEmailRequest
 */
export interface DefaultApiUsersControllerFindOneByEmailRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiUsersControllerFindOneByEmail
   */
  readonly email: string
}

export interface DefaultApiTransmissionReportsControllerVendorDbPatientDeviceTypeRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerVendorDbPatientDeviceType
   */
  readonly transmissionReportId: number
}

/**
 * Request parameters for usersControllerCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerCreateRequest
 */
export interface DefaultApiUsersControllerCreateRequest {
  /**
   *
   * @type {CreateUserParams}
   * @memberof DefaultApiUsersControllerCreate
   */
  readonly createUserParams: CreateUserParams
}

/**
 * Request parameters for usersControllerCreateUserAddress operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerCreateUserAddressRequest
 */
export interface DefaultApiUsersControllerCreateUserAddressRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiUsersControllerCreateUserAddress
   */
  readonly userId: string

  /**
   *
   * @type {CreateUserAddressParams}
   * @memberof DefaultApiUsersControllerCreateUserAddress
   */
  readonly createUserAddressParams: CreateUserAddressParams
}

/**
 * Request parameters for usersControllerDeleteAddress operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerDeleteAddressRequest
 */
export interface DefaultApiUsersControllerDeleteAddressRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiUsersControllerDeleteAddress
   */
  readonly userId: string
}

/**
 * Request parameters for usersControllerFindAll operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerFindAllRequest
 */
export interface DefaultApiUsersControllerFindAllRequest {
  /**
   *
   * @type {FindAllUsersQueryParams}
   * @memberof DefaultApiUsersControllerFindAll
   */
  readonly queryParams?: FindAllUsersQueryParams
}

/**
 * Request parameters for usersControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerFindOneRequest
 */
export interface DefaultApiUsersControllerFindOneRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiUsersControllerFindOne
   */
  readonly userId: string
}

/**
 * Request parameters for usersControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerUpdateRequest
 */
export interface DefaultApiUsersControllerUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiUsersControllerUpdate
   */
  readonly userId: string

  /**
   *
   * @type {UpdateUserParams}
   * @memberof DefaultApiUsersControllerUpdate
   */
  readonly updateUserParams: UpdateUserParams
}

/**
 * Request parameters for usersControllerUpdateUserAddress operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerUpdateUserAddressRequest
 */
export interface DefaultApiUsersControllerUpdateUserAddressRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiUsersControllerUpdateUserAddress
   */
  readonly userId: string

  /**
   *
   * @type {UpdateUserAddressParams}
   * @memberof DefaultApiUsersControllerUpdateUserAddress
   */
  readonly updateUserAddressParams: UpdateUserAddressParams
}

/**
 * Request parameters for vtVendorControllerLeadVendorList operation in DefaultApi.
 * @export
 * @interface DefaultApiVtVendorControllerLeadVendorListRequest
 */
export interface DefaultApiVtVendorControllerLeadVendorListRequest {
  /**
   *
   * @type {VtLeadVendorListParams}
   * @memberof DefaultApiVtVendorControllerLeadVendorList
   */
  readonly vtLeadVendorListParams: VtLeadVendorListParams
}

/**
 * Request parameters for vtVendorControllerList operation in DefaultApi.
 * @export
 * @interface DefaultApiVtVendorControllerListRequest
 */
export interface DefaultApiVtVendorControllerListRequest {
  /**
   *
   * @type {VtVendorListParams}
   * @memberof DefaultApiVtVendorControllerList
   */
  readonly vtVendorListParams: VtVendorListParams
}

/**
 * Request parameters for analyticsControllerAccountOverviewReport operation in DefaultApi.
 * @export
 * @interface DefaultApiAnalyticsControllerAccountOverviewReportRequest
 */
export interface DefaultApiAnalyticsControllerAccountOverviewReportRequest {
  /**
   *
   * @type {AccountOverviewQuery}
   * @memberof DefaultApiAnalyticsControllerAccountOverviewReport
   */
  readonly accountOverviewQuery: AccountOverviewQuery
}

/**
* Request parameters for analyticsControllerAlertsReport operation in DefaultApi.
* @export
* @interface DefaultApiAnalyticsControllerAlertsReportRequest
*/
export interface DefaultApiAnalyticsControllerAlertsReportRequest {
  /**
   *
   * @type {AlertsQuery}
   * @memberof DefaultApiAnalyticsControllerAlertsReport
   */
  readonly alertsQuery: AlertsQuery
}

/**
* Request parameters for analyticsControllerBillableReport operation in DefaultApi.
* @export
* @interface DefaultApiAnalyticsControllerBillableReportRequest
*/
export interface DefaultApiAnalyticsControllerBillableReportRequest {
  /**
   *
   * @type {BillableQuery}
   * @memberof DefaultApiAnalyticsControllerBillableReport
   */
  readonly billableQuery: BillableQuery
}

/**
 * Request parameters for analyticsControllerDownloadReport operation in DefaultApi.
 * @export
 * @interface DefaultApiAnalyticsControllerDownloadReportRequest
 */
export interface DefaultApiAnalyticsControllerDownloadReportRequest {
  /**
   *
   * @type {DownloadReportRequest}
   * @memberof DefaultApiAnalyticsControllerDownloadReport
   */
  readonly downloadReportRequest: DownloadReportRequest
}

/**
* Request parameters for analyticsControllerGetPractitioners operation in DefaultApi.
* @export
* @interface DefaultApiAnalyticsControllerGetPractitionersRequest
*/
export interface DefaultApiAnalyticsControllerGetPractitionersRequest {
  /**
   *
   * @type {GetPractitionersQuery}
   * @memberof DefaultApiAnalyticsControllerGetPractitioners
   */
  readonly getPractitionersQuery: GetPractitionersQuery
}

/**
* Request parameters for analyticsControllerSchedulingReport operation in DefaultApi.
* @export
* @interface DefaultApiAnalyticsControllerSchedulingReportRequest
*/
export interface DefaultApiAnalyticsControllerSchedulingReportRequest {
  /**
   *
   * @type {SchedulingQuery}
   * @memberof DefaultApiAnalyticsControllerSchedulingReport
   */
  readonly schedulingQuery: SchedulingQuery
}

/**
* Request parameters for analyticsControllerSignatureStatusReport operation in DefaultApi.
* @export
* @interface DefaultApiAnalyticsControllerSignatureStatusReportRequest
*/
export interface DefaultApiAnalyticsControllerSignatureStatusReportRequest {
  /**
   *
   * @type {SignatureStatusQuery}
   * @memberof DefaultApiAnalyticsControllerSignatureStatusReport
   */
  readonly signatureStatusQuery: SignatureStatusQuery
}

/**
 * Request parameters for batchDownloadControllerCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiBatchDownloadControllerCreateRequest
 */
export interface DefaultApiBatchDownloadControllerCreateRequest {
  /**
   *
   * @type {CreateInitializeBatchJobRequest}
   * @memberof DefaultApiBatchDownloadControllerCreate
   */
  readonly createInitializeBatchJobRequest: CreateInitializeBatchJobRequest
}

/**
 * Request parameters for batchDownloadControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiBatchDownloadControllerFindOneRequest
 */
export interface DefaultApiBatchDownloadControllerFindOneRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiBatchDownloadControllerFindOne
   */
  readonly id: number
}

/**
 * Request parameters for batchDownloadControllerGetPatientTransmissionData operation in DefaultApi.
 * @export
 * @interface DefaultApiBatchDownloadControllerGetPatientTransmissionDataRequest
 */
export interface DefaultApiBatchDownloadControllerGetPatientTransmissionDataRequest {
  /**
   *
   * @type {TransmissionIdsRequest}
   * @memberof DefaultApiBatchDownloadControllerGetPatientTransmissionData
   */
  readonly transmissionIdsRequest: TransmissionIdsRequest
}

/**
 * Request parameters for batchDownloadControllerRemove operation in DefaultApi.
 * @export
 * @interface DefaultApiBatchDownloadControllerRemoveRequest
 */
export interface DefaultApiBatchDownloadControllerRemoveRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiBatchDownloadControllerRemove
   */
  readonly id: number
}

/**
 * Request parameters for batchDownloadControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiBatchDownloadControllerUpdateRequest
 */
export interface DefaultApiBatchDownloadControllerUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiBatchDownloadControllerUpdate
   */
  readonly id: number

  /**
   *
   * @type {UpdateBatchDownloadDto}
   * @memberof DefaultApiBatchDownloadControllerUpdate
   */
  readonly updateBatchDownloadDto: UpdateBatchDownloadDto
}

/**
 * Request parameters for batchDownloadControllerUpdateBatchPDFDownloadInfo operation in DefaultApi.
 * @export
 * @interface DefaultApiBatchDownloadControllerUpdateBatchPDFDownloadInfoRequest
 */
export interface DefaultApiBatchDownloadControllerUpdateBatchPDFDownloadInfoRequest {
  /**
   *
   * @type {BatchPDFDownloadInfoRequest}
   * @memberof DefaultApiBatchDownloadControllerUpdateBatchPDFDownloadInfo
   */
  readonly batchPDFDownloadInfoRequest: BatchPDFDownloadInfoRequest
}

/**
 * Request parameters for settingControllerGetSetting operation in DefaultApi.
 * @export
 * @interface DefaultApiSettingControllerGetSettingRequest
 */
export interface DefaultApiSettingControllerGetSettingRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiSettingControllerGetSetting
   */
  readonly name: string
}

/**
 * Request parameters for clinicalNotesControllerCreateNote operation in DefaultApi.
 * @export
 * @interface DefaultApiClinicalNotesControllerCreateNoteRequest
 */
export interface DefaultApiClinicalNotesControllerCreateNoteRequest {
  /**
   *
   * @type {CreateNoteRequest}
   * @memberof DefaultApiClinicalNotesControllerCreateNote
   */
  readonly createNoteRequest: CreateNoteRequest
}

/**
* Request parameters for clinicalNotesControllerDeleteNote operation in DefaultApi.
* @export
* @interface DefaultApiClinicalNotesControllerDeleteNoteRequest
*/
export interface DefaultApiClinicalNotesControllerDeleteNoteRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiClinicalNotesControllerDeleteNote
   */
  readonly noteId: string
}

/**
 * Request parameters for clinicalNotesControllerGetClinicalNoteById operation in DefaultApi.
 * @export
 * @interface DefaultApiClinicalNotesControllerGetClinicalNoteByIdRequest
 */
export interface DefaultApiClinicalNotesControllerGetClinicalNoteByIdRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiClinicalNotesControllerGetClinicalNoteById
   */
  readonly noteId: string
}

/**
* Request parameters for clinicalNotesControllerGetClinicalNotes operation in DefaultApi.
* @export
* @interface DefaultApiClinicalNotesControllerGetClinicalNotesRequest
*/
export interface DefaultApiClinicalNotesControllerGetClinicalNotesRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiClinicalNotesControllerGetClinicalNotes
   */
  readonly patientId: string

  /**
   *
   * @type {number}
   * @memberof DefaultApiClinicalNotesControllerGetClinicalNotes
   */
  readonly page?: number

  /**
   *
   * @type {number}
   * @memberof DefaultApiClinicalNotesControllerGetClinicalNotes
   */
  readonly limit?: number
}


/**
 * Request parameters for clinicalNotesControllerGetPinnedNotesAndTotalCount operation in DefaultApi.
 * @export
 * @interface DefaultApiClinicalNotesControllerGetPinnedNotesAndTotalCountRequest
 */
export interface DefaultApiClinicalNotesControllerGetPinnedNotesAndTotalCountRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiClinicalNotesControllerGetPinnedNotesAndTotalCount
   */
  readonly patientId: string
}

/**
* Request parameters for clinicalNotesControllerUpdateNote operation in DefaultApi.
* @export
* @interface DefaultApiClinicalNotesControllerUpdateNoteRequest
*/
export interface DefaultApiClinicalNotesControllerUpdateNoteRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiClinicalNotesControllerUpdateNote
   */
  readonly noteId: string

  /**
   *
   * @type {UpdateNoteRequest}
   * @memberof DefaultApiClinicalNotesControllerUpdateNote
   */
  readonly updateNoteRequest: UpdateNoteRequest
}

/**
 * Request parameters for transmissionReportsControllerAddAttachments operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerAddAttachmentsRequest
 */
export interface DefaultApiTransmissionReportsControllerAddAttachmentsRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerAddAttachments
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {any}
   * @memberof DefaultApiTransmissionReportsControllerAddAttachments
   */
  readonly files?: any

  /**
   *
   * @type {Array<string>}
   * @memberof DefaultApiTransmissionReportsControllerAddAttachments
   */
  readonly deletedFiles?: Array<string>
}

/**
 *
 * @export
 * @interface ReportAddDiagnosisCodeDto
 */
export interface ReportAddDiagnosisCodeDto {
  /**
   *
   * @type {LabelAndValue}
   * @memberof ReportAddDiagnosisCodeDto
   */
  'diagnosisCode': LabelAndValue;
}

/**
 *
 * @export
 * @interface LabelAndValue
 */
export interface LabelAndValue {
  /**
   *
   * @type {string}
   * @memberof LabelAndValue
   */
  'label': string;
  /**
   *
   * @type {string}
   * @memberof LabelAndValue
   */
  'value': string;
}

/**
 * Request parameters for transmissionReportsControllerAddDiagnosisCodeToBilling operation in DefaultApi.
 * @export
 * @interface DefaultApiTransmissionReportsControllerAddDiagnosisCodeToBillingRequest
 */
export interface DefaultApiTransmissionReportsControllerAddDiagnosisCodeToBillingRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiTransmissionReportsControllerAddDiagnosisCodeToBilling
   */
  readonly transmissionReportId: number

  /**
   *
   * @type {string}
   * @memberof DefaultApiTransmissionReportsControllerAddDiagnosisCodeToBilling
   */
  readonly billingId: string

  /**
   *
   * @type {ReportAddDiagnosisCodeDto}
   * @memberof DefaultApiTransmissionReportsControllerAddDiagnosisCodeToBilling
   */
  readonly reportAddDiagnosisCodeDto: ReportAddDiagnosisCodeDto
}


/**
 * Request parameters for icdDiagnosisControllerGetIcdCodes operation in DefaultApi.
 * @export
 * @interface DefaultApiIcdDiagnosisControllerGetIcdCodesRequest
 */
export interface DefaultApiIcdDiagnosisControllerGetIcdCodesRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiIcdDiagnosisControllerGetIcdCodes
   */
  readonly query: string
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {number} transmissionReportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerGetInClinicEpisode(transmissionReportId: number, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerGetInClinicEpisode(transmissionReportId, options).then((request) => request(this.axios, this.basePath));
  }
  /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
  public transmissionReportsControllerLeadVendorList(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios,this.configuration).transmissionReportsControllerLeadVendorList(options).then((request) => request(this.axios, this.basePath));
}


  /**
   *
   * @param {number} transmissionReportId
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerUpdateInClinicEpisode(transmissionReportId: number, body: object, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios,this.configuration).transmissionReportsControllerUpdateInClinicEpisode(transmissionReportId, body, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {string} billingId
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public billingControllerUpdateBilling(billingId: string, body: UpdateBillingDto, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).billingControllerUpdateBilling(billingId, body, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} billingId
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public billingControllerUpdateIsBillable(billingId: string, body: object, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).billingControllerUpdateIsBillable(billingId, body, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {DefaultApiTransmissionReportsControllerAddDiagnosisCodeToBillingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerAddDiagnosisCodeToBilling(requestParameters: DefaultApiTransmissionReportsControllerAddDiagnosisCodeToBillingRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerAddDiagnosisCodeToBilling(requestParameters.transmissionReportId, requestParameters.billingId, requestParameters.reportAddDiagnosisCodeDto, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerAddAttachmentsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerAddAttachments(requestParameters: DefaultApiTransmissionReportsControllerAddAttachmentsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerAddAttachments(requestParameters.transmissionReportId, requestParameters.files, requestParameters.deletedFiles, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerGetAttachmentsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerGetAttachments(requestParameters: DefaultApiTransmissionReportsControllerGetAttachmentsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerGetAttachments(requestParameters.transmissionReportId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiFilesControllerGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public filesControllerGet(
    requestParameters: DefaultApiFilesControllerGetRequest,
    options?: AxiosRequestConfig,
  ) {
    console.log(this.axios)
    return DefaultApiFp(this.axios, this.configuration)
      .filesControllerGet(requestParameters.fileId, options)
      .then(request => request(this.axios, this.basePath))
  }

   /**
     *
     * @param {DefaultApiTransmissionReportsControllerSignAllToLambdaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
   public transmissionReportsControllerSignAllToLambda(requestParameters: DefaultApiTransmissionReportsControllerSignAllToLambdaRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerSignAllToLambda(requestParameters.transmissionReportSignAllParams, options).then((request) => request(this.axios, this.basePath));
}

  /**
   *
   * @param {DefaultApiFilesControllerRemoveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public filesControllerRemove(requestParameters: DefaultApiFilesControllerRemoveRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).filesControllerRemove(requestParameters.fileId, requestParameters.path, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiFilesControllerUploadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public filesControllerUpload(
    requestParameters: DefaultApiFilesControllerUploadRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .filesControllerUpload(
        requestParameters.file,
        requestParameters.path,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiGlobalFilterControllerCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public globalFilterControllerCreate(
    requestParameters: DefaultApiGlobalFilterControllerCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .globalFilterControllerCreate(
        requestParameters.createGlobalFilterParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public globalFilterControllerFindOne(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration)
      .globalFilterControllerFindOne(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiScheduledTransmissionControllerUpdateScheduleTransmissionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public scheduledTransmissionControllerUpdateScheduleTransmission(
    requestParameters: DefaultApiScheduledTransmissionControllerUpdateScheduleTransmissionRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .scheduledTransmissionControllerUpdateScheduleTransmission(
        requestParameters.id,
        requestParameters.updateScheduledTransmissionTypeDto,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiGlobalFilterControllerUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public globalFilterControllerUpdate(
    requestParameters: DefaultApiGlobalFilterControllerUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .globalFilterControllerUpdate(
        requestParameters.updateGlobalFilterParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public healthControllerPing(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration)
      .healthControllerPing(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiHistoryControllerFindOneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public historyControllerFindOne(requestParameters: DefaultApiHistoryControllerFindOneRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).historyControllerFindOne(requestParameters.transmissionReportId, requestParameters.patientId, requestParameters.signedDate, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiOrganizationsControllerCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public organizationsControllerCreate(
    requestParameters: DefaultApiOrganizationsControllerCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .organizationsControllerCreate(
        requestParameters.createOrganizationParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiOrganizationsControllerCreatePatientRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public organizationsControllerCreatePatient(
    requestParameters: DefaultApiOrganizationsControllerCreatePatientRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .organizationsControllerCreatePatient(
        requestParameters.organizationId,
        requestParameters.createPatientParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiOrganizationsControllerFindAllRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public organizationsControllerFindAll(
    requestParameters: DefaultApiOrganizationsControllerFindAllRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .organizationsControllerFindAll(requestParameters.queryParams, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiOrganizationsControllerFindAllPatientsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public organizationsControllerFindAllPatients(
    requestParameters: DefaultApiOrganizationsControllerFindAllPatientsRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .organizationsControllerFindAllPatients(
        requestParameters.organizationId,
        requestParameters.queryParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiOrganizationsControllerFindOneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public organizationsControllerFindOne(
    requestParameters: DefaultApiOrganizationsControllerFindOneRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .organizationsControllerFindOne(requestParameters.organizationId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiOrganizationsControllerUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public organizationsControllerUpdate(
    requestParameters: DefaultApiOrganizationsControllerUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .organizationsControllerUpdate(
        requestParameters.organizationId,
        requestParameters.updateOrganizationParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiOrganizationsControllerUpdateVendorIntegrationStatusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public organizationsControllerUpdateVendorIntegrationStatus(
    requestParameters: DefaultApiOrganizationsControllerUpdateVendorIntegrationStatusRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .organizationsControllerUpdateVendorIntegrationStatus(
        requestParameters.organizationId,
        requestParameters.vendorIntegrationId,
        requestParameters.updateVendorIntegrationStatusParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiPatientsControllerCreatePatientAddressRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public patientsControllerCreatePatientAddress(
    requestParameters: DefaultApiPatientsControllerCreatePatientAddressRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .patientsControllerCreatePatientAddress(
        requestParameters.patientId,
        requestParameters.createPatientAddressParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiPatientsControllerCreatePatientMatchDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public patientsControllerCreatePatientMatchDevice(
    requestParameters: DefaultApiPatientsControllerCreatePatientMatchDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .patientsControllerCreatePatientMatchDevice(
        requestParameters.patientId,
        requestParameters.createPatientMatchDeviceParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiPatientsControllerFindAllAuthorizedPractitionersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public patientsControllerFindAllAuthorizedPractitioners(
    requestParameters: DefaultApiPatientsControllerFindAllAuthorizedPractitionersRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .patientsControllerFindAllAuthorizedPractitioners(
        requestParameters.patientId,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiPatientsControllerFindOneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public patientsControllerFindOne(
    requestParameters: DefaultApiPatientsControllerFindOneRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .patientsControllerFindOne(requestParameters.patientId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiPatientsControllerFindOneKareoPatientRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public patientsControllerFindOneKareoPatient(
    requestParameters: DefaultApiPatientsControllerFindOneKareoPatientRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .patientsControllerFindOneKareoPatient(requestParameters.mrn1, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiPatientsControllerGetAllPatientsWithAccessToRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public patientsControllerGetAllPatientsWithAccessTo(requestParameters: DefaultApiPatientsControllerGetAllPatientsWithAccessToRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).patientsControllerGetAllPatientsWithAccessTo(requestParameters.findAllPatientsParams, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiPatientsControllerMatchTransmissionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public patientsControllerMatchTransmission(
    requestParameters: DefaultApiPatientsControllerMatchTransmissionRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .patientsControllerMatchTransmission(
        requestParameters.matchTransmissionParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiPatientsControllerTotalMonitoredRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public patientsControllerTotalMonitored(
    requestParameters: DefaultApiPatientsControllerTotalMonitoredRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .patientsControllerTotalMonitored(requestParameters.queryParams, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiPatientsControllerPatientActiveInactiveStatusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public patientsControllerPatientActiveInactiveStatus(
    requestParameters: DefaultApiPatientsControllerPatientActiveInactiveStatusRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .patientsControllerPatientActiveInactiveStatus(
        requestParameters.patientId,
        requestParameters.patientActiveInactiveStatusParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiPatientsControllerUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public patientsControllerUpdate(
    requestParameters: DefaultApiPatientsControllerUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .patientsControllerUpdate(
        requestParameters.patientId,
        requestParameters.updatePatientParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiPatientsControllerUpdatePatientAddressRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public patientsControllerUpdatePatientAddress(
    requestParameters: DefaultApiPatientsControllerUpdatePatientAddressRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .patientsControllerUpdatePatientAddress(
        requestParameters.patientId,
        requestParameters.updatePatientAddressParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiPatientsControllerUpdatePatientMatchDeviceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public patientsControllerUpdatePatientMatchDevice(
    requestParameters: DefaultApiPatientsControllerUpdatePatientMatchDeviceRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .patientsControllerUpdatePatientMatchDevice(
        requestParameters.patientId,
        requestParameters.updatePatientMatchDeviceParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }
  /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
  public registeredNpisControllerGetAllTaxonomyData(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).registeredNpisControllerGetAllTaxonomyData(options).then((request) => request(this.axios, this.basePath));
}

/**
 *
 * @param {DefaultApiRegisteredNpisControllerGetNpisDataRequest} requestParameters Request parameters.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
 * @memberof DefaultApi
 */
public registeredNpisControllerGetNpisData(requestParameters: DefaultApiRegisteredNpisControllerGetNpisDataRequest = {}, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).registeredNpisControllerGetNpisData(requestParameters.firstName, requestParameters.lastName, requestParameters.state, requestParameters.city, requestParameters.limit, requestParameters.offset, requestParameters.npi, requestParameters.taxonomy, requestParameters.sortBy, requestParameters.sortDirection, options).then((request) => request(this.axios, this.basePath));
}

    /**
       *
       * @param {DefaultApiReportTemplateControllerApplyTemplateRequest} requestParameters Request parameters.
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       * @memberof DefaultApi
       */
    public reportTemplateControllerApplyTemplate(requestParameters: DefaultApiReportTemplateControllerApplyTemplateRequest, options?: AxiosRequestConfig) {
      return DefaultApiFp(this.axios, this.configuration).reportTemplateControllerApplyTemplate(requestParameters.applyOrReplaceTemplateRequest, options).then((request) => request(this.axios, this.basePath));
  }

    /**
     *
     * @param {DefaultApiReportTemplateControllerGetReportTemplatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reportTemplateControllerGetReportTemplates(requestParameters: DefaultApiReportTemplateControllerGetReportTemplatesRequest, options?: AxiosRequestConfig) {
      return DefaultApiFp(this.axios, this.configuration).reportTemplateControllerGetReportTemplates(requestParameters.transmissionReportId, requestParameters.vendor, requestParameters.deviceType, options).then((request) => request(this.axios, this.basePath));
    }

  /**
   *
   * @param {DefaultApiReportTemplateControllerReplaceTemplateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public reportTemplateControllerReplaceTemplate(requestParameters: DefaultApiReportTemplateControllerReplaceTemplateRequest, options?: AxiosRequestConfig) {
      return DefaultApiFp(this.axios, this.configuration).reportTemplateControllerReplaceTemplate(requestParameters.applyOrReplaceTemplateRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiReportTemplateControllerRemoveTemplateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public reportTemplateControllerRemoveTemplate(requestParameters: DefaultApiReportTemplateControllerRemoveTemplateRequest, options?: AxiosRequestConfig) {
      return DefaultApiFp(this.axios, this.configuration).reportTemplateControllerRemoveTemplate(requestParameters.removeTemplateRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerAddAlertImageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerAddAlertImage(
    requestParameters: DefaultApiTransmissionReportsControllerAddAlertImageRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerAddAlertImage(
        requestParameters.transmissionReportId,
        requestParameters.alertId,
        requestParameters.transmissionReportAddAlertImageParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerAddInClinicPdfToTempBucketRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerAddInClinicPdfToTempBucket(requestParameters: DefaultApiTransmissionReportsControllerAddInClinicPdfToTempBucketRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerAddInClinicPdfToTempBucket(requestParameters.patientId, requestParameters.fileName, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerAddRhythmVendorPdfToBucketRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerAddRhythmVendorPdfToBucket(
    requestParameters: DefaultApiTransmissionReportsControllerAddRhythmVendorPdfToBucketRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerAddRhythmVendorPdfToBucket(
        requestParameters.transmissionReportId,
        requestParameters.file,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerCountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerCount(
    requestParameters: DefaultApiTransmissionReportsControllerCountRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerCount(
        requestParameters.queryParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerCreateAlertRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerCreateAlert(
    requestParameters: DefaultApiTransmissionReportsControllerCreateAlertRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerCreateAlert(
        requestParameters.transmissionReportId,
        requestParameters.transmissionReportAlertCreateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerCreateCountersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerCreateCounters(
    requestParameters: DefaultApiTransmissionReportsControllerCreateCountersRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerCreateCounters(
        requestParameters.transmissionReportId,
        requestParameters.transmissionReportCountersCreateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerCreateBillingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerCreateBilling(
    requestParameters: DefaultApiTransmissionReportsControllerCreateBillingRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerCreateBilling(
        requestParameters.transmissionReportId,
        requestParameters.transmissionReportBillingCreateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerCreateNoteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerCreateNote(
    requestParameters: DefaultApiTransmissionReportsControllerCreateNoteRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerCreateNote(
        requestParameters.transmissionReportId,
        requestParameters.flag,
        requestParameters.transmissionReportNoteCreateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerDeleteAlertRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerDeleteAlert(
    requestParameters: DefaultApiTransmissionReportsControllerDeleteAlertRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerDeleteAlert(
        requestParameters.transmissionReportId,
        requestParameters.alertId,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerDeleteAlertImageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerDeleteAlertImage(
    requestParameters: DefaultApiTransmissionReportsControllerDeleteAlertImageRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerDeleteAlertImage(
        requestParameters.transmissionReportId,
        requestParameters.alertId,
        requestParameters.imageId,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerDeleteBillingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerDeleteBilling(
    requestParameters: DefaultApiTransmissionReportsControllerDeleteBillingRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerDeleteBilling(
        requestParameters.transmissionReportId,
        requestParameters.billingId,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerDeleteNoteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerDeleteNote(
    requestParameters: DefaultApiTransmissionReportsControllerDeleteNoteRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerDeleteNote(
        requestParameters.transmissionReportId,
        requestParameters.noteId,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerDismissRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerDismiss(
    requestParameters: DefaultApiTransmissionReportsControllerDismissRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerDismiss(
        requestParameters.transmissionReportId,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerEditReportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerEditReport(
    requestParameters: DefaultApiTransmissionReportsControllerEditReportRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerEditReport(
        requestParameters.transmissionReportId,
        requestParameters.isEditable,
        requestParameters?.file,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerDismissAllRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerDismissAll(requestParameters: DefaultApiTransmissionReportsControllerDismissAllRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerDismissAll(requestParameters.tranmissionReportIds, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} transmissionReportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerGetBilling(transmissionReportId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerGetBilling(transmissionReportId, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiPatientsControllerFindPatientsReportsDeviceTypeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public patientsControllerFindPatientsReportsDeviceType(requestParameters: DefaultApiPatientsControllerFindPatientsReportsDeviceTypeRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).patientsControllerFindPatientsReportsDeviceType(requestParameters.patientId, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerUnDismissRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerUnDismiss(
    requestParameters: DefaultApiTransmissionReportsControllerUnDismissRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerUnDismiss(
        requestParameters.transmissionReportId,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerFetchICDDiagnosisCodes(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerFetchICDDiagnosisCodes(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get ICD Diagnosis Codes
   * @param {DefaultApiIcdDiagnosisControllerGetIcdCodesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public icdDiagnosisControllerGetIcdCodes(requestParameters: DefaultApiIcdDiagnosisControllerGetIcdCodesRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).icdDiagnosisControllerGetIcdCodes(requestParameters.query, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerFetchIsBillableForReportsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerFetchIsBillableForReports(requestParameters: DefaultApiTransmissionReportsControllerFetchIsBillableForReportsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerFetchIsBillableForReports(requestParameters.transmissionReportIds, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerFindOneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerFindOne(
    requestParameters: DefaultApiTransmissionReportsControllerFindOneRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerFindOne(
        requestParameters.transmissionReportId,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerList(
    requestParameters: DefaultApiTransmissionReportsControllerListRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerList(
        requestParameters.transmissionReportListRequestDto,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerSearchUnsignedReportsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerSearchUnsignedReports(requestParameters: DefaultApiTransmissionReportsControllerSearchUnsignedReportsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerSearchUnsignedReports(requestParameters.transmissionReportSearchUnsignedReportsRequestDto, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerSignRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerSign(requestParameters: DefaultApiTransmissionReportsControllerSignRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerSign(requestParameters.transmissionReportId, requestParameters.transmissionReportSignParams, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerSubmitManualAndInclinicReportsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerSubmitManualAndInclinicReports(requestParameters: DefaultApiTransmissionReportsControllerSubmitManualAndInclinicReportsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerSubmitManualAndInclinicReports(requestParameters.manualORInclinicTransmissionReportRequestDto, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerTransmissionOverrideRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerTransmissionOverride(
    requestParameters: DefaultApiTransmissionReportsControllerTransmissionOverrideRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerTransmissionOverride(
        requestParameters.transmissionReportId,
        requestParameters.vendorDataOverrides,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerUpdate(
    requestParameters: DefaultApiTransmissionReportsControllerUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerUpdate(
        requestParameters.transmissionReportId,
        requestParameters.updateTransmissionReportParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerUpdateAlertRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerUpdateAlert(
    requestParameters: DefaultApiTransmissionReportsControllerUpdateAlertRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerUpdateAlert(
        requestParameters.transmissionReportId,
        requestParameters.alertId,
        requestParameters.transmissionReportAlertUpdateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerUpdateCountersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerUpdateCounters(
    requestParameters: DefaultApiTransmissionReportsControllerUpdateCountersRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerUpdateCounters(
        requestParameters.transmissionReportId,
        requestParameters.countersId,
        requestParameters.transmissionReportCountersUpdateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerStorePDFDownloadInfoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerStorePDFDownloadInfo(
    requestParameters: DefaultApiTransmissionReportsControllerStorePDFDownloadInfoRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerStorePDFDownloadInfo(requestParameters.transmissionReportId, requestParameters.downloadDate, options).then((request) => {
      return request(this.axios, this.basePath)
    })
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerUpdateBillingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerUpdateBilling(
    requestParameters: DefaultApiTransmissionReportsControllerUpdateBillingRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerUpdateBilling(
        requestParameters.transmissionReportId,
        requestParameters.billingId,
        requestParameters.transmissionReportBillingUpdateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerUpdateNoteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerUpdateNote(
    requestParameters: DefaultApiTransmissionReportsControllerUpdateNoteRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerUpdateNote(
        requestParameters.transmissionReportId,
        requestParameters.noteId,
        requestParameters.transmissionReportNoteUpdateParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

          /**
         *
         * @param {number} transmissionReportId
         * @param {string} programmedParameterId
         * @param {ProgrammedParametersDto} programmedParametersDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
          public transmissionReportsControllerUpdateProgrammedParameters(transmissionReportId: number, programmedParametersDto: ProgrammedParametersDto, options?: any) {
            return DefaultApiFp(this.axios, this.configuration)
            .transmissionReportsControllerUpdateProgrammedParameters(transmissionReportId, programmedParametersDto, options)
            .then(request => request(this.axios, this.basePath))
        }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerVendorTransmissionReportUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerVendorTransmissionReportUrl(
    requestParameters: DefaultApiTransmissionReportsControllerVendorTransmissionReportUrlRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerVendorTransmissionReportUrl(
        requestParameters.transmissionReportId,
        requestParameters.queryParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerVendorTransmissionsReportsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerVendorTransmissionsReports(
    requestParameters: DefaultApiTransmissionReportsControllerVendorTransmissionsReportsRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerVendorTransmissionsReports(
        requestParameters.transmissionReportId,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiUsersControllerCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerCreate(
    requestParameters: DefaultApiUsersControllerCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .usersControllerCreate(requestParameters.createUserParams, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiUsersControllerCreateUserAddressRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerCreateUserAddress(
    requestParameters: DefaultApiUsersControllerCreateUserAddressRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .usersControllerCreateUserAddress(
        requestParameters.userId,
        requestParameters.createUserAddressParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiTransmissionReportsControllerVendorDbPatientDeviceTypeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerVendorDbPatientDeviceType(
    requestParameters: DefaultApiTransmissionReportsControllerVendorDbPatientDeviceTypeRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .transmissionReportsControllerVendorDbPatientDeviceType(
        requestParameters.transmissionReportId,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiUsersControllerDeleteAddressRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerDeleteAddress(
    requestParameters: DefaultApiUsersControllerDeleteAddressRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .usersControllerDeleteAddress(requestParameters.userId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiUsersControllerFindAllRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerFindAll(
    requestParameters: DefaultApiUsersControllerFindAllRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .usersControllerFindAll(requestParameters.queryParams, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiUsersControllerFindOneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerFindOne(
    requestParameters: DefaultApiUsersControllerFindOneRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .usersControllerFindOne(requestParameters.userId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerFindCurrentUserInfo(options?: any) {
    return DefaultApiFp(this.axios, this.configuration)
      .usersControllerFindCurrentUserInfo(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiUsersControllerUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerUpdate(
    requestParameters: DefaultApiUsersControllerUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .usersControllerUpdate(
        requestParameters.userId,
        requestParameters.updateUserParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiUsersControllerUpdateUserAddressRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersControllerUpdateUserAddress(
    requestParameters: DefaultApiUsersControllerUpdateUserAddressRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .usersControllerUpdateUserAddress(
        requestParameters.userId,
        requestParameters.updateUserAddressParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiVtVendorControllerLeadVendorListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public vtVendorControllerLeadVendorList(
    requestParameters: DefaultApiVtVendorControllerLeadVendorListRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .vtVendorControllerLeadVendorList(
        requestParameters.vtLeadVendorListParams,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiVtVendorControllerListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public vtVendorControllerList(
    requestParameters: DefaultApiVtVendorControllerListRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.axios, this.configuration)
      .vtVendorControllerList(requestParameters.vtVendorListParams, options)
      .then(request => request(this.axios, this.basePath))
  }

    /**
     *
     * @param {DefaultApiAnalyticsControllerAccountOverviewReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public analyticsControllerAccountOverviewReport(requestParameters: DefaultApiAnalyticsControllerAccountOverviewReportRequest, options?: AxiosRequestConfig) {
      return DefaultApiFp(this.axios, this.configuration).analyticsControllerAccountOverviewReport(requestParameters.accountOverviewQuery, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiAnalyticsControllerAlertsReportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public analyticsControllerAlertsReport(requestParameters: DefaultApiAnalyticsControllerAlertsReportRequest, options?: AxiosRequestConfig) {
      return DefaultApiFp(this.axios, this.configuration).analyticsControllerAlertsReport(requestParameters.alertsQuery, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiAnalyticsControllerBillableReportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public analyticsControllerBillableReport(requestParameters: DefaultApiAnalyticsControllerBillableReportRequest, options?: AxiosRequestConfig) {
      return DefaultApiFp(this.axios, this.configuration).analyticsControllerBillableReport(requestParameters.billableQuery, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiAnalyticsControllerDownloadReportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public analyticsControllerDownloadReport(requestParameters: DefaultApiAnalyticsControllerDownloadReportRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).analyticsControllerDownloadReport(requestParameters.downloadReportRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiAnalyticsControllerGetPractitionersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public analyticsControllerGetPractitioners(requestParameters: DefaultApiAnalyticsControllerGetPractitionersRequest, options?: AxiosRequestConfig) {
      return DefaultApiFp(this.axios, this.configuration).analyticsControllerGetPractitioners(requestParameters.getPractitionersQuery, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiAnalyticsControllerSchedulingReportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public analyticsControllerSchedulingReport(requestParameters: DefaultApiAnalyticsControllerSchedulingReportRequest, options?: AxiosRequestConfig) {
      return DefaultApiFp(this.axios, this.configuration).analyticsControllerSchedulingReport(requestParameters.schedulingQuery, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiAnalyticsControllerSignatureStatusReportRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public analyticsControllerSignatureStatusReport(requestParameters: DefaultApiAnalyticsControllerSignatureStatusReportRequest, options?: AxiosRequestConfig) {
      return DefaultApiFp(this.axios, this.configuration).analyticsControllerSignatureStatusReport(requestParameters.signatureStatusQuery, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiBatchDownloadControllerCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public batchDownloadControllerCreate(requestParameters: DefaultApiBatchDownloadControllerCreateRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).batchDownloadControllerCreate(requestParameters.createInitializeBatchJobRequest, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public batchDownloadControllerFindAll(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).batchDownloadControllerFindAll(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiBatchDownloadControllerFindOneRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public batchDownloadControllerFindOne(requestParameters: DefaultApiBatchDownloadControllerFindOneRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).batchDownloadControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiBatchDownloadControllerGetPatientTransmissionDataRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public batchDownloadControllerGetPatientTransmissionData(requestParameters: DefaultApiBatchDownloadControllerGetPatientTransmissionDataRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).batchDownloadControllerGetPatientTransmissionData(requestParameters.transmissionIdsRequest, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiBatchDownloadControllerRemoveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public batchDownloadControllerRemove(requestParameters: DefaultApiBatchDownloadControllerRemoveRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).batchDownloadControllerRemove(requestParameters.id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiBatchDownloadControllerUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public batchDownloadControllerUpdate(requestParameters: DefaultApiBatchDownloadControllerUpdateRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).batchDownloadControllerUpdate(requestParameters.id, requestParameters.updateBatchDownloadDto, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiBatchDownloadControllerUpdateBatchPDFDownloadInfoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public batchDownloadControllerUpdateBatchPDFDownloadInfo(requestParameters: DefaultApiBatchDownloadControllerUpdateBatchPDFDownloadInfoRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).batchDownloadControllerUpdateBatchPDFDownloadInfo(requestParameters.batchPDFDownloadInfoRequest, options).then((request) => request(this.axios, this.basePath))
  }


  /**
   *
   * @param {DefaultApiScheduledTransmissionControllerCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public scheduledTransmissionControllerCreate(requestParameters: DefaultApiScheduledTransmissionControllerCreateRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).scheduledTransmissionControllerCreate(requestParameters.createScheduledTransmissionDto, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiScheduledTransmissionControllerFindUpcomingScheduledTransmissionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public scheduledTransmissionControllerFindUpcomingScheduledTransmission(requestParameters: DefaultApiScheduledTransmissionControllerFindUpcomingScheduledTransmissionRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).scheduledTransmissionControllerFindUpcomingScheduledTransmission(requestParameters.patientId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiScheduledTransmissionControllerGetAllRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public scheduledTransmissionControllerGetAll(requestParameters: DefaultApiScheduledTransmissionControllerGetAllRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).scheduledTransmissionControllerGetAll(requestParameters.deviceId, requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiScheduledTransmissionControllerUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public scheduledTransmissionControllerUpdate(requestParameters: DefaultApiScheduledTransmissionControllerUpdateRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).scheduledTransmissionControllerUpdate(requestParameters.scheduledTransmissionId, requestParameters.updateDateScheduledTransmissionDto, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiScheduledTransmissionControllerResetScheduledTransmissionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public scheduledTransmissionControllerResetScheduledTransmissions(requestParameters: DefaultApiScheduledTransmissionControllerResetScheduledTransmissionsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).scheduledTransmissionControllerResetScheduledTransmissions(requestParameters.deviceId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DefaultApiScheduledTransmissionControllerCreateAdHocRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public scheduledTransmissionControllerCreateAdHoc(requestParameters: DefaultApiScheduledTransmissionControllerCreateAdHocRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).scheduledTransmissionControllerCreateAdHoc(requestParameters.createAdHocTransmissionDto, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public scheduledTransmissionControllerFetchTermDays(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).scheduledTransmissionControllerFetchTermDays(options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
+  * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public transmissionReportsControllerSignAllStatus(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).transmissionReportsControllerSignAllStatus(options).then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {DefaultApiSettingControllerGetSettingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public settingControllerGetSetting(requestParameters: DefaultApiSettingControllerGetSettingRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).settingControllerGetSetting(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {DefaultApiClinicalNotesControllerCreateNoteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public clinicalNotesControllerCreateNote(requestParameters: DefaultApiClinicalNotesControllerCreateNoteRequest, options?: AxiosRequestConfig) {
      return DefaultApiFp(this.axios, this.configuration).clinicalNotesControllerCreateNote(requestParameters.createNoteRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiClinicalNotesControllerDeleteNoteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public clinicalNotesControllerDeleteNote(requestParameters: DefaultApiClinicalNotesControllerDeleteNoteRequest, options?: AxiosRequestConfig) {
      return DefaultApiFp(this.axios, this.configuration).clinicalNotesControllerDeleteNote(requestParameters.noteId, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiClinicalNotesControllerGetClinicalNoteByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public clinicalNotesControllerGetClinicalNoteById(requestParameters: DefaultApiClinicalNotesControllerGetClinicalNoteByIdRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).clinicalNotesControllerGetClinicalNoteById(requestParameters.noteId, options).then((request) => request(this.axios, this.basePath));
  }


  /**
   *
   * @param {DefaultApiClinicalNotesControllerGetClinicalNotesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
   public clinicalNotesControllerGetClinicalNotes(requestParameters: DefaultApiClinicalNotesControllerGetClinicalNotesRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).clinicalNotesControllerGetClinicalNotes(requestParameters.patientId, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiClinicalNotesControllerGetPinnedNotesAndTotalCountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public clinicalNotesControllerGetPinnedNotesAndTotalCount(requestParameters: DefaultApiClinicalNotesControllerGetPinnedNotesAndTotalCountRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.axios, this.configuration).clinicalNotesControllerGetPinnedNotesAndTotalCount(requestParameters.patientId, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiClinicalNotesControllerUpdateNoteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public clinicalNotesControllerUpdateNote(requestParameters: DefaultApiClinicalNotesControllerUpdateNoteRequest, options?: AxiosRequestConfig) {
      return DefaultApiFp(this.axios, this.configuration).clinicalNotesControllerUpdateNote(requestParameters.noteId, requestParameters.updateNoteRequest, options).then((request) => request(this.axios, this.basePath));
  }
          /**
         *
         * @param {CreateFeatureFlagDto} createFeatureFlagDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
          public featureFlagControllerCreateFeatureFlag(createFeatureFlagDto: CreateFeatureFlagDto, options?: any): AxiosPromise<GetFeatureFlagsResponse> {
            return DefaultApiFp(this.axios, this.configuration).featureFlagControllerCreateFeatureFlag(createFeatureFlagDto, options).then((request) => request(this.axios, this.basePath));
        }
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        public featureFlagControllerGetAllFlags(options?: any): AxiosPromise<Array<FlagsMapDTO>> {
            return DefaultApiFp(this.axios, this.configuration).featureFlagControllerGetAllFlags(options).then((request) => request(this.axios, this.basePath));
        }
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        public featureFlagControllerGetAllFlagsAdmin(options?: any): AxiosPromise<GetFeatureFlagsResponse> {
            return DefaultApiFp(this.axios, this.configuration).featureFlagControllerGetAllFlagsAdmin(options).then((request) => request(this.axios, this.basePath));
        }
        /**
         *
         * @param {string} shortCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        public featureFlagControllerGetFlagStatus(shortCode: string, options?: any): AxiosPromise<GetFeatureFlagsResponse> {
            return DefaultApiFp(this.axios, this.configuration).featureFlagControllerGetFlagStatus(shortCode, options).then((request) => request(this.axios, this.basePath));
        }
        /**
         *
         * @param {string} flagId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        public featureFlagControllerRemove(flagId: string, options?: any): AxiosPromise<string> {
            return DefaultApiFp(this.axios, this.configuration).featureFlagControllerRemove(flagId, options).then((request) => request(this.axios, this.basePath));
        }
        /**
         *
         * @param {string} flagId
         * @param {UpdateFeatureFlagDto} updateFeatureFlagDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        public featureFlagControllerUpdate(flagId: string, updateFeatureFlagDto: UpdateFeatureFlagDto, options?: any): AxiosPromise<FeatureFlag> {
            return DefaultApiFp(this.axios, this.configuration).featureFlagControllerUpdate(flagId, updateFeatureFlagDto, options).then((request) => request(this.axios, this.basePath));
        }
        /**
         *
         * @param {UpdateSettingsDTO} updateSettingsDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        public featureFlagControllerUpdateMany(updateSettingsDTO: UpdateSettingsDTO[], options?: any): AxiosPromise<Array<FeatureFlag>> {
          return DefaultApiFp(this.axios, this.configuration).featureFlagControllerUpdateMany(updateSettingsDTO, options).then((request) => request(this.axios, this.basePath));
      }
}



/**
 * Request parameters for scheduledTransmissionControllerCreateAdHoc operation in DefaultApi.
 * @export
 * @interface DefaultApiScheduledTransmissionControllerCreateAdHocRequest
 */
export interface DefaultApiScheduledTransmissionControllerCreateAdHocRequest {
  /**
   *
   * @type {CreateAdHocTransmissionDto}
   * @memberof DefaultApiScheduledTransmissionControllerCreateAdHoc
   */
  readonly createAdHocTransmissionDto: CreateAdHocTransmissionDto
}


/**
 * Request parameters for scheduledTransmissionControllerCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiScheduledTransmissionControllerCreateRequest
 */
export interface DefaultApiScheduledTransmissionControllerCreateRequest {
  /**
   *
   * @type {CreateScheduledTransmissionDto}
   * @memberof DefaultApiScheduledTransmissionControllerCreate
   */
  readonly createScheduledTransmissionDto: CreateScheduledTransmissionDto
}

/**
 * Request parameters for scheduledTransmissionControllerGetAll operation in DefaultApi.
 * @export
 * @interface DefaultApiScheduledTransmissionControllerGetAllRequest
 */
export interface DefaultApiScheduledTransmissionControllerGetAllRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiScheduledTransmissionControllerGetAll
   */
  readonly deviceId: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiScheduledTransmissionControllerGetAll
   */
  readonly fromDate?: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiScheduledTransmissionControllerGetAll
   */
  readonly toDate?: string
}

/**
 * Request parameters for scheduledTransmissionControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiScheduledTransmissionControllerUpdateRequest
 */
export interface DefaultApiScheduledTransmissionControllerUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiScheduledTransmissionControllerUpdate
   */
  readonly scheduledTransmissionId: string

  /**
   *
   * @type {UpdateDateScheduledTransmissionDto}
   * @memberof DefaultApiScheduledTransmissionControllerUpdate
   */
  readonly updateDateScheduledTransmissionDto: UpdateDateScheduledTransmissionDto
}

/**
 * Request parameters for scheduledTransmissionControllerResetScheduledTransmissions operation in DefaultApi.
 * @export
 * @interface DefaultApiScheduledTransmissionControllerResetScheduledTransmissionsRequest
 */
export interface DefaultApiScheduledTransmissionControllerResetScheduledTransmissionsRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiScheduledTransmissionControllerResetScheduledTransmissions
   */
  readonly deviceId: string
}

/**
 *
 * @export
 * @interface NextScheduleTranmission
 */
export interface NextScheduleTranmission {
  /**
   *
   * @type {string}
   * @memberof NextScheduleTranmission
   */
  'deviceId': string;
  /**
   *
   * @type {string}
   * @memberof NextScheduleTranmission
   */
  'deviceType': string;
  /**
   *
   * @type {string}
   * @memberof NextScheduleTranmission
   */
  'LastScheduledDate': string;
  /**
   *
   * @type {string}
   * @memberof NextScheduleTranmission
   */
  'nextScheduledDate': string;
  /**
   *
   * @type {string}
   * @memberof NextScheduleTranmission
   */
  'transmissionType': string;
  /**
   *
   * @type {number}
   * @memberof NextScheduleTranmission
   */
  'scheduleFrequency': number;
}

/**
 *
 * @export
 * @interface NextScheduleTranmissionResponse
 */
export interface NextScheduleTranmissionResponse {
  /**
   *
   * @type {string}
   * @memberof NextScheduleTranmissionResponse
   */
  'patientId': string;
  /**
   *
   * @type {Array<NextScheduleTranmission>}
   * @memberof NextScheduleTranmissionResponse
   */
  'nextScheduleTranmission': Array<NextScheduleTranmission>;

  'scheduleTransmissionReadOnly': boolean;
}

/**
 * Request parameters for scheduledTransmissionControllerUpdateScheduleTransmission operation in DefaultApi.
 * @export
 * @interface DefaultApiScheduledTransmissionControllerUpdateScheduleTransmissionRequest
 */
export interface DefaultApiScheduledTransmissionControllerUpdateScheduleTransmissionRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiScheduledTransmissionControllerUpdateScheduleTransmission
   */
  readonly id: string

  /**
   *
   * @type {UpdateScheduledTransmissionTypeDto}
   * @memberof DefaultApiScheduledTransmissionControllerUpdateScheduleTransmission
   */
  readonly updateScheduledTransmissionTypeDto: UpdateScheduledTransmissionTypeDto
}

/**
 * Request parameters for scheduledTransmissionControllerFindUpcomingScheduledTransmission operation in DefaultApi.
 * @export
 * @interface DefaultApiScheduledTransmissionControllerFindUpcomingScheduledTransmissionRequest
 */
export interface DefaultApiScheduledTransmissionControllerFindUpcomingScheduledTransmissionRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiScheduledTransmissionControllerFindUpcomingScheduledTransmission
   */
  readonly patientId: string
}
