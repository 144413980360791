import React from 'react'

import {
  Box,
  BoxProps,
  Center,
  Icon,
  IconBadge,
  SkeletonSquare,
} from '@rhythm/components'

import { TransmissionReportDtoColorEnum } from '../../../../lib/api'

export interface TransmissionReportsStatusCardProps extends BoxProps {
  color?: string
  size?: string
  showCheck?: boolean
  checkSize?: string | number
  checkBottom?: string | number
}
export const TransmissionReportColorBadge = ({
  color,
  size = 'md',
  showCheck = false,
  checkSize = 'sm',
  checkBottom = '-8px',
  ...restOfBoxProps
}: TransmissionReportsStatusCardProps): React.ReactElement => {
  return (
    <SkeletonSquare isLoaded={color !== undefined} height="56px" width="56px">
      <Box mb="sm" position="relative" {...restOfBoxProps}>
        {color &&
          renderIconBadge(
            color as unknown as TransmissionReportDtoColorEnum,
            size,
          )}
        {showCheck && (
          <Box position="absolute" right="-4px" bottom="-4px">
            <Center>
              <Icon
                icon="check-circle-filled"
                boxSize={checkSize}
                color="neutral.black"
              />
            </Center>
          </Box>
        )}
      </Box>
    </SkeletonSquare>
  )
}

const renderIconBadge = (
  color: TransmissionReportDtoColorEnum,
  size: string,
) => {
  switch (color) {
    case TransmissionReportDtoColorEnum.Red:
      return <IconBadge icon="information" size={size} variant="error" />
    case TransmissionReportDtoColorEnum.Yellow:
      return <IconBadge icon="status-warning" size={size} variant="warning" />
    case TransmissionReportDtoColorEnum.Green:
      return <IconBadge icon="signature-filled" size={size} variant="success" />
    default:
      return <IconBadge icon="file" size={size} />
  }
}
