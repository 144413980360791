import React, { useState } from 'react'

import { Box, InputProps } from '@rhythm/components'
import ReactDatePicker, { DatePickerProps } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import { datePickerStyling } from './datePickerStylings'
import { DatePickerHeader, DatePickerInput } from './DatePickerSubcomponents'

export type SingleDatePickerProps = Extract<
  DatePickerProps,
  {
    selectsRange?: never
    selectsMultiple?: never
    onChange?: (
      date: Date | null,
      event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    ) => void
  }
>

export interface DatePickerV2Props {
  inputProps?: Partial<InputProps>
  toggleCalendarOnIconClick?: boolean
  datePickerProps?: SingleDatePickerProps
}

/**
 * DatePickerv2 is a wrapper around the react-datepicker library that provides a more customizable and accessible date picker component.
 * @param inputProps - Props for the input field of the date picker
 * @param toggleCalendarOnIconClick - If true, the calendar will only open when the icon is clicked. If false, the calendar will open when the input field is clicked.
 * @param datePickerProps - Props for the react-datepicker component
 * @returns A date picker component
 */
const DatePickerv2 = ({
  inputProps = {},
  toggleCalendarOnIconClick = false,
  datePickerProps,
}: DatePickerV2Props): React.ReactElement => {
  const { onSelect, ...restDatePickerProps } = datePickerProps ?? {}
  const [calenderOpen, setCalenderOpen] = useState<boolean>(false)

  return (
    <Box __css={datePickerStyling}>
      <ReactDatePicker
        open={calenderOpen}
        customInput={
          <DatePickerInput
            {...inputProps}
            setCalenderOpen={setCalenderOpen}
            bgColor={'white'}
          />
        }
        onInputClick={() => {
          if (!toggleCalendarOnIconClick) {
            setCalenderOpen(true)
          }
        }}
        onClickOutside={() => setCalenderOpen(false)}
        onSelect={(date, e) => {
          setCalenderOpen(false)
          if (onSelect !== undefined) {
            onSelect(date, e)
          }
        }}
        renderCustomHeader={DatePickerHeader}
        showPopperArrow={false}
        {...restDatePickerProps}
      />
    </Box>
  )
}

export default DatePickerv2
