import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { CircularProgress } from '@chakra-ui/react'
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  HStack,
  IconBadge,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from '@rhythm/components'
import { useMutation } from 'react-query'

import { useApiContext } from '../../../../../context/ApiContext'
import { useFeatureFlagsContext } from '../../../../../context/FeatureFlagsContext'
import { useTransmissionReportContext } from '../../../../../features/transmissionReports'
import {
  DefaultApiPatientsControllerUpdateRequest,
  DefaultApiTransmissionReportsControllerAddDiagnosisCodeToBillingRequest,
  DefaultApiTransmissionReportsControllerUpdateRequest,
  PatientOacEnum,
  queryClient,
  TransmissionReportDto,
  TransmissionReportDtoSignatureStatusEnum,
  UpdatePatientParamsOacEnum,
} from '../../../../../lib/api'
import { PractitionerOption } from '../../TransmissionReportPage'

import DemographicFields, {
  PatientDemographicsFormType,
} from './components/DemographicsFields'

interface DemographicsCardProps {
  isEhrIntegrated: boolean
  transmissionReport?: TransmissionReportDto
  practitionerOptions?: PractitionerOption[]
}

const DemographicsCard: React.FC<DemographicsCardProps> = ({
  isEhrIntegrated,
  transmissionReport,
  practitionerOptions,
}) => {
  const { hasFeatureFlag } = useFeatureFlagsContext()
  const isClinicalNotesEnabled = hasFeatureFlag('clinicalNotes')

  const {
    clinicalNotesSideBarIsOpen,
    setClinicalNotesSideBarIsOpen,
    setIsBillingLoading,
  } = useTransmissionReportContext()

  const isInClinicReport =
    transmissionReport?.transmissionType === 'in-clinic' ||
    transmissionReport?.transmissionType === 'remote-in-clinic'

  const isSigned =
    transmissionReport?.signatureStatus ===
    TransmissionReportDtoSignatureStatusEnum.Signed

  const Api = useApiContext()

  const { mutateAsync: updatePatient, isLoading: isPatientUpdating } =
    useMutation({
      mutationFn: (
        updatePatientParams: DefaultApiPatientsControllerUpdateRequest,
      ) => Api.patientsControllerUpdate(updatePatientParams),
      onSuccess: () => {
        queryClient.invalidateQueries([
          'patient',
          transmissionReport?.patient?.id,
        ])
        queryClient.invalidateQueries([
          'transmissionReport',
          transmissionReport?.id,
        ])
      },
    })
  const { mutateAsync: updateTransmissionReport, isLoading: isReportUpdating } =
    useMutation({
      mutationFn: (
        updateTransmissionReportParams: DefaultApiTransmissionReportsControllerUpdateRequest,
      ) =>
        Api.transmissionReportsControllerUpdate(updateTransmissionReportParams),
      onSuccess: () => {
        queryClient.invalidateQueries([
          'transmissionReport',
          transmissionReport?.id,
        ])
      },
    })

  const {
    mutateAsync: addDiagnosisCodeMutation,
    isLoading: isAddDiagnosisCodeLoading,
  } = useMutation({
    mutationFn: async (
      addDiagnosisCodeParams: DefaultApiTransmissionReportsControllerAddDiagnosisCodeToBillingRequest,
    ) => {
      setIsBillingLoading(true)
      await Api.transmissionReportsControllerAddDiagnosisCodeToBilling(
        addDiagnosisCodeParams,
      )
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(['billing', transmissionReport?.id])
    },
    onSettled: () => {
      setIsBillingLoading(false)
    },
  })

  const handleFormChange = async (
    processedData: Partial<
      Record<keyof PatientDemographicsFormType, string | undefined>
    >,
  ) => {
    if (
      processedData.diagnosisOption &&
      transmissionReport?.billing?.id &&
      transmissionReport?.id
    ) {
      await addDiagnosisCodeMutation({
        billingId: transmissionReport?.billing?.id,
        reportAddDiagnosisCodeDto: {
          diagnosisCode: {
            label: processedData.diagnosisOption,
            value: processedData.diagnosisOption,
          },
        },
        transmissionReportId: transmissionReport?.id,
      })
    }

    if (
      isInClinicReport &&
      !isSigned &&
      (processedData.followUpReason || processedData.otherReason)
    ) {
      await updateTransmissionReport({
        transmissionReportId: transmissionReport?.id,
        updateTransmissionReportParams: {
          followUpReason: processedData.followUpReason,
          otherReason: processedData.otherReason,
        },
      })
    } else if (isSigned) {
      await updateTransmissionReport({
        transmissionReportId: transmissionReport?.id,
        updateTransmissionReportParams: {
          ...processedData,
          oac: processedData.oac as PatientOacEnum,
        },
      })
    } else {
      await updatePatient({
        patientId: transmissionReport?.patient?.id as string,
        updatePatientParams: {
          oac: processedData.oac as UpdatePatientParamsOacEnum,
          monitorReason: processedData.monitorReason,
          diagnosisOption: processedData.diagnosisOption,
          practitionerId: processedData.practitionerId,
          referringProvider: processedData.referringProvider,
        },
      })
    }
  }

  if (!transmissionReport) {
    return (
      <Card w={'full'}>
        <Stack spacing={'2xl'}>
          <HStack align={'center'} spacing={'2xl'}>
            <Skeleton height={'16'} width={'16'} />
            <Box flex={1}>
              <Skeleton height={'8'} />
            </Box>
          </HStack>
          <SimpleGrid columns={3} spacing={'2xl'}>
            {Array.from({ length: 8 }).map((_, index) => (
              <Skeleton key={index} height={'8'} />
            ))}
          </SimpleGrid>
        </Stack>
      </Card>
    )
  }

  return (
    <Card w={'full'}>
      <Stack spacing={'2xl'}>
        <HStack align={'stretch'} spacing={'2xl'}>
          <Flex
            height={'16'}
            width={'16'}
            bg={'neutral.200'}
            borderRadius={8}
            justify={'center'}
            align={'center'}
          >
            {isPatientUpdating ||
            isReportUpdating ||
            isAddDiagnosisCodeLoading ? (
              <CircularProgress
                size={30}
                style={{ color: 'primary.400' }}
                isIndeterminate
              />
            ) : (
              <IconBadge icon="account" size="lg" />
            )}
          </Flex>

          <Stack justify={'center'}>
            <Text
              fontSize="2xl"
              fontWeight={600}
              color={'gray.900'}
              lineHeight={6}
            >
              {`${transmissionReport.patient?.givenName} ${transmissionReport.patient?.familyName}`.trim()}
            </Text>
            <Text color={'gray.500'} fontSize={'md'} fontWeight={600}>
              {transmissionReport?.patient?.clinic?.name}
            </Text>
          </Stack>
          <HStack spacing={'xl'} align={'flex-start'}>
            <RouterLink
              to={`/physician/patient/profile/${transmissionReport.patient?.id}`}
            >
              <Button
                variant={'plain'}
                size={'sm'}
                rightIcon={'arrow-right'}
                color={'primary.600'}
                _hover={{ color: 'primary.400', bg: 'neutral.200' }}
              >
                View Profile
              </Button>
            </RouterLink>
            {isClinicalNotesEnabled && (
              <>
                <Divider orientation={'vertical'} h={'8'} />
                <Button
                  variant={'plain'}
                  size={'sm'}
                  rightIcon={'arrow-right'}
                  color={'primary.600'}
                  onClick={() =>
                    setClinicalNotesSideBarIsOpen(!clinicalNotesSideBarIsOpen)
                  }
                  _hover={{ color: 'primary.400', bg: 'neutral.200' }}
                >
                  {clinicalNotesSideBarIsOpen ? 'Hide' : 'View'} Notes
                </Button>
              </>
            )}
          </HStack>
        </HStack>
        <DemographicFields
          report={transmissionReport}
          isILRDevice={transmissionReport?.device?.deviceType === 'ILR'}
          practitionerOptions={practitionerOptions}
          isInClinicReport={isInClinicReport}
          onSubmit={handleFormChange}
          isEHRIntegrated={isEhrIntegrated}
        />
      </Stack>
    </Card>
  )
}

export default DemographicsCard
