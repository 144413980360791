import { RegisteredNpis, TransmissionReportDto } from '../../../../../lib/api'
import {
  FOLLOW_UP_OPTIONS,
  OAC_OPTIONS,
  REASONS_FOR_MONITORING_OPTIONS,
} from '../../../../../utils/constants/transmissionReports'

import { PatientDemographicsFormType } from './components/DemographicsFields'

export const getReferringProviderName = ({
  referringProvider,
  isEHRIntegrated,
  referringPhysician,
}: {
  referringProvider?: RegisteredNpis | null
  isEHRIntegrated?: boolean
  referringPhysician?: string | null
}): string => {
  if (referringProvider) {
    const { firstName = '', lastName = '', NPI = '' } = referringProvider
    const npiDetails = !isEHRIntegrated ? `(${NPI})` : ''
    return `${firstName} ${lastName} ${npiDetails}`.trim()
  }
  return referringPhysician ?? ''
}

export function generateDefaultDemographicFormValues({
  report,
  isEHRIntegrated,
}: {
  report: TransmissionReportDto
  isEHRIntegrated: boolean
}): PatientDemographicsFormType {
  const { icdDiagnosis, followUpReason, otherReason, patient } = report

  if (!patient) {
    return {} as PatientDemographicsFormType
  }

  const {
    oac,
    monitorReason,
    practitioner,
    referringProvider,
    referringPhysician,
  } = patient

  const defaultReferringProvider = getReferringProviderName({
    referringProvider,
    referringPhysician,
    isEHRIntegrated,
  })

  return {
    diagnosisOption: icdDiagnosis
      ? {
          label: `${icdDiagnosis.code} - ${icdDiagnosis.description}`,
          value: icdDiagnosis.code,
        }
      : null,
    oac: OAC_OPTIONS.find(option => option.value === oac) ?? null,
    monitorReason:
      REASONS_FOR_MONITORING_OPTIONS.find(opt => opt.value === monitorReason) ??
      null,
    practitionerId: practitioner
      ? {
          label: `${practitioner?.prefix?.length ? practitioner.prefix : ''} ${
            practitioner.given
          } ${practitioner.family}`.trim(),
          value: practitioner.id,
        }
      : null,
    followUpReason:
      FOLLOW_UP_OPTIONS.find(opt => opt.value === followUpReason) ?? null,
    otherReason: otherReason ?? undefined,
    referringProvider: {
      label: defaultReferringProvider,
      value: defaultReferringProvider !== '' ? defaultReferringProvider : '-',
    },
  }
}

// Extract only dirty fields for submission

/**
 * A generic function that extracts only the "dirty" fields from a form's data.
 *
 * @template T The shape of your form's data, e.g., PatientDemographicsFormType
 * @param formValues The current form data object.
 * @param dirtyFields A record of which fields are dirty (typically from React Hook Form).
 * @returns An object containing only the dirty fields with their final values.
 */
export function getDirtyFields<T extends Record<string, any>>(
  formValues: T,
  dirtyFields: Record<string, any>,
): Partial<Record<keyof T, any>> {
  return Object.keys(dirtyFields).reduce(
    (acc, key) => {
      const typedKey = key as keyof T
      const value = formValues[typedKey]

      // If it's an object with a "value" property (e.g., react-select),
      // use that property's value. Otherwise, return the entire field's value.
      if (value && typeof value === 'object' && 'value' in value) {
        acc[typedKey] = value.value
      } else {
        acc[typedKey] = value?.trim() ?? value
      }

      return acc
    },
    {} as Partial<Record<keyof T, unknown>>,
  )
}
