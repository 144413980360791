import React, { useEffect, useMemo, useState } from 'react'

import { Divider, Flex, Heading, VStack } from '@rhythm/components'

import {
  TransmissionAlertsCards,
  useTransmissionReportContext,
} from '../../../../../features/transmissionReports'
import { ClinicianInterpretationCards } from '../../../../../features/transmissionReports/components/TransmissionReportClinicalInterpretation'
import ProgrammingChanges from '../../../../../features/transmissionReports/components/TransmissionReportProgrammingChanges/ProgrammingChanges'
import {
  LeadVendor,
  TransmissionReportDto,
  VtVendor,
} from '../../../../../lib/api'
import { REASONS_FOR_MONITORING_OPTIONS } from '../../../../../utils/constants/transmissionReports'
import { PractitionerOption } from '../../TransmissionReportPage'
import BillingCard from '../BillingCard'
import CountersValuesCard from '../CountersValuesCard'
import DeviceBatteryCard from '../DeviceBatteryCard'
import DeviceDemographicsCard from '../DeviceDemographicsCard'
import DeviceMonitoredValuesCard from '../DeviceMonitoredValuesCard'
import DeviceProgrammedParamsAndMonitoredValuesCard from '../DeviceProgrammedParametersMonitoredValuesCard/DeviceProgrammedParametersMonitoredValuesCard'
import InClinicEpisodesCard from '../InClinicEpisodesCard/InClinicEpisodesCard'
import DemographicsCard from '../PatientDemographicsCard'
import SignOffCard from '../SignOffCard'
import TransmissionNotesCard from '../TransmissionNotesCard'
import TransmissionSummaryCard from '../TransmissionSummaryCard'

const TransmissionReportDetails = ({
  vendors,
  leadVendors,
  trReport,
  practitionerOptions,
}: {
  vendors?: VtVendor[]
  leadVendors?: LeadVendor[]
  trReport?: TransmissionReportDto
  practitionerOptions?: PractitionerOption[]
}): React.ReactElement => {
  const { transmissionReport, setCurrentTransmission } =
    useTransmissionReportContext()
  const [callDelete, setCallDelete] = useState(false)

  useEffect(() => {
    if (trReport) {
      setCurrentTransmission(trReport)
    }
  }, [trReport, setCurrentTransmission])

  const isRemote = transmissionReport?.transmissionType === 'remote'
  const isILRDevice = transmissionReport?.device?.deviceType === 'ILR'
  const isInClinic =
    transmissionReport?.transmissionType === 'in-clinic' ||
    transmissionReport?.transmissionType === 'remote-in-clinic'

  const ehrIntegration = transmissionReport?.account?.ehrIntegration ?? false

  // If the report is inClinic then check the inClinicReportWorkflow and if isn't then check
  // the sentToEhr value to determine if the report is second sign.
  const isSecondSign = useMemo(
    () =>
      (transmissionReport?.account?.sentToEhr === 'secondSign' &&
        !isInClinic) ||
      (transmissionReport?.account?.inClinicReportWorkflow === 'secondSign' &&
        isInClinic),
    [
      isInClinic,
      transmissionReport?.account?.inClinicReportWorkflow,
      transmissionReport?.account?.sentToEhr,
    ],
  )

  const handleNoteDelete = (noteId: string) => setCallDelete(prev => !prev)

  useEffect(() => {
    if (transmissionReport?.patient) {
      const monitorReasonExists = REASONS_FOR_MONITORING_OPTIONS.some(
        e => e.value === transmissionReport?.patient?.monitorReason,
      )
      if (!monitorReasonExists) {
        transmissionReport.patient.monitorReason = ''
      }
    }
  }, [transmissionReport?.patient])

  return (
    <>
      <TransmissionSummaryCard transmissionReport={transmissionReport} />

      <Flex flexDirection="column" mt="5xl" mb="3xl">
        <Heading variant="h6" mb="xl">
          Background
        </Heading>
        <VStack direction={{ base: 'column', md: 'row' }} spacing="xl">
          <DemographicsCard
            isEhrIntegrated={ehrIntegration}
            practitionerOptions={practitionerOptions}
            transmissionReport={transmissionReport}
          />
          <DeviceDemographicsCard
            key={`device-demographics-${transmissionReport?.id}`}
            transmissionReport={transmissionReport}
            vendors={vendors}
            leadVendors={leadVendors}
          />
        </VStack>
      </Flex>
      <Divider />
      <VStack mb={isRemote ? '5xl' : ''} spacing="xl" mt="3xl">
        <TransmissionAlertsCards callDelete={callDelete} />
      </VStack>
      {isRemote && (
        <VStack hidden={isILRDevice}>
          <DeviceMonitoredValuesCard />
        </VStack>
      )}
      {isInClinic && (
        <VStack hidden={isILRDevice} mt={'3xl'}>
          <DeviceProgrammedParamsAndMonitoredValuesCard />
        </VStack>
      )}
      {(isRemote || isInClinic) && (
        <VStack hidden={!isILRDevice} mt={'3xl'}>
          <CountersValuesCard />
        </VStack>
      )}
      {(isRemote || isInClinic) && (
        <VStack mb={isILRDevice ? '1xl' : '5xl'} spacing="xl" mt="3xl">
          <DeviceBatteryCard isInClinic={isInClinic} />
        </VStack>
      )}
      {isInClinic && transmissionReport.id && (
        <VStack spacing="xl" mt="3xl">
          <InClinicEpisodesCard reportId={transmissionReport.id} />
        </VStack>
      )}

      <VStack spacing="xl" mt="3xl">
        <TransmissionNotesCard onDeleteNote={handleNoteDelete} />
        {isInClinic && transmissionReport && (
          <ProgrammingChanges transmissionReport={transmissionReport} />
        )}
        {isSecondSign && (
          <ClinicianInterpretationCards
            transmissionReport={transmissionReport}
          />
        )}
      </VStack>

      <VStack spacing="xl" mt={isRemote || isInClinic ? '3xl' : 'xl'}>
        {(isRemote || isInClinic) && <BillingCard />}
      </VStack>
      <VStack mb="3xl" spacing="xl" mt={'3xl'}>
        <SignOffCard transmissionReport={transmissionReport} />
      </VStack>
    </>
  )
}

export default TransmissionReportDetails
