import React from 'react'

import { IconButton } from '@chakra-ui/react'
import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@rhythm/components'
import { DatePickerProps } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

export const DateRangePickerHeader: DatePickerProps['renderCustomHeader'] = ({
  decreaseMonth,
  increaseMonth,
  customHeaderCount,
  monthDate,
}: {
  decreaseMonth: () => void
  increaseMonth: () => void
  customHeaderCount: number
  monthDate: Date
}) => {
  return (
    <Box
      mb={5}
      __css={{
        '.react-datepicker__navigation': {
          top: '-5px',
        },
      }}
    >
      <button
        aria-label="Previous Month"
        className={
          'react-datepicker__navigation react-datepicker__navigation--previous'
        }
        style={customHeaderCount === 1 ? { visibility: 'hidden' } : {}}
        onClick={decreaseMonth}
      >
        <span
          className={
            'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
          }
        >
          {'<'}
        </span>
      </button>
      <span className="react-datepicker__current-month">
        {monthDate.toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        })}
      </span>
      <button
        aria-label="Next Month"
        className={
          'react-datepicker__navigation react-datepicker__navigation--next'
        }
        style={customHeaderCount === 0 ? { visibility: 'hidden' } : {}}
        onClick={increaseMonth}
      >
        <span
          className={
            'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'
          }
        >
          {'>'}
        </span>
      </button>
    </Box>
  )
}

export interface DateRangePickerInputProps extends InputProps {
  toggleCalendarOnIconClick?: boolean
  setCalenderOpen?: (v: boolean) => void
}

export const DateRangePickerInput = React.forwardRef<
  HTMLInputElement,
  DateRangePickerInputProps
>((props, ref) => {
  const { toggleCalendarOnIconClick, setCalenderOpen, ...restProps } = props
  return (
    <InputGroup>
      <Input ref={ref} {...restProps} bgColor={'white'} />
      {
        <InputRightElement zIndex={0}>
          <IconButton
            variant="ghost"
            size={'sm'}
            aria-label="Open Calender"
            icon={<Icon icon="calendar" color="neutral.800" boxSize="sm" />}
            onClick={() => {
              if (setCalenderOpen) {
                toggleCalendarOnIconClick !== undefined
                  ? setCalenderOpen(toggleCalendarOnIconClick)
                  : setCalenderOpen(true)
              }
            }}
          />
        </InputRightElement>
      }
    </InputGroup>
  )
})

DateRangePickerInput.displayName = 'DatePickerInput'

export const quickNavigationOptions = [
  'Today',
  'Yesterday',
  'Last 7 Days',
  'Last 30 Days',
  'This Month',
  'Last Month',
  'This Year',
  'Last Year',
]

export const getDateRange = (title: string) => {
  const now = new Date()
  let startDate
  let endDate = new Date()

  switch (title) {
    case 'Today':
      startDate = new Date(now.setHours(0, 0, 0, 0))
      endDate = new Date(now.setHours(23, 59, 59, 999))
      break
    case 'Yesterday':
      startDate = new Date(now.setDate(now.getDate() - 1))
      startDate.setHours(0, 0, 0, 0)
      endDate = new Date(now.setDate(now.getDate()))
      endDate.setHours(23, 59, 59, 999)
      break
    case 'Last 7 Days':
      startDate = new Date(now.setDate(now.getDate() - 7))
      startDate.setHours(0, 0, 0, 0)
      break
    case 'Last 30 Days':
      startDate = new Date(now.setDate(now.getDate() - 30))
      startDate.setHours(0, 0, 0, 0)
      break
    case 'This Month':
      startDate = new Date(now.getFullYear(), now.getMonth(), 1)
      break
    case 'Last Month':
      startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1)
      endDate = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999)
      break
    case 'This Year':
      startDate = new Date(now.getFullYear(), 0, 1)
      break
    case 'Last Year':
      startDate = new Date(now.getFullYear() - 1, 0, 1)
      endDate = new Date(now.getFullYear(), 0, 0, 23, 59, 59, 999)
      break
    default:
      startDate = new Date()
      endDate = new Date()
  }
  return [startDate, endDate]
}
