import { useQuery } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { DefaultApiPatientsControllerFindOneRequest } from '../../../lib/api/generated-client'

export const useGetPatient = ({
  patientId,
}: DefaultApiPatientsControllerFindOneRequest) => {
  const Api = useApiContext()
  return useQuery({
    queryKey: ['patient', patientId],
    queryFn: async () => {
      const {
        data: { patient },
      } = await Api.patientsControllerFindOne({ patientId })
      return patient
    },
  })
}
