import React, { useEffect, useState } from 'react'

import { InputGroup, InputRightElement } from '@chakra-ui/react'
import {
  Box,
  Error,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
} from '@rhythm/components'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'

import {
  LabelAndValue,
  RegisteredNpis,
  TransmissionReportDto,
} from '../../../../../../lib/api'
import {
  FOLLOW_UP_OPTIONS,
  OAC_OPTIONS,
  REASONS_FOR_MONITORING_OPTIONS,
} from '../../../../../../utils/constants/transmissionReports'
import formatDate from '../../../../../../utils/formatDate'
import ReferringPhysicianModal from '../../../../RegisteredNpi/ReferringPhysician'
import { PractitionerOption } from '../../../TransmissionReportPage'
import {
  generateDefaultDemographicFormValues,
  getDirtyFields,
  getReferringProviderName,
} from '../helper'

import DiagnosisSelect from './DiagnosisSelect'

export type PatientDemographicsFormType = {
  diagnosisOption: LabelAndValue | null
  oac: LabelAndValue | null
  monitorReason: LabelAndValue | null
  practitionerId: LabelAndValue | null
  followUpReason: LabelAndValue | null
  otherReason?: string
  referringProvider: LabelAndValue | null
}

const DemographicFields = ({
  report,
  isEHRIntegrated,
  isInClinicReport,
  isILRDevice,
  practitionerOptions,
  onSubmit,
}: {
  report: TransmissionReportDto
  practitionerOptions?: PractitionerOption[]
  isInClinicReport: boolean
  isILRDevice: boolean
  isEHRIntegrated: boolean
  onSubmit: (
    data: Partial<
      Record<keyof PatientDemographicsFormType, string | undefined>
    >,
  ) => void
}) => {
  const [editMedicalDetailModalIsOpen, setEditMedicalDetailModalIsOpen] =
    useState(false)

  const formMethods = useForm<PatientDemographicsFormType>({
    mode: 'onBlur',
    defaultValues: generateDefaultDemographicFormValues({
      report,
      isEHRIntegrated,
    }),
  })

  const { control, formState, reset, handleSubmit, setValue } = formMethods
  const { dirtyFields } = formState

  const formValues = useWatch({ control })

  // Trigger submission on form changes
  useEffect(() => {
    const debounce = setTimeout(() => {
      const dirtyData = getDirtyFields(formValues, dirtyFields)
      const changedFields = Object.keys(dirtyData)
      if (changedFields.length === 0) return

      handleSubmit(() => onSubmit(dirtyData))()

      // Reset the dirty fields so we don't keep resubmitting.
      reset(formValues, { keepDirty: false })

      // Set the other reason to empty if the followUpReason is changed to something else.
      if (
        'followUpReason' in dirtyData &&
        dirtyData.followUpReason !== 'other' &&
        formValues.otherReason != ''
      ) {
        setValue('otherReason', '')
      }
    }, 500)

    return () => clearTimeout(debounce)
  }, [formValues, handleSubmit, onSubmit, reset, setValue])

  useEffect(() => {
    reset(generateDefaultDemographicFormValues({ report, isEHRIntegrated }))
  }, [report.id])

  const handleReferringPhysicianSubmit = (data: RegisteredNpis) => {
    formMethods.setValue(
      'referringProvider',
      {
        label: getReferringProviderName({
          referringProvider: data,
          isEHRIntegrated: isEHRIntegrated,
        }),
        value: data.NPI,
      },
      { shouldDirty: true },
    )
    setEditMedicalDetailModalIsOpen(false)
  }

  const handleClearReferringPhysician = () => {
    formMethods.setValue(
      'referringProvider',
      {
        label: '',
        value: '-',
      },
      { shouldDirty: true },
    )
  }

  const practitionerErrorMessage =
    !!report?.account?.isFollowingPractitionerReq && // must require a practitioner
    (practitionerOptions?.length ?? 0) > 0 && // must have available practitioners
    !report?.patient?.practitioner && // must not already have one assigned
    !formValues.practitionerId && // must have attempted to pick in the form
    'Please select a Following Practitioner'

  return (
    <FormProvider {...formMethods}>
      <form>
        <SimpleGrid
          columns={{ sm: 2, lg: 3 }}
          spacing={'2xl'}
          alignItems={'stretch'}
        >
          <Stack spacing={'sm'} height={'100%'}>
            <Text
              fontSize={'sm'}
              color={'gray.500'}
              fontWeight={600}
              textTransform={'uppercase'}
            >
              Mrn
            </Text>
            <Input
              name={'mrn2'}
              value={report.patient?.mrn2}
              color={'gray.500'}
              _hover={{}}
              _focus={{}}
              background={'neutral.200'}
              cursor={'text'}
              style={{ caretColor: 'transparent' }}
              height={'inherit'}
            />
          </Stack>
          <Stack spacing={'sm'} height={'100%'}>
            <Text
              fontSize={'sm'}
              color={'gray.500'}
              fontWeight={600}
              textTransform={'uppercase'}
            >
              DOB
            </Text>
            <Input
              name={'dob'}
              value={formatDate(report.patient?.birthDate) ?? ''}
              color={'gray.500'}
              _hover={{}}
              _focus={{}}
              userSelect={'auto'}
              background={'neutral.200'}
              style={{ caretColor: 'transparent' }}
              cursor={'text'}
              height={'inherit'}
            />
          </Stack>
          <Stack spacing={'sm'}>
            <Text
              fontSize={'sm'}
              color={'gray.500'}
              fontWeight={600}
              textTransform={'uppercase'}
            >
              Diagnosis
            </Text>
            <Controller
              name="diagnosisOption"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DiagnosisSelect value={value} handleChange={onChange} />
              )}
            />
          </Stack>
          <Stack spacing={'sm'}>
            <Text
              fontSize={'sm'}
              color={'gray.500'}
              fontWeight={600}
              textTransform={'uppercase'}
            >
              OAC
            </Text>
            <Controller
              name="oac"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  ref={ref}
                  value={value}
                  options={OAC_OPTIONS}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
          <Stack spacing={'sm'}>
            <Text
              fontSize={'sm'}
              color={'gray.500'}
              fontWeight={600}
              textTransform={'uppercase'}
            >
              Referring Provider
            </Text>
            <HStack align={'center'}>
              <InputGroup position={'relative'} py={'1px'}>
                {!formValues?.referringProvider?.label && (
                  <Box
                    color={'gray.400'}
                    position={'absolute'}
                    top={1}
                    bottom={1}
                    height={'fit-content'}
                    margin={'auto'}
                    pl={'2px'}
                    zIndex={0}
                    left={1}
                  >
                    <Icon icon={'search'} boxSize={'5'} />
                  </Box>
                )}
                <Input
                  py={'6px'}
                  {...(!formValues?.referringProvider?.label && {
                    onClick: () => setEditMedicalDetailModalIsOpen(true),
                    cursor: 'pointer',
                  })}
                  value={formValues?.referringProvider?.label?.toLowerCase()}
                  placeholder={'Search for provider'}
                  textTransform={'capitalize'}
                  pl={!formValues?.referringProvider?.label ? '28px' : '8px'}
                  isReadOnly
                />
                {formValues?.referringProvider?.label && (
                  <InputRightElement>
                    <IconButton
                      size={'sm'}
                      top={0.5}
                      bottom={0.5}
                      margin={'auto'}
                      icon={'close'}
                      onClick={handleClearReferringPhysician}
                      borderRadius={'full'}
                      aria-label={'edit'}
                      variant={'ghost'}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
              {formValues?.referringProvider?.label && (
                <IconButton
                  size={'sm'}
                  icon={'edit'}
                  ml={'sm'}
                  onClick={() => setEditMedicalDetailModalIsOpen(true)}
                  borderRadius={'full'}
                  aria-label={'edit'}
                  variant={'ghost'}
                />
              )}
            </HStack>
          </Stack>

          <Stack spacing={'sm'}>
            <Text
              fontSize={'sm'}
              color={'gray.500'}
              fontWeight={600}
              textTransform={'uppercase'}
            >
              Following Practitioner
            </Text>
            <Controller
              name="practitionerId"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  ref={ref}
                  value={value}
                  options={practitionerOptions}
                  onChange={onChange}
                />
              )}
            />
            {practitionerErrorMessage && (
              <Error message={practitionerErrorMessage} />
            )}
          </Stack>
          {isILRDevice && (
            <Stack spacing={'sm'}>
              <Text
                fontSize={'sm'}
                color={'gray.500'}
                fontWeight={600}
                textTransform={'uppercase'}
              >
                Reason For Monitoring
              </Text>
              <Controller
                name="monitorReason"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    ref={ref}
                    value={value}
                    options={REASONS_FOR_MONITORING_OPTIONS}
                    onChange={onChange}
                  />
                )}
              />
            </Stack>
          )}
          {isInClinicReport && (
            <>
              <Stack spacing={'sm'}>
                <Text
                  fontSize={'sm'}
                  color={'gray.500'}
                  fontWeight={600}
                  textTransform={'uppercase'}
                >
                  Reason For Follow Up
                </Text>
                <Controller
                  name="followUpReason"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      ref={ref}
                      value={value}
                      options={FOLLOW_UP_OPTIONS}
                      onChange={onChange}
                    />
                  )}
                />
              </Stack>
              {formValues?.followUpReason?.value === 'other' && (
                <Stack spacing={'sm'}>
                  <Text
                    fontSize={'sm'}
                    color={'gray.500'}
                    fontWeight={600}
                    textTransform={'uppercase'}
                  >
                    Other Reason
                  </Text>
                  <Controller
                    name="otherReason"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <Input
                        value={value}
                        onChange={onChange}
                        ref={ref}
                        placeholder="Enter other reason"
                      />
                    )}
                  />
                </Stack>
              )}
            </>
          )}
        </SimpleGrid>
        <ReferringPhysicianModal
          isOpen={editMedicalDetailModalIsOpen}
          onClose={() => setEditMedicalDetailModalIsOpen(false)}
          onSubmit={handleReferringPhysicianSubmit}
        />
      </form>
    </FormProvider>
  )
}

export default DemographicFields
