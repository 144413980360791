import { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Stack,
  Text,
  useToast,
  VStack,
} from '@rhythm/components'
import { AxiosResponse } from 'axios'
import { FileRejection, useDropzone } from 'react-dropzone'
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID'

import { useApiContext } from '../../../../../context/ApiContext'
import { queryClient } from '../../../../../lib/api'
import { Patient } from '../../../../../types'

// Define the props interface for the AddReport component
export interface AddReportProps {
  onClose: (arg?: boolean | undefined) => void
  patient?: Patient | undefined
  onContinue: (arg?: boolean | undefined) => void
}

interface CustomFileType extends File {
  id: string
  status: boolean | null
  errors?: FileRejection['errors']
}

type DeviceOption = {
  label?: string | null
  value?: string | null
  id?: string | number
}

const INVALID_CHARS_REGEX = /[/\\:*?"<>|]/

const MAX_FILENAME_LENGTH = 100
const MAX_FILES = 10

// Main component for adding a report
export const AddReport = ({ patient, onClose, onContinue }: AddReportProps) => {
  // State variables to manage uploaded files, uploading status, report type, selected device, etc.
  const [reportType, setReportType] = useState('')
  const [device, setDevice] = useState<DeviceOption | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const [uploadedFiles, setUploadedFiles] = useState<CustomFileType[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const isMaxFilesReached = uploadedFiles.length >= MAX_FILES

  // Accessing API context for making API calls
  const Api = useApiContext()

  const toast = useToast()

  // Prepare device options based on the patient data
  const options = patient
    ? patient.matchDevices
        ?.filter(e => e.modelName)
        .map(e => {
          return { label: e.modelName, value: e.modelName, id: e.id }
        })
    : []

  // Automatically select a device if the patient has only one matching device
  useEffect(() => {
    const valueOptions = patient
      ? patient.matchDevices
          ?.filter(e => e.modelName)
          .map(e => {
            return { label: e.modelName, value: e.modelName, id: e.id }
          })
      : []
    if (valueOptions?.length === 1 && valueOptions[0]) {
      setDevice(valueOptions[0])
    }
  }, [patient])

  // Function to get the presigned URL for uploading a file to S3
  const getS3Url = async (fileName: string): Promise<string> => {
    const { data } =
      (await Api.transmissionReportsControllerAddInClinicPdfToTempBucket({
        patientId: patient?.id?.toString() || '',
        fileName,
      })) as unknown as AxiosResponse<{
        preSignedUrl: string
        id: string
      }>

    return data.preSignedUrl
  }

  // Function to handle file drop event from the dropzone
  const onDrop = async (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
  ) => {
    if (
      uploadedFiles.length + acceptedFiles.length + rejectedFiles.length >
      MAX_FILES
    ) {
      toast({
        title: 'Too Many Files',
        description: "You can't attach more than 10 files",
        variant: 'subtle',
        position: 'bottom-right',
        status: 'error',
      })
      return
    }

    const rejectedFilesWithStatus: CustomFileType[] = rejectedFiles.map(
      rejectedFile => {
        return Object.assign(rejectedFile.file, {
          status: false,
          id: generateUniqueID(),
          errors: rejectedFile.errors,
        })
      },
    )

    setUploadedFiles(prevFiles => [...prevFiles, ...rejectedFilesWithStatus])

    setIsUploading(true)

    try {
      const uploadPromises = acceptedFiles.map(async file => {
        const s3Url = await getS3Url(file.name)
        const result = await fetch(s3Url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/pdf',
            filename: file.name,
          },
          body: file,
        })
        if (!result.ok) {
          console.error('Inclinic/Manual s3 report upload failed', result)
          return
        }

        const newFile: CustomFileType = Object.assign(file, {
          id: generateUniqueID(),
          status: true,
        })

        setUploadedFiles(prevFiles => [...prevFiles, newFile])
      })

      await Promise.all(uploadPromises)
    } catch (error) {
      console.error('Inclinic/Manual s3 report upload failed', error)
    } finally {
      setIsUploading(false)
    }
  }

  // Initialize the Dropzone component for file uploads
  const { open, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    onDrop,
    multiple: true,
    accept: '.pdf',
    maxFiles: MAX_FILES,
    // maxSize: 10 * 1024 * 1024,
    disabled: isUploading,
    validator: file => {
      if (file.size > 10 * 1024 * 1024) {
        return {
          isValid: false,
          code: 'File Too Large',
          message: 'File Too Large, maximum size is 10 MB',
        }
      }
      if (file.type !== 'application/pdf') {
        return {
          isValid: false,
          code: 'Invalid File Type',
          message: 'Only PDF files are allowed',
        }
      }
      if (INVALID_CHARS_REGEX.test(file.name)) {
        console.log('invalid file name', INVALID_CHARS_REGEX.test(file.name))
        return {
          isValid: false,
          code: 'Invalid File Name',
          message: 'Invalid File Name',
        }
      }
      if (file.name.length > MAX_FILENAME_LENGTH) {
        return {
          isValid: false,
          code: 'File Name Too Long',
          message: 'File Name Too Long',
        }
      }
      return null
    },
  })

  // Function to handle form submission
  const onSubmit = async () => {
    setIsLoading(true)
    const fileNames = uploadedFiles.filter(e => e.status).map(e => e.name)
    const requestBody: any = {
      manualORInclinicTransmissionReportRequestDto: {
        patientId: patient?.id ? patient?.id.toString() : '',
        reportType: reportType,
        files: fileNames,
        deviceId: device?.id,
      },
    }
    try {
      const response =
        await Api.transmissionReportsControllerSubmitManualAndInclinicReports(
          requestBody,
        )

      // Handle failed file uploads
      const failedUploadFiles = response?.data?.fileStatus
        ?.filter(e => e.status === 'failed')
        .map(e => e.fileName)

      if (failedUploadFiles && failedUploadFiles.length) {
        // mark the failed files as failed in the uploadedFiles state
        setUploadedFiles(files =>
          files.map(file => {
            if (failedUploadFiles.includes(file.name)) {
              return { ...file, status: false } as CustomFileType
            }
            return file
          }),
        )

        toast({
          title: 'Failed to Upload Files',
          description: `Failed to upload files: ${failedUploadFiles.join(
            ', ',
          )}`,
          variant: 'subtle',
          position: 'bottom-right',
          status: 'error',
        })

        setIsLoading(false)
      } else {
        onContinue(true)
      }
      queryClient.invalidateQueries('patient')
    } catch (error) {
      console.log('Save Report Failed:', error)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      isOpen
      onClose={onClose}
      closeOnOverlayClick={false}
      footer={
        <>
          <Button
            style={{
              marginRight: '16px',
              background: '#455468',
              borderRadius: '5px',
            }}
            onClick={() => onClose(true)}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            disabled={
              !(
                reportType &&
                device &&
                uploadedFiles.length > 0 &&
                uploadedFiles.every(file => file.status)
              ) || isLoading
            }
            isLoading={isLoading}
          >
            Continue
          </Button>
        </>
      }
    >
      <HStack>
        <Box>
          <div style={{ display: 'flex' }}>
            <div>
              <div
                style={{
                  height: '60px',
                  width: '60px',
                  background: '#EFF2F6',
                  border: '1px solid #EFF2F6',
                  borderRadius: '12px',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '16px',
                    left: '16px',
                    width: '26px',
                    color: '#0D6DAD',
                  }}
                >
                  <Icon icon="add" boxSize="s" />
                </div>
              </div>
            </div>
            <div style={{ paddingTop: '7px', paddingLeft: '16px' }}>
              <Stack>
                <label
                  style={{
                    fontSize: '14px',
                    fontWeight: 700,
                    marginBottom: '5px',
                  }}
                >
                  {patient?.givenName} {patient?.familyName}
                </label>
              </Stack>
              <Stack>
                <label
                  style={{
                    fontSize: '21px',
                    fontWeight: 700,
                    marginBottom: '30px',
                  }}
                >
                  Add Reports
                </label>
              </Stack>
            </div>
          </div>
        </Box>
      </HStack>
      <Stack style={{ marginTop: '25px', marginBottom: '8px' }}>
        <Text
          variant="secondary"
          fontSize="11px"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          REPORT TYPE &nbsp;
          <Text variant="secondary" fontSize="11px">
            (required)
          </Text>
        </Text>
        <RadioGroup
          onChange={value => {
            setReportType(value)
          }}
        >
          <HStack spacing="md" style={{ marginRight: '24px' }}>
            <Radio value="in-clinic" spacing="md">
              In-clinic Report
            </Radio>
            <span style={{ marginRight: '24px' }} />
            <Radio value="manual" spacing="md">
              Manual Report
            </Radio>
          </HStack>
        </RadioGroup>
      </Stack>
      <Stack style={{ marginTop: '25px', marginBottom: '8px' }}>
        <Text
          variant="secondary"
          fontSize="11px"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          DEVICE &nbsp;
          <Text variant="secondary" fontSize="11px">
            (required)
          </Text>
        </Text>
        <div style={{ width: '100%' }}>
          <Select
            options={options}
            onChange={(selection: any) => {
              setDevice(selection)
            }}
            value={device}
            placeholder="Select"
            noSeparator
          />
        </div>
      </Stack>
      <Flex
        {...getRootProps()}
        height="180px"
        marginBottom="20px"
        borderColor="neutral.600"
        borderRadius="2px"
        borderStyle="dashed"
        borderWidth="1px"
        alignItems="center"
        justifyContent="center"
        transitionDuration={'0.3s'}
        {...(isMaxFilesReached
          ? { opacity: 0.5 }
          : {
              _hover: { bg: 'gray.50', cursor: 'pointer' },
            })}
        onClick={open}
        w="full"
      >
        <input {...getInputProps()} />
        <VStack spacing={4}>
          <Flex
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {isMaxFilesReached ? (
              <>
                <Flex
                  width={12}
                  height={12}
                  justifyContent={'center'}
                  alignItems={'center'}
                  borderRadius={'50%'}
                  border={'2px dotted'}
                  borderColor={'neutral.600'}
                >
                  <Icon icon="close" boxSize={6} p={0.5} />
                </Flex>
                <Text fontSize={'1.1rem'} mt={2}>
                  Max files reached
                </Text>
                <Text variant="secondary" align={'center'}>
                  Users can only attach 10 files
                </Text>
              </>
            ) : (
              <>
                <Flex
                  width={12}
                  height={12}
                  justifyContent={'center'}
                  alignItems={'center'}
                  borderRadius={'50%'}
                  border={'2px dotted'}
                  borderColor={'neutral.600'}
                >
                  <Icon icon="upload" boxSize={6} p={0.5} />
                </Flex>
                <Text fontSize={'1.1rem'} mt={2}>
                  Drag and drop PDF files here or click here to select files.
                </Text>
                <Text variant="secondary" align={'center'}>
                  Users can upload 10 pdf files (up to 10 MB each)
                </Text>
              </>
            )}
          </Flex>
        </VStack>
      </Flex>

      {(uploadedFiles?.length > 0 || isUploading) && (
        <Box width="full">
          <Heading variant="h6" mb={1}>
            Added Attachments:
          </Heading>
          <Divider />
          <Flex
            flexDirection="column"
            gap={3}
            mt={1}
            maxHeight="200px"
            overflow="auto"
          >
            {uploadedFiles?.map(file => (
              <AttachmentListItem
                key={file.id}
                id={file.id}
                errors={file.errors}
                originalName={file.name}
                onDelete={(id: string) => {
                  const newUploadedFiles = uploadedFiles.filter(
                    file => file.id !== id,
                  )
                  setUploadedFiles(newUploadedFiles)
                }}
              />
            ))}
            <Skeleton
              width={'full'}
              height={'lg'}
              noOfLines={1}
              isLoaded={!isUploading}
            />
          </Flex>
        </Box>
      )}
    </Modal>
  )
}

const AttachmentListItem = (input: {
  id: string
  originalName: string
  onDelete: (id: string) => void
  errors?: FileRejection['errors']
}) => {
  const { id, originalName, onDelete, errors } = input

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex gap={2} alignItems="center">
        <Icon icon="file" boxSize={4} color="text.primary" />
        <Flex flexDirection="column" gap={0.5}>
          <Text variant="h5" fontWeight={600}>
            {originalName}
          </Text>
          {errors && (
            <Text variant="smallCaps" color="red.300">
              {errors.map(error => error.message).join(', ')}
            </Text>
          )}
        </Flex>
      </Flex>
      <IconButton
        variant="plain"
        color="red.300"
        _hover={{ color: 'red.400', background: 'red.50' }}
        mr={1}
        onClick={() => onDelete(id)}
        size="sm"
        icon="delete"
        aria-label="Delete file"
      />
    </Flex>
  )
}
