import React, { ReactElement, useMemo } from 'react'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Stack,
  Text,
} from '@rhythm/components'

import AttributeDisplay from '../../../../../components/AttributeDisplay'
import { Patient } from '../../../../../types'
import { capitalizeFirstCharacter } from '../../../../../utils'
import formatDate from '../../../../../utils/formatDate'
import { getReferringProviderName } from '../EditMedicalDetailsModal/EditMedicalDetailsModal'

export interface MedicalDetailsCardProps {
  patient: Patient
  practitioner: any
  onEditMedical: () => void
}

const MedicalDetailsCard = ({
  patient,
  practitioner,
  onEditMedical,
}: MedicalDetailsCardProps): ReactElement => {
  const patientDiagnosis = useMemo(() => {
    return patient?.icdDiagnosis
      ? `${patient.icdDiagnosis.code} - ${patient.icdDiagnosis.description}`
      : ''
  }, [patient])

  const onEditMedicalClick = (e: any) => {
    e.preventDefault()
    onEditMedical()
  }
  return (
    <Accordion
      allowToggle
      defaultIndex={[0]}
      overflowY="scroll"
      sx={{
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        heightStyle: 'content',
      }}
    >
      <Card>
        <AccordionItem borderTop="none" borderBottom="none">
          <AccordionButton
            as="div"
            flexDirection="row"
            alignItems="flex-end"
            _hover={{
              cursor: 'pointer',
            }}
          >
            <HStack spacing="xl" mb="lg" width={'100%'}>
              <div
                style={{
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  display: 'flex',
                  margin: '1px auto 0px -17px',
                }}
              >
                <Icon icon="medical" color="primary.600" />
                <Heading variant="h5" marginLeft="10px">
                  Medical Details
                </Heading>
              </div>
              <Icon
                icon="edit"
                boxSize="sm"
                color="neutral.800"
                cursor="pointer"
                onClick={onEditMedicalClick}
              />
              <AccordionIcon />
            </HStack>
          </AccordionButton>
          <AccordionPanel
            p="none"
            h={'100%'}
            overflow={'auto'}
            sx={{
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Stack spacing="xl" pl="5xl">
              <Text fontWeight="bold">Patient Details</Text>
              <SimpleGrid columns={{ base: 2, md: 3 }} spacing="xl">
                <AttributeDisplay label="Name">{`${patient.givenName} ${patient.familyName}`}</AttributeDisplay>
                <AttributeDisplay label="MRN">{patient.mrn2}</AttributeDisplay>
                <AttributeDisplay label="DOB">
                  {formatDate(patient.birthDate)}
                </AttributeDisplay>
                <AttributeDisplay label="Gender">
                  <Text style={{ textTransform: 'capitalize' }}>
                    {patient.sex}
                  </Text>
                </AttributeDisplay>
                <AttributeDisplay label="OAC">{patient.oac}</AttributeDisplay>
                <AttributeDisplay label="DIAGNOSIS">
                  {patientDiagnosis}
                </AttributeDisplay>
                <AttributeDisplay label="Referring Provider">
                  {capitalizeFirstCharacter(getReferringProviderName(patient))}
                </AttributeDisplay>
                <AttributeDisplay label="FOLLOWING PRACTITIONER">
                  {`${practitioner?.given} ${practitioner?.family}`}
                </AttributeDisplay>
                <AttributeDisplay label="TX Schedule">
                  {patient?.matchDevices?.length &&
                  patient?.matchDevices?.length > 0 ? (
                    patient?.matchDevices?.map((device, deviceIndex) => {
                      const seenAppointments = new Set<string>()
                      const appointmentTexts = device?.appointments
                        ?.filter(
                          appointment =>
                            !['cancelled', 'completed'].includes(
                              appointment?.status?.toLowerCase() || '',
                            ),
                        )
                        ?.map(appointment => {
                          const text = `${
                            appointment.type || 'Unknown Type'
                          } Remote Tx every ${
                            appointment.frequency || '91'
                          } days`
                          if (!seenAppointments.has(text)) {
                            seenAppointments.add(text)
                            return text
                          }
                          return null
                        })
                        .filter(Boolean)

                      return (
                        <Text key={deviceIndex}>
                          {appointmentTexts && appointmentTexts.length > 0
                            ? appointmentTexts.join(', ')
                            : 'No Appointments'}
                        </Text>
                      )
                    })
                  ) : (
                    <Text>-</Text>
                  )}
                </AttributeDisplay>
              </SimpleGrid>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Card>
    </Accordion>
  )
}

export default MedicalDetailsCard
