import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { CircularProgress } from '@chakra-ui/react'
import {
  Box,
  Button,
  DataTable,
  Flex,
  Heading,
  Select,
  Tab,
  TableHeader,
  TableRow,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@rhythm/components'
import moment from 'moment'

import { useGetAllFlagsAdmin } from '../../features/featureFlags/api/useGetAllFlagsAdmin'
import { useUpdateManyFeatureFlags } from '../../features/featureFlags/api/useUpdateManyFlags'
import useCurrentUser from '../../hooks/useCurrentUser'
import { useToastHook } from '../../hooks/useToastHook'
import { FeatureFlag, Roles } from '../../lib/api'
import UserCard from '../UserSettingsPage/components/UserCard'

const OpsDashboardPage: React.FC = () => {
  const { currentUser } = useCurrentUser()
  const isSuperAdmin: boolean = currentUser.role === Roles.InternalsuperAdmin
  const { data: featureFlagsList } = useGetAllFlagsAdmin()
  const updateFeatureFlagMutation = useUpdateManyFeatureFlags()
  const [isLoading, setIsLoading] = useState(false)
  const [isSaveEnabled, setIsSaveEnabled] = useState(false)
  const [updatedFlags, setUpdatedFlags] = useState<Map<string, number>>(
    new Map(),
  )

  const initialFlags = useMemo(() => {
    const initialMap = new Map()
    if (featureFlagsList) {
      featureFlagsList.data.forEach((flag: FeatureFlag) => {
        initialMap.set(flag.id, flag.setting)
      })
    }
    return initialMap
  }, [featureFlagsList])

  const featureFlags = useMemo(() => featureFlagsList?.data, [featureFlagsList])
  useEffect(() => {
    setUpdatedFlags(initialFlags)
  }, [initialFlags])

  const onCancel = useCallback(() => {
    setUpdatedFlags(initialFlags)
    setIsSaveEnabled(false)
  }, [initialFlags])

  const { showToast } = useToastHook()

  const handleDropdownChange = useCallback((flagId: string, value: number) => {
    setUpdatedFlags(prev => {
      const newMap = new Map(prev)
      newMap.set(flagId, value)
      return newMap
    })
    setIsSaveEnabled(true)
  }, [])

  const handleSave = useCallback(async () => {
    const changes = Array.from(updatedFlags.entries()).filter(
      ([id, setting]) => setting !== initialFlags.get(id),
    )

    if (changes.length > 0) {
      const updateSettingsDTO = changes.map(([id, setting]) => ({
        id,
        setting,
      }))
      try {
        setIsLoading(true)
        await updateFeatureFlagMutation.mutateAsync(updateSettingsDTO)
        setIsLoading(false)
        setIsSaveEnabled(false)
        showToast('Flag(s) saved successfully')
      } catch (err) {
        showToast('Something went wrong', 'error')
        setIsLoading(false)
      }
    }
  }, [updatedFlags, initialFlags, updateFeatureFlagMutation, showToast])

  const Cells = {
    DateCell: ({
      row: { original },
      value,
    }: {
      row: { original: FeatureFlag }
      value: number
    }) => (
      <Text variant="secondary">{`${original.updated_by}  (${moment(
        value,
      ).format('MMMM Do, YYYY')})`}</Text>
    ),
    DefaultCell: ({ value }: { value: string }) => (
      <Text variant="secondary">{value}</Text>
    ),
    ValueCell: ({
      row: { original },
      value,
    }: {
      row: { original: FeatureFlag }
      value: number
    }) => {
      const selectOptions = [
        { label: 'Off', value: 0 },
        { label: 'Backend Only', value: 1 },
        { label: 'Testing', value: 2 },
        { label: 'Internal', value: 3 },
        { label: 'All', value: 4 },
      ]
      const handleChange = (selectedOption: any) => {
        if (selectedOption && typeof selectedOption.value === 'number') {
          handleDropdownChange(original.id, selectedOption.value)
        }
      }
      const selectedOption =
        selectOptions.find(
          option => option.value === updatedFlags.get(original.id),
        ) || null
      const defaultValue = selectOptions.find(option => option.value === value)
      const hasChanged =
        updatedFlags.get(original.id) !== initialFlags.get(original.id)
      const customStyles = {
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: state.isSelected ? 'lightblue' : 'light',
        }),
        container: (provided: any, state: any) => ({
          ...provided,
          fontWeight: hasChanged ? 'bold' : '',
        }),
      }

      return (
        <Select
          isCreatable
          key={original.id}
          value={selectedOption || defaultValue}
          options={selectOptions}
          onChange={handleChange}
          styles={customStyles}
        />
      )
    },
  }

  return (
    <Box mt="2xl" pb="80px">
      <Heading variant="h1" fontWeight="light" mb="5xl">
        Manage Your Settings
      </Heading>
      <Flex>
        <Flex alignItems="flex-start" direction="column" mr="5xl">
          <UserCard
            name={`${currentUser.firstName} ${currentUser.lastName}`}
            onUpdateProfileImage={() => {}}
          />
        </Flex>
        <Tabs>
          <TabList display="flex" mr="5xl">
            {isSuperAdmin && <Tab>Feature Flags</Tab>}
          </TabList>
          <TabPanels>
            {isSuperAdmin && (
              <TabPanel>
                <Box>
                  <DataTable
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'displayName',
                        Cell: Cells.DefaultCell,
                      },
                      {
                        Header: 'Description',
                        accessor: 'displaySummary',
                        Cell: Cells.DefaultCell,
                      },
                      {
                        Header: 'Key',
                        accessor: 'shortCode',
                        Cell: Cells.DefaultCell,
                      },
                      {
                        Header: 'Value',
                        accessor: 'setting',
                        Cell: Cells.ValueCell,
                      },
                      {
                        Header: 'Last edited by',
                        accessor: 'updatedAt',
                        Cell: Cells.DateCell,
                      },
                    ]}
                    data={featureFlags ?? []}
                    initialSortBy={[{ id: 'updatedAt', desc: true }]}
                    size="sm"
                    width={'6xl'}
                    isSortable
                    isSearchable
                    isClickable
                    selectedRowBackgroundColor="primary.100"
                    selectedRowBorderColor="primary.400"
                    borderCollapse="separate"
                    borderColor="neutral.200"
                    borderStyle="solid"
                    // borderWidth="1px"
                    borderSpacing="0 2px"
                    tableHeadCell={
                      <TableHeader
                        color="neutral.800"
                        bg="neutral.white"
                        borderColor="neutral.200"
                        borderBottomStyle="solid"
                        borderBottomWidth="1px"
                        py="xl"
                      />
                    }
                    tableBodyRow={() => (
                      <TableRow
                        alignItems="center"
                        borderBottomColor="neutral.200"
                        borderBottomStyle="solid"
                        borderBottomWidth="1px"
                        sx={{
                          svg: {
                            display: 'none',
                          },
                          '&:hover svg': {
                            display: 'block',
                          },
                        }}
                        bg="neutral.white"
                      />
                    )}
                  />
                  <Box
                    borderBottom="1px solid"
                    borderBottomColor="neutral.200"
                    backgroundColor={'#fff'} // Match table border
                    px={4} // Add padding to match table cell padding
                    py={2}
                  >
                    <Flex justifyContent="flex-end">
                      <Button variant="plain" mr={2} onClick={onCancel}>
                        Cancel
                      </Button>
                      <Button
                        colorScheme="blue"
                        disabled={!isSaveEnabled}
                        onClick={handleSave}
                      >
                        {!isLoading ? (
                          'Save'
                        ) : (
                          <CircularProgress size={20} isIndeterminate />
                        )}
                      </Button>
                    </Flex>
                  </Box>
                </Box>
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Flex>
    </Box>
  )
}

export default OpsDashboardPage
