import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ButtonGroup } from '@chakra-ui/react'
import { pdf } from '@react-pdf/renderer'
import {
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@rhythm/components'
import { useQuery } from 'react-query'

import {
  NonClinicalRoles,
  PractitionerRoles,
} from '../../../../../constants/authorization'
import { useApiContext } from '../../../../../context/ApiContext'
import { CurrentUser } from '../../../../../context/UserContext'
import {
  useGetVendorTransmissionReports,
  useTransmissionReportContext,
  useTransmissionReportImages,
} from '../../../../../features/transmissionReports'
import { useOrganization } from '../../../../../features/transmissionReports/api/getOrganization'
import {
  TransmissionReportAlert,
  TransmissionReportDto,
  TransmissionReportDtoColorEnum,
  TransmissionReportDtoSignatureStatusEnum,
  User,
} from '../../../../../lib/api'
import { routes } from '../../../../../routes'
import {
  checkIfEditedAfterSigned,
  getEGMImage,
  getFullPDF,
} from '../../../../../utils/transmissionReportUtil'
import { PrimaryCtaText } from '../../../PatientProfilePage/components/DeviceDetailsWithTransmissionSchedule/constants'
import { PractitionerOption } from '../../TransmissionReportPage'
import CreatingILRPDFGrey from '../ViewReportModal/CreatingILRPDFGrey'
import CreatingPDFGrey from '../ViewReportModal/CreatingPDFGrey'
import ViewPDF from '../ViewReportModal/ViewPDF'

import AttachPDFModal from './components/AttachPDFModal'
import PrimaryCtaButton from './PrimaryCtaButton'

export interface TransmissionActionBarProps {
  marginLeft: number
  sideBarWidth: number
  currentUser: Partial<CurrentUser> | undefined
  onSendReport: () => void
  onPrimaryCtaClick: () => void
  onViewVendorReport: () => void
  historyReport: () => void
  reportsList: any[]
  onClose: () => void
  primaryCtaText: PrimaryCtaText
  reviewSideBarIsOpen?: boolean
  isEditing?: boolean
  transmissionReport?: TransmissionReportDto
  setPrimaryCtaText: (text: PrimaryCtaText) => void
  isPrimaryCtaLoading: boolean
  transmissionType: string
}

// Function to construct the full name from parts
const getFullName = (familyName?: string, givenName?: string): string => {
  return `${familyName ? familyName + ' ' : ''}${givenName ?? ''}`
}

const TransmissionActionBar = ({
  marginLeft,
  sideBarWidth,
  currentUser,
  onPrimaryCtaClick,
  onViewVendorReport,
  historyReport,
  reportsList,
  primaryCtaText,
  reviewSideBarIsOpen = false,
  isEditing,
  onClose,
  transmissionReport,
  setPrimaryCtaText,
  isPrimaryCtaLoading,
  transmissionType,
}: TransmissionActionBarProps): React.ReactElement => {
  // listening to billing date change to disable sign reports button
  const { isBillingValid, clinicalNotesSideBarIsOpen } =
    useTransmissionReportContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()

  const { getAlertImages } = useTransmissionReportImages({
    transmissionReport,
  })
  const [viewVendorPDFModalIsOpen, setViewVendorPDFModalIsOpen] =
    useState(false)

  const onSignReportButtonClick = () => {
    if (
      primaryCtaText === PrimaryCtaText.NextTransmission &&
      viewVendorPDFModalIsOpen
    ) {
      setViewVendorPDFModalIsOpen(false)
    }
    onPrimaryCtaClick()
  }

  const [flagAndMessage, setFlagAndMessage] = useState<{
    flag: boolean
    msg: string
  }>({
    flag: false,
    msg: '',
  })

  const {
    data: reportAttachments,
    status,
    isLoading: isAttachmentsLoading = false,
  } = useQuery({
    queryKey: ['transmissionReportAttachments', transmissionReport?.id],
    queryFn: async () => {
      const response = await Api.transmissionReportsControllerGetAttachments({
        transmissionReportId: transmissionReport?.id ?? 0,
      })
      return response.data
    },
    enabled: !!transmissionReport?.id,
  })
  const deviceType = transmissionReport?.device?.deviceType

  const { data: vendorTransmissionReportsList } =
    useGetVendorTransmissionReports(
      {
        transmissionReportId: transmissionReport?.id ?? 0,
      },
      {
        enabled: !!transmissionReport?.id,
      },
    )
  const egmImageOverideUrl: any = transmissionReport?.overrides ?? {}
  let urlegm: any = undefined
  let dynamicImage = undefined
  const requiredImageInfo = useMemo(
    () => getEGMImage(vendorTransmissionReportsList),
    [vendorTransmissionReportsList],
  )
  if (requiredImageInfo?.baseEgmUrl && requiredImageInfo.name) {
    dynamicImage = requiredImageInfo.baseEgmUrl
  }
  if (dynamicImage && egmImageOverideUrl.egmImage) {
    urlegm = dynamicImage
  }

  const [disabled, setDisabled] = useState<boolean>(false)
  const [fullPdf, setFullPdf] = useState<string>('')
  const [practitioners, setPractitioners] = useState<User[]>([])
  const [practitionerOptions, setPractitionerOptions] = useState(
    [] as PractitionerOption[],
  )
  const Api = useApiContext()
  const organizationId: string = transmissionReport?.patient?.clinic?.id ?? ''

  const { data: orgsData } = useOrganization({
    organizationId,
  })
  const clinicAddress = orgsData
  const [isAttachPdfModalOpen, setIsAttachPdfModalOpen] = useState(false)

  const fetchPdfUtl = async () => {
    if (reportsList && reportsList.length !== 0) {
      setIsLoading(true)
      setFullPdf(await getFullPDF(reportsList))
      setIsLoading(false)
    } else {
      setDisabled(true)
    }
  }

  useEffect(() => {
    setFullPdf('')
    setDisabled(false)
    fetchPdfUtl()
    // eslint-disable-next-line
  }, [reportsList])

  const handleClick = (name: string) => {
    if (reportsList && reportsList.length !== 0 && fullPdf) {
      if (name === 'tab') {
        window.open(fullPdf)
      } else if (name === 'window') {
        window.open(fullPdf, '_blank', 'width=1200,height=1200')
      }
    }
  }
  const showEditedByInfoOnPdf = checkIfEditedAfterSigned(transmissionReport!)

  const MAX_CARDS = 3
  // Create alerts array of length MAX_CARDS, filling it with empty arrays if needed.
  const alerts = Array.from(
    { length: Math.max(MAX_CARDS, transmissionReport?.alerts?.length ?? 0) },
    (_, index) => transmissionReport?.alerts?.[index] ?? [],
  ) as TransmissionReportAlert[]

  const alertImageMap: Record<string, { id: string; images: string[] }> = {}

  // Create an array of arrays of alert images, each array of alert images is of length MAX_CARDS.
  const alertImagesBase64Urls: string[][] = alerts.map(alert => {
    const alertImages = getAlertImages(alert.id)?.slice(0, MAX_CARDS) ?? []
    return alertImages.map(alertImage => {
      const base64Url = alertImage.base64Url as unknown as string

      if (!alertImageMap[alert.id]) {
        alertImageMap[alert.id] = { id: alert.id, images: [] }
      }

      alertImageMap[alert.id].images.push(base64Url)
      return base64Url
    })
  })

  const getPDFComponentWithProps = (Component: any, specificProps = {}) => {
    const commonProps = {
      key: transmissionReport?.patient?.id,
      transmissionReport,
      urlImg1: alertImagesBase64Urls[0][0],
      urlImg2: alertImagesBase64Urls[0][1],
      urlImg3: alertImagesBase64Urls[0][2],
      urlImg4: alertImagesBase64Urls[1][0],
      urlImg5: alertImagesBase64Urls[1][1],
      urlImg6: alertImagesBase64Urls[1][2],
      urlImgEpisode1: alertImagesBase64Urls[2][0],
      urlImgEpisode2: alertImagesBase64Urls[2][1],
      urlImgEpisode3: alertImagesBase64Urls[2][2],
      urlegm,
      practitionGet: undefined,
      deviceType,
      clinicAddress,
      showEditedByInfoOnPdf,
      editedUser: transmissionReport?.editedUser,
      editedDate: transmissionReport?.editedDate,
      alertImageMap,
    }
    const componentProps = {
      ...commonProps,
      ...specificProps,
    }
    return <Component {...componentProps} />
  }
  const saveOnEdit = async () => {
    const signatureStatus =
      transmissionReport?.signatureStatus ===
      TransmissionReportDtoSignatureStatusEnum.NonPractitionerSigned
    const PDFComponent =
      deviceType === 'ILR' ? CreatingILRPDFGrey : CreatingPDFGrey
    const element = getPDFComponentWithProps(PDFComponent)
    const pdfObj = pdf(element)
    pdfObj.updateContainer(element)
    const blob = await pdfObj.toBlob()
    const fileData = new File([blob], 'rhythmvendorreport+.pdf', {
      type: blob.type,
    })
    if (transmissionReport?.id) {
      await Api.transmissionReportsControllerEditReport({
        transmissionReportId: transmissionReport.id,
        isEditable: true,
        file: signatureStatus ? ({} as File) : fileData,
      })
      history.push({
        pathname: routes.physician.patientProfile.replace(
          ':id',
          transmissionReport?.patient?.id ?? '',
        ),
      })
    }
  }

  const patientId = transmissionReport?.patient?.id ?? ''

  const fetchPractitioners = useCallback(async () => {
    try {
      const resp = await Api.patientsControllerFindAllAuthorizedPractitioners({
        patientId,
      })
      setPractitioners(resp.data.practitioners)
      const newPractitionerOptions = practitioners?.map(
        ({ id, family, given, prefix }: User) => ({
          label: (prefix.length ? `${prefix} ` : '') + `${given} ${family}`,
          value: id,
        }),
      )
      if (newPractitionerOptions?.length)
        setPractitionerOptions(newPractitionerOptions)
    } catch (error) {
      console.error("Unable to fetch patient's practitioners")
    }
  }, [patientId, Api])

  useEffect(() => {
    if (patientId !== '') {
      fetchPractitioners()
    }
  }, [patientId, fetchPractitioners])

  const isFollowingPractitionerReq =
    transmissionReport?.account?.isFollowingPractitionerReq ?? false

  const isPractitionerAssigned =
    transmissionReport?.patient?.practitioner !== null

  useEffect(() => {
    if (!transmissionReport) {
      setFlagAndMessage({
        flag: false,
        msg: '',
      })
      return
    }

    if (
      isFollowingPractitionerReq &&
      (practitioners?.length ?? 0) > 0 &&
      !isPractitionerAssigned
    ) {
      setFlagAndMessage({
        flag: true,
        msg: 'Please select a Following Practitioner',
      })
      return
    } else if (
      transmissionReport?.color === TransmissionReportDtoColorEnum.Gray
    ) {
      setFlagAndMessage({
        flag: true,
        msg: 'Please select a triage color (Required)',
      })
      return
    } else if (
      currentUser?.role &&
      NonClinicalRoles.includes(currentUser?.role)
    ) {
      setFlagAndMessage({
        flag: true,
        msg: 'Non-Clinician users do not have sufficient permissions to sign a report',
      })
      return
    } else if (
      ['remote', 'in-clinic', 'remote-in-clinic'].includes(transmissionType) &&
      !isBillingValid
    ) {
      setFlagAndMessage({
        flag: true,
        msg: 'Please select Billing Details',
      })
      return
    } else if (
      transmissionReport.signatures?.length === 0 &&
      currentUser?.role &&
      PractitionerRoles.includes(currentUser?.role)
    ) {
      setFlagAndMessage({
        flag: true,
        msg: 'A Practitioner cannot sign a report before a clinician',
      })
      return
    }
    setFlagAndMessage({
      flag: false,
      msg: '',
    })
    return () => {
      setFlagAndMessage({
        flag: false,
        msg: '',
      })
    }
  }, [
    isFollowingPractitionerReq,
    isPractitionerAssigned,
    practitioners?.length,
    transmissionReport,
    currentUser?.role,
    transmissionType,
    isBillingValid,
  ])

  const patientName = useMemo(() => {
    return getFullName(
      transmissionReport?.patient?.familyName,
      transmissionReport?.patient?.givenName,
    )
  }, [
    transmissionReport?.patient?.familyName,
    transmissionReport?.patient?.givenName,
  ])

  const getMarginLeft = () => {
    if (reviewSideBarIsOpen && clinicalNotesSideBarIsOpen) {
      if (window.innerWidth < 1530) {
        return '0px'
      }
      return `${marginLeft + 15}px`
    }
    return `${marginLeft + 35}px`
  }

  const getWidth = () => {
    if (reviewSideBarIsOpen && clinicalNotesSideBarIsOpen) {
      if (window.innerWidth < 1530) {
        return '100%'
      }
      return `calc(100% - ${sideBarWidth + 30}px)`
    }
    return `calc(100% - ${sideBarWidth + 70}px)`
  }

  return (
    <Box
      bg="neutral.black"
      borderTopRadius="md"
      p="xl"
      ml={getMarginLeft()}
      width={getWidth()}
      position="fixed"
      left={0}
      zIndex={5}
      right={0}
      bottom={0}
      transition="all 0.25s ease"
      transitionProperty="margin, width"
    >
      <HStack justifyContent="space-between" spacing="xl">
        <HStack>
          {isEditing && (
            <Button variant={'secondaryDark'} onClick={historyReport}>
              Report History
            </Button>
          )}
          <ButtonGroup isAttached>
            <Button
              iconSpacing={4}
              rightIcon={'upload'}
              variant={'secondaryDark'}
              px={'4'}
              isLoading={['loading', 'idle'].includes(status)}
              isDisabled={['loading', 'idle'].includes(status)}
              onClick={() => setIsAttachPdfModalOpen(true)}
            >
              Attach PDF ({reportAttachments?.total ?? 0})
            </Button>
          </ButtonGroup>
          <Button
            variant={'secondaryDark'}
            onClick={() =>
              setViewVendorPDFModalIsOpen(!!transmissionReport?.id)
            }
          >
            Print Report
          </Button>
        </HStack>
        <HStack>
          <ButtonGroup isAttached>
            <Button
              variant={'secondaryDark'}
              px={'4'}
              onClick={onViewVendorReport}
            >
              View Vendor Report
            </Button>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    disabled={disabled}
                    transition="all 0.2s"
                    isLoading={isLoading}
                    h={'inherit'}
                    as={IconButton}
                    variant={'secondaryDark'}
                    icon={isOpen ? 'drop-down' : 'drop-up'}
                    aria-label={'option'}
                    borderLeft={'1px solid'}
                    borderLeftColor={'neutral.800'}
                  />
                  <MenuList>
                    <MenuItem onClick={() => handleClick('tab')}>
                      Open in new tab
                    </MenuItem>
                    <MenuItem onClick={() => handleClick('window')}>
                      Open in new window
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </ButtonGroup>
          {isEditing ? (
            <>
              <Button onClick={onClose} variant="secondaryDark">
                Close
              </Button>
              <Button onClick={saveOnEdit}>Save</Button>
            </>
          ) : (
            <PrimaryCtaButton
              flagAndMessage={flagAndMessage}
              onPrimaryCtaClick={onSignReportButtonClick}
              isDisabled={
                (flagAndMessage.flag &&
                  [
                    PrimaryCtaText.SignReport,
                    PrimaryCtaText.SignAndNext,
                  ].includes(primaryCtaText)) ||
                (['remote', 'in-clinic', 'remote-in-clinic'].includes(
                  transmissionType,
                ) &&
                  !isBillingValid)
              }
              isLoading={isPrimaryCtaLoading}
              primaryCtaText={primaryCtaText}
              setPrimaryCtaText={setPrimaryCtaText}
            />
          )}
        </HStack>
      </HStack>
      {transmissionReport && (
        <AttachPDFModal
          attachments={reportAttachments?.data ?? []}
          reportId={transmissionReport?.id}
          patientName={patientName}
          isOpen={isAttachPdfModalOpen && !['idle', 'loading'].includes(status)}
          onClose={() => setIsAttachPdfModalOpen(false)}
        />
      )}
      {transmissionReport && (
        <ViewPDF
          isOpen={viewVendorPDFModalIsOpen}
          onOpen={() => {
            if (primaryCtaText === PrimaryCtaText.SignAndNext)
              setPrimaryCtaText(PrimaryCtaText.SignReport)
          }}
          onClose={() => {
            setViewVendorPDFModalIsOpen(false)
            const textVal = sessionStorage.getItem(
              'primaryCtaText',
            ) as PrimaryCtaText
            if (textVal === PrimaryCtaText.SignAndNext)
              setPrimaryCtaText(textVal)
          }}
          transmissionId={transmissionReport.id}
          rhythmid={transmissionReport?.patient?.rhythmId!}
          alertImages={alertImagesBase64Urls}
          alertImageMap={alertImageMap}
          urlegm={urlegm}
          devicetype={deviceType}
          practitionGet={practitionerOptions}
          clinicAddress={clinicAddress}
          saveCombinedPDF={false}
          SignReportButton={() =>
            !isEditing ? (
              <PrimaryCtaButton
                flagAndMessage={flagAndMessage}
                onPrimaryCtaClick={onSignReportButtonClick}
                isDisabled={
                  (flagAndMessage.flag &&
                    primaryCtaText === PrimaryCtaText.SignReport) ||
                  (['remote', 'in-clinic', 'remote-in-clinic'].includes(
                    transmissionReport?.transmissionType,
                  ) &&
                    !isBillingValid)
                }
                isLoading={isPrimaryCtaLoading}
                primaryCtaText={
                  primaryCtaText === PrimaryCtaText.SignAndNext
                    ? PrimaryCtaText.SignReport
                    : primaryCtaText
                }
                setPrimaryCtaText={setPrimaryCtaText}
                allowSignAndNext={false}
              />
            ) : (
              <></>
            )
          }
        />
      )}
    </Box>
  )
}

export default TransmissionActionBar
