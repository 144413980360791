import React, { FocusEventHandler, useMemo, useState } from 'react'

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconBadge,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  Select,
  SimpleGrid,
} from '@rhythm/components'
import { Controller, useForm } from 'react-hook-form'

import {
  useGetAuthorizedPractitioners,
  useUpdatePatient,
} from '../../../../../features/patients'
import {
  LabelAndValue,
  queryClient,
  RegisteredNpis,
  UpdatePatientParamsOacEnum,
  User,
} from '../../../../../lib/api'
import { Patient } from '../../../../../types'
import { capitalizeFirstCharacter } from '../../../../../utils'
import { OAC_OPTIONS } from '../../../../../utils/constants/transmissionReports'
import ReferringPhysicianModal from '../../../RegisteredNpi/ReferringPhysician'
import DiagnosisSelect from '../../../TransmissionReportPage/components/PatientDemographicsCard/components/DiagnosisSelect'

export interface EditMedicalDetailsModalProps {
  patient: Patient
  practitioner: any
  isOpen: boolean
  ehrIntegration?: boolean
  onClose: () => void
  setEditMedicalDetailModalIsOpen: (data: boolean) => void
}

interface FormValuesType {
  referringProvider?: string
  oac: { label: string; value: string } | null
  practitioner: { label: string; value: string } | undefined
  diagnosisOption: LabelAndValue | null
  displayReferringPhysician?: string
}
export const getReferringProviderName = (patient: Patient) => {
  return patient && patient.referringProvider
    ? `${patient.referringProvider.firstName} ${patient.referringProvider.lastName} (${patient.referringProvider.NPI})`
    : patient.referringPhysician
      ? patient.referringPhysician
      : ''
}
const EditMedicalDetailsModal = ({
  patient,
  practitioner,
  isOpen,
  ehrIntegration,
  onClose,
  setEditMedicalDetailModalIsOpen,
}: EditMedicalDetailsModalProps): React.ReactElement => {
  const [isProviderModal, setIsProviderModal] = useState(false)
  const defaultValues: FormValuesType = {
    referringProvider: patient.referringProvider
      ? patient.referringProvider.NPI
      : undefined,
    oac: patient.oac ? { label: patient.oac, value: patient.oac } : null,
    displayReferringPhysician: capitalizeFirstCharacter(
      getReferringProviderName(patient),
    ),
    practitioner: practitioner && {
      label: `${practitioner.given} ${practitioner.family}`,
      value: practitioner.id,
    },
    diagnosisOption: patient?.icdDiagnosis
      ? {
          label: `${patient.icdDiagnosis?.code} - ${patient.icdDiagnosis?.description}`,
          value: patient.icdDiagnosis?.code,
        }
      : null,
  }

  const methods = useForm<FormValuesType>({
    defaultValues,
  })

  const patientId = patient.id as string

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods

  const {
    data: practitioners,
    isLoading: isLoadingPractitioners,
    refetch: refetchPatientPractitioners,
  } = useGetAuthorizedPractitioners(
    {
      patientId,
    },
    { enabled: !!patientId },
  )

  const {
    mutateAsync: mutateUpdatePatient,
    isLoading: isPatientUpdateMutationLoading,
  } = useUpdatePatient()

  const practitionerOptions: { label: string; value: string }[] = useMemo(
    () =>
      practitioners && !isLoadingPractitioners
        ? practitioners.map(({ id, family, given, prefix }: User) => ({
            label: (prefix.length ? `${prefix} ` : '') + `${given} ${family}`,
            value: id,
          }))
        : [],
    [practitioners, isLoadingPractitioners],
  )

  const closeModal = () => {
    methods.reset()
    onClose()
  }

  const handleUpdatePatient = async (data: FormValuesType) => {
    await mutateUpdatePatient({
      patientId,
      updatePatientParams: {
        referringProvider: data.referringProvider?.trim(),
        oac: data.oac?.value as UpdatePatientParamsOacEnum,
        practitionerId: data.practitioner?.value,
        diagnosisOption: data.diagnosisOption?.value,
      },
    })
    queryClient.invalidateQueries(['patient', patientId])
    methods.reset(data)
    onClose()
  }

  const handleReferringPhysicianSubmit = (data: RegisteredNpis) => {
    const displayProviderName = ehrIntegration
      ? capitalizeFirstCharacter(`${data.firstName} ${data.lastName}`)
      : capitalizeFirstCharacter(
          `${data.firstName} ${data.lastName} (${data.NPI})`,
        )
    methods.setValue('referringProvider', data.NPI, {
      shouldDirty: true,
    })
    methods.setValue('displayReferringPhysician', displayProviderName)
    setIsProviderModal(false)
    setEditMedicalDetailModalIsOpen(true)
  }
  const onModalCLose = () => {
    onClose()
    setIsProviderModal(false)
    setEditMedicalDetailModalIsOpen(true)
  }

  const handleClear = () => {
    methods.setValue('referringProvider', '-')
    methods.setValue('displayReferringPhysician', '')
  }

  return (
    <>
      {isProviderModal ? (
        <>
          <ReferringPhysicianModal
            isOpen={isOpen}
            onClose={onModalCLose}
            onSubmit={handleReferringPhysicianSubmit}
            isProfilePage={true}
          />
        </>
      ) : (
        <>
          <Modal
            width="50%"
            isOpen={isOpen}
            onClose={closeModal}
            closeOnOverlayClick={false}
            footer={
              <HStack spacing="xl">
                <Button variant="secondaryDark" onClick={closeModal}>
                  Cancel
                </Button>
                <Button
                  isLoading={isPatientUpdateMutationLoading}
                  onClick={handleSubmit(handleUpdatePatient)}
                >
                  Save Changes
                </Button>
              </HStack>
            }
          >
            <HStack spacing="xl" mb="4xl">
              <IconBadge icon="patients" size="lg" />
              <Heading variant="h5">Patient Details</Heading>
            </HStack>
            <form>
              <SimpleGrid columns={2} gap={'xl'}>
                <FormControl>
                  <FormLabel htmlFor="referringProvider">
                    REFERRING PROVIDER
                  </FormLabel>
                  <Flex>
                    <InputGroup>
                      {!methods.watch('displayReferringPhysician')?.length && (
                        <InputLeftElement>
                          <Icon
                            aria-label="Clear input"
                            icon="search"
                            bg={'none'}
                            color={'#8390a2'}
                            boxSize="sm"
                          />
                        </InputLeftElement>
                      )}
                      <Input
                        {...methods.register('displayReferringPhysician')}
                        name="displayReferringPhysician"
                        placeholder="        Search"
                        onClick={() => {
                          if (
                            !methods.watch('displayReferringPhysician')?.length
                          ) {
                            setIsProviderModal(true)
                          }
                        }}
                        isReadOnly
                      />
                      {methods.watch('displayReferringPhysician')?.length && (
                        <InputRightElement>
                          <IconButton
                            aria-label="Clear input"
                            icon="close"
                            onClick={handleClear}
                            bg={'none'}
                            color={'black'}
                            _hover={{ bg: '#dee1e6', borderRadius: '50%' }}
                            size="sm"
                          />
                        </InputRightElement>
                      )}
                    </InputGroup>
                    {methods.watch('displayReferringPhysician')?.length ? (
                      <IconButton
                        aria-label="Clear input"
                        icon="edit"
                        onClick={() => setIsProviderModal(true)}
                        bg={'none'}
                        color={'black'}
                        _hover={{ bg: '#dee1e6', borderRadius: '50%' }}
                        size="sm"
                        ml={'2px'}
                      />
                    ) : null}
                  </Flex>
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="oac">OAC</FormLabel>
                  <Controller
                    name="oac"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        ref={ref}
                        value={value}
                        options={OAC_OPTIONS}
                        isInvalid={errors.oac as unknown as boolean | undefined}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="practitioner">
                    FOLLOWING PRACTITIONER
                  </FormLabel>
                  <Controller
                    name="practitioner"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        ref={ref}
                        isLoading={isLoadingPractitioners}
                        options={practitionerOptions}
                        onFocus={
                          refetchPatientPractitioners as unknown as FocusEventHandler<HTMLInputElement>
                        }
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Flex direction={'column'}>
                    <FormLabel>DIAGNOSIS</FormLabel>
                    <Controller
                      name="diagnosisOption"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DiagnosisSelect
                          value={value}
                          isUpdating={isPatientUpdateMutationLoading}
                          handleChange={onChange}
                        />
                      )}
                    />
                  </Flex>
                </FormControl>
              </SimpleGrid>
            </form>
          </Modal>
        </>
      )}
    </>
  )
}

export default EditMedicalDetailsModal
