import {
  Billing,
  DeviceTypes,
  DeviceVendors,
  IcdDiagnosisCode,
  MatchDevice,
  Organization,
  PatientSex,
  PatientStatus,
  RegisteredNpis,
  Roles,
  TransmissionData,
  TransmissionReportDtoFilesUploadStatus,
  TransmissionReportSignatureStatusEnum,
  VtDevice,
  VtVendor,
} from './lib/api'

export enum TransmissionStatus {
  RED = 'red',
  YELLOW = 'yellow',
  ROUTINE = 'routine',
  GREEN = 'green',
  GRAY = 'gray',
}

export enum ReportType {
  InClinic = 'in-clinic',
  Manual = 'manual',
  RemoteInClinic = 'remote-in-clinic',
  Remote = 'remote',
}

export const ReportTypeMap: Record<string, string> = {
  'in-clinic': 'Manual In-Clinic',
  manual: 'Manual',
  'remote-in-clinic': 'Remote In-Clinic',
  remote: 'Remote',
}

export enum OrderStatus {
  EmptyAsPending = '',
  PendingOrder = 'Pending_Order',
  OrderFailedToPost = 'Order_Failed_To_Post',
  OrderRequiresSupport = 'Order_Requires_Support',
  ReadyToTransmit = 'Ready_To_Transmit',
  ReportFailedToPost = 'Report_Failed_To_Post',
  ReportRequiresSupport = 'Report_Requires_Support',
  SuccessfullyTransmitted = 'Successfully_Transmitted',
  OrderModified = 'Order_Modified',
}

export type Clinic = {
  id: string
  name: string
  location?: string
}

export type User = {
  id: string
  externalId?: string
  email: string
  firstName: string
  lastName: string
  prefix?: string
  suffix?: string
  phoneNumber?: string
  profilePic?: string
  role?: Roles
  credentials?: string | null
  organizations?: Organization[]
  featureFlagRole?: number
}

export type Appointment = {
  id: string
  salesforceRemoteId: string
  appointmentName: string
  appointmentNotes?: string | null
  completedDate?: Date | null
  cptCode?: string[] | null
  customFrequency?: number | null
  encounterDate?: Date | null
  endDate?: Date | null
  frequency?: string | null
  frequencyNumber?: number | null
  includeEriTracking: boolean
  isMonthly: boolean
  scheduledDate?: Date | null
  status?: string | null
  transmissionEligibleDate?: Date | null
  type?: string | null
  matchDevice: Device
  createdAt: Date
  updatedAt: Date
  createdBy?: string | null
  updatedBy: string
  isReceived: boolean
}

export type Device = {
  appointments?: Appointment[]
  scheduleTerm?: any
  scheduleTransmissions?: Array<any>
  id?: string | number
  manufacturer?: string
  vendor?: string
  model?: string
  modelName?: string | null
  modelNumber?: string | null
  serialNumber?: string
  diagnosisCode?: string
  type?: string
  implantDate?: string
  removalDate?: string
  implantingPhysician?: string
  notes?: string
  highRateEpisodesAlert?: boolean
  raLead?: string
  rvLead?: string
  lvLead?: string | null
  raSensing?: string
  rvSensing?: string
  lvSensing?: string
  raLeadImpedance?: string
  rvLeadImpedance?: string
  lvLeadImpedance?: string
  raPacingThreshold?: string
  rvPacingThreshold?: string
  lvPacingThreshold?: string
  raHighVoltageImpedance?: string[]
  rvHighVoltageImpedance?: string[]
  lvHighVoltageImpedance?: string[]
  raHighRateEpisodes?: string[]
  rvHighRateEpisodes?: string[]
  lvHighRateEpisodes?: string[]
  raAfBurden?: string
  rvAfBurden?: string
  lvAfBurden?: string
  raPercentPaced?: string
  rvPercentPaced?: string
  lvPercentPaced?: string
  batteryRemaining?: string
  chargeTime?: string
  longevity?: string
}

export type ScheduleEvent = {
  id: string | number
  name: string | null
  date: string
  time?: string
  location: string | null
}

export type Physician = {
  firstName: string
  lastName: string
}

export type Patient = {
  id?: string | number
  birthDate?: string
  givenName?: string
  familyName?: string
  sex?: any | null
  mrn1?: string
  mrn2?: string
  oac?: any
  updatedAt?: any
  rhythmId?: string
  chadsVasc?: number
  diagnosisCode?: string
  referringPhysician?: string
  monitorReason?: string
  diagnosisOption?: string
  implantingPhysician?: Physician
  followingPhysician?: Physician
  matchDevices?: Device[] | null
  priority?: string | null
  insuranceCarrier?: string | null
  transmissionSchedule?: string | null
  ejectionFraction?: number | [number, number] | null
  episodes?: string[]
  problems?: string[]
  medications?: string[]
  address?: any | null
  connectivityPercentage?: number
  lastConnectedDatetime?: string
  riskFactors?: {
    chf?: boolean
    hypertension?: boolean
    diabetes?: boolean
    stroke?: boolean
    vascularDisease?: boolean
  }
  alertHistory?: {
    status: TransmissionStatus
    date: string
    time?: string | null
    diagnosisCode?: string | null
    description?: string
    notes?: string | null
    isMissing?: boolean
  }[]
  deviceHistory?: Device[]
  notes?: PatientNote[]
  scheduleEvents?: ScheduleEvent[]
  PatientActiveInactiveStatus?: PatientActiveInactiveStatus[]
  patientStatus?: string | null
  statusReason?: string | null
  statusNotes?: string | null
  referringProvider?: RegisteredNpis | null
  icdDiagnosis?: IcdDiagnosisCode | null
}

export type PatientNote = {
  id: string | number
  author: User
  body: string
  createdDateTime: string
  isPinned?: boolean
}

export enum TramssionReportColor {
  RED = 'RED',
  GRAY = 'GRAY',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
}

export enum PatientActiveStatusEnum {
  All = 'All',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
  Paused = 'PAUSED',
  New = 'NEW',
  InProgress = 'IN PROGRESS',
  Closed = 'CLOSED',
  Candidate = 'CANDIDATE',
}

export const PatientStatusReasons = [
  'Empty',
  'Account - Account deactivated',
  'Account - Device type not monitored',
  'Billing - Program cost',
  'Compliance - Not responsive to outreach',
  "Compliance - Won't connect to home monitor",
  "Compliance - Won't transmit",
  'Device - End of service',
  'Device - Incompatible with remote monitoring',
  'Device - Unable to be remotely monitored',
  'Duplicate - Already Patient',
  'Eligibility - Insurance plan not covered',
  'Eligibility - Hospice',
  'Eligibility - Currently monitored elsewhere',
  'Eligibility - Moved location',
  'Eligibility - Moved, location not supported',
  'Eligibility - Incarcarated',
  'Eligibility - Unable to verify insurance',
  'Eligibility - Unable to verify demographics',
  'Medical - Phyician request',
  'Medical - Clinic request',
  'Medical - Deceased',
  'Medical - Explanted',
  'Patient Request - Refused monitoring',
  'Other',
  "Patient Request - Doesn't find value in monitoring",
  'RECEIVED PHYSICIAN ORDER FORM',
]

export type PatientActiveInactiveStatus = {
  status: string
  reason: string | null
  note: string | null
}

export type PatientTransmissionReport = {
  color: TramssionReportColor
  createdAt: string
  id: string
  fileName: string
  isPhysicianSigned: boolean
  orderStatus: string
  signatureStatus: TransmissionReportSignatureStatusEnum
  updatedAt: string
  editedUser: string
  editedDate: string
  transmissionType: string
  filesUploadStatus: TransmissionReportDtoFilesUploadStatus
  flagHeartFailure: boolean
  vtTransmisisonDate: string
  matchDevice?: MatchDevice
  billing?: Billing
}

export type Transmission = {
  id?: string
  date?: string
  completedBy?: string
  billingCode?: string
  status?: TransmissionStatus
  fileName?: string
  label?: string
  clinicianSignedDate?: string
  physicianSignedDate?: string
  patient?: Patient
  alerts?: NarrativeAlert[]
  carePlans?: CarePlan[]
  deviceMonitoredValuesNotes?: NarrativeNote[]
  deviceBatteryNotes?: NarrativeNote[]
  notes?: NarrativeNote[]
  signatures?: {
    label: string
    imageSrc: string
  }[]
  egmImageSrc?: string
  narrativeReportUrl?: string
  vendorReportUrl?: string
  deviceVendor?: VtVendor
  device?: VtDevice
}

export type NarrativeAlert = {
  status: TransmissionStatus
  label: string
  notes?: NarrativeNote[]
  imageSrc?: string
}

export type NarrativeNote = {
  id: string
  body: string
  isPinned?: boolean
}

export type CarePlan = {
  id: string
  author?: User
  body: string
  createdDateTime: string
  isPinned?: boolean
}

export enum AnalyticReportType {
  PRODUCTIVITY = 'productivity',
}

export type AnalyticReport = {
  title: string
  lastUpdatedDate: string
  type?: AnalyticReportType
  isBookmarked?: boolean
  chartImageSrc: string
}

export enum RiskLevels {
  LOW,
  MODERATE,
  HIGH,
}

export enum TimeOfDay {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
}

export enum DeviceLeadLocation {
  RA = 'RA',
  RV = 'RV',
  LV = 'LV',
}

export type VendorOption = {
  label: string
  url: string
  value: DeviceVendors
}

export type MatchDeviceTypeOption = {
  label: DeviceTypes
  value: DeviceTypes
}

export interface DeviceFormInputTypes {
  id: string
  vendorName: Omit<VendorOption, 'url'>
  vendorSite: string
  deviceModelNumber: string
  deviceSerialNumber: string
  deviceModelName: string
  deviceType: MatchDeviceTypeOption[]
}

// export type DeviceFormInputGroupProps = {
//   fields: Record<'id', string>[]
//   addField: UseFieldArrayReturn['append']
//   removeField: UseFieldArrayReturn['remove']
//   updateField: UseFieldArrayReturn['update']
//   methods: UseFormReturn
// }

export type DeviceFormInputGroupSectionProps = {
  index: number
  onRemove: () => void
  canRemove: boolean
  vendorOptions: VendorOption[]
  // errors: DeviceFormInputGroupProps['methods']['formState']['errors']
}

export type PatientFormInputTypes = {
  familyName: string
  givenName: string
  birthDate: string
  sex?: {
    label: string
    value: PatientSex
  }
  mrn1: string
  mrn2: string
  oac: any
  rhythmId: string
  diagnosisCode: string
  referringPhysician: string
  monitorReason: any
  diagnosisOption: any
  implantingPhysician: Physician
  followingPhysician: Physician
  priority: string
  insuranceCarrier: string
  address: string // AddressFormInputTypes
  ssn?: string
  age?: string
  status: PatientStatus
  transmissionSchedule?: string | null
  fileIds: string[]
  matchDevices: DeviceFormInputTypes[]
  practitioner: practitioner
}

export interface practitioner {
  label: string
  value: string
}

// export interface GlobalFilterValues {
//   userId: string
//   clinics: GlobalFilterClinicOptions
// }
// export interface GlobalFilterClinicOptions {
//   options: Array<{
//     clinicId: string
//     clinicName: string
//     organizationId: string
//     organizationName: string
//     isSelected: boolean
//   }>
// }

export interface OptionTypeGlobalFilter {
  id: string
  firstLineText: string
  secondLineText: string
  isSelected: boolean
}

export interface GlobalFilterSelectedItems {
  entity: string
  selectedIds: Array<string>
}

export enum TrackableEvents {
  ViewedSigned = 'Viewed, Signed',
  ViewedNotSigned = 'Viewed, Not Signed',
  EpisodesActivate = 'No Alert No Episodes Button, Activate',
  EpisodesDiactivate = 'No Alert No Episodes Button, Deactivate',
}

export interface CheckedPatients {
  patientId: string
  transmissionData: Array<TransmissionData>
}

export enum FlagType {
  NOT_SELECTED = 'NOT_SELECTED',
  NO_ALERT = 'NO_ALERT',
  HEART_FAILURE = 'HEART_FAILURE',
}

export enum FileUploadStoragePathEnum {
  TransmissionReportImage = 'transmission-reports-alerts-images',
  UserProfileImage = 'user-profile-image',
  OrganizationDocs = 'organization-docs',
  PatientDocs = 'patient-docs',
  Default = 'default', // change these accordingly
}
